import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import DataGrid from "../../Components/DataGrid"
import FETCH_TEAM_LOG from "./Gqls/FETCH_TEAM_LOG"
import { Box } from "@material-ui/core"
import getFilterSchema from "./Schemas/filterSchema"

const beforeSearchData = (data: any) => {
  const res: any = {}
  if (data.team) {
    res["team_id"] = data.team.id
  }
  res["orderBy"] = [
    {
      order: "DESC",
      column: "ID",
    },
  ]
  return res
}
const columns = [
  { headerName: "Team Change", field: "type_name", width: 150 },
  {
    headerName: "Current Team", field: "team", width: 150,
    renderCell: (params: any) => {
      if (!params.value) {
        return ''
      }
      return params.value.name
    }
  },
  {
    headerName: "User Name",
    field: "username",
    width: 120,
  },
  { headerName: "Apply Date", field: "at", width: 120 },
  { headerName: "Note", field: "note", width: 300 },
  { headerName: "Created At", field: "created_at", width: 140 },
]

const TeamLog = (_: RouteComponentProps) => {
  const filterSchema = React.useMemo(() => getFilterSchema(), [])
  return (
    <Box>
      <DataGrid
        title="TeamLogs"
        name="teamLogs"
        mode="local"
        filterSchema={filterSchema}
        beforeSearchData={beforeSearchData}
        searchGql={FETCH_TEAM_LOG}
        columnSchema={columns}
      />
    </Box>
  )
}

export default TeamLog
