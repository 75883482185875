import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"

const getUpdateSchema = () => {
  const schema = {
    fields: [
      {
        component: FieldTypes.SUB_FORM,
        name: "subform",
        sm: 12,
        ItemsGridProps: {
          className: "custom-form-border",
          spacing: 3,
          container: true
        },
        fields: [{
          component: FieldTypes.SUB_FORM,
          name: "subform1",
          sm: 4,
          fields: [
            {
              name: "slug",
              label: "Project ID",
              component: FieldTypes.TEXT_FIELD,
              isRequired: true,
              validate: [
                {
                  type: ValidatorTypes.REQUIRED,
                },
              ],
            },
            {
              name: "name",
              label: "Project Name",
              component: FieldTypes.TEXT_FIELD,
              isRequired: true,
              validate: [
                {
                  type: ValidatorTypes.REQUIRED,
                },
              ],
            },
            {
              name: "abbr",
              label: "Project Abbr",
              component: FieldTypes.TEXT_FIELD,
              isRequired: true,
              validate: [
                {
                  type: ValidatorTypes.REQUIRED,
                },
              ],
            },
            {
              name: "customer",
              label: "Customer",
              component: FieldTypes.TEXT_FIELD,
              isRequired: true,
              validate: [
                {
                  type: ValidatorTypes.REQUIRED,
                },
              ],
            },
            {
              name: "team",
              label: "Team",
              component: 'TeamSelectorField',
              isRequired: true,
              width: "100%",
              FormFieldGridProps: {
                xs:12
              },
              validate: [
                {
                  type: ValidatorTypes.REQUIRED,
                },
              ],
            },
            {
              name: "manager",
              label: "Manager Name",
              component: "UserSelectorField",
              isRequired: true,
              width: "100%",
              FormFieldGridProps: {
                container: true
              },
              validate: [
                {
                  type: ValidatorTypes.REQUIRED,
                },
              ],
            },
          ]
        },
        {
          component: FieldTypes.SUB_FORM,
          name: "subform2",
          sm: 8,
          fields: [
            {
              name: "projectUsers",
              label: "Project ID",
              component: 'ProjectMember',
              validate: [
                {
                  type: ValidatorTypes.REQUIRED,
                },
              ],
            }
          ]
        },],
      },

    ],
  }

  return schema
}

export default getUpdateSchema
