import { gql } from "@apollo/client"

const UPDATE_PASSWORD = gql`
  mutation UpdataPassword(
    $oldPassword: String!
    $newPassword: String!
    $rePassword: String!
  ) {
    updatePassword(
      oldPassword: $oldPassword
      newPassword: $newPassword
      rePassword: $rePassword
    ) {
      id
      name
    }
  }
`

export default UPDATE_PASSWORD