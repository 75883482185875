import { gql } from "@apollo/client"

const SEARCH_PROJECT_REPORT = gql`
  query ProjectReports(
    $year: String
    $quarter: String
    $month: String
    $project_name: String
    $project_slug: String
    $project_id: ID
    $type: Int!
    $team_id: ID
    $orderBy: [QueryProjectExportReportsOrderByOrderByClause!]
    $page: Int,
    $first: Int
  ) {
    projectExportReports(
      year: $year
      quarter: $quarter
      month: $month
      project_name: $project_name
      project_slug: $project_slug
      project_id: $project_id
      team_id: $team_id
      type: $type
      orderBy: $orderBy
      page: $page
      first: $first
    ) {
      data {
        id
        project {
          id
          slug
          name
          team {
            name
          }
        }
        year
        quarter
        month
        start_period
        end_period
        actual_working_hours
        project_name
        project_slug
        created_at
        updated_at
      }
      paginatorInfo {
        total
        currentPage
      }
    }
  }
`

export default SEARCH_PROJECT_REPORT