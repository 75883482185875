import React, { useMemo } from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import {
  Box,
  Button,
  IconButton,
  Input,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { useApolloClient } from "@apollo/client"
import UpdateOkr from "../Gqls/UPDATE_OKR_OBJECT"
import CreateOkr from "../Gqls/CREATE_OKR_OBJECT"
import { OkrObjectType, KeyResultType, OkrObjectDataType } from "../Types"
import { List, Map } from "immutable"
import KeyResult from "./keyResult"
import { useQueryParam, NumericArrayParam } from "use-query-params"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AccordionRoot: {
      boxShadow: "none",
    },
    AccordionSummaryRoot: {
      background: "#F4F6F8",
      "&.Mui-expanded": {
        minHeight: "48px",
      },
    },
    AccordionSummaryContent: {
      margin: "0",
      "&.Mui-expanded": {
        margin: "0",
      },
    },
    Chip: {
      background: "#D8291F",
      color: "#ffffff",
      height: 24,
    },
    InputTitle: {
      fontSize: 16,
      height: 32,
    },
    thead: {
      color: "#979FA7",
    },
    ButtonContained: {
      color: theme.palette.primary.main,
      backgroundColor: "#FDF4F4",
    },
    AccordionDetail: {
      border: "1px solid #ccc",
      paddingTop: 10,
      paddingBottom: 20,
    },
    progress: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "27px",
    },
  })
)

const createMap = (data: OkrObjectDataType) => {
  return Map({ ...data, keyResults: List(data.keyResults) })
}

export default function OkrObject({
  data,
  index,
  deleteObject,
  updateObject,
}: OkrObjectType) {
  const classes = useStyles()
  const [editAble, setEditAble] = React.useState(data.id ? false : true)
  const [expandList, setExpandList] = useQueryParam(
    "expanded",
    NumericArrayParam
  )
  const baseData = createMap(data)
  const [form, setForm] = React.useState<Map<string, any>>(baseData)
  const [oldForm, setOldForm] = React.useState<Map<string, any>>(baseData)

  const exist_ids = useMemo(() => {
    const ids: number[] = []
    oldForm.get("keyResults").forEach((v: any) => {
      if (v.id) {
        ids.push(v.id)
      }
    })
    return ids
  }, [oldForm])

  const isExpanded = useMemo(() => {
    const id = form.get("id")
    if (!id || expandList?.includes(parseInt(id))) {
      return true
    }
    return false
  }, [expandList, form])

  const progress = useMemo(() => {
    let len = form.get("keyResults").size
    if (len === 0) {
      return 0
    }
    let sum = 0
    form.get("keyResults").forEach((v: any) => {
      sum += v.progress
    })
    return (sum / len).toFixed()
  }, [form])
  const graphqlClient = useApolloClient()

  const validate = () => {
    const keyResults: any[] = []
    form.get("keyResults").forEach((v: any) => {
      const krv: any = { ...v, error: { content: "", progress: "" } }
      krv["error"]["content"] = krv.content ? "" : "KR can not be empty"
      krv["error"]["progress"] = ""
      if (krv.progress < 0 || krv.progress > 100) {
        krv["error"]["progress"] = "The progress range from 0% to 100%"
      }
      krv["error"]["progress"] = isNaN(krv.progress)
        ? "Progress must be number"
        : krv["error"]["progress"]
      keyResults.push(krv)
    })
    return form.set("keyResults", List(keyResults))
  }

  const handleAdd = () => {
    const kr = {
      content: "",
      progress: 0,
      key: Math.random(),
    }
    const keyResults = form.get("keyResults").push(kr)
    setForm(form.set("keyResults", keyResults))
    setEditAble(true)
  }
  const setkeyResults = (i: number, kr: any) => {
    const keyResults = form.get("keyResults").set(i, kr)
    const obj = form.set("keyResults", keyResults)
    setForm(obj)
    updateObject(index, obj.toJS())
  }
  const handlePublish = async () => {
    const myForm = validate() // 验证数据
    const updateKey: KeyResultType[] = []
    const createKey: KeyResultType[] = []
    const innerKey: number[] = []
    let hasError = false
    myForm.get("keyResults").forEach((v: any) => {
      if (v.error && (v.error.content || v.error.progress)) {
        hasError = true
      }
      if (!v.id) {
        createKey.push({ content: v.content, progress: v.progress })
      }
      if (v.id && exist_ids.includes(v.id)) {
        updateKey.push({ id: v.id, content: v.content, progress: v.progress })
      }
      if (v.id) {
        innerKey.push(v.id)
      }
    })
    if (hasError) {
      setForm(myForm)
      return
    }
    const deleteKey = exist_ids.filter((x) => !innerKey.includes(x))

    const input: any = {
      year: myForm.get("year"),
      quarter: myForm.get("quarter"),
      content: myForm.get("content"),
      keyResults: {
        create: createKey,
        update: updateKey,
        delete: deleteKey,
      },
    }
    !createKey.length && delete input["keyResults"]["create"]
    !updateKey.length && delete input["keyResults"]["update"]
    !deleteKey.length && delete input["keyResults"]["delete"]

    if (JSON.stringify(input.keyResults) === "{}") {
      delete input["keyResults"]
    }

    if (myForm.get("id")) {
      delete input["year"]
      delete input["quarter"]
      const { data } = await graphqlClient.mutate({
        mutation: UpdateOkr,
        variables: {
          input,
          id: myForm.get("id"),
        },
      })
      setEditAble(false)
      setForm(createMap(data.updateOkrObject))
      setOldForm(createMap(data.updateOkrObject))
      updateObject(index, createMap(data.updateOkrObject).toJS())
      return
    }

    const { data } = await graphqlClient.mutate({
      mutation: CreateOkr,
      variables: {
        input,
      },
    })
    setForm(createMap(data.createOkrObject))
    setOldForm(createMap(data.createOkrObject))
    updateObject(index, createMap(data.createOkrObject).toJS())
    handleExpand(data.createOkrObject.id, true)
    setEditAble(false)
  }
  const changeContent = (event: any) => {
    setForm(form.set("content", event.target.value))
  }
  const handleCancel = () => {
    setForm(oldForm)
    updateObject(index, oldForm.toJS())
    setEditAble(false)
  }
  const deleteKeyResult = (i: number) => {
    const keyResults = form.get("keyResults").delete(i)
    const obj = form.set("keyResults", keyResults)
    setForm(obj)
    updateObject(index, obj.toJS())
  }

  const handleExpand = (id: any, expand: boolean) => {
    if (id) {
      let arr: number[] = []
      if (expand) {
        arr = expandList ? [...expandList, id] : [id]
        const set = new Set(arr)
        arr = Array.from(set)
      } else {
        arr = []
        expandList?.forEach((v) => {
          if (v && parseInt(id) !== v) {
            arr.push(v)
          }
        })
      }
      setExpandList(arr)
    }
  }

  return (
    <Box mt={2}>
      <Accordion
        expanded={isExpanded}
        square={true}
        classes={{ root: classes.AccordionRoot }}
        onChange={(event, expand) => {
          const id = form.get("id")
          handleExpand(id, expand)
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          classes={{
            root: classes.AccordionSummaryRoot,
            content: classes.AccordionSummaryContent,
          }}
        >
          <Box display="flex" width="100%">
            <Box pl={2} display="flex" alignItems="center">
              <Chip
                label={"O" + (index + 1)}
                className={classes.Chip}
                size="small"
              />
            </Box>
            <Box
              display="flex"
              flexGrow={1}
              alignItems="center"
              onClick={(event) => editAble && event.stopPropagation()}
            >
              <Box flexGrow={1} ml={4} mr={8}>
                {editAble ? (
                  <Box>
                    <Input
                      placeholder="Type Object here"
                      onChange={changeContent}
                      classes={{
                        input: classes.InputTitle,
                      }}
                      defaultValue={form.get("content")}
                      fullWidth={true}
                    />
                  </Box>
                ) : (
                  <Box fontSize={16} color="#051629">
                    {form.get("content")}
                  </Box>
                )}
              </Box>

              <Box width={100}>
                <Box className={classes.thead} textAlign="center">
                  Progress
                </Box>
                <Box className={classes.progress}>{progress} %</Box>
              </Box>
              {editAble && (
                <Box width={100}>
                  <Box className={classes.thead} textAlign="center">
                    Operation
                  </Box>
                  <Box className={classes.progress}>
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => deleteObject(index)}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails className={classes.AccordionDetail}>
          <Box pl={15} width="100%">
            {form.get("keyResults").map((v: any, i: number) => {
              return (
                <KeyResult
                  editAble={editAble}
                  data={v}
                  key={v.id ? v.id : v.key}
                  index={i}
                  deleteKeyResult={deleteKeyResult}
                  setkeyResults={setkeyResults}
                />
              )
            })}
            <Box pt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                startIcon={<AddCircleOutlineIcon />}
              >
                Add Key Result
              </Button>

              {!editAble && (
                <Button
                  style={{ marginLeft: 8 }}
                  classes={{ contained: classes.ButtonContained }}
                  variant="contained"
                  onClick={() => setEditAble(true)}
                  startIcon={<EditIcon />}
                >
                  Edit My Okr
                </Button>
              )}
            </Box>
            {editAble && (
              <Box pt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handlePublish}
                >
                  Publish
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 8 }}
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}
