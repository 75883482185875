import { gql } from "@apollo/client"

const FETCH_ROLE = gql`
query AllRole{
  roles{
    id
    name
    menu
    is_default
  }
}
`

export default FETCH_ROLE