import { gql } from "@apollo/client"

const FETCH_ATTRITION_RATE = gql`
query DashboardAttritionRate($start: Date!,$end: Date!, $type: Int!, $abscissa: Int!) {
  DashboardAttritionRate(start: $start,end: $end, type: $type, abscissa: $abscissa) {
    name
    x
    y
  }
}
`
export default FETCH_ATTRITION_RATE