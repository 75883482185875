import React from "react"
import { Box } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      boxShadow: theme.shadows[1],
    },
    title: {
      textAlign: "center",
      fontSize: 16,
      color: theme.palette.text.hint,
    },
    content: {
      fontSize: 30,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  })
)
interface CardProps {
  title?: string
  content?: string
  children?: React.ReactNode
}

export default function SimpleCard(props: CardProps) {
  const classes = useStyles()
  return (
    <Box p={2} mb={2} className={classes.root}>
      <Box className={classes.title}>{props.title}</Box>
      <Box className={classes.content}>
        {props.children}
        <Box>{props.content}</Box>
      </Box>
    </Box>
  )
}
