import * as React from "react"
import { RouteComponentProps } from "@reach/router"

import CREATE_DATA from "./Gqls/CREATE_DATA"
import UPDATE_DATA from "./Gqls/UPDATE_DATA"
import FETCH_DATA from "./Gqls/FETCH_DATA"
import DELETE_DATA from "./Gqls/DELETE_DATA"
import columnSchema from "./Schemas/columnSchema"
import getCreateSchema from "./Schemas/createSchema"
import { useMemo } from "react"
import DataGrid from "../../Components/DataGrid"
import useSession from "../../Hooks/useSession"
import { Box, IconButton, Popover } from "@material-ui/core"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
const beforeFormSubmit = (data: any) => {
  const res: any = {}
  if (data.id) {
    res["id"] = data.id
  }
  if (data.name) {
    res["name"] = data.name
  }
  return res
}
const Hint = () => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handlePopoverClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton color="primary" onClick={handleClick}>
        <HelpOutlineIcon />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        open={open}
        onClose={handlePopoverClose}
        disableRestoreFocus
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>Item is for Bonus Allocation page</Box>
      </Popover>
    </>
  )
}
const TeamBuilding = (_: RouteComponentProps) => {
  const { can } = useSession()
  const createSchema = useMemo(() => getCreateSchema(), [])
  const updateSchema = useMemo(() => getCreateSchema(), [])
  return (
    <Box>
      <DataGrid
        leftButton={<Hint />}
        title="Item"
        name="teamBuildings"
        mode="local"
        searchGql={FETCH_DATA}
        createGql={can("team-building.create", CREATE_DATA)}
        updateGql={can("team-building.update", UPDATE_DATA)}
        deleteGql={can("team-building.delete", DELETE_DATA)}
        createSchema={can("team-building.create", createSchema)}
        updateSchema={can("team-building.update", updateSchema)}
        columnSchema={columnSchema}
        beforeCreateFormSubmit={beforeFormSubmit}
        beforeUpdateFormSubmit={beforeFormSubmit}
      />
    </Box>
  )
}

export default TeamBuilding
