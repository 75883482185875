import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import DataGrid from "../../Components/DataGrid"
import SEARCH_OPERATION_LOG from "./Gqls/SEARCH_OPERATION_LOG"
import columnSchema from "./Schemas/columnSchema"
import getFilterSchema from "./Schemas/filterSchema"
import { useMemo } from "react"
import moment from "moment"
import useSession from "../../Hooks/useSession"

const beforeSearchData = (data: any) => {
  const res: any = {}
  if (data["page"]) {
    res["page"] = data["page"]
  }
  if (data["first"]) {
    res["first"] = data["first"]
  }
  if (data["orderBy"]) {
    res["orderBy"] = data.orderBy
  }
  const AND: any = []
  if (data.start) {
    const start = moment(data.start).format("YYYY-MM-DD HH:mm:ss")
    const end = data.end
      ? moment(data.end).format("YYYY-MM-DD HH:mm:ss")
      : moment().format("YYYY-MM-DD HH:mm:ss")
    AND.push({
      column: "CREATED_AT",
      operator: "BETWEEN",
      value: [start, end],
    })
  }
  if (data.type) {
    AND.push({
      column: "MUTATION_ID",
      operator: "EQ",
      value: data.type.id,
    })
  }
  if (data.user_id) {
    AND.push({
      column: "USER_ID",
      operator: "EQ",
      value: data.user_id,
    })
  }
  if (AND.length > 0) {
    res.where = { AND }
  }
  return res
}

const OperationLog = (_: RouteComponentProps) => {
  const filterSchema = useMemo(() => getFilterSchema(), [])
  const { can } = useSession()

  return (
    <DataGrid
      title="OperationLog"
      name="newOperationLogs"
      exportUrl={can("operation-log.export", "/api/export/operation/log")}
      searchGql={SEARCH_OPERATION_LOG}
      filterSchema={filterSchema}
      columnSchema={columnSchema}
      beforeSearchData={beforeSearchData}
    />
  )
}

export default OperationLog
