import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"
import { Sites, Areas } from "./index"

const getCreateSchema = () => {
  const schema = {
    fields: [
      {
        component: FieldTypes.SUB_FORM,
        name: "subform",
        ItemsGridProps: {
          className: "custom-form-border",
          spacing: 3,
          container: true
        },
        sm: 12,
        fields: [
          {
            name: "training_name",
            label: "Training Title",
            component: FieldTypes.TEXT_FIELD,
            FormFieldGridProps: {
              xs: 12,
            },
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "area",
            label: "Area",
            component: FieldTypes.SELECT,
            isRequired: true,
            options: Areas,
            FormFieldGridProps: {
              xs: 4,
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
            getOptionSelected: (option: any, value: any) => option.value === value,
          },
          {
            component: FieldTypes.DATE_PICKER,
            label: "Date",
            name: "training_at",
            FormFieldGridProps: {
              xs: 4,
            },
            DatePickerProps: {
              format: "yyyy/MM/DD",
              clearable: true
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "host_site",
            label: "Host Site",
            component: FieldTypes.SELECT,
            options: Sites,
            isRequired: true,
            FormFieldGridProps: {
              xs: 4,
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
            getOptionSelected: (option: any, value: any) => option.value === value,
          },
          {
            name: "expense",
            label: "TBA Price/Person",
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            type: "number",
            FormFieldGridProps: {
              xs: 4,
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "satisfaction",
            label: "Satisfaction Rate (0-100)",
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            type: "number",
            FormFieldGridProps: {
              xs: 4,
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
              {
                type: ValidatorTypes.MIN_NUMBER_VALUE,
                includeThreshold: true,
                value: 0
              },
              {
                type: ValidatorTypes.MAX_NUMBER_VALUE,
                includeThreshold: true,
                value: 100
              },

            ],
          },
          {
            name: "during",
            label: "Duration (minutes)",
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            type: "number",
            FormFieldGridProps: {
              xs: 4,
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
              {
                type: ValidatorTypes.PATTERN,
                pattern: '^[0-9]+$',
                message: 'Duration must be integer'
              }
            ],
          },
          {
            component: FieldTypes.SUB_FORM,
            name: "subform1",
            sm: 12,
            fields: [
              {
                name: "trainingMember",
                component: 'TrainingMember',
                validate: [
                  (value: any) => {
                    // if (!value || !value.trainers || value.trainers.length === 0) {
                    //   return "Trainers is required"
                    // }
                    if (!value || !value.attendees || value.attendees.length === 0) {
                      return "Attendees is required"
                    }
                    return undefined
                  }
                ],
              },
            ]
          },
        ]
      }
    ],


  }

  schema.fields.forEach((field: any) => {
    if (field.component === "select") {
      const props = {
        margin: "none",
      }
      field.TextFieldProps = field.TextFieldProps ? {
        ...field.TextFieldProps,
        ...props
      } : props
    } else if (field.component === FieldTypes.DATE_PICKER) {
      const props = {
        margin: "none",
      }
      field.DatePickerProps = field.DatePickerProps ? {
        ...field.DatePickerProps,
        ...props
      } : props
    }

    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 12,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4,
      }
    }
  })

  return schema
}

export default getCreateSchema
