import { gql } from "@apollo/client"

const FETCH_OKR_OBJECT = gql`
query FetchOkrObjects($where: QueryOkrObjectsWhereWhereConditions) {
  okrObjects(where: $where) {
    id
    content
    progress
    year
    quarter
    user {
      id
      avatar
      name
      term
      underTeams{
        id
        name
      }
    }
    team {
      id
      name
    }
    keyResults {
      id
      content
      progress
      year
      quarter
      user {
        id
        avatar
        name
      }
      team {
        id
        name
      }
      created_at
      updated_at
    }
    created_at
    updated_at
  }
}

`
export default FETCH_OKR_OBJECT