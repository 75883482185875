export const createLineChart = (data: any[], per = false) => {
  const sortData = [...data].sort(function (a, b) {
    var nameA = a.name.toUpperCase(); // ignore upper and lowercase
    var nameB = b.name.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
  
    return 0;
  });
  const legendData = sortData.map(v => v.name);
  const xData = sortData[0].x;
  const seriesData = sortData.map((v, k) => {
    const obj: any = {
      name: v.name,
      type: 'line',
      data: v.y,
      // smooth: true
    }
    if (v.name === "Avg") {
      obj.lineStyle = {
        width: 2,
        type: 'dotted' //'dotted'虚线 'solid'实线
      };
    }
    return obj
  })
  const yAxis: any = {
    type: 'value',
    position: 'left',
    axisLine: {
      show: true,
    },
    min: function (value: any) {
      return Math.floor(value.min);
    },
    max: function (value: any) {
      return Math.ceil(value.max);
    }
  }
  if (per) {
    yAxis['axisLabel'] = {
      formatter: '{value} %'
    };
  }

  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let content = '<div style="font-weight:bold;padding-bottom:5px">' + params[0].axisValue + '</div>';
        params.sort(((a: any, b: any) => {
          return Number(b.value) - Number(a.value)
        }))
        params.forEach((v: any) => {
          if (per) {
            content += '<div style="display:flex;justify-content:space-between;line-height:30px"><div>' + v.marker + v.seriesName + ": </div><div style='padding-left:20px;text-align:right'>" + Number(v.value).toFixed(2) + '%</div></div>';
          } else {
            content += '<div style="display:flex;justify-content:space-between;line-height:30px"><div>' + v.marker + v.seriesName + ": </div><div style='padding-left:20px;text-align:right''>" + Number(v.value).toFixed(2) + '</div></div>';
          }
        })
        return content
      },
    },
    legend: {
      data: legendData,
      top: 0,
    },
    grid: {
      top: "70",
      left: '20',
      right: '25',
      bottom: '20',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xData
    },
    yAxis,
    series: seriesData
  };
}


// export const createLineChartTeam = (data: any[], name2avatar: any) => {
//   const legendData = data.map(v => v.name);
//   const xData = data[0].x;
//   const seriesData = data.map((v, k) => {
//     const obj: any = {
//       name: v.name,
//       type: 'line',
//       data: v.y,
//       markPoint: {
//         data: [
//           {
//             type: 'max',
//             name: 'Max',
//             symbolOffset: [0, 0],
//             // valueDim: 'x',
//             label: {
//               show: false
//             },
//             symbol: 'image://' + name2avatar[v.name]
//           }
//         ]
//       }
//     }

//     return obj
//   })
//   const yAxis: any = {
//     type: 'value',
//     position: 'left',
//     axisLine: {
//       show: true,
//     },
//     min: function (value: any) {
//       return Math.floor(value.min);
//     },
//     max: function (value: any) {
//       return Math.ceil(value.max);
//     }
//   }

//   yAxis['axisLabel'] = {
//     formatter: '{value} %'
//   };


//   return {
//     tooltip: {
//       trigger: 'axis',
//       formatter: (params: any) => {
//         let content = '<div>' + params[0].axisValue + '</div>';
//         params.sort(((a: any, b: any) => {
//           return Number(b.value) - Number(a.value)
//         }))
//         params.forEach((v: any) => {
//           content += '<div style="display:flex;justify-content:space-between"><div>' + v.marker + v.seriesName + ": </div><div style='padding-left:20px;text-align:right'>" + Number(v.value).toFixed(2) + '%</div></div>';
//         })
//         return content
//       },
//     },
//     legend: {
//       data: legendData,
//       top: 10,
//       right: 20,
//     },
//     grid: {
//       left: '20',
//       right: '20',
//       bottom: '20',
//       containLabel: true
//     },
//     xAxis: {
//       type: 'category',
//       boundaryGap: false,
//       data: xData
//     },
//     yAxis,
//     series: seriesData
//   };
// }
