import React from "react"
import { Box } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import release from "../Utils/release"

const Release = (_: RouteComponentProps) => {
  return (
    <Box>
      {release.map((v, i) => (
        <Box key={i} width={980} margin="0 auto" pb={4} borderBottom={1}>
          <Box fontSize={22} pt={4}>
            v{v.version} is released on {v.at}
          </Box>
          {v.items.map((vv, kk) => (
            <Box key={kk}>
              <Box fontSize={18} pt={2}>
                {vv.name}
              </Box>
              {vv.items.map((vvv, kkk) => {
                if (typeof vvv == "string") {
                  return (
                    <Box pt={2} key={vvv}>
                      {kkk + 1}. {vvv}
                    </Box>
                  )
                }
                return (
                  <Box>
                    {kkk + 1}. {vvv.title}
                    <ul style={{ margin: "5px 0" }}>
                      {vvv.items.map((v4) => (
                        <li key={v4}>{v4}</li>
                      ))}
                    </ul>
                  </Box>
                )
              })}
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  )
}

export default Release
