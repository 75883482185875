import qs from "qs"
import moment from "moment"
import { JsonParam, NumberParam, withDefault } from "serialize-query-params"
import { StringParam } from "use-query-params"

export const globalYear = () => {
  const year = localStorage.getItem("GLOBAL_YEAR");
  return year ? year : moment().format("YYYY");
}
export const globalQuarter = () => {
  const quarter = localStorage.getItem("GLOBAL_QUARTER");
  return quarter ? quarter : moment().quarter() + ""
}

export const downloadUrl = async (url: string, params?: any) => {
  const token = localStorage.getItem("token") || sessionStorage.getItem("token")
  const ext = '?' + qs.stringify({ ...params, token })
  const baseUrl = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST.slice(0, -8) : "";
  window.open(baseUrl + url + ext, '_blank')
}

export const restfulUrl = (url: string) => {
  const token = localStorage.getItem("token") || sessionStorage.getItem("token")
  const ext = '?token=' + token;
  const baseUrl = process.env.REACT_APP_API_HOST ? process.env.REACT_APP_API_HOST.slice(0, -8) : "";
  return baseUrl + url + ext;
}
export const DefaultYear = withDefault(StringParam, moment().format("YYYY"))
export const DefaultQuarter = withDefault(StringParam, moment().quarter() + "")
export const DefaultNumberZero = withDefault(NumberParam, 0)
export const DefaultNumberOne = withDefault(NumberParam, 1)
export const DefaultOrderBy = withDefault(JsonParam,
  [
    {
      column: "ID",
      order: "DESC",
    },
  ],
)
export const DefaultPage = withDefault(NumberParam, 0);
export const DefaultPageSize = withDefault(NumberParam, 25);


const YearType = ['All', 'First half', 'Second half', 'Q1', 'Q2', 'Q3', 'Q4'];
export const YearTypeOperations = YearType.map((v, k) => {
  return {
    label: v,
    value: k + 1,
  }
})

export const fillVariable = (variable:any, defaultValue: any = '') => {
  return variable ? variable : defaultValue
}

export const Type2DateRange = (year: string, type: number) => {
  let range: any = [];
  switch (type) {
    case 1:
      range = ["01-01", "12-31"] // 整年
      break
    case 2:
      range = ["01-01", "06-30"] // 上半年
      break
    case 3:
      range = ["07-01", "12-31"] // 下半年
      break
    case 4:
      range = ["01-01", "03-31"] // 一季度
      break
    case 5:
      range = ["04-01", "06-30"] // 二季度
      break
    case 6:
      range = ["07-01", "09-30"] // 三季度
      break
    case 7:
      range = ["10-01", "12-31"] // 四季度
      break
    default:
      return [];
  }
  return [year + "-" + range[0], year + "-" + range[1]];
}



export function containSpecial(s: string) {
  var containSpecial = new RegExp("[`~!@#$^&*()=|{}':;',\\[\\].<>/?~！@#￥……&*（）——|{}【】‘；：”“'。，、？]")
  return containSpecial.test(s);
}



export function getParams(url: string) {
  const res: any = {}
  if (url.includes('?')) {
    const str = url.split('?')[1]
    const arr = str.split('&')
    arr.forEach(item => {
      const key = item.split('=')[0]
      const val = item.split('=')[1]
      res[key] = decodeURIComponent(val) // 解码
    })
  }
  return res
}




/**
 * 生成表格
 */
export function generateMdTable(row = 2, col = 1) {
  //base
  const row_base = `|      `;
  const separator_base = `| ---- `;

  let render_separator = '';
  for (let i = 0; i < col; i++) {
    render_separator += separator_base;
  }
  render_separator += '|\n';

  let render_row = '';
  for (let i = 0; i < col; i++) {
    render_row += row_base;
  }
  render_row += '|\n';
  let renderRes = render_row + render_separator;
  for (let i = 1; i < row; i++) {
    renderRes += render_row;
  }

  return renderRes;
}