import React, { useCallback } from "react"
import { DataFilterProps } from "./Types"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Form from "./Form"

const templeteProps = {
  submitLabel: "Search",
  resetLabel: "Reset",
  canReset: true,
}

const DataFilter = ({
  filterSchema,
  onFilterChanged,
  slotHeader,
  templeteProps,
  title,
}: DataFilterProps) => {
  // 修改过滤条件
  const filterChangedHandler = useCallback(
    (filter) => onFilterChanged(filter),
    [onFilterChanged]
  )

  // 重置过滤条件
  const filterResetedHandler = useCallback(
    () => onFilterChanged({}),
    [onFilterChanged]
  )

  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {title}
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        {slotHeader ? slotHeader : null}
        <Form
          schema={filterSchema}
          onSubmit={filterChangedHandler}
          onReset={filterResetedHandler}
          templateProps={templeteProps}
        />
      </AccordionDetails>
    </Accordion>
  )
}

DataFilter.defaultProps = {
  templeteProps,
  title: "Search",
}

export default DataFilter
