import { Schema } from "@data-driven-forms/react-form-renderer"


const getFilterSchema = () => {
  const filterSchema: Schema = {
    fields: [
      {
        name: "team",
        label: "Team",
        component: "TeamSelectorField",
        width: "100%",
      },
    ],

  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}

export default getFilterSchema
