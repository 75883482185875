import { gql } from "@apollo/client"

const UPLOAD_REPORT = gql`
  mutation singleUpload($file: Upload!, $override: Boolean) {
    uploadProjectReport(file: $file, override:$override) {
      code
      message
      key
      report{
        id
        project_slug
        start_period
        end_period
      }
    }
  }
`
export default UPLOAD_REPORT