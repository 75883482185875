import * as React from "react"
import { Box } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import useKpiArchive from "../../../Hooks/useKpiArchive"
import { useQueryParams } from "use-query-params"
import Filter from "../../../Components/Filter"
import { DataGrid } from "@material-ui/data-grid"
import useKpiSetup from "../../../Hooks/useKpiSetup"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"

const columnSchema = [
  {
    headerName: "Team Name",
    field: "team_name",
    width: 150,
  },
  {
    headerName: "Current Ranking",
    field: "ranking",
    width: 150,
  },
]

const Archive = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const { kpiArchive } = useKpiArchive()
  const { kpiSetup } = useKpiSetup()
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const list = React.useMemo(() => {
    if (!kpiArchive) {
      return []
    }
    const res: any[] = []
    kpiArchive.content.forEach((v: any) => {
      const obj: any = {
        id: v.team_id,
        team_name: v.team_name,
        ranking: v.ranking,
      }
      v.content.forEach((vv: any) => {
        const ext = vv.value_type === "Percentage" ? "%" : ""
        obj[vv.column] = vv.value + ext
      })
      res.push(obj)
    })
    return res
  }, [kpiArchive])

  const columnSchemaRes = React.useMemo(() => {
    if (!(kpiSetup && kpiSetup.content)) {
      return [...columnSchema]
    }
    const column = [...columnSchema]
    kpiSetup.content.forEach((v: any) => {
      column.push({
        headerName: v.label,
        field: v.column,
        width: 150,
      })
    })
    return column
  }, [kpiSetup])
  return (
    <Box m="2%">
      <Box>
        <Filter data={query} handleSubmit={(value: any) => setQuery(value)} />
      </Box>
      <Box pt={2}>
        <DataGrid
          headerHeight={52}
          rowHeight={32}
          autoHeight
          disableColumnMenu={true}
          hideFooterPagination
          columns={columnSchemaRes}
          rows={list}
        />
      </Box>
    </Box>
  )
}
export default Archive
