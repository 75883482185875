import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react"

const ALL_ROLES = gql`
query AllRoles{
  allRoles{
    id
    is_default
    name
  }
}
`

const useAllRoles = () => {
  const { data, loading, error } = useQuery(ALL_ROLES, {
    fetchPolicy: "cache-and-network",
  })
  const roles = useMemo(() => {
    return loading || error ? [] : data.allRoles;
  }, [loading, data, error])

  return { roles, loading }
}

export default useAllRoles