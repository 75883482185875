import { gql } from "@apollo/client"

const PROJECT_DASHBOARD = gql`
 query DiagnosticAll($show: Boolean) {
  DiagnosticAll(show: $show) {
    name
    id
    reference
    value
  }
}
`

export default PROJECT_DASHBOARD