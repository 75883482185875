import React, { useState } from "react"
import MDEditor from "@uiw/react-md-editor"
import { navigate, RouteComponentProps } from "@reach/router"
import {
  Box,
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
} from "@material-ui/core"
import customCommands from "./commands"
import { useMutation, useQuery } from "@apollo/client"
import UPDATE_FILE from "./Gqls/UPDATE_FILE"
import FIND_FILE from "./Gqls/FIND_FILE"
import CREATE_FILE from "./Gqls/CREATE_FILE"
import { FILE_STATUS } from "../../Utils/config"

interface FileSystemEditorProps extends RouteComponentProps {
  id?: String
}

const FileSystemEditor = (props: FileSystemEditorProps) => {
  const id = props.id ?? 0
  // 更新
  const [updateFile] = useMutation(UPDATE_FILE)
  const [createFile] = useMutation(CREATE_FILE)
  const [status, setStatus] = useState(0)
  const [height] = React.useState(() => window.innerHeight - 150)
  const [content, setContent] = React.useState<any>("")
  const [filename, setFilename] = React.useState<any>("")
  const { data, loading } = useQuery(FIND_FILE, {
    variables: { id },
    fetchPolicy: "network-only",
  })

  React.useEffect(() => {
    // const ss = document.getElementsByTagName("svg")
    const tool = document.querySelectorAll(".w-md-editor-toolbar ")

    if (tool && tool[0]) {
      for (let i = 0; i < tool[0].children.length; i++) {
        const v = tool[0].children[i]
        for (let j = 0; j < v.children.length; j++) {
          const vv = v.children[j]
          const el = vv.firstChild?.firstChild
          if (el) {
            // @ts-ignore
            el.setAttribute("width", 16)
            // @ts-ignore
            el.setAttribute("height", 16)
          }
        }
      }
    }
    if (!data || !data.file) {
    } else {
      setStatus(data.file.status)
      setFilename(data.file.filename)
      setContent(data.file.content)
    }
  }, [data])

  const handleSubmit = async () => {
    if (id) {
      await updateFile({
        variables: {
          id,
          input: {
            filename,
            content,
            status,
          },
        },
      })
      navigate("/document")
      return
    }
    await createFile({
      variables: {
        input: {
          filename,
          content,
          status,
        },
      },
    })

    navigate("/document")
  }

  if (loading) {
    return <CircularProgress />
  }

  return (
    <Box p={2}>
      <Box mb={2} display={"flex"}>
        <Box flexGrow={1}>
          <TextField
            fullWidth
            placeholder="Document Title"
            value={filename}
            onChange={(e) => {
              setFilename(e.target.value)
            }}
          />
        </Box>
        <Box ml={2} width={100}>
          <Select
            fullWidth
            name="select_status"
            value={status}
            onChange={(e:any) => {
              setStatus(e.target.value)
              console.log(e.target.value)
            }}
          >
            {FILE_STATUS.map((v,k) => (
              <MenuItem key={k} value={k}>
                {v}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box ml={2}>
          <Button
            style={{ width: 100 }}
            variant="contained"
            disableElevation
            size="small"
            color={"primary"}
            onClick={handleSubmit}
          >
            {"Save"}
          </Button>
        </Box>

        <Box ml={2}>
          <Button
            style={{ width: 100 }}
            variant="contained"
            disableElevation
            size="small"
            onClick={() => navigate("/document")}
          >
            {"Cancel"}
          </Button>
        </Box>
      </Box>
      <Box id="mdBox">
        <MDEditor
          toolbarHeight={50}
          value={content}
          onChange={setContent}
          height={height}
          commands={customCommands}
        />
      </Box>
    </Box>
  )
}

export default FileSystemEditor
