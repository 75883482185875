import { useMutation, useQuery } from "@apollo/client"
import { Box, CircularProgress } from "@material-ui/core"
import { navigate, RouteComponentProps } from "@reach/router"
import DataEditor from "../../Components/DataGrid/DataEditor"
import FIND_USER from "../User/Gqls/FIND_USER"
import UPDATE_USER_ROLE from "./Gqls/UPDATE_USER_ROLE"
import getUpdateeSchema from "./Schemas/updateAssignmentSchema"

interface RoleAssignmentEditorProps extends RouteComponentProps {
  id?: String
}
const AssignmentEditor = (props: RoleAssignmentEditorProps) => {
  const id = props.id
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE)
  const schema = getUpdateeSchema()
  const { data, loading } = useQuery(FIND_USER, {
    variables: { id },
    fetchPolicy: "network-only",
  })
  const handleSubmit = async (e) => {
    await updateUserRole({
      variables: {
        id,
        roles: { sync: e.roles.map((v) => v.id) },
      },
    })
    navigate("/role/assignment")
    return
  }
  if (loading) {
    return <CircularProgress />
  }

  return (
    <Box m={"2%"}>
      <h1>Assignment Role </h1>
      <DataEditor
        data={data.user}
        schema={schema}
        onSubmit={handleSubmit}
        onCancel={() => window.history.back()}
      />
    </Box>
  )
}
export default AssignmentEditor
