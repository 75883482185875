import * as React from "react"
import { Box } from "@material-ui/core"
import { DataGrid } from "@material-ui/data-grid"
import { RouteComponentProps } from "@reach/router"
import FETCH_KPI_BONUS_LOGS from "../Gqls/FETCH_KPI_BONUS_LOGS"
import { useQuery } from "@apollo/client"
import UnderTeamsSelect from "../../../Components/UnderTeamsSelect"
import { StringParam, useQueryParam } from "use-query-params"
import useUserInfo from "../../../Hooks/useUserInfo"

const columns = [
  {
    headerName: "Items",
    field: "type",
    width: 150,
  },
  {
    headerName: "At",
    field: "at",
    width: 150,
    renderCell: (params: any) => {
      return params.row.year + " Q" + params.row.quarter
    },
  },
  {
    headerName: "Amount",
    field: "amount",
    width: 150,
    renderCell: (params: any) => {
      if (params.row.amount > 0) {
        return "+" + params.row.amount
      }
      return params.row.amount
    },
  },
  {
    headerName: "Operator",
    field: "content",
    width: 300,
    renderCell: (params: any) => {
      return params.row.user.name
    },
  },
  {
    headerName: "Time",
    field: "created_at",
    width: 250,
  },
  { headerName: "Remaining Balance", field: "remaining_balance", width: 200 },
]

const MyTeam = (_: RouteComponentProps) => {
  const { user } = useUserInfo()
  const [teamId] = useQueryParam("team_id", StringParam)
  const underTeams = user?.underTeams
  let team_id = teamId ? teamId : user?.myTeam.id
  if (!teamId && underTeams && underTeams.length > 0) {
    team_id = teamId ? teamId : underTeams[0].id
  }
  const { data, loading } = useQuery(FETCH_KPI_BONUS_LOGS, {
    fetchPolicy: "cache-and-network",
    variables: {
      team_id,
    },
  })
  const rows = React.useMemo(() => {
    if (!data) {
      return []
    }
    const arr = [...data.kpiBonusLogs]
    // 计算 Remaining
    // 1.先按照year+quarter 排序
    const sortData = arr.sort(
      (a: any, b: any) =>
        Number(a.year + "" + a.quarter) - Number(b.year + "" + b.quarter)
    )
    // 2.添加 Remaining
    const res: any[] = []
    let remaining_balance = 0
    sortData.forEach((v) => {
      remaining_balance += v.amount * 100
      res.push({
        ...v,
        amount: v.amount.toFixed(2),
        remaining_balance: (remaining_balance / 100).toFixed(2),
      })
    })
    return res
  }, [data])
  const balance = React.useMemo(() => {
    if (!data) {
      return 0
    }
    let sum = 0
    data.kpiBonusLogs.forEach((v: any) => {
      sum += v.amount
    })
    return sum
  }, [data])
  return (
    <Box>
      <Box m="2%">
        <UnderTeamsSelect />
        <Box pt={2}>
          <DataGrid
            density={"compact"}
            disableColumnMenu={true}
            hideFooterPagination
            hideFooterRowCount
            hideFooterSelectedRowCount
            loading={loading}
            autoHeight
            rows={rows}
            columns={columns}
            components={{
              Toolbar: () => (
                <Box p={4} fontSize="14px">
                  Team Bonus Balance: {balance}
                </Box>
              ),
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default MyTeam
