import { gql, useMutation } from "@apollo/client"
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core"
// import { toast } from "material-react-toastify"
import React from "react"

export const SEND_RECOVERY_MAIL = gql`
  mutation SendRecoveryMail($email: String!) {
    passwordRecovery(email:$email){
      id
      name
    }
  }
`
const ResetDialog = ({ show, setShow }: any) => {
  const [email, setEmail] = React.useState("")
  const [error, setError] = React.useState("")

  const [callResetApi, { loading }] = useMutation(SEND_RECOVERY_MAIL, {
    onCompleted(res) {
      if (res.passwordRecovery) {
        // toast.success("An authentication email has been sent!", {
        //   position: "top-center",
        //   autoClose: 3000,
        // })
        setShow(false)
      }
    },
    onError(error) {
      setError("The email address doesn't exist in the system.");
      console.log(error)
    },
  })

  const handleClose = () => {
    setShow(false)
  }
  const handleSubmit = () => {
    setError("");
    callResetApi({ variables: { email } })
  }

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Reset password</DialogTitle>
      <DialogContent>
        <DialogContentText>
          We will send a verification email to your mailbox. You need to click
          the verification link in that email to reset your password. Please
          confirm.
        </DialogContentText>
        <TextField
          autoFocus
          error={error !== ""}
          helperText={error}
          margin="dense"
          label="Email Address"
          type="email"
          onChange={(e: any) => {
            setEmail(e.target.value)
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Confirm
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ResetDialog
