import { gql } from "@apollo/client"

const SEARCH_PROJECT = gql`
  query Projects(
    $name: String
    $slug: String
    $first: Int!
    $team_id: ID
    $manager_id: ID
    $page: Int!
    $orderBy: [QueryProjectsOrderByOrderByClause!]
  ) {
    projects(
      name: $name
      slug: $slug
      manager_id: $manager_id
      team_id: $team_id
      first: $first
      page: $page
      orderBy: $orderBy
    ) {
      data {
        id
        slug
        name
        abbr
        customer
        team_id
        manager_id
        manager {
          id
          name
        }
        team {
          id
          name
        }
        remainingPO
        projectUsers {
          id
          user {
            id
            name
            slug
            abbr
          }
          unit_price
        }
        created_at
        updated_at
      }
      paginatorInfo {
        total
        currentPage
      }
    }
  }
`

export default SEARCH_PROJECT