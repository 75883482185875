import { gql } from "@apollo/client"

const UPDATE_PROJECT_PURCHASE = gql`
  mutation UpgradeTimePeriodFile(
    $id: ID
    $name: String!
    $data: [TimePeriodItems!]
  ) {
    upgradeTimePeriodFile(id: $id, name: $name, data: $data) {
      code
      message
    }
  }
`
export default UPDATE_PROJECT_PURCHASE