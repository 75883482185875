import * as React from "react"
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api"
import { USER_JOB_LEVEL_OPTIONS } from "../../../Utils/config"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"

const JobLevelField = (props: any) => {
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta
  const { value, onChange } = input
  const { label } = props
  const { getFieldState } = useFormApi()
  const [show, setShow] = React.useState(false)
  React.useEffect(() => {
    const relation = getFieldState("job_title")
    const job_title = relation?.value
    setShow(job_title === "Consultant")
  }, [props, getFieldState])

  return show ? (
    <Grid item {...props.FormFieldGridProps}>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select label={label} value={value} onChange={onChange}>
          {USER_JOB_LEVEL_OPTIONS.map((v) => (
            <MenuItem key={v.value} value={v.value}>
              {v.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {submitFailed && error && <Box color="primary.main">{error}</Box>}
    </Grid>
  ) : null
}

export default JobLevelField
