import React from "react"
import Tooltip from '@material-ui/core/Tooltip';
const columnSchema = [
  { headerName: "Project ID", field: "slug", width: 108 },
  { headerName: "Project Name", field: "name", width: 280 },
  { headerName: "Project Abbr", field: "abbr", width: 120 },
  { headerName: "Customer", field: "customer", width: 108 },
  {
    headerName: "Remaining PO", field: "remainingPO", width: 120, sortable: false, renderCell: (params: any) => {
      return React.createElement("div", {
        style: {
          textAlign: 'right',
          width: "100%"
        }
      }, params.value.toFixed(2))
    }
  },
  {
    headerName: "Team", field: "team_id", width: 120, renderCell: (params: any) => {
      if (params.row.team) {
        return params.row.team.name
      }
      return ' ';
    }
  },
  {
    headerName: "Manager", field: "manager_id", width: 120, renderCell: (params: any) => {
      if (params.row.manager) {
        return params.row.manager.name
      }
      return ' ';
    }
  },
  {
    headerName: "Project Members",
    field: "projectUsers",
    width: 250,
    sortable: false,
    renderCell: (params: any) => {
      const label = params.value.map((item: any) => item.user.abbr.toUpperCase()).join(",")
      const title = params.value.map((item: any) => item.user.abbr.toUpperCase() + "(" + item.user.name + ")").join(",")
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      }, label)
      return React.createElement(Tooltip, { title, children });
    }
    // cellClassName: () => 'table-cell--scroll',
  },
]

export default columnSchema
