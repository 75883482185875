import { gql } from "@apollo/client"

const FIND_FILE = gql`
query FindFile($id: ID) {
  file(id: $id) {
    id
    status
    filename
    content
  }
}
`

export default FIND_FILE