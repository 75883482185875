import * as React from "react"
import { navigate, RouteComponentProps } from "@reach/router"

import CREATE_ROLE from "./Gqls/CREATE_ROLE"
import UPDATE_ROLE from "./Gqls/UPDATE_ROLE"
import FETCH_ROLE from "./Gqls/FETCH_ROLE"
import DELETE_ROLE from "./Gqls/DELETE_ROLE"
import columnSchema from "./Schemas/columnSchema"
import getCreateSchema from "./Schemas/createSchema"
import getUpdateeSchema from "./Schemas/updateSchema"
import { useMemo } from "react"
import DataGrid from "../../Components/DataGrid"
import useSession from "../../Hooks/useSession"
import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"
import RightField from "./Field/RightField"
import MenuField from "./Field/MenuField"
import { Button } from "@material-ui/core"

ComponentMapper["RightField"] = RightField
ComponentMapper["MenuField"] = MenuField

const beforeFormSubmit = (data: any) => {
  return data
}

const Role = (_: RouteComponentProps) => {
  const { can } = useSession()
  const createSchema = useMemo(() => getCreateSchema(), [])
  const updateSchema = useMemo(() => getUpdateeSchema(), [])
  return (
    <DataGrid
      leftButton={
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={() => navigate("/role/assignment")}
        >
          Role Assignment
        </Button>
      }
      title="Role"
      name="roles"
      mode="local"
      rowHeight={440}
      searchGql={FETCH_ROLE}
      createGql={can("role.create", CREATE_ROLE)}
      updateGql={can("role.update", UPDATE_ROLE)}
      deleteGql={can("role.delete", DELETE_ROLE)}
      createSchema={can("role.create", createSchema)}
      updateSchema={can("role.update", updateSchema)}
      columnSchema={columnSchema}
      beforeCreateFormSubmit={beforeFormSubmit}
      beforeUpdateFormSubmit={beforeFormSubmit}
    />
  )
}

export default Role
