import React from "react"
import MDEditor from "@uiw/react-md-editor"
import { RouteComponentProps } from "@reach/router"
import { Box, CircularProgress, Paper } from "@material-ui/core"
import { useQuery } from "@apollo/client"
import FIND_FILE from "./Gqls/FIND_FILE"

interface FileSystemEditorProps extends RouteComponentProps {
  id?: String
}

const FileSystemView = (props: FileSystemEditorProps) => {
  const id = props.id
  const { data, loading } = useQuery(FIND_FILE, {
    variables: { id },
    fetchPolicy: "network-only",
  })

  if (loading) {
    return <CircularProgress />
  }

  if (!data.file) {
    return <Box>file not found</Box>
  }

  return (
    <Box p={4}>
      <Paper>
        <Box p={4}>
          <MDEditor.Markdown source={data.file.content} />
        </Box>
      </Paper>
    </Box>
  )
}

export default FileSystemView
