import { makeOptions } from "../../../Utils/config"

// const makeKvOptions = (data: Array<string>) => data.map((item, index) => ({
//   label: item,
//   value: index + ''
// }))
export const Areas = makeOptions(["CPI process & tool", "CPI development", "Soft skill", "Product Knowledge", "English", "Industry trends"])
export const Sites = makeOptions(["Beijing", "Shanghai", "Guangzhou"])
// export const Platforms = makeKvOptions(["Internal", "得到", "Python"])
export const Platforms = [{
  label: "得到",
  value: 1,
},
{
  label: "Python",
  value: 2,
}]