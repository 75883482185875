import * as React from "react"
import { DataTableLocalProps } from "./Types"
import {
  DataGrid as Table,
  GridCellParams as TableCellParams,
} from "@material-ui/data-grid"
import Button from "@material-ui/core/Button"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import Tooltip from "@material-ui/core/Tooltip"
import { Box } from "@material-ui/core"

const DataTableLocal = ({
  columnSchema,
  name,
  height,
  loading,
  data,
  onRowEdit,
  onRowDelete,
  ...rest
}: DataTableLocalProps) => {
  const columns = React.useMemo(() => {
    const myColumnSchema = columnSchema.map((v) => {
      if (v.renderCell) {
        return v
      }
      v.renderCell = (params: any) => {
        const title = params.value
        if (!title) {
          return title
        }
        const children = React.createElement("div", {}, title)
        return React.createElement(Tooltip, { title, children })
      }
      return v
    })

    if (!onRowEdit && !onRowDelete) {
      return myColumnSchema
    }

    const renderEditButton = (params: any) => {
      return (
        <Button
          data-id={params.id}
          color="primary"
          size="small"
          style={{ marginRight: 5 }}
          onClick={() => onRowEdit && onRowEdit(params.row)}
        >
          <EditIcon />
        </Button>
      )
    }

    const renderDeleteButton = (params: any) => {
      return (
        <Button
          color="primary"
          size="small"
          onClick={() => onRowDelete && onRowDelete(params.row)}
        >
          <DeleteIcon />
        </Button>
      )
    }
    return [
      {
        headerName: "Operate",
        width: 80,
        field: "__action",
        sortable: false,
        renderCell: (params: TableCellParams) => (
          <>
            {onRowEdit && renderEditButton(params)}
            {onRowDelete && renderDeleteButton(params)}
          </>
        ),
      },
      ...myColumnSchema,
    ]
  }, [columnSchema, onRowEdit, onRowDelete])
  const components = React.useMemo(() => {
    if (name === "TbaTrainings" && data) {
      let sum = 0
      data.forEach((v) => {
        sum += v.earning
      })
      return {
        Toolbar: () => (
          <Box p={4} fontSize="14px">
            Earned Fund Sub Total: {sum.toFixed(2)}
          </Box>
        ),
      }
    }
    return {}
  }, [name, data])

  return (
    <>
      <Table
        autoHeight
        loading={loading}
        density={"compact"}
        rows={loading ? [] : data}
        columns={columns}
        components={components}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
        {...rest}
      />
    </>
  )
}

export default DataTableLocal
