import React from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { gql, useMutation } from "@apollo/client"

import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core"
import useSession from "../Hooks/useSession"
import { containSpecial } from "../Utils"
import { toast } from "material-react-toastify"

export const CHANGE_PASS = gql`
  mutation ResetPassword($newPassword: String!, $rePassword: String!) {
    needChangePassword(newPassword: $newPassword, rePassword: $rePassword) {
      id
      name
    }
  }
`

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    btn: {
      width: "200px",
    },
    highlight: {
      color: theme.palette.primary.main,
    },
    normal: {
      color: "green",
    },
    field: {
      width: "300px",
    },
    formBox: {
      padding: 20,
    },
  })
})
const NeedChangePass = (_: RouteComponentProps) => {
  const classes = useStyles()
  const { session } = useSession()
  const [password, setPassword] = React.useState<string>("")
  const [rePassword, setRePassword] = React.useState<string>("")

  const [callResetApi, { loading }] = useMutation(CHANGE_PASS)
  const handleSubmit = (e: any) => {
    e.preventDefault()
    if (loading) {
      return
    }
    callResetApi({ variables: { newPassword: password, rePassword } })
      .then((res) => {
        console.log(res)
        if (res.data) {
          toast.success("Password changed successfully!", {
            position: "top-center",
            autoClose: 3000,
          })
          setTimeout(() => {
            navigate("/login")
          }, 3000)
        }
      })
      .catch((e: any) => {
        console.log(e)
      })
  }
  const validateLen = React.useMemo(() => {
    if (password.length < 8) {
      return false
    }
    return true
  }, [password])
  const validateInUser = React.useMemo(() => {
    if (!session) return true
    if (password.indexOf(session.name) > -1) {
      return false
    }
    return true
  }, [password, session])
  const validateContainSpecial = React.useMemo(() => {
    if (containSpecial(password)) {
      return 1
    }
    return 0
  }, [password])

  const validateUpcase = React.useMemo(() => {
    if (/[A-Z]/.test(password)) {
      return 1
    }
    return 0
  }, [password])

  const validateLowcase = React.useMemo(() => {
    if (/[a-z]/.test(password)) {
      return 1
    }
    return 0
  }, [password])

  const validateNumber = React.useMemo(() => {
    if (/[0-9]/.test(password)) {
      return 1
    }
    return 0
  }, [password])
  const validateLeast = React.useMemo(() => {
    if (
      validateContainSpecial +
        validateUpcase +
        validateLowcase +
        validateNumber <
      3
    ) {
      return false
    }
    return true
  }, [validateContainSpecial, validateUpcase, validateLowcase, validateNumber])

  const validatePassword = React.useMemo(() => {
    if (!password) return ""
    if (!validateLen) {
      return "At least 8 characters"
    }
    if (!validateLeast) {
      return "At Least 3 out of 4"
    }
    if (!validateInUser) {
      return "Not containing your name or username"
    }
    return ""
  }, [password, validateInUser, validateLen, validateLeast])

  const validateSame = React.useMemo(() => {
    if (!password) return ""
    if (password === rePassword) return ""
    return "Different passwords"
  }, [password, rePassword])

  const disabled = React.useMemo(() => {
    return validatePassword !== "" || validateSame !== "" || password === ""
  }, [validatePassword, validateSame, password])

  return (
    <Box className={classes.formBox}>
      <Box fontSize={24} p={2} borderBottom="3px solid red">
        Set Your Password
      </Box>
      <Box display="flex" p={2}>
        <Box>
          <form autoComplete="off">
            {/* <Box mt={2}>
              <TextField
                className={classes.field}
                label="Account"
                defaultValue={session && session.name}
                disabled
              />
            </Box> */}
            <Box mt={2}>
              <TextField
                autoComplete=""
                className={classes.field}
                label="New Password"
                type="password"
                error={validatePassword !== ""}
                helperText={validatePassword}
                onChange={(e: any) => {
                  setPassword(e.target.value)
                }}
              />
            </Box>
            <Box mt={2}>
              <TextField
                autoComplete=""
                className={classes.field}
                type="password"
                error={validateSame !== ""}
                helperText={validateSame}
                label="Confirm New Password"
                onChange={(e: any) => {
                  setRePassword(e.target.value)
                }}
              />
            </Box>
          </form>
        </Box>
        <Box>
          <Box fontSize={14}>
            <ul>
              Your password must meet the following requirements:
              <li className={validateLen ? classes.normal : classes.highlight}>
                At least 8 characters
              </li>
              <li
                className={validateLeast ? classes.normal : classes.highlight}
              >
                At Least 3 out of 4
                <ul style={{ paddingInlineStart: "20px" }}>
                  <li
                    className={
                      validateLowcase ? classes.normal : classes.highlight
                    }
                  >
                    Lowercase letters
                  </li>
                  <li
                    className={
                      validateUpcase ? classes.normal : classes.highlight
                    }
                  >
                    Uppercase letters
                  </li>
                  <li
                    className={
                      validateNumber ? classes.normal : classes.highlight
                    }
                  >
                    Numbers
                  </li>
                  <li
                    className={
                      validateContainSpecial
                        ? classes.normal
                        : classes.highlight
                    }
                  >
                    Special characters
                  </li>
                </ul>
              </li>
              <li
                className={validateInUser ? classes.normal : classes.highlight}
              >
                Not containing your name or username
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        {loading ? (
          <Box textAlign="center">
            <CircularProgress />
          </Box>
        ) : (
          <Box display="flex">
            <Button
              variant="contained"
              className={classes.btn}
              disabled={disabled}
              color="primary"
              onClick={handleSubmit}
            >
              Submit
            </Button>
            <Button
              style={{ marginLeft: "10px" }}
              variant="contained"
              className={classes.btn}
              onClick={() => navigate("/login", { replace: true })}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default NeedChangePass
