import React from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import {
  Avatar,
  Badge,
  Box,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core"
import useUserInfo from "../../Hooks/useUserInfo"
import PhotoCameraOutlinedIcon from "@material-ui/icons/PhotoCameraOutlined"
import MailOutlineIcon from "@material-ui/icons/MailOutline"
import CakeIcon from "@material-ui/icons/Cake"
import { FeMaleIcon, MaleIcon } from "../../Utils/Icon"
// import TimeLine from "./timeline"
import PhoneIcon from "@material-ui/icons/Phone"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    largeAvatar: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
    paper: {
      padding: theme.spacing(2),
    },
    icon: {
      color: "#9F9F9F",
      width: "14px",
      height: "14px",
    },
    labname: {
      color: "#787878",
      fontSize: 15,
    },
    labvalue: {
      borderBottom: "1px solid #eee",
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: "32px",
    },
    badgeIcon: {
      background: "#fff",
      borderRadius: "50%",
      padding: "6px",
      width: "30px",
      height: "30px",
      color: "red ",
      cursor: "pointer",
    },
    divider: {
      [theme.breakpoints.down("md")]: {
        width: 0,
      },
    },
    headBox: {
      display: "flex",
      [theme.breakpoints.down("md")]: {
        display: "block",
      },
    },
    headGrid: {
      [theme.breakpoints.down("md")]: {
        paddingTop: 30,
      },
    },
    userline: {
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      color: "#9F9F9F",
    },
  })
)

const Home = (props: RouteComponentProps) => {
  const classes = useStyles()
  const { user } = useUserInfo()

  if (!user) {
    return <Box></Box>
  }

  const column = [
    {
      name: "User ID",
      value: user.slug,
    },
    {
      name: "Unit",
      value: user.unit,
    },
    {
      name: "Team",
      value: user.team,
    },
    {
      name: "Team Manager",
      value: user.myTeam.managers.map((v) => v.name).join(","),
    },
    {
      name: "Job Title",
      value: user.job_title,
    },
    // {
    //   name: "Competency",
    //   value: user.competency,
    // },
    {
      name: "Job Level",
      value: user.job_level,
    },
  ]

  return (
    <Box className={classes.root} m={4}>
      <Box fontSize={24} mb={4}>
        My Information
      </Box>
      <Box boxShadow={2} p={10} style={{ background: "#FEFEFE" }}>
        <Box display="flex" alignItems={"center"} className={classes.headBox}>
          <Box width={400} display="flex" flexShrink={0}>
            <Box>
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  <PhotoCameraOutlinedIcon
                    className={classes.badgeIcon}
                    onClick={() => navigate("/user/avatar")}
                  />
                }
              >
                <Avatar className={classes.largeAvatar} src={user.avatar} />
              </Badge>
            </Box>
            <Box ml={4}>
              <Box display={"flex"} alignItems={"center"} fontWeight="bold">
                <Box fontSize={20}>{user.name}</Box>
                {user.gender === "1" ? (
                  <MaleIcon color={"primary"} />
                ) : (
                  <FeMaleIcon color={"primary"} />
                )}
              </Box>
              <Box mt="2">
                <Box display={"flex"}>
                  <Box className={classes.userline}>
                    <PhoneIcon className={classes.icon} />
                    <Box ml={2}>{user.phone}</Box>
                  </Box>
                  <Box className={classes.userline} ml={2}>
                    <CakeIcon className={classes.icon} />
                    <Box ml={2}>{user.birthday.replace(/-/g, "/")}</Box>
                  </Box>
                </Box>

                <Box className={classes.userline}>
                  <MailOutlineIcon className={classes.icon} />
                  <Box ml={2}>{user.email}</Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box width={50} flexShrink={0}></Box>
          <Divider
            className={classes.divider}
            orientation="vertical"
            flexItem
          />
          <Box width={50} flexShrink={0}></Box>
          <Grid className={classes.headGrid} container>
            {column.map((v) => (
              <Grid item sm={4} xs={12} key={v.name} style={{marginBottom:"10px"}}>
                <Box className={classes.labname}>{v.name}</Box>
                <Box className={classes.labvalue}>
                  {v.value ? v.value : "N/A"}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* <Box boxShadow={2} mt={4} style={{ background: "#FEFEFE" }}>
        <Box fontSize={24} p={4}>
          My Growth
        </Box>
        <TimeLine items={user.timeline} /> 
      </Box> */}
    </Box>
  )
}

export default Home
