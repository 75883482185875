import { Schema } from "@data-driven-forms/react-form-renderer"
import { FieldTypes } from "../../../Components/DataGrid/Form"
import { YearTypeOperations } from "../../../Utils";
import { Sites } from "./index"

const getFilterSchema = () => {
  const filterSchema: Schema = {
    fields: [
      {
        name: "training_name",
        label: "Training Title",
        component: FieldTypes.TEXT_FIELD,
      },
      {
        name: "user_id",
        label: "Trainer",
        component: "UserSelectorField",
        width: "auto"
      },
      {
        name: "host_site",
        label: "Host Site",
        isClearable: true,
        component: FieldTypes.SELECT,
        options: Sites,
        TextFieldProps: {
          margin: "none",
        },
        getOptionSelected: (option: any, value: any) => option.value === value,
      },
      {
        name: "year",
        label: "Year",
        component: "date-picker",
        DatePickerProps: {
          views: ["year"],
          clearable: true
        },
      },
      {
        name: "period",
        label: "Period",
        isClearable: true,
        component: FieldTypes.SELECT,
        options: YearTypeOperations,
        TextFieldProps: {
          margin: "none",
        },
      },
    ],

  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}

export default getFilterSchema
