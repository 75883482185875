import * as React from "react"
import { Box, Button } from "@material-ui/core"
import { useApolloClient } from "@apollo/client"
import UPDATE_TBA_ARCHIVE from "../Gqls/UPDATE_TBA_ARCHIVE"
import { useConfirm } from "material-ui-confirm"
import { toast } from "material-react-toastify"

const ArchiveButton = ({ at, isArchived, refetch ,loading}: any) => {
  const graphqlClient = useApolloClient()

  const confirm = useConfirm()
  const createArchive = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: UPDATE_TBA_ARCHIVE,
      variables: { at, status: 1 },
    })
  }, [graphqlClient, at])

  const deleteArchive = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: UPDATE_TBA_ARCHIVE,
      variables: { at, status: 0 },
    })
  }, [graphqlClient, at])

  const handleCreate = async () => {
    await confirm({
      title: "Warning!",
      description:
        "Note: After it's archived, all data can't be modified any more, unless you unarchived it",
    })
    await createArchive()
    refetch();
  }
  const handleDelete = async () => {
    await confirm({
      title: "Warning!",
      description: "After unarchive, the data will become changeable",
    })
    await deleteArchive().catch((err) => {
      toast.error(err.message, {
        position: "top-center",
        autoClose: 3000,
      })
    })
    refetch();
  }
  if (loading) {
    return null;
  }
  return (
    <Box>
      {!isArchived ? (
        <Button variant="contained" color="primary" onClick={handleCreate}>
          Archive
        </Button>
      ) : (
        <Button variant="contained" onClick={handleDelete}>
          UnArchive
        </Button>
      )}
    </Box>
  )
}
export default ArchiveButton
