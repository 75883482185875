import * as React from "react"
import { Box, Button } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import { useQueryParams } from "use-query-params"
import { useApolloClient } from "@apollo/client"
import Filter from "../../../Components/Filter"
import FETCH_KPI_SETUP from "../Gqls/FETCH_KPI_SETUP"
import Form from "./form"
import UPDATE_KPI_SETUP from "../Gqls/UPDATE_KPI_SETUP"
import { toast } from "material-react-toastify"
import useKpiArchive from "../../../Hooks/useKpiArchive"
import useSession from "../../../Hooks/useSession"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"

const Kpi = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const { can } = useSession()
  const { kpiArchive, loading } = useKpiArchive()
  const [form, setForm] = React.useState<any>({
    budget: 30000,
    manager_per: 0,
    content: [],
  })
  const variables = React.useMemo(() => {
    return {
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
    }
  }, [query])
  const graphqlClient = useApolloClient()
  const getData = React.useCallback(
    (variables) => {
      return graphqlClient.query({
        query: FETCH_KPI_SETUP,
        fetchPolicy: "network-only",
        variables,
      })
    },
    [graphqlClient]
  )

  React.useEffect(() => {
    getData(variables).then((res) => {
      setForm(res.data.kpiSetup)
    })
  }, [getData, variables, setForm])

  const handleSubmit = async () => {
    let count = 0;
    form.content.forEach((v: any) => { count+=v.weight})
    if (count !== 100) {
      toast.warning("Update failed, The weight sum must be 100", {
        position: "top-center",
        autoClose: 3000,
      })
      return;
    }

    await graphqlClient.mutate({
      mutation: UPDATE_KPI_SETUP,
      variables: {
        ...form,
        content: form.content.map((v: any) => {
          return {
            column: v.column,
            is_custom: v.is_custom,
            label: v.label,
            sort: v.sort,
            value_type: v.value_type,
            weight: v.weight,
          }
        }),
      },
    })
    toast.success("success!", {
      position: "top-center",
      autoClose: 3000,
    })
  }

  return (
    <Box m="2%">
      <Box>
        <Filter data={query} handleSubmit={(value: any) => setQuery(value)} />
      </Box>
      <Box>
        <Form form={form} setForm={setForm} />
      </Box>
      {can(
        "kpi-setup.update",
        <Box pt={4} textAlign="center">
          {kpiArchive || loading ? null : (
            <Button
              style={{ width: 200 }}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Save
            </Button>
          )}
        </Box>
      )}
    </Box>
  )
}
export default Kpi
