import { gql } from "@apollo/client"

const OKR_LIST_VIEW = gql`
query OkrListView($year: Int!, $quarter: Int!, $team_id: [ID], $user_id: [ID]) {
  okrListView(
    year: $year
    quarter: $quarter
    team_id: $team_id
    user_id: $user_id
  ) {
    id
    name
    leader {
      id
      avatar
      job_title
      name
      items {
        id
        keyResults {
          id
          content
          progress
        }
        content
        progress
      }
      progress
    }
    members {
      id
      avatar
      name
      items {
        id
        keyResults {
          id
          content
          progress
        }
        content
        progress
      }
      progress
    }
    progress
  }
}

`
export default OKR_LIST_VIEW