import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"

const getCreateSchema = () => {
  const schema = {
    component: FieldTypes.SUB_FORM,
    name: "subform",
    ItemsGridProps: {
      className: "custom-form-border",
      spacing: 3,
      container: true
    },
    sm: 12,

    fields: [
      {
        name: "training_name",
        label: "Training Title",
        component: FieldTypes.TEXT_FIELD,
        FormFieldGridProps: {
          xs: 12,
        },
        isRequired: true,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
      {
        component: FieldTypes.DATE_PICKER,
        label: "Date",
        name: "training_at",
        FormFieldGridProps: {
          xs: 12,
        },
        DatePickerProps: {
          format: "yyyy/MM/DD",
          clearable: true
        },
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },

      {
        name: "price",
        label: "Price",
        component: FieldTypes.TEXT_FIELD,
        isRequired: true,
        type: "number",
        FormFieldGridProps: {
          xs: 12,
        },
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
      {
        name: "user",
        label: "User Name",
        component: "UserSelectorField",
        isRequired: true,
        width: "100%",
        FormFieldGridProps: {
          container: true
        },
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
    ]
  }


  schema.fields.forEach((field: any) => {
    if (field.component === "select") {
      const props = {
        margin: "none",
      }
      field.TextFieldProps = field.TextFieldProps ? {
        ...field.TextFieldProps,
        ...props
      } : props
    } else if (field.component === FieldTypes.DATE_PICKER) {
      const props = {
        margin: "none",
      }
      field.DatePickerProps = field.DatePickerProps ? {
        ...field.DatePickerProps,
        ...props
      } : props
    }

    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 12,
        sm: 4,
        md: 4,
        lg: 4,
        xl: 4,
      }
    }
  })
  return schema
}

export default getCreateSchema
