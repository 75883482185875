import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"

const getUpdateSchema = () => {
  const schema = {
    fields: [
      {
        component: FieldTypes.SUB_FORM,
        name: "subform",
        sm: 12,
        ItemsGridProps: {
          className: "custom-form-border",
          spacing: 3,
          container: true
        },
        fields: [
          {
            component: FieldTypes.SUB_FORM,
            name: "subform1",
            sm: 4,
            fields: [
              {
                name: "number",
                label: "PO Number",
                component: FieldTypes.TEXT_FIELD,
                isRequired: true,
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
              },
              {
                name: "project",
                label: "Project Name",
                component: "ProjectSelector",
                FormFieldGridProps: {
                  container: true
                },
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
              },
              {
                name: "start",
                label: "Start",
                component: FieldTypes.DATE_PICKER,
                isRequired: true,
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
              },
              {
                name: "end",
                label: "End",
                component: FieldTypes.DATE_PICKER,
                isRequired: true,
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
              },
            ],
          },
          {
            component: FieldTypes.SUB_FORM,
            name: "subform2",
            sm: 8,
            fields: [
              {
                name: "total_amount",
                label: "Total Amount",
                helperText: "Tax Included",
                component: FieldTypes.TEXT_FIELD,
                dataType: 'float',
                isRequired: true,
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ]
              },
              {
                name: "members",
                label: "Project ID",
                component: "ProjectPurchaseMember",
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
              },
            ],
          },
        ]
      }
    ],
  }

  return schema
}

export default getUpdateSchema
