import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import DataGrid from "../../Components/DataGrid"
import CREATE_USER from "./Gqls/CREATE_USER"
import UPDATE_USER from "./Gqls/UPDATE_USER"
import SEARCH_USER from "./Gqls/SEARCH_USER"
import columnSchema from "./Schemas/columnSchema"
import getUpdateSchema from "./Schemas/updateSchema"
import getCreateSchema from "./Schemas/createSchema"
import getFilterSchema from "./Schemas/filterSchema"
import { useMemo } from "react"
import useSession from "../../Hooks/useSession"
import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"
import UserStatusInfo from "./Field/UserStatusInfo"
import { createStyles, makeStyles } from "@material-ui/core"
import { fillVariable } from "../../Utils"

ComponentMapper["UserStatusInfo"] = UserStatusInfo

const useStyles = makeStyles(() =>
  createStyles({
    active: {},
    left: {
      background: "#C0C4CC",
    },
    leaving: {
      background: "#E6A23C",
    },
  })
)

const filterDateValue = (value: any) => {
  return typeof value == "string" ? value : value.format("YYYY-MM-DD")
}
const beforeSearchData = (data: any) => {
  const where: any = { AND: [] }
  let hasRole: any = {}

  if (data.role) {
    hasRole = {
      column: "ID",
      operator: "EQ",
      value: data.role.id,
    }
  }
  if (data.unit) {
    where.AND.push({
      column: "UNIT",
      operator: "EQ",
      value: data.unit.name,
    })
  }

  if (data.team) {
    where.AND.push({
      column: "TEAM",
      operator: "EQ",
      value: data.team.name,
    })
  }
  if (data.name) {
    where.AND.push({
      column: "NAME",
      operator: "LIKE",
      value: "%" + data.name + "%",
    })
  }
  if (data.slug) {
    where.AND.push({
      column: "SLUG",
      operator: "LIKE",
      value: "%" + data.slug + "%",
    })
  }
  if (data["status"] && data["status"] !== "ALL") {
    where.AND.push({
      column: "STATUS",
      operator: "EQ",
      value: data["status"],
    })
  }
  if (data.gender) {
    where.AND.push({
      column: "GENDER",
      operator: "EQ",
      value: data.gender,
    })
  }
  if (data.type) {
    where.AND.push({
      column: "TYPE",
      operator: "EQ",
      value: data.type,
    })
  }
  if (data.leave_status) {
    where.AND.push({
      column: "LEAVE_STATUS",
      operator: "EQ",
      value: data.leave_status,
    })
  }

  if (data.job_title) {
    where.AND.push({
      column: "JOB_TITLE",
      operator: "EQ",
      value: data.job_title,
    })
  }
  if (data.competency) {
    where.AND.push({
      column: "COMPETENCY",
      operator: "LIKE",
      value: "%" + data.competency + "%",
    })
  }
  if (data.job_level) {
    where.AND.push({
      column: "JOB_LEVEL",
      operator: "EQ",
      value: data.job_level,
    })
  }

  return {
    orderBy: data["orderBy"],
    first: data["first"],
    page: data["page"],
    where,
    hasRole,
  }
}

const beforeFormSubmit = (data: any) => {
  const input = {}
  // 为了后端判断是否修改了入职时间
  // delete input["start"]
  input["name"] = fillVariable(data.name)
  input["slug"] = fillVariable(data.slug)
  input["abbr"] = fillVariable(data.abbr)
  input["email"] = fillVariable(data.email)
  input["eid"] = fillVariable(data.eid)
  input["competency"] = fillVariable(data.competency, [])
  input["job_title"] = fillVariable(data.job_title)
  input["job_level"] = fillVariable(data.job_level)
  input["phone"] = fillVariable(data.phone)
  input["gender"] = fillVariable(data.gender)
  if (data["password"]) {
    input["password"] = fillVariable(data.password)
  }
  if (data.team) {
    input["team_id"] = data.team.id
  }
  if (data.roles) {
    input["roles"] = {
      sync: data.roles.map((v) => v.id),
    }
  }
  if (data.start) {
    input["start"] = filterDateValue(data.start)
  }
  if (data.term) {
    input["term"] = filterDateValue(data.term)
  }
  if (data.myUnit) {
    input['unit_id'] = data.myUnit.id;
  }

  if (data.birthday) {
    input["birthday"] = filterDateValue(data.birthday)
  }

  const id = data["id"]
  if (id) {
    return {
      id,
      input,
    }
  }
  return {
    input,
  }
}

const User = (_: RouteComponentProps) => {
  const { can } = useSession()
  const classes = useStyles()

  const createSchema = useMemo(() => getCreateSchema(), [])

  const updateSchema = useMemo(() => getUpdateSchema(), [])

  const filterSchema = useMemo(() => getFilterSchema(), [])

  return (
    <DataGrid
      getRowClassName={(e) => {
        // console.log(e)
        if (e.row.status === "Leaving") {
          return classes.leaving
        }
        if (e.row.status === "Left") {
          return classes.left
        }
      }}
      title="User"
      name="users"
      searchGql={SEARCH_USER}
      exportUrl={can("user.export","/api/export/user/management")}
      createGql={can("user.create", CREATE_USER)}
      updateGql={can("user.update", UPDATE_USER)}
      // deleteGql={DELETE_USER}
      filterSchema={filterSchema}
      createSchema={can("user.create", createSchema)}
      updateSchema={can("user.update", updateSchema)}
      columnSchema={columnSchema}
      beforeCreateFormSubmit={beforeFormSubmit}
      beforeUpdateFormSubmit={beforeFormSubmit}
      beforeSearchData={beforeSearchData}
    />
  )
}

export default User
