
const month_v = ['', "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const quarty_v = ['', 1, 1, 1, 2, 2, 2, 3, 3, 3, 4, 4, 4]
export const TimePeriodColumnSchema = [
  { headerName: "Period", field: "period", width: 120 },
  { headerName: "Start Date", field: "start_date", width: 150 },
  { headerName: "Final Date", field: "final_date", width: 150 },
  { headerName: "Working Hours", field: "working_hours", width: 150 },
  { headerName: "Year", field: "year", width: 120 },
  {
    field: "quarty",
    headerName: "Quarty",
    width: 120,
    sortable: false,
    valueGetter: (params: any) => {
      return "Q" + quarty_v[params["row"]["month"]]
    },
  },
  {
    headerName: "Month", field: "month", width: 120,
    valueGetter: (params: any) => {
      return month_v[params["row"]["month"]]
    },
  },
  { headerName: "Total Month Working Hours", field: "total_month_working_hours", width: 250 },
]

