import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react";

const FETCH_SETTING = gql`
  query AllSetting {
    allSetting {
      id
      name
      value
    }
  }
`

const useSettings = () => {
  const { data, loading, error } = useQuery(FETCH_SETTING, {
    fetchPolicy: "cache-and-network",
  })

  const settings = useMemo(() => {
    return loading || error ? [] : data.allSetting;
  }, [loading, data, error])

  const settingsKV = useMemo(() => {
    const obj: any = {};
    settings.forEach((v: any) => {
      obj[v.name] = v.value
    });
    return obj
  }, [settings])
  return { settings, loading, settingsKV }
}

export default useSettings