import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"

const getUpdateSchema = () => {
  const schema = {
    fields: [
      {
        name: "name",
        label: "User Name",
        component: FieldTypes.TEXT_FIELD,
        isReadOnly:true,
      },
      {
        name: "roles",
        label: "Role",
        component: "RoleSelectorField",
        isRequired: true,
        multiple: true,
        width: "100%",
        FormFieldGridProps: {
          xs: 12
        },
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
    ]
  };

  return schema
}

export default getUpdateSchema
