import { gql } from "@apollo/client"

const SEARCH_USER = gql`
query FetchUser(
  $where: QueryUsersWhereWhereConditions
  $hasRole: QueryUsersHasRoleWhereHasConditions
  $orderBy: [QueryUsersOrderByOrderByClause!]
  $first: Int
  $page: Int
) {
  users(
    where: $where
    hasRole: $hasRole
    orderBy: $orderBy
    first: $first
    page: $page
  ) {
    data {
      id
      name
      email
      created_at
      updated_at
      future_team_apply_at
      future_team
      future_team_id
      formal_date
      gender
      token
      slug
      abbr
      unit
      roles{
        id
        name
      }
      team
      eid
      start
      term
      birthday
      phone
      type
      status
      leave_status
      leave_status_name
      future_job_level_apply_at
      future_job_level
      job_level
      job_title
      competency
    }
    paginatorInfo {
      total
      currentPage
    }
  }
}

`

export default SEARCH_USER