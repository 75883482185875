import { gql } from "@apollo/client"

// UnArchiveBonus 重命名
const UNARCHIVE_BONUS = gql`
mutation BonusUnarchive($id: ID!) {
  unArchiveBonus(id: $id) {
    id
    year
    quarter
    amount
    status
    teams {
      id
      ranking
      bonus
      previous_balance
      members {
        id
        bonus
        remark
      }
    }
  }
}
`
export default UNARCHIVE_BONUS