import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, CircularProgress } from "@material-ui/core"
import Toolbar from "./Toolbar"
import ReactEcharts from "../../../Components/Echart"
import { createLineChart } from "../Schemas"
import moment from "moment"
import { useQuery } from "@apollo/client"
import FETCH_PO_REMAINING from "../Gqls/FETCH_PO_REMAINING"

const PORemaining = (props: RouteComponentProps) => {
  const [filter, setFilter] = React.useState(() => ({
    start: moment().format("YYYY-01-01"),
    end: moment().format("YYYY-12-31"),
    type:0,
    abscissa: 2,
  }))

  const { data, loading } = useQuery(FETCH_PO_REMAINING, {
    fetchPolicy: "cache-and-network",
    variables: filter,
  })

  const option = React.useMemo(() => {
    if (data && data.DashboardPORemaining) {
      return createLineChart(data.DashboardPORemaining, true)
    }
    return []
  }, [data])

  return (
    <Box boxShadow={5} p={4} mt={4} style={{ background: "#F4FCF9" }}>
      <Box p={4} pt={2} color="#278DB0" fontSize="20px" fontWeight="bold">
        PO Remaining Rate
      </Box>
      <Toolbar setFilter={setFilter}  propType={0} propAbscissa={2} />
      {loading ? (
        <Box
          height="400px"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <CircularProgress />
        </Box>
      ) : (
        <ReactEcharts
          option={option}
          style={{
            height: 400,
          }}
        />
      )}
    </Box>
  )
}

export default PORemaining
