import * as React from "react"
import { Box, Button } from "@material-ui/core"
import { useApolloClient } from "@apollo/client"
import CREATE_KPI_ARCHIVE from "../Gqls/CREATE_KPI_ARCHIVE"
import useKpiArchive from "../../../Hooks/useKpiArchive"
import { useConfirm } from "material-ui-confirm"
import DELETE_KPI_ARCHIVE from "../Gqls/DELETE_KPI_ARCHIVE"
import { toast } from "material-react-toastify"

const ArchiveButton = () => {
  const graphqlClient = useApolloClient()
  const { kpiArchive, year, quarter, loading, refetch } = useKpiArchive()
  const confirm = useConfirm()
  const createArchive = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: CREATE_KPI_ARCHIVE,
      variables: { year: parseInt(year), quarter: parseInt(quarter) },
    })
  }, [graphqlClient, year, quarter])

  const deleteArchive = React.useCallback(async () => {
    if (kpiArchive) {
      return graphqlClient.mutate({
        mutation: DELETE_KPI_ARCHIVE,
        variables: {
          id: kpiArchive.id,
          year: parseInt(year),
          quarter: parseInt(quarter),
        },
      })
    }
  }, [graphqlClient, kpiArchive, year, quarter])

  const handleCreate = async () => {
    await confirm({
      title: "Warning!",
      description:
        "Note: After it's archived, all data can't be modified any more, unless you unarchived it",
    })
    await createArchive()
    refetch()
  }
  const handleDelete = async () => {
    await confirm({
      title: "Warning!",
      description: "After unarchive, the data will become changeable",
    })
    await deleteArchive().catch((err) => {
      toast.error(err.message, {
        position: "top-center",
        autoClose: 3000,
      })
    })
    refetch()
  }
  if (loading) {
    return null
  }
  return (
    <Box>
      {!kpiArchive ? (
        <Button variant="contained" color="primary" onClick={handleCreate}>
          Archive
        </Button>
      ) : (
        <Button variant="contained" onClick={handleDelete}>
          UnArchive
        </Button>
      )}
    </Box>
  )
}
export default ArchiveButton
