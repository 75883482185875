import { gql } from "@apollo/client"

const UPDATE_KPI_SETUP = gql`
mutation UpdateKpiSetup(
  $id: ID!
  $year: Int!
  $quarter: Int!
  $budget: Float!
  $manager_per: Float!
  $content: [TeamSetupItemInput]!
) {
  updateKpiSetup(
    id: $id
    year: $year
    quarter: $quarter
    budget: $budget
    manager_per: $manager_per
    content: $content
  ) {
    id
    budget
    year
    quarter
    manager_per
    content {
      weight
      label
      is_custom
      value_type
      column
      sort
    }
  }
}
`
export default UPDATE_KPI_SETUP