import { gql } from "@apollo/client"

const FETCH_TBA_TRAINING = gql`
query FetchTba(
  $hasAttendees: QueryTbaTrainingsHasAttendeesWhereHasConditions
  $where: QueryTbaTrainingsWhereWhereConditions
  $hasTrainers: QueryTbaTrainingsHasTrainersWhereHasConditions
) {
  TbaTrainings(
    where: $where
    hasAttendees: $hasAttendees
    hasTrainers: $hasTrainers
  ) {
    id
    area
    training_name
    expense
    training_at
    during
    host_site
    attendances_number
    trainers {
      id
      name
      abbr
    }
    attendees {
      id
      name
    }
    creator {
      id
      name
      abbr
    }
    platform
    platform_name
    satisfaction
    earning
    created_at
  }
}

`

export default FETCH_TBA_TRAINING