import * as React from "react"
import { Box, IconButton } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import { useQueryParam, useQueryParams } from "use-query-params"
import Filter from "../../../Components/Filter"
import { DataGrid } from "@material-ui/data-grid"
import useKpiData from "../../../Hooks/useKpiData"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import { withStyles, Theme } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"
import useKpiArchive from "../../../Hooks/useKpiArchive"
import UnderTeamsSelect from "../../../Components/UnderTeamsSelect"
import useSession from "../../../Hooks/useSession"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip)

const MyTeamKpi = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const { session } = useSession()
  const [teamId] = useQueryParam("team_id", StringParam)
  const { mixtureTeams } = useKpiData()
  const { kpiArchive } = useKpiArchive()

  const myTeam = React.useMemo(() => {
    // 必须设置个默认值：否在点击菜单再次重置 无法触发useEffect 导致原默认值设置失效
    const underTeams = session?.underTeams
    let team_id = teamId
    if (!teamId && underTeams && underTeams.length > 0) {
      team_id = teamId ? teamId : underTeams[0].id
    }
    const res = mixtureTeams.find((v: any) => v.team_id === team_id)
    if (!res || !kpiArchive) {
      return {
        ranking: 0,
        score: 0,
        content: [],
      }
    }
    return res
  }, [mixtureTeams, kpiArchive, teamId, session])

  const column = [
    {
      headerName: "KPI Items",
      field: "label",
      width: 300,
      renderCell: (params: any) => {
        return params.value + ` (${params.row.weight}%)`
      },
    },
    {
      headerName: "Value",
      field: "value",
      width: 120,
    },
    {
      headerName: "Ranking",

      field: "ranking",
      width: 120,
      renderCell: (params: any) => {
        return params.value + ` / ` + mixtureTeams.length
      },
    },
    {
      renderHeader: () => (
        <Box>
          Score
          <LightTooltip
            title="每个KPI Item得分 = 团队总数量 - 该KPI Item的团队排名。
            例如，总共6个团队，其中一项KPI Item在6个团队中排名第一，
            则该项KPI Item得分为6-1=5分。"
          >
            <IconButton size="small" aria-label="hint">
              <HelpOutlineIcon />
            </IconButton>
          </LightTooltip>
        </Box>
      ),
      field: "score",
      width: 120,
    },
  ]

  const Footer = () => {
    return (
      <Box
        p={2}
        display="flex"
        alignItems="center"
        fontSize={14}
        fontWeight="bold"
      >
        <Box color="primary.main">Current Team Ranking:</Box>
        <Box pl={2}>{myTeam.ranking} </Box>
        <Box pl={2} color="primary.main">
          ({(myTeam.score / 100).toFixed(2)})
        </Box>
        <Box pl={2}>
          <LightTooltip title="总分数=每个KPI Item的得分加权求和">
            <IconButton size="small" aria-label="hint">
              <HelpOutlineIcon />
            </IconButton>
          </LightTooltip>
        </Box>
      </Box>
    )
  }

  return (
    <Box m="2%">
      <Box>
        <Filter
          data={query}
          handleSubmit={(value: any) => setQuery(value)}
          CustomLeftText={<UnderTeamsSelect />}
        />
      </Box>

      <Box pt={2}>
        {!kpiArchive ? (
          "The KPI of this quarter has not been finalized. Please check it later."
        ) : (
          <DataGrid
            autoHeight
            hideFooterPagination
            headerHeight={52}
            rowHeight={32}
            columns={column}
            rows={myTeam.content}
            disableColumnMenu={true}
            components={{
              Footer: Footer,
            }}
          />
        )}
      </Box>
    </Box>
  )
}
export default MyTeamKpi
