import { gql } from "@apollo/client"

const USER_KPI_BONUS = gql`
query UserKpiBonus($year: String!, $team_id: [ID], $unit_id: [ID]) {
  userKpiBonus(year: $year, team_id: $team_id, unit_id: $unit_id) {
    id
    name
    slug
    unit
    team
    q1
    q2
    q3
    q4
    total
    rank
  }
}
`
export default USER_KPI_BONUS