import { gql } from "@apollo/client"

const USER_JOB_LEVEL = gql`
query UserJobLevels($team_id: ID, $unit_id: ID, $job_level: String) {
  userJobLevels(team_id: $team_id, unit_id: $unit_id, job_level: $job_level) {
    id
    name
    team
    unit
    job_level
    J1
    J2
    E1
    E2
    S1
    S2
    EX1
    EX2
    status
    sort
  }
}

`
export default USER_JOB_LEVEL