import OSS from "ali-oss"
import axios from "axios";
import { restfulUrl } from ".";

type STSToken = {
  Expiration: string
  AccessKeyId: string
  AccessKeySecret: string
  SecurityToken: string
  Region: string
  Bucket: string
}


const getCache = () => {
  const str = localStorage.getItem("sts_token");
  if (str) {
    const obj = JSON.parse(str);
    if (new Date(obj.Expiration).getTime() > new Date().getTime() + 600000) {
      return obj;
    }
  }
  return null;
}
const setCache = (obj: STSToken) => {
  localStorage.setItem("sts_token", JSON.stringify(obj));
}

const getOssConfig = async (): Promise<STSToken> => {
  // 1.判断token是否过期
  const config = getCache();
  if (config) {
    return config;
  }
  // 2.token已过期or不存在
  const res = await axios.get(restfulUrl("/api/STSToken"));
  setCache(res.data);
  return res.data
}

export const generateOss = async () => {
  const config = await getOssConfig();

  const oss = new OSS({
    // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
    region: config.Region,
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: config.AccessKeyId,
    accessKeySecret: config.AccessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: config.SecurityToken,
    refreshSTSToken: async () => {
      // 向您搭建的STS服务获取临时访问凭证。
      const info = await getOssConfig();
      return {
        accessKeyId: info.AccessKeyId,
        accessKeySecret: info.AccessKeySecret,
        stsToken: info.SecurityToken,
      }
    },
    // 刷新临时访问凭证的时间间隔，单位为毫秒。
    refreshSTSTokenInterval: 300000,
    // 填写Bucket名称。
    bucket: config.Bucket,
  });
  console.log("genOss", oss)
  return oss;
}


