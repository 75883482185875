
const columnSchema = [
  {
    headerName: "User Name", field: "user_id", width: 120,
    renderCell: (params: any) => {
      if (params.row.user) {
        return params.row.user.name
      }
      return '';
    }
  },
  {
    headerName: "Team", field: "myTeam", width: 120, renderCell: (params: any) => {
      if (params.value) {
        return params.value.name
      }
      return ''
    }
  },
  {
    headerName: "Unit", field: "myUnit", width: 120, renderCell: (params: any) => {
      if (params.value) {
        return params.value.name
      }
      return ''
    }
  },
  {
    headerName: "Month", field: "month", width: 120,
    renderCell: (params: any) => {
      if (params.value < 9) {
        return "0" + params.value
      }
      return params.value
    }
  },
  { headerName: "Start", field: "start", width: 150 },
  { headerName: "End", field: "end", width: 150 },
  { headerName: "Duration", field: "duration", width: 100 },
  { headerName: "Type", field: "type", width: 150 },
  {
    headerName: "Note", field: "note", width: 120,
  },
  {
    headerName: "Approver", field: "approver_id", width: 120,
    renderCell: (params: any) => {
      if (params.row.approver) {
        return params.row.approver.name
      }
      return '';
    }
  },
  { headerName: "Created At", field: "created_at", width: 150 },
]

export default columnSchema
