import { useApolloClient } from "@apollo/client"
import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Dialog,
  Slider,
  Avatar,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core"
import { toast } from "material-react-toastify"
import { useConfirm } from "material-ui-confirm"
import React from "react"
import AvatarEditor from "react-avatar-editor"
import useOss from "../../../Hooks/useOss"
import useUserInfo from "../../../Hooks/useUserInfo"
import CHANGE_AVATAR from "../Gqls/CHANGE_AVATAR"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(8),
      height: theme.spacing(8),
    },
    middle: {
      width: theme.spacing(12),
      height: theme.spacing(12),
    },
    large: {
      width: theme.spacing(20),
      height: theme.spacing(20),
    },
  })
)

const MyAvatar = () => {
  const classes = useStyles()
  const { user, refetch } = useUserInfo()
  const ref = React.useRef<AvatarEditor | null>(null)
  const InputRef = React.useRef<HTMLInputElement | null>(null)
  const [image, setImage] = React.useState<any>()
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const [scale, setScale] = React.useState(1)
  const confirm = useConfirm()
  const { client } = useOss()
  const graphqlClient = useApolloClient()


  const resetAvatar = async () => {
    await confirm({
      description: "Are you sure to delete Avatar?",
    })
    await graphqlClient.mutate({
      mutation: CHANGE_AVATAR,
      variables: {
        avatar: "",
      },
    })
    refetch()
    toast.success("Avatar reset", {
      position: "top-center",
      autoClose: 3000,
    })
  }
  const handleSubmit = () => {
    setLoading(true)
    ref?.current?.getImageScaledToCanvas().toBlob(async (blob) => {
      const filename = user.id + "_" + new Date().getTime() + ".jpg"
      const res: any = await client?.put(`/avatar/${filename}`, blob)
      if (res.url) {
        await graphqlClient.mutate({
          mutation: CHANGE_AVATAR,
          variables: {
            avatar: res.url,
          },
        })
        refetch()
        toast.success("Avatar changed successfully!", {
          position: "top-center",
          autoClose: 3000,
        })
        handleClose()
      }
      setLoading(false)
    }, "image/jpeg")
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box>
      <Box>
        <Box pb={2} display={"flex"} alignItems="end">
          <Box mr={4}>
            <Avatar src={user ? user.avatar : ""} className={classes.large} />
          </Box>
          <Box mr={4}>
            <Avatar src={user ? user.avatar : ""} className={classes.middle} />
          </Box>
          <Box mr={4}>
            <Avatar src={user ? user.avatar : ""} className={classes.small} />
          </Box>
        </Box>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            InputRef.current?.click()
          }}
        >
          Upload Avatar
        </Button>
        {user?.avatar ? (
          <Button
            style={{ marginLeft: "10px" }}
            variant="contained"
            color="primary"
            onClick={() => {
              resetAvatar()
            }}
          >
            Delete Avatar
          </Button>
        ) : null}

        <input
          hidden
          accept="image/*"
          ref={InputRef}
          type="file"
          onChange={(e) => {
            if (e.target.files) {
              setImage(e.target.files[0])
              setOpen(true)
              e.target.value = ""
            }
          }}
        />
      </Box>

      <Dialog onClose={handleClose} open={open}>
        <DialogContent dividers>
          <Box maxWidth={250} mt={2}>
            <AvatarEditor
              ref={ref}
              width={200}
              height={200}
              image={image}
              scale={scale}
              borderRadius={180}
            />
            <Slider
              onChange={(event, newValue: any) => {
                setScale(newValue)
              }}
              step={0.05}
              min={1}
              max={2}
            />
            <Box textAlign="right">
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  submit
                </Button>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

export default MyAvatar
