import * as React from "react"

import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Box from "@material-ui/core/Box"
import { StringParam, useQueryParams, ObjectParam } from "use-query-params"
import UnitSelector from "../../../Components/UnitSelector"
import TeamSelector from "../../../Components/TeamSelector"
import QuarterSelector from "../../../Components/QuarterSelector"
import UserTypeSelector from "../../../Components/UserTypeSelector"
import { Grid, TextField } from "@material-ui/core"

const Search = ({ showUserType }: any) => {
  const [query, setQuery] = useQueryParams({
    year: StringParam,
    quarter: StringParam,
    unit: StringParam,
    unit_t: ObjectParam,
    team: StringParam,
    team_t: ObjectParam,
    type: StringParam,
    username: StringParam,
  })

  const handleDateChange = (v: any) => {
    if (v) {
      setQuery({ ...query, year: v.format("YYYY") + "" })
    } else {
      setQuery({ ...query, year: "" })
    }
  }
  const handleQuarterChange = (e: any) => {
    const quarter = e.target.value
    setQuery({ ...query, quarter })
  }
  const handleTeamChange = (e: any, value: any) => {
    if (value) {
      setQuery({ ...query, team: value.name, team_t: value })
    } else {
      setQuery({ ...query, team: "", team_t: value })
    }
  }
  const handleUnitChange = (e: any, value: any) => {
    if (value) {
      setQuery({ ...query, unit: value.name, unit_t: value })
    } else {
      setQuery({ ...query, unit: "", unit_t: value })
    }
  }
  const handleTypeChange = (e: any) => {
    const type = e.target.value
    setQuery({ ...query, type })
  }
  const handleUserChange = (e: any) => {
    const username = e.target.value
    setQuery({ ...query, username })
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box display="flex" p={2} boxShadow={1}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <DatePicker
              label="Year"
              fullWidth
              views={["year"]}
              placeholder="choose year"
              clearable={true}
              value={query["year"] ? query["year"] : null}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <QuarterSelector
              fullWidth
              label="Quarter"
              onChange={handleQuarterChange}
              value={query.quarter}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <UnitSelector
              fullWidth
              label="Unit"
              onChange={handleUnitChange}
              value={query.unit_t}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TeamSelector
              label="Team"
              onChange={handleTeamChange}
              value={query.team_t}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TextField label="User Name" fullWidth onChange={handleUserChange} />
          </Grid>
          {showUserType ? (
            <Grid item xs={6} sm={3}>
              <UserTypeSelector
                label="Type"
                fullWidth
                onChange={handleTypeChange}
                value={query.type}
              />
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export default Search
