import { gql } from "@apollo/client"

// CreateOkrObject 重命名
const CREATE_OKR_OBJECT = gql`
mutation CreateOkr($input: CreateOkrObjectInput!) {
  createOkrObject(input: $input) {
    id
    year
    quarter
    content
    progress
    keyResults {
      id
      content
      progress
    }
    created_at
    updated_at
  }
}
`
export default CREATE_OKR_OBJECT