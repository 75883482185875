import * as React from "react"
import { Box, TextField } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import { useQueryParams } from "use-query-params"
import Filter from "../../../Components/Filter"
import { DataGrid } from "@material-ui/data-grid"
import useKpiData from "../../../Hooks/useKpiData"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import ArchiveButton from "./ArchiveButton"
import UpdateButton from "./UpdateButon"
import useSession from "../../../Hooks/useSession"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      "& input": {
        height: "31px",
        lineHight: "31px",
      },
    },
  })
)

const columnSchema = [
  {
    headerName: "Team Name",
    field: "team_name",
    width: 150,
  },
]
const columnRanking = {
  headerName: "Ranking",
  field: "score",
  width: 150,
  renderCell: (params: any) => {
    return params.row.ranking + " (" + (params.value / 100).toFixed(2) + ")"
  },
}

const AllKpi = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const classes = useStyles()
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const { can } = useSession()
  const [columnMode, setColumnMode] = React.useState("view")
  const { mixtureTeams, kpiSetup } = useKpiData()
  const [form, setForm] = React.useState<any>({})

  const list = React.useMemo(() => {
    return mixtureTeams.map((value: any) => {
      const row = {
        id: value.id,
        team_name: value.team_name,
        score: value.score,
        ranking: value.ranking,
      }
      value.content.forEach((item: any) => {
        //@ts-ignore
        row[item.column] = item.value
      })
      return row
    })
  }, [mixtureTeams])

  React.useEffect(() => {
    setForm(JSON.parse(JSON.stringify(list)))
  }, [list])

  const changeFromColumn = React.useCallback(
    (id, field, value) => {
      const row = form.find((v: any) => v.id === id)
      row[field] = Number(value)
    },
    [form]
  )
  const viewColumnSchema = React.useMemo(() => {
    const column = [...columnSchema]
    if (kpiSetup.length === 0) {
      return column
    }
    kpiSetup.content.forEach((v: any) => {
      const field = {
        renderHeader: () => (
          <Box>
            <Box lineHeight="20px">{v.label}</Box>
            <Box lineHeight="20px">({v.weight}%)</Box>
          </Box>
        ),
        field: v.column,
        width: 150,
        renderCell: (params: any) => {
          const ext = v.value_type === "Percentage" ? "%" : ""
          return params.value + ext
        },
      }
      //@ts-ignore
      column.push(field)
    })
    return column
  }, [kpiSetup])

  const updateColumnSchema = React.useMemo(() => {
    const column = [...columnSchema]
    if (kpiSetup.length === 0) {
      return column
    }
    kpiSetup.content.forEach((v: any) => {
      const field = {
        renderHeader: () => (
          <Box>
            <Box lineHeight="20px">{v.label}</Box>
            <Box lineHeight="20px">({v.weight}%)</Box>
          </Box>
        ),
        field: v.column,
        width: 150,
        renderCell: (params: any) => {
          if (v.is_custom) {
            return (
              <TextField
                defaultValue={params.value}
                onChange={(e) => {
                  changeFromColumn(params.row.id, params.field, e.target.value)
                }}
                classes={{ root: classes.inputRoot }}
                type="number"
              />
            )
          }
          const ext = v.value_type === "Percentage" ? "%" : ""
          return params.value + ext
        },
      }
      //@ts-ignore
      column.push(field)
    })
    return column
  }, [kpiSetup, classes, changeFromColumn])

  const columnSchemaRes = React.useMemo(() => {
    return columnMode === "view" ? viewColumnSchema : updateColumnSchema
  }, [viewColumnSchema, updateColumnSchema, columnMode])

  return (
    <Box m="2%">
      <Box>
        <Filter data={query} handleSubmit={(value: any) => setQuery(value)} />
      </Box>
      <Box>
        <UpdateButton
          columnMode={columnMode}
          setColumnMode={setColumnMode}
          form={form}
          setForm={setForm}
          list={list}
        />
      </Box>
      <Box pt={2}>
        <DataGrid
          headerHeight={52}
          rowHeight={32}
          disableColumnMenu={true}
          autoHeight
          hideFooterPagination
          columns={[...columnSchemaRes, columnRanking]}
          rows={list}
        />
      </Box>
      <Box pt={2}>{can("kpi-archive.update", <ArchiveButton />)}</Box>
    </Box>
  )
}
export default AllKpi
