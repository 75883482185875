import { gql } from "@apollo/client"

const DELETE_TBA_EXTERNAL_TRAINING = gql`
mutation DeleteTbaExternalTraining($id:ID!) {
  deleteTbaExternalTraining(id:$id) {
    id
    training_name
  }
}
`
export default DELETE_TBA_EXTERNAL_TRAINING