import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react";

const FETCH_TEAM_BUILDING = gql`
  query TeamBuilding {
    allTeamBuilding {
      id
      name
    }
  }
`

const useAllTeamBuildings = () => {
  const { data, loading, error } = useQuery(FETCH_TEAM_BUILDING, {
    fetchPolicy: "cache-and-network",
  })

  const team_buildings = useMemo(() => {
    return loading || error ? [] : data.allTeamBuilding;
  }, [loading, data, error])

  const buildingKv = useMemo(() => {
    let kv: any = {};
    team_buildings.forEach((v: any) => {
      kv[v.id] = v.name;
    })
    return kv
  }, [team_buildings])


  return { team_buildings, loading, buildingKv }
}

export default useAllTeamBuildings