import { gql } from "@apollo/client"

// UpdateOkrObject 重命名
const UPDATE_OKR_OBJECT = gql`
mutation UpdateOkr($id: ID!, $input: UpdateOkrObjectInput!) {
  updateOkrObject(id: $id, input: $input) {
    id
    year
    quarter
    content
    progress
    keyResults {
      id
      content
      progress
    }
    created_at
    updated_at
  }
}

`
export default UPDATE_OKR_OBJECT