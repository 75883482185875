import { gql } from "@apollo/client"

const FETCH_KPI_TEAM_BONUS = gql`
query FetchKpiTeamBonus($year: Int!, $quarter: Int!, $team_id: ID!) {
  kpiTeamBonuses(year: $year, quarter: $quarter, team_id: $team_id) {
    id
    ranking
    bonus
    previous_balance
    kpiBonus{
      id
      status
    }
    members {
      id
      bonus
      remark
      username
      attendance
      attendance_rate
      tba_expend
    }
    teamBuildings{
      id
      bonus
      remark
      team_building_id
      teamBuilding {
        name
      }
    }
    created_at
  }
}

`
export default FETCH_KPI_TEAM_BONUS