import React from "react"
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

const columnSchema = [
  {
    headerName: "Name", field: "user", width: 150,
    renderCell: (params: any) => {
      const title: string = params.value.name;
      const children = React.createElement("div", {}, title)
      return React.createElement(Tooltip, { title, children });
    }
  },
  { headerName: "Training Title", field: "training_name", width: 250, },
  { headerName: "Platform", field: "platform_name", width: 150 },
  { headerName: "Date", field: "training_at", width: 100, },
  {
    headerName: "Price", field: "price", width: 130, renderCell: (params: any) => {
      const children = (params.value).toFixed(2);
      return React.createElement(Box, { textAlign: "center", width: "100%", children });
    }
  },
]

export default columnSchema
