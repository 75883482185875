import React, { useEffect } from "react"
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy"
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api"
import { useQueryParam, JsonParam } from "use-query-params"
// 根据搜索条件：自动更新对应搜索字段
const FieldListener = () => {
  const { change, getRegisteredFields } = useFormApi()
  const [filter] = useQueryParam("search", JsonParam)
  useEffect(() => {
    const fields = getRegisteredFields()
    fields.forEach((name) => {
      if (filter && filter[name]) {
        change(name, filter[name])
      } else {
        change(name, null)
      }
    })
  }, [filter, change, getRegisteredFields])
  return null
}

const FieldListenerWrapper = () => (
  <FormSpy subscription={{ values: true }}>{() => <FieldListener />}</FormSpy>
)

export default FieldListenerWrapper
