import { Schema } from "@data-driven-forms/react-form-renderer"
import { FieldTypes } from "../../../Components/DataGrid/Form"
import { USER_JOB_COMPETENCY_OPTIONS, USER_JOB_LEVEL_OPTIONS, USER_JOB_TITLE_OPTIONS } from "../../../Utils/config"
import { LeaveStatus, Status, Types ,Gender} from "./index"

const getFilterSchema = () => {
  const filterSchema: Schema = {
    fields: [
      {
        name: "name",
        label: "User Name",
        component: FieldTypes.TEXT_FIELD,
      },
      { label: "User ID", name: "slug", component: FieldTypes.TEXT_FIELD, },
      {
        name: "role",
        label: "Role",
        component: "RoleSelectorField",
        width: "100%",
      },
      {
        label: "Job Title",
        name: "job_title",
        component: FieldTypes.SELECT,
        isClearable: true,
        options: USER_JOB_TITLE_OPTIONS,
        TextFieldProps: {
          margin: "none",
        },
      },
      {
        label: "Competency",
        name: "competency",
        component: FieldTypes.SELECT,
        isClearable: true,
        options: USER_JOB_COMPETENCY_OPTIONS,
        TextFieldProps: {
          margin: "none",
        },
      },
      {
        label: "Job Level",
        name: "job_level",
        component: FieldTypes.SELECT,
        isClearable: true,
        options: USER_JOB_LEVEL_OPTIONS,
        TextFieldProps: {
          margin: "none",
        },
      },
      {
        label: "Gender",
        name: "gender",
        component: FieldTypes.SELECT,
        isClearable: true,
        options: Gender,
        TextFieldProps: {
          margin: "none",
        },
      },
      {
        name: "unit",
        label: "Unit",
        component: "UnitSelectorField",
        width: "100%",
      },
      {
        name: "team",
        label: "Team",
        component: "TeamSelectorField",
        width: "100%",
      },
      {
        label: "Type",
        name: "type",
        component: FieldTypes.SELECT,
        isClearable: true,
        options: Types,
        TextFieldProps: {
          margin: "none",
        },
      },
      {
        name: "status",
        label: "Status",
        component: FieldTypes.SELECT,
        isClearable: true,
        options: Status,
        TextFieldProps: {
          margin: "none",
        },
      },
      {
        label: "Leave Status",
        name: "leave_status",
        component: FieldTypes.SELECT,
        isClearable: true,
        options: LeaveStatus,
        TextFieldProps: {
          margin: "none",
        },
      },
    ],

  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}

export default getFilterSchema
