import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react";

const FETCH_TEAM = gql`
query fetchTeam {
  teams {
    id
    name
    quarter_members{
      id
      avatar
      name
      status
      term
      underTeams{
        id
      }
    }
    members {
      id
      name
      avatar
      status
      term
      underTeams{
        id
      }
    }
  }
}
`

const useAllTeams = () => {
  const { data, loading, error } = useQuery(FETCH_TEAM, {
    fetchPolicy: "cache-and-network",
  })

  const teams = useMemo(() => {
    return loading || error ? [] : data.teams;
  }, [loading, data, error])


  return { teams, loading }
}

export default useAllTeams