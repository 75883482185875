import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import DataGrid from "../../Components/DataGrid"
import CREATE_PROJECT from "./Gqls/CREATE_PROJECT"
import UPDATE_PROJECT from "./Gqls/UPDATE_PROJECT"
import SEARCH_PROJECT from "./Gqls/SEARCH_PROJECT"
import columnSchema from "./Schemas/columnSchema"
import getUpdateSchema from "./Schemas/updateSchema"
import getCreateSchema from "./Schemas/createSchema"
import getFilterSchema from "./Schemas/filterSchema"
import { useMemo } from "react"
import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"
import ProjectMember from "./Field/ProjectMember"
import useSession from "../../Hooks/useSession"

ComponentMapper["ProjectMember"] = ProjectMember

const beforeFormSubmit = (data: any) => {
  data["manager_id"] = data.manager.id
  data["team_id"] = data.team.id
  data["members"] = {
    sync: data.projectUsers.map((item: any) => ({
      id: item.user.id,
      unit_price: item.unit_price,
    })),
  }
  return data
}
const beforeSearchData = (data: any) => {
  if (data.manager) {
    data["manager_id"] = data.manager.id
  }
  if (data.team) {
    data["team_id"] = data.team.id
  }
  return data
}

const Project = (_: RouteComponentProps) => {
  const { can } = useSession()
  const createSchema = useMemo(() => getCreateSchema(), [])

  const updateSchema = useMemo(() => getUpdateSchema(), [])

  const filterSchema = useMemo(() => getFilterSchema(), [])

  return (
    <DataGrid
      title="Project"
      name="projects"
      searchGql={SEARCH_PROJECT}
      createGql={can("project.create", CREATE_PROJECT)}
      updateGql={can("project.update", UPDATE_PROJECT)}
      // deleteGql={DELETE_PROJECT}
      filterSchema={filterSchema}
      createSchema={can("project.create", createSchema)}
      updateSchema={can("project.update", updateSchema)}
      columnSchema={columnSchema}
      beforeSearchData={beforeSearchData}
      beforeCreateFormSubmit={beforeFormSubmit}
      beforeUpdateFormSubmit={beforeFormSubmit}
    />
  )
}

export default Project
