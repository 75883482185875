import { gql } from "@apollo/client"

const CREATE_FILE = gql`
mutation CreateFile($input: FileInput!) {
  createFile(input: $input) {
    id
    filename
    content
    creator_id
    revisor_id
  }
}
`
export default CREATE_FILE