import * as React from "react"
import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"

const UserTypeSelector = (props: any) => {
  const { value, onChange, label } = props
  return (
    <FormControl {...props}>
      <InputLabel>{label}</InputLabel>
      <Select label={label} value={value} onChange={onChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem value="Formal">Formal</MenuItem>
        <MenuItem value="Probation">Probation</MenuItem>
      </Select>
    </FormControl>
  )
}

export default UserTypeSelector
