import * as React from "react"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import { useState } from "react"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import UserSelector from "../../../Components/UserSelector"
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"
import { useCallback } from "react"
import Chip from "@material-ui/core/Chip"

const useStyles = makeStyles({
  header: {
    backgroundColor: "#87888A",
    lineHeight: "2rem",
    padding: "1rem",
    fontSize: "1.2rem",
    fontWeight: 'bolder',
    color: 'white'
  },
  chip: {
    marginRight: '1rem',
    marginBottom: '1rem'
  },
  error: {
    padding: "1rem",
    color: "red"
  }
})

const ProjectMember = (props: any) => {
  const classes = useStyles()
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta

  // const [price, setPrice] = useState()
  const [user, setUser] = useState<any>()

  // const handleChangePrice = useCallback((event) => {
  //   setPrice(event.target.value)
  // }, [setPrice])

  const handleChangeUser = useCallback((_, value) => {
    setUser(value)
  }, [setUser])

  const handleAddUser = useCallback(() => {
    const existedFilter = (item:any) => item.user.id === user.id

    if (user && (!input.value || !input.value.filter(existedFilter).length)) {
      const item = {user, unit_price: 0}
      const value = input.value ? [...input.value, item] : [item]
      input.onChange(value)
    }
  }, [user, input])

  const handleDelete = (userId:any) => {
    const validFilter = (item:any) => item.user.id !== userId
    if (input.value){
      const value = input.value.filter(validFilter)
      input.onChange(value)
    }
  }

  return (
    <Box border={1} flexGrow={1}>
      <Box className={classes.header}>Add Project Members</Box>
      <Box display="flex" p={2}>
        <Box mr={2}>
          <UserSelector onChange={handleChangeUser} label="User Name" />
        </Box>
        {/* <Box ml={5} mr={1}>
          <TextField label="Unit Price" helperText="tax excluded" type="number" onChange={handleChangePrice} />
        </Box> */}
         <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleAddUser}
        >
          <Icon>add</Icon>
        </Button>
      </Box>
      <Box p={2}>
        {input.value && input.value.map((item:any, index:number) => (
          <Chip
            data-id={100}
            className={classes.chip}
            key={index}
            variant="outlined"
            size="small"
            label={item.user.name}
            onDelete={() => handleDelete(item.user.id)}
            color="primary"
          />
        ))}
      </Box>
      {submitFailed && error && (<Box className={classes.error}>{error}</Box>)}
    </Box>
  )
}

export default ProjectMember
