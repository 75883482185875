import React, { useEffect, useState } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { gql, useMutation } from "@apollo/client"

import {
  Box,
  Button,
  createStyles,
  FormControl,
  Grid,
  Input,
  InputAdornment,
  makeStyles,
  Theme,
  Tooltip,
} from "@material-ui/core"
import useSession from "../../Hooks/useSession"
import ResetDialog from "./ResetDialog"
import LockIcon from "@material-ui/icons/Lock"
import PersonIcon from "@material-ui/icons/Person"
interface Login_login {
  __typename: "User"
  id: string
  token: string | null
  is_need_change_pass: boolean
}

interface LoginTpye {
  login: Login_login | null
}

interface LoginVariables {
  slug: string
  password: string
}

export const LOGIN_USER = gql`
  mutation Login($slug: String!, $password: String!) {
    login(slug: $slug, password: $password) {
      id
      avatar
      token
      name
      myTeam {
        id
        name
      }
      underTeams {
        id
        name
      }
      menu
      render_menu {
        title
        icon
        url
        children {
          title
          url
        }
      }
      is_locked
      is_need_change_pass
      last_change_pass
    }
  }
`

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    imgBox: {
      position: "absolute",
      "& img": {
        height: 50,
      },
    },
    inputBox: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
      background: "#fff",
      border: "1px solid #ccc",
      borderRadius: 24,
      height: 36,
      marginBottom: 20,
      width: "100%",
    },

    button: {
      marginTop: "5px",
      height: "36px",
      color: "#fff",
      background: "#1E2228",
      borderRadius: "30px",
      boxShadow: "0px 8px 5px 0px rgba(0, 0, 0, 0.15)",
      "&:hover": {
        backgroundColor: "#1E2228",
        boxShadow: "0px 8px 5px 0px rgba(0, 0, 0, 0.15)",
      },
    },
    leftBox: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    rightBox: {
      background: "#EE2E24 linear-gradient(180deg, #C50000 0%, #E6001D 100%);",
    },
    rightBoxBg: {
      width: "100%",
      height: "100vh",
      backgroundImage: "url(/bg.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundAttachment: "fixed",
      backgroundPosition: "right",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    rightBoxContent: {
      width: "400px",
      margin: "0 auto",
    },
    tooltip: {
      background: "#fff",
      color: theme.palette.primary.main,
      padding: "10px 20px",
      width: "400px",
    },
    popper: {
      zIndex: 900,
    },
  })
})

const Login = (_: RouteComponentProps) => {
  const classes = useStyles()
  const [slug, setSlug] = useState<string>("")
  const [error, setError] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const { login, isLogin } = useSession()

  useEffect(() => {
    if (isLogin) {
      // 如果已登录直接调转到欢迎界面
      navigate("/home")
    }
  }, [isLogin])

  const [callLoginApi, { loading }] = useMutation<LoginTpye, LoginVariables>(
    LOGIN_USER,
    {
      onCompleted({ login: data }) {
        if (!data) {
          return
        }
        sessionStorage.clear()// 防止
        if (data.is_need_change_pass) {
          navigate("/please/change/password?token=" + data.token)
          return
        }
        login(data as any, false)
      },
      onError(error) {
        setError(error.message)
        // toast.error(error.message, {
        //   position: "top-center",
        //   autoClose: false,
        // })
      },
    }
  )

  const handleClick = (e: any) => {
    e.preventDefault()
    if (loading) {
      return
    }
    if (!slug || !password) {
      // toast.error("Your account or password is incorrect. Please retry!", {
      //   position: "top-center",
      // })
      setError("Your account or password is incorrect. Please retry!")
    } else {
      callLoginApi({ variables: { slug, password } })
    }
  }

  const open = React.useMemo(() => !!error, [error])

  return (
    <Box>
      <Grid container>
        <Grid item xs={4} className={classes.leftBox}>
          <Box className={classes.imgBox} p={8}>
            <img src="/black-logo.png" alt="logo"></img>
          </Box>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            p={8}
          >
            <Box height={200}></Box>
            <Box fontWeight="bold" fontSize={32}>
              WELCOME TO
            </Box>
            <Box fontWeight="bold" color="primary.main" fontSize={32}>
              SIGMA Technology China ERP
            </Box>
          </Box>
          <Box pb={4} pl={8}>
            <Box display={"flex"} pb={2}>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://beian.miit.gov.cn/"
                style={{ fontSize: "12px" }}
              >
                京ICP备2022019757号-1
              </a>
              <span style={{padding:"0 5px"}}>|</span>
              <span style={{ fontSize: "12px" }}>
                2022 © sigma-erp.com.cn
              </span>
            </Box>
            <Box display={"flex"} alignItems="center">
              <Box pr={1} style={{ height: "18px" }}>
                <img src="/gongan.png" alt="gongan" />
              </Box>
              <Box>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502050262"
                  style={{ fontSize: "12px", lineHeight: "18px" }}
                >
                  京公网安备 11010502050262号
                </a>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={8} className={classes.rightBox}>
          <Box className={classes.rightBoxBg}>
            <Box className={classes.rightBoxContent}>
              <Box>
                <Box
                  color="white"
                  fontSize={26}
                  fontWeight="bold"
                  textAlign="center"
                  p={5}
                >
                  LOGIN
                </Box>
              </Box>
              <Box>
                <FormControl fullWidth>
                  <Input
                    className={classes.inputBox}
                    disableUnderline={true}
                    placeholder="Your employee ID or Sigma email address"
                    onChange={(e) => setSlug(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <FormControl fullWidth>
                  <Input
                    className={classes.inputBox}
                    disableUnderline={true}
                    placeholder="Your password"
                    type="password"
                    onChange={(e) => setPassword(e.target.value)}
                    startAdornment={
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
              <Box>
                <Tooltip
                  open={open}
                  title={error}
                  classes={{ tooltip: classes.tooltip, popper: classes.popper }}
                >
                  <Button
                    variant="contained"
                    className={classes.button}
                    fullWidth
                    onClick={handleClick}
                  >
                    Login
                  </Button>
                </Tooltip>
              </Box>
              <Box height={open ? 45 : 0}></Box>
              <Box textAlign="center" color="white" p={5}>
                <label
                  style={{ cursor: "pointer" }}
                  onClick={() => setShowDialog(true)}
                >
                  Reset password
                </label>
              </Box>
              <Box height={100}></Box>
            </Box>
          </Box>
        </Grid>
      </Grid>

      <ResetDialog show={showDialog} setShow={setShowDialog} />
    </Box>
  )
}

export default Login
