import { gql } from "@apollo/client"

const PROJECT_DASHBOARD = gql`
  query ProjectDashboard (
    $project_slug: String!
    $start_period: String
    $end_period: String
    $year: Int
    $quarter: Int
    $month: Int
  ) {
    projectDashboard(
      project_slug: $project_slug
      start_period: $start_period
      end_period: $end_period
      year: $year
      quarter: $quarter
      month: $month
    )  {
      project{
        id
        name
        slug
      }
      start_period
      end_period
      total_working_hours
      total_leave_hours
      actual_working_hours
      total_members
      members_with_leaves
      total_cost
      remaining_po_budget
      pos {
        id
        number
      }
      activities_hours{
        name
        value
      }
      members_hours{
        label
        name
        value
      }
      year_revenue{
        name
        revenues
        hours
      }
    }
  }
`

export default PROJECT_DASHBOARD