import { gql } from "@apollo/client"

const FETCH_TEAM_LOG = gql`
  query FetchTeamLog($team_id: ID,$orderBy:[QueryTeamLogsOrderByOrderByClause!]) {
    teamLogs(team_id: $team_id, orderBy:$orderBy) {
      id
      at
      type_name
      note
      team {
        name
      }
      username
      created_at
    }
  }
`

export default FETCH_TEAM_LOG