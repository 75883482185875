import { gql } from "@apollo/client"

// ArchiveBonus 重命名
const ARCHIVE_BONUS = gql`
mutation BonusArchive($id: ID!) {
  archiveBonus(id: $id) {
    id
    year
    quarter
    amount
    status
    teams {
      id
      ranking
      bonus
      previous_balance
      members {
        id
        bonus
        remark
      }
    }
  }
}
`
export default ARCHIVE_BONUS