import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import DataGrid from "../../Components/DataGrid"
import CREATE_PROJECT from "./Gqls/CREATE_PROJECT_PURCHASE"
import UPDATE_PROJECT from "./Gqls/UPDATE_PROJECT_PURCHASE"
import ALL_PROJECT_PURCHASE from "./Gqls/ALL_PROJECT_PURCHASE"
import columnSchema from "./Schemas/columnSchema"
import getUpdateSchema from "./Schemas/updateSchema"
import getCreateSchema from "./Schemas/createSchema"
import getFilterSchema from "./Schemas/filterSchema"
import { useMemo } from "react"
import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"
import ProjectPurchaseMember from "./Field/ProjectPurchaseMember"
import ProjectSelector from "../../Components/ProjectSelector"
import useSession from "../../Hooks/useSession"
import { Button } from "@material-ui/core"
import { navigate } from "@reach/router"
ComponentMapper["ProjectPurchaseMember"] = ProjectPurchaseMember
ComponentMapper["ProjectSelector"] = ProjectSelector

const filterDateValue = (value: any) => {
  return typeof value == "string" ? value : value.format("YYYY-MM-DD")
}

const beforeFormSubmit = (data: any) => {
  const value = {
    ...data,
    project_id: data.project.id,
    start: filterDateValue(data.start),
    end: filterDateValue(data.end),
    members: {
      sync: data.members.map((item: any) => ({
        id: item.id,
      })),
    },
  }
  return value
}
const beforeDownloadData = (data: any) => {
  const params: any = { ...data }
  if (data["team"]) {
    params["team"] = data["team"].name
  }
  return params
}
const beforeSearchData = (data: any) => {
  const params: any = {}
  if (data["status"] && data["status"] !== "All") {
    params["status"] = data["status"]
  }
  if (data["number"]) {
    params["number"] = data["number"]
  }
  if (data["project"]) {
    params["project"] = {
      column: "SLUG",
      operator: "EQ",
      value: data["project"],
    }
  }
  if (data["team"]) {
    params["team"] = data["team"].name
  }
  return params
}

const Project = (_: RouteComponentProps) => {
  const { can } = useSession()
  const createSchema = useMemo(() => getCreateSchema(), [])

  const updateSchema = useMemo(() => getUpdateSchema(), [])

  const filterSchema = useMemo(() => getFilterSchema(), [])
  const leftButton = (
    <Button
      variant="contained"
      style={{ marginLeft: 8 }}
      onClick={() => navigate("/po/assignment")}
    >
      PO Assignment
    </Button>
  )
  return (
    <DataGrid
      title="PO"
      mode="local"
      name="allProjectPurchases"
      leftButton={leftButton}
      searchGql={ALL_PROJECT_PURCHASE}
      createGql={can("project-purchase.create", CREATE_PROJECT)}
      updateGql={can("project-purchase.update", UPDATE_PROJECT)}
      exportUrl={can("project-purchase.export","/api/export/project/management")}
      beforeDownloadData={beforeDownloadData}
      filterSchema={filterSchema}
      createSchema={can("project-purchase.create", createSchema)}
      updateSchema={can("project-purchase.update", updateSchema)}
      columnSchema={columnSchema}
      beforeCreateFormSubmit={beforeFormSubmit}
      beforeUpdateFormSubmit={beforeFormSubmit}
      beforeSearchData={beforeSearchData}
    />
  )
}

export default Project
