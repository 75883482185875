import React from "react"
import { Box, IconButton, Input, Tooltip } from "@material-ui/core"
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    InputContent: {
      height: 20,
    },
    InputProgress: {
      textAlign: "center",
    },
    progress: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      textAlign: "center",
      fontSize: "16px",
      lineHeight: "27px",
    },
  })
)
const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: "#FFF3D5",
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[1],
    padding: "5px 10px",
    fontSize: 11,
  },
}))(Tooltip)

const KeyResult = ({
  editAble,
  data,
  index,
  setkeyResults,
  deleteKeyResult,
}: any) => {
  const classes = useStyles()

  const changeContent = (value: any) => {
    setkeyResults(index, { ...data, content: value })
  }
  const changeProgress = (value: any) => {
    setkeyResults(index, { ...data, progress: parseInt(value) })
  }

  return (
    <Box
      display="flex"
      alignItems="baseline"
      width="100%"
      my={2}
      borderBottom="1px solid #eee"
    >
      <Box fontWeight="bold" color="primary.main">
        KR{index + 1}
      </Box>
      <Box flexGrow={1} ml={2} mr={8}>
        {editAble ? (
          <LightTooltip
            placement="left-start"
            open={data.error && data.error["content"] !== "" ? true : false}
            disableFocusListener
            disableHoverListener
            title={
              data.error && data.error["content"] ? data.error["content"] : ""
            }
          >
            <Box>
              <Input
                placeholder="Type KR here"
                fullWidth={true}
                error={
                  data.error && data.error["content"] !== "" ? true : false
                }
                classes={{
                  input: classes.InputContent,
                }}
                onChange={(event) => changeContent(event.target.value)}
                defaultValue={data.content}
              />
            </Box>
          </LightTooltip>
        ) : (
          <Box py={2} color="#051629">
            {data.content}
          </Box>
        )}
      </Box>
      <Box width={100} display="flex" justifyContent="center">
        {editAble ? (
          <Box>
            <LightTooltip
              placement="left-start"
              open={data.error && data.error["progress"] !== "" ? true : false}
              disableFocusListener
              disableHoverListener
              title={
                data.error && data.error["progress"]
                  ? data.error["progress"]
                  : ""
              }
            >
              <Box display="flex" alignItems="center">
                <Box width={50}>
                  <Input
                    classes={{ input: classes.InputProgress }}
                    error={
                      data.error && data.error["progress"] !== "" ? true : false
                    }
                    placeholder="Progress"
                    onChange={(event) => changeProgress(event.target.value)}
                    type="number"
                    defaultValue={data.progress}
                  />
                </Box>
                <Box>%</Box>
              </Box>
            </LightTooltip>
          </Box>
        ) : (
          <Box textAlign="center">{data.progress}%</Box>
        )}
      </Box>
      {editAble && (
        <Box width={100} textAlign="center">
          <IconButton
            size="small"
            color="primary"
            onClick={() => deleteKeyResult(index)}
          >
            <HighlightOffIcon />
          </IconButton>
        </Box>
      )}
      <Box width={29}></Box>
    </Box>
  )
}

export default KeyResult
