import { gql } from "@apollo/client"

const UPDATA_JOB_LEVEL = gql`
mutation UpdateJobLevelAction(
  $id: ID!
  $future_job_level: String!
  $future_job_level_apply_at: Date!
) {
  changeJobLevel(
    id: $id
    future_job_level: $future_job_level
    future_job_level_apply_at: $future_job_level_apply_at
  ) {
    id
    name
    future_job_level_apply_at
    future_job_level
    job_level
  }
}

`
export default UPDATA_JOB_LEVEL