import { gql } from "@apollo/client"

const UPDATE_TBA_EXTERNAL_TRAINING = gql`
mutation UpdateTbaExternalTraining($input: UpdateTbaExternalTrainingInput!) {
  updateTbaExternalTraining(input: $input) {
    id
    training_name
    price
    training_at
    platform_name
    platform
    user {
      id
      name
    }
    created_at
  }
}

`
export default UPDATE_TBA_EXTERNAL_TRAINING