import React from "react"
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';

const columnSchema = [
  { headerName: "Area", field: "area", width: 150 },
  { headerName: "Training Title", field: "training_name", width: 250, },
  { headerName: "Date", field: "training_at", width: 100, },
  {
    headerName: "Trainer", field: "trainers", width: 100,
    renderCell: (params: any) => {
      const trainer = params.value.map((v: any) => v.abbr);
      const label = trainer.join(",")
      const title = params.value.map((item: any) => item.abbr.toUpperCase() + "(" + item.name + ")").join(",");
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      }, label)
      return React.createElement(Tooltip, { title, children });
    }
  },
  { headerName: "Host Site", field: "host_site", width: 120, },
  {
    headerName: "Price/Person", field: "expense", width: 130, renderCell: (params: any) => {
      const children = (params.value).toFixed(2);
      return React.createElement(Box, { textAlign: "center", width: "100%", children });
    }
  },
  { headerName: "No. of Attendees", field: "attendances_number", width: 150 },
  {
    headerName: "Satisfaction Rate", field: "satisfaction", width: 150,
    renderCell: (params: any) => {
      return (params.row.satisfaction).toFixed(2) + "%"
    }
  },
  {
    headerName: "Earned Cash", field: "earning", width: 130,
    renderCell: (params: any) => {
      const children = (params.value).toFixed(2)
      return React.createElement(Box, { textAlign: "center", width: "100%", children });
    }
  },
  {
    headerName: "Creator", field: "creator", width: 100,
    renderCell: (params: any) => {
      return params.row.creator.abbr
    }
  },
]

export default columnSchema
