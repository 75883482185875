import { useCallback } from "react"
import Box from "@material-ui/core/Box"
import { RouteComponentProps } from "@reach/router"
import { DropzoneArea } from "material-ui-dropzone"
import { useMutation } from "@apollo/client"
import UPLOAD_REPORT from "./Gqls/UPLOAD_REPORT"
import OVERRIDE_REPORT from "./Gqls/OVERRIDE_REPORT"
import { useConfirm } from "material-ui-confirm"
import { toast, TypeOptions, ToastContent } from "material-react-toastify"
import CircularProgress from "@material-ui/core/CircularProgress"
import ImportLog from "../../Components/ImportLog"
// import { navigate } from "@reach/router"

const Import = (props: RouteComponentProps) => {
  const confirm = useConfirm()
  const showMessage = useCallback(
    (type: TypeOptions, message: ToastContent) => {
      toast(message, {
        type,
        position: "top-center",
        autoClose: 3000,
      })
    },
    []
  )

  // 覆盖报告
  const [overrideReport, { loading: overriding }] = useMutation(
    OVERRIDE_REPORT,
    {
      onCompleted: (data) => {
        showMessage("success", "success")
      },
      onError: (error) => {
        showMessage(
          "error",
          "Oho, something is wrong. Please retry or contact the administrator."
        )
      },
    }
  )

  // 上传报告
  const [uploadReport, { loading: uploading }] = useMutation(UPLOAD_REPORT, {
    onCompleted: (data) => {
     if (data.uploadProjectReport.code === 2) {
        confirm({
          title: "warning!",
          description: data.uploadProjectReport.message,
        })
          .then(() => {
            overrideReport({
              variables: { key: data.uploadProjectReport.key },
            })
          })
          .catch(() => {})
      }
    },
  })
  // 上传
  const uploadFiles = useCallback(
    async (loadedFiles: File[]) => {
      if (loadedFiles.length === 0) {
        return
      }
      if (loadedFiles.length === 1) {
        const res = await uploadReport({
          variables: { file: loadedFiles[0] },
        })
        if (res.data.uploadProjectReport.code === 0) {
          showMessage("success", "success")
        } else { 
          showMessage("error", "import faled")
        }
        return
      }
      let sum = 0
      let index = 0
      let total = loadedFiles.length
      loadedFiles.forEach(async (file) => {
        const res = await uploadReport({
          variables: { file, override: true },
        })
        if (res.data.uploadProjectReport.code === 0) {
          sum++
        }
        index++
        if (index === total) {
          toast.info(`imported success ${sum}. faild ${total - sum}`, {
            position: "top-center",
            autoClose: 5000,
            closeOnClick: false,
            draggable: false,
          })
        }
      })
    },
    [uploadReport, showMessage]
  )
  return (
    <Box mx={[2, 4, 8]} py={5} mt={5}>
      <Box my={4} component="h1">
        Import PX Report
      </Box>
      <Box mb={5}>
        {(uploading || overriding) && <CircularProgress />}
        {!uploading ? (
          <Box>
            <DropzoneArea
              showPreviewsInDropzone={false}
              showFileNames={true}
              onChange={uploadFiles}
              filesLimit={20}
              showAlerts={false}
              acceptedFiles={[".xls", ".xlsx"]}
            />
          </Box>
        ) : null}
      </Box>
      {!uploading ? <ImportLog type={3} /> : null}
    </Box>
  )
}

export default Import
