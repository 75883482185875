import { gql } from "@apollo/client"

const FETCH_TEAM = gql`
  query TeamBuilding {
    teamBuildings {
      id
      name
    }
  }
`
export default FETCH_TEAM