import { gql } from "@apollo/client"

const UPDATE_TBA_ARCHIVE = gql`
mutation Update($at: String!, $status: Int) {
  updateTbaArchive(at: $at, status: $status) {
    id
    status
    at
    funds {
      user {
        name
        team
      }
      amount
      status
    }
  }
}
`
export default UPDATE_TBA_ARCHIVE