
import { ME } from "../Hooks/useUserInfo"
import {
  ApolloClient,
} from "@apollo/client"

interface Client extends ApolloClient<any> { }

export class Alive {
  time: ReturnType<typeof setTimeout> | 0;
  client: Client;

  constructor(client) {
    this.time = 0;
    this.client = client;
  }

  heartbeat() {
    if (this.time) {
      return
    }
    this.time = setTimeout(() => {
      this.time = 0;
      this.client.query({
        query: ME,
        fetchPolicy: "network-only"
      })
    }, 60000)
  }
}

