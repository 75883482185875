import * as React from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useAllUsers from "../Hooks/useAllUsers"

const filterOptions = (options: Array<any>, { inputValue }: any) => {
  if (!inputValue) {
    return options
  }

  return options.filter((user) => {
    const matchValue = inputValue.toLowerCase()

    return user.name.toLowerCase().indexOf(matchValue) !== -1
  })
}

const TeamUserSelector = (props: any) => {
  const { users, loading } = useAllUsers()
  const teamObj: any = {}
  const user: any[] = []
  users.forEach((v: any) => {
    teamObj[v.team_id] = {
      type: "team",
      name: v.team,
      team_id: v.team_id,
    }
    if (v.status === "Active") {
      user.push({
        type: "user",
        name: v.name + " | " + v.team,
        user_id: v.id,
        team_id: v.team_id,
      })
    }
  })
  const team:any[] = []
  for (let key in teamObj) {
    team.push(teamObj[key])
  }
  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      getOptionSelected={(opt: any, val: any) => {
        if (opt.type === "user" && opt.user_id === val.user_id) {
          return opt
        }
        if (opt.type === "team" && opt.team_id === val.team_id) {
          return opt
        }
        return false
      }}
      size="small"
      filterOptions={filterOptions}
      options={[...team, ...user]}
      getOptionLabel={(option: any) => (option ? option.name : "")}
      style={{ width: 300 }}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextField {...params} placeholder="Enter the team or member name" />
        )
      }}
      {...props}
    />
  )
}

export default TeamUserSelector
