import * as React from "react"
import { Box, Button } from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import { downloadUrl } from "../../../Utils"
import { useQueryParams } from "use-query-params"
import Filter from "../../../Components/Filter"
import FETCH_KPI_BONUS from "../Gqls/FETCH_KPI_BONUS"
import { useApolloClient, useQuery } from "@apollo/client"
import { DataGrid } from "@material-ui/data-grid"
import { Link } from "@reach/router"
import ARCHIVE_BONUS from "../Gqls/ARCHIVE_BONUS"
import UNARCHIVE_BONUS from "../Gqls/UNARCHIVE_BONUS"
import { toast } from "material-react-toastify"
import { useConfirm } from "material-ui-confirm"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"

const Archive = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const graphqlClient = useApolloClient()
  const confirm = useConfirm()
  const { data } = useQuery(FETCH_KPI_BONUS, {
    fetchPolicy: "cache-and-network",
    variables: {
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
    },
  })
  const list = React.useMemo(() => {
    if (data && data.kpiBonuses && data.kpiBonuses.teams.length) {
      return data.kpiBonuses.teams.map((v) => ({
        ...v,
        remaining: v.previous_balance + v.bonus - v.allocated,
      }))
    }
    return []
  }, [data])

  const t2m = React.useMemo(() => {
    if (
      data &&
      data.kpiBonuses &&
      data.kpiBonuses.target_quarter_team_member_number
    ) {
      const m_n = data.kpiBonuses.target_quarter_team_member_number
      const obj: any = {}
      m_n.forEach((v: any) => {
        obj[v.team_id] = v["number"]
      })
      return obj
    }
    return {}
  }, [data])

  const columns = [
    {
      headerName: "Team",
      field: "team",
      width: 300,
      renderCell: (params: any) => {
        return (
          <Box color="primary.main">
            <Link
              style={{ color: "red" }}
              to={
                "/bonus/allocate/team" +
                "?year=" +
                params.row.year +
                "&quarter=" +
                params.row.quarter +
                "&team_id=" +
                params.row.team.id
              }
            >
              {params.row.team.name + " (" + t2m[params.row.team.id] + ")"}
            </Link>
          </Box>
        )
      },
    },
    {
      headerName: "Balance Budget",
      field: "previous_balance",
      width: 200,
      renderCell: (params: any) => {
        return params.row.previous_balance.toFixed(2)
      },
    },
    {
      headerName: "Quarter Budget",
      field: "bonus",
      width: 200,
      renderCell: (params: any) => {
        return params.row.bonus.toFixed(2)
      },
    },
    {
      headerName: "Allocated Budget",
      field: "allocated",
      width: 200,
      renderCell: (params: any) => {
        return params.row.allocated.toFixed(2)
      },
    },
    {
      headerName: "Remaining Budget",
      field: "remaining",
      width: 200,
      renderCell: (params: any) => {
        return (
          params.row.previous_balance +
          params.row.bonus -
          params.row.allocated
        ).toFixed(2)
      },
    },
  ]

  const id = React.useMemo(() => {
    if (data && data.kpiBonuses) {
      return data.kpiBonuses.id
    }
    return 0
  }, [data])

  const isArchived = React.useMemo(() => {
    if (data && data.kpiBonuses) {
      return data.kpiBonuses.status === "archived"
    }
    return true
  }, [data])

  const archiveBonus = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: ARCHIVE_BONUS,
      variables: { id },
    })
  }, [graphqlClient, id])

  const unArchiveBonus = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: UNARCHIVE_BONUS,
      variables: { id },
    })
  }, [graphqlClient, id])

  const handleArchive = async () => {
    await confirm({
      title: "Warning!",
      description:
        "Please Notice: Bonus Archive operation cannot be revoked. Upon archive operation, team managers can't modify their bonus allocation any more.",
    })
    await archiveBonus()
    toast.success("success!", {
      position: "top-center",
      autoClose: 3000,
    })
  }
  const handleUnArchive = async () => {
    await confirm({
      title: "Warning!",
      description: "After unarchive, the data will become changeable",
    })
    await unArchiveBonus()
    toast.success("success!", {
      position: "top-center",
      autoClose: 3000,
    })
  }
  const TotalBalanceBudget = React.useMemo(() => {
    let sum = 0
    list.forEach((v: any) => {
      sum += Number(v.previous_balance)
    })
    return sum.toFixed(2)
  }, [list])
  const TotalBudget = React.useMemo(() => {
    let sum = 0
    list.forEach((v: any) => {
      sum += Number(v.bonus)
    })
    return sum.toFixed(2)
  }, [list])
  const TotalAllocated = React.useMemo(() => {
    let sum = 0
    list.forEach((v: any) => {
      sum += Number(v.allocated)
    })
    return sum.toFixed(2)
  }, [list])
  const TotalUndistributed = React.useMemo(() => {
    return (
      Number(TotalBalanceBudget) +
      Number(TotalBudget) -
      Number(TotalAllocated)
    ).toFixed(2)
  }, [TotalBudget, TotalAllocated, TotalBalanceBudget])

  // 数据导出
  const exportLink = React.useCallback(() => {
    downloadUrl("/api/export/bouns/archive", query)
    console.log("download")
  }, [query])
  const Footer = () => {
    return (
      <Box
        p={2}
        display="flex"
        alignItems="center"
        fontSize={14}
        fontWeight="bold"
      >
        <Box width={300}>Sub Total</Box>
        <Box width={200}>{TotalBalanceBudget}</Box>
        <Box width={200} color="primary.main">
          {TotalBudget}
        </Box>
        <Box width={200} color="primary.main">
          {TotalAllocated}
        </Box>
        <Box width={200} color="primary.main">
          {TotalUndistributed}
        </Box>
      </Box>
    )
  }
  return (
    <Box m="2%">
      <Filter
        data={query}
        CustomLeftText={
          !id ? (
            ""
          ) : (
            <Button variant="contained" color="primary" onClick={exportLink}>
              Export
            </Button>
          )
        }
        handleSubmit={(value: any) => setQuery(value)}
      />
      {!id ? (
        <Box pt={2}>
          The KPI of this quarter has not been finalized, there is no bonus to
          archive. Please check it later.
        </Box>
      ) : (
        <Box>
          <Box pt={2}>
            <DataGrid
              headerHeight={52}
              rowHeight={52}
              autoHeight={true}
              disableColumnMenu={true}
              hideFooterPagination
              hideFooterRowCount
              hideFooterSelectedRowCount
              columns={columns}
              rows={list}
              components={{
                Footer: Footer,
              }}
            />
          </Box>
          {isArchived ? (
            <Box pt={2}>
              <Button variant="contained" onClick={handleUnArchive}>
                UnArchive
              </Button>
            </Box>
          ) : (
            <Box pt={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleArchive}
              >
                Archive
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

export default Archive
