import React from "react"
import styled from "styled-components"
import { makeStyles } from "@material-ui/core/styles"
import CssBaseline from "@material-ui/core/CssBaseline"
import Toolbar from "@material-ui/core/Toolbar"
import {
  Root,
  getHeader,
  getDrawerSidebar,
  getSidebarTrigger,
  getSidebarContent,
  getContent,
  getFixedScheme,
} from "@mui-treasury/layout"
// import LayoutBuilder from "@mui-treasury/layout/builders/LayoutBuilder"
import { Box, useTheme } from "@material-ui/core"
import LeftMenu from "./LeftMenu"
import Link from "@material-ui/core/Link/Link"
import release from "../../Utils/release"
import UserMenu from "./UserMenu"

const Header = getHeader(styled)
const DrawerSidebar = getDrawerSidebar(styled)
const SidebarTrigger = getSidebarTrigger(styled)
const SidebarContent = getSidebarContent(styled)
const Content = getContent(styled)

const useStyles = makeStyles({
  grow: {
    flexGrow: 1,
  },
  preLogo: {
    fontSize: "1rem",
    background: "#002140",
    color: "#ffffff",
    textAlign: "center",
    lineHeight: 2.5,
  },
  siderbar: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#051629",
    color: "white",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  logo: {
    width: "100%",
    padding: "0 12px",
  },
})

const schema = getFixedScheme()

schema.configureHeader((builder) => {
  builder
    .registerConfig("xs", {
      position: "relative",
      initialHeight: 56,
    })
    .registerConfig("md", {
      position: "sticky",
    })
})

schema.configureEdgeSidebar((builder) => {
  builder
    .create("primarySidebar", {
      anchor: "left",
    })
    .registerPermanentConfig("md", {
      width: 200,
      collapsible: false,
    })
})

const Layout = ({ children }: any) => {
  const theme = useTheme()
  const classes = useStyles()
  React.useEffect(() => {
    // console.log("children", document.documentElement.scrollTop)
    document.documentElement.scrollTop = 0
  }, [children])

  return (
    <Root scheme={schema} theme={theme}>
      <CssBaseline />
      <Header>
        <Toolbar>
          <SidebarTrigger sidebarId={"primarySidebar"} />
          <div className={classes.grow} />
          <Box ml={2}>
            <UserMenu />
          </Box>
        </Toolbar>
      </Header>
      <DrawerSidebar sidebarId={"primarySidebar"}>
        <SidebarContent className={classes.siderbar}>
          <Box className={classes.preLogo}>SIGMA Technology China ERP</Box>
          <Box p={2}>
            <img src="/logo.png" className={classes.logo} alt="logo" />
          </Box>
          <Box flexGrow={1}>
            <LeftMenu />
          </Box>
          <Box pb={2} pl={2}>
            <Link href="/release">v{release[0].version}</Link>
          </Box>
        </SidebarContent>
      </DrawerSidebar>
      <Content>{children}</Content>
    </Root>
  )
}
export default Layout
