import { gql } from "@apollo/client"

const FETCH_PO_REMAINING = gql`
query DashboardPORemaining($start: Date!,$end: Date!, $type: Int!, $abscissa: Int!) {
  DashboardPORemaining(start: $start,end: $end, type: $type, abscissa: $abscissa) {
    name
    x
    y
  }
}
`
export default FETCH_PO_REMAINING