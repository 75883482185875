import * as React from "react"
import { OkrObjectDataType } from "./Types"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import GroupIcon from "@material-ui/icons/Group"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Member from "./memberList"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core"
import { OkrIcon } from "../../Utils/Icon"

type TeamOkrListProps = {
  data: OkrObjectDataType[]
  expandedType: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AccordionRoot: {
      boxShadow: "none",
    },
    AccordionSummaryRoot: {
      background: "#FBE9E8",
      "&.Mui-expanded": {
        minHeight: "48px",
      },
    },
    AccordionSummaryContent: {
      margin: "0",
      "&.Mui-expanded": {
        margin: "0",
      },
    },
    progress: {
      fontWeight: "bold",
      "&>span": {
        fontSize: "16px",
        color: theme.palette.primary.main,
      },
    },
    AccordionDetail: {
      display: "block",
      padding: 0,
    },
  })
)

const sortOkr = (teams: any) => {
  const data = JSON.parse(JSON.stringify(teams))
  const sortMember = data.map((object: any) => {
    object.members = object.members.sort((a, b) => b.progress - a.progress)
    return object
  })
  return sortMember.sort((a, b) => b.progress - a.progress)
}
/**
 * 传入okrlist 根据team 重新排序
 * 然后输出
 */
const TeamOkrList = ({ data, expandedType }: TeamOkrListProps) => {
  const classes = useStyles()
  const res = sortOkr(data)
  const [expandedArr, setExpandedArr] = React.useState<any[]>([])
  // 展开事件--懒加载提升加载速度
  const onAccordion = (id: string, expanded: boolean) => {
    const newArr = [...expandedArr, id]
    if (expanded && !expandedArr.includes(id)) {
      setExpandedArr(newArr)
    }
  }

  return (
    <Box>
      {res.map((team: any) => {
        return (
          <Box pt={2} key={team.id}>
            <Accordion
              square={true}
              classes={{ root: classes.AccordionRoot }}
              defaultExpanded={expandedType > 0}
              onChange={(e, expanded) => {
                onAccordion(team.id, expanded)
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.AccordionSummaryRoot,
                  content: classes.AccordionSummaryContent,
                }}
              >
                <Box display="flex" justifyContent="space-between" width="100%">
                  <Box display="flex" alignItems="center">
                    <GroupIcon color="primary" />
                    <Box ml={2} display="flex">
                      <Box fontWeight="bold" color="primary.main">
                        {team.name}
                      </Box>
                      <Box ml={1}>Team</Box>
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <OkrIcon />
                    <Box ml={2} className={classes.progress}>
                      Team Progress <span>{team.progress}%</span>
                    </Box>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.AccordionDetail}>
                {expandedArr.includes(team.id) || expandedType > 0 ? (
                  <Box>
                    <Member
                      data={team.leader}
                      isLeader={true}
                      expandedType={expandedType}
                    />
                    <Member data={team.members} expandedType={expandedType} />
                  </Box>
                ) : null}
              </AccordionDetails>
            </Accordion>
          </Box>
        )
      })}
    </Box>
  )
}

export default TeamOkrList
