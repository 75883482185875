import * as React from "react"
import { Box, Button } from "@material-ui/core"
import { useApolloClient } from "@apollo/client"
import useKpiArchive from "../../../Hooks/useKpiArchive"
import UPDATE_BATCH_KPI_TEAM_EDITOR from "../Gqls/UPDATE_BATCH_KPI_TEAM_EDITOR"
import CALCULATE_KPI_TEAM_EDITOR from "../Gqls/CALCULATE_KPI_TEAM_EDITOR"
import { toast } from "material-react-toastify"
import useKpiTeamEditor from "../../../Hooks/useKpiTeamEditor"
import useSession from "../../../Hooks/useSession"

const UpdateButon = ({
  form,
  setForm,
  list,
  columnMode,
  setColumnMode,
}: any) => {
  const { can } = useSession()
  const graphqlClient = useApolloClient()
  const { refetch } = useKpiTeamEditor()
  const { kpiArchive, loading, year, quarter } = useKpiArchive()
  const calculateEditorData = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: CALCULATE_KPI_TEAM_EDITOR,
      variables: { year: parseInt(year), quarter: parseInt(quarter) },
    })
  }, [graphqlClient, year, quarter])
  const updateEditorData = React.useCallback(
    async (form) => {
      return graphqlClient.mutate({
        mutation: UPDATE_BATCH_KPI_TEAM_EDITOR,
        variables: { form },
      })
    },
    [graphqlClient]
  )
  const handleSave = async () => {
    const ext = ["id", "team_name", "score", "ranking"]
    const newForm: any[] = []
    form.forEach((item: any) => {
      let content:any[] = []
      for (let key in item) {
        if (!ext.includes(key)) {
          content.push({ column: key, value: item[key] })
        }
      }
      newForm.push({
        id: item.id,
        content,
      })
    })
    await updateEditorData(newForm)
    setColumnMode("view")
  }
  const toUpdateMode = () => {
    setColumnMode("update")
  }
  const calculateField = async () => {
    await calculateEditorData()
    toast.success("success!", {
      position: "top-center",
      autoClose: 3000,
    })
    refetch()
  }
  const handleCancel = () => {
    setForm(JSON.parse(JSON.stringify(list)))
    setColumnMode("view")
  }
  let operation:any = null
  let calculate:any = null
  if (!kpiArchive) {
    if (columnMode === "view") {
      operation = (
        <Button variant="contained" color="primary" onClick={toUpdateMode}>
          Edit
        </Button>
      )
    }
    if (columnMode === "update") {
      operation = (
        <Box>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          <Button
            style={{ marginLeft: 8 }}
            variant="contained"
            color="default"
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </Box>
      )
    }
    calculate = (
      <Box mr={2}>
        <Button variant="contained" color="primary" onClick={calculateField}>
          Refresh
        </Button>
      </Box>
    )
  }
  if (loading) {
    return null
  }
  return (
    <Box display="flex">
      {calculate}
      {can("kpi-archive.update", operation)}
    </Box>
  )
}
export default UpdateButon
