import React from "react"

const columnSchema = [
  {
    headerName: "Role", field: "name", width: 150,
    renderCell: (params: any) => {
      return React.createElement("div", {
        style: {
          width: "100%",
          whiteSpace: 'normal',
          lineHeight: "18px",
          textOverflow: 'ellipsis',
        }
      }, params.value)
    }
  },
  {
    headerName: "Default", field: "is_default", width: 150,
    renderCell: (params: any) => {
      return React.createElement("div", {
        style: {
          width: "100%",
          whiteSpace: 'normal',
          lineHeight: "18px",
          textOverflow: 'ellipsis',
        }
      }, params.value ? "Yes" : "")
    }
  },
  {
    headerName: "Permissions", field: "menu", width: 800,
    renderCell: (params: any) => {
      if (!params.value) {
        return ''
      }
      const title = params.value.map((item: any) => item).join(", ")
      // const title = JSON.stringify(params.value)
      return React.createElement("div", {
        style: {
          width: "100%",
          whiteSpace: 'normal',
          lineHeight: "18px",
          textOverflow: 'ellipsis',
        }
      }, title)
    }
  }
]

export default columnSchema
