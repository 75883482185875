import { Tooltip } from "@material-ui/core";
import React from "react";
import copy from 'copy-to-clipboard';
import { toast } from "material-react-toastify";


const columnSchema: any = [
  { headerName: "User", field: "user", width: 120 },
  { headerName: "Team", field: "team", width: 120 }
];
const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
for (let i = 1; i <= 12; i++) {
  columnSchema.push({
    headerName: month[i - 1], field: "m" + i, width: 120,
    renderCell: (params: any) => {
      const label = params.value;
      const title = params.value;
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        },
        onClick: () => {
          toast.success("Copy to clipboard!", {
            position: "top-center",
          })
          copy(label);
        }
      }, label)
      return React.createElement(Tooltip, { title, children });
    }
  })
}

export default columnSchema




