import { gql } from "@apollo/client"

const FETCH_REVENUE_PER_CAPITA = gql`
query DashboardRevenuePerCapita($start: Date!,$end: Date!, $type: Int!, $abscissa: Int!) {
  DashboardRevenuePerCapita(start: $start,end: $end, type: $type, abscissa: $abscissa){
    name
    x
    y
  }
}
`
export default FETCH_REVENUE_PER_CAPITA