import * as React from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Avatar,
  Badge,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import { OkrIcon, LeaderIcon } from "../../Utils/Icon"
import OkrObject from "./objectList"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AccordionRoot: {
      boxShadow: "none",
    },
    AccordionSummaryRoot: {
      height:"60px",
      background: "#F4F6F8",
      "&.Mui-expanded": {
        minHeight: "48px",
      },
    },
    AccordionSummaryContent: {
      margin: "0",
      "&.Mui-expanded": {
        margin: "0",
      },
    },

    AccordionDetail: {
      display: "block",
      padding: 0,
    },
  })
)

const OkrMembers = ({ data, isLeader, expandedType }: any) => {
  const classes = useStyles()
  const [expandedArr, setExpandedArr] = React.useState<any[]>([])
  // 展开事件--懒加载提升加载速度
  const onAccordion = (id: string, expanded: boolean) => {
    const newArr = [...expandedArr, id]
    if (expanded && !expandedArr.includes(id)) {
      setExpandedArr(newArr)
    }
  }

  return (
    <Box>
      {data &&
        data.map((object: any) => (
          <Box pt={2} key={object.id}>
            <Accordion
              square={true}
              classes={{ root: classes.AccordionRoot }}
              defaultExpanded={expandedType > 1}
              onChange={(e, expanded) => {
                onAccordion(object.id, expanded)
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                classes={{
                  root: classes.AccordionSummaryRoot,
                  content: classes.AccordionSummaryContent,
                }}
              >
                <Box display="flex" justifyContent="space-between" width="100%" >
                  <Box display="flex" alignItems={"center"}>
                    <Box>
                      <Badge
                        overlap="circle"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          isLeader ? (
                            <Box
                              style={{
                                background: "#fff",
                                borderRadius: "50%",
                                width: "12px",
                                height: "12px",
                              }}
                            >
                              <LeaderIcon
                                style={{ width: "12px", height: "12px" }}
                                color="primary"
                              />
                            </Box>
                          ) : null
                        }
                      >
                        <Avatar src={object.avatar} />
                      </Badge>
                      {/* {isLeader ? (
                        <LeaderIcon color="primary" />
                      ) : (
                        <PersonIcon color="disabled" />
                      )} */}
                    </Box>
                    <Box
                      ml={2}
                      fontWeight="bold"
                      color={isLeader ? "primary.main" : ""}
                    >
                      {object.name}
                    </Box>
                    <Box ml={2}>{isLeader ? object.job_title : ""}</Box>
                  </Box>
                  <Box display="flex" alignItems={"center"}>
                    <OkrIcon />
                    {object.items.length === 0 ? (
                      <Box ml={2} fontWeight="bold" width="80px">
                        Not Created
                      </Box>
                    ) : (
                      <Box ml={2} display="flex" width="80px" fontWeight="bold">
                        <Box>Progress</Box>
                        <Box textAlign="right" flexGrow={1}>
                          {`${object.progress}%`}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails className={classes.AccordionDetail}>
                {expandedArr.includes(object.id) || expandedType > 1 ? (
                  <OkrObject
                    objects={object.items}
                    expandedType={expandedType}
                  />
                ) : null}
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
    </Box>
  )
}
export default OkrMembers
