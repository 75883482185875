import { Box, Button } from "@material-ui/core"
import * as React from "react"
import { StringParam, useQueryParam } from "use-query-params"
import useSession from "../Hooks/useSession"

const UnderTeamsSelect = () => {
  const { session } = useSession()
  const [teamId, setTeamId] = useQueryParam("team_id", StringParam)
  // 首次赋值Team
  React.useEffect(() => {
    if (!teamId) {
      // 已存在teamId 情况下不进行初始化赋值
      const underTeams = session?.underTeams
      if (underTeams && underTeams.length > 0) {
        setTeamId(underTeams[0].id)
      } else {
        // 如果是普通成员直接使用myTeam.id
        setTeamId(session?.myTeam.id)
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const SelectTeam = React.useMemo(() => {
    const underTeams = session?.underTeams
    if (!underTeams || underTeams.length === 0) {
      return null
    }
    const team_id = teamId ? teamId : underTeams[0].id
    return (
      <Box display="flex">
        {underTeams.map((v) => (
          <Box mr={2} key={v.id}>
            <Button
              variant="contained"
              color={v.id === team_id ? "primary" : "default"}
              key={v.id}
              onClick={() => {
                setTeamId(v.id)
              }}
            >
              {v.name}
            </Button>
          </Box>
        ))}
      </Box>
    )
  }, [setTeamId, teamId, session])

  return SelectTeam
}

export default UnderTeamsSelect
