import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import SearchIcon from "@material-ui/icons/Search"
import TeamUserSelector from "../../Components/TeamUserSelector"
import { useQueryParams, JsonParam } from "use-query-params"

export default function MultipleSelect({ value, handleSubmit }: any) {
  const [query] = useQueryParams({
    search: JsonParam,
  })
  const searchParams = query["search"] ? query["search"] : []
  const [searchValue, setSearchValue] = useState(searchParams)
  useEffect(() => {
    setSearchValue(query.search ? query["search"] : [])
  }, [query])
  const handleSearch = () => {
    handleSubmit({ search: searchValue })
  }
  const handleChangeSearch = (_: any, selected: any) => {
    setSearchValue(selected)
  }
  const handleReset = () => {
    handleSubmit({ search: [] })
  }
  return (
    <Box display="flex" alignItems="center">
      <Box>
        <TeamUserSelector value={searchValue} onChange={handleChangeSearch} />
      </Box>
      <Box ml={2}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<SearchIcon />}
          onClick={handleSearch}
        >
          Search
        </Button>
        <Button
          style={{ marginLeft: 8 }}
          variant="contained"
          color="default"
          disabled={searchValue.length === 0}
          size="small"
          onClick={handleReset}
        >
          Reset
        </Button>
      </Box>
    </Box>
  )
}
