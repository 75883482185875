import * as React from "react"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core"
import { useApolloClient } from "@apollo/client"
import moment from "moment"
import CHANGE_TEAM from "../Gqls/CHANGE_TEAM"
import TeamSelector from "../../../Components/TeamSelector"

const ChangeTeam = (props: any) => {
  const [open, setOpen] = React.useState(false)
  const [selectDate, setSelectDate] = React.useState<any>(null)
  const [team, setTeam] = React.useState<any>()
  const graphqlClient = useApolloClient()
  const values = props.values

  const submit = React.useCallback(async () => {
    if (!selectDate) {
      throw Error("by date must be set")
    }
    return graphqlClient.mutate({
      mutation: CHANGE_TEAM,
      variables: {
        id: values.id,
        future_team_id: team.id,
        future_team_apply_at: moment(selectDate).format("YYYY-MM-DD"),
      },
    })
  }, [graphqlClient, selectDate, values, team])

  const TeamContext = React.useMemo(() => {
    if (values.future_team) {
      return ` (The user will be changed to ${values.future_team} team on ${values.future_team_apply_at})`
    }
    return ""
  }, [values])

  const handleClose = () => {
    setOpen(false)
  }
  const submitConfitm = async () => {
    const res = await submit().catch((e) => {
      return false
    })
    if (res) {
      window.history.back()
    }
  }
  const handleChangeTeam = React.useCallback(
    (_, value) => {
      if (value) {
        setTeam(value)
      } else {
        setTeam(null)
      }
    },
    [setTeam]
  )
  return (
    <Box display="flex" alignItems="center" height={30}>
      <Box color="text.secondary" fontWeight="bold">
        Team:
      </Box>
      <Box ml={2}>
        {values.team}
        {TeamContext ? TeamContext : ""}
      </Box>
      {
        <Box ml={2}>
          {TeamContext ? (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectDate(values.future_team_apply_at)
                setOpen(true)
              }}
            >
              Change Team
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => setOpen(true)}
            >
              Change Team
            </Button>
          )}
        </Box>
      }

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="change-team"
      >
        <DialogTitle id="change-team">Change Team</DialogTitle>
        <DialogContent>
          <Box minWidth={300}>
            <TeamSelector
              onChange={handleChangeTeam}
              value={team}
              label="Transfer To Team"
            />
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                fullWidth
                label="By Date(first day in new team)"
                showTodayButton
                value={selectDate}
                onChange={(date) => setSelectDate(date)}
                format="yyyy/MM/DD"
              />
            </MuiPickersUtilsProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={submitConfitm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default ChangeTeam
