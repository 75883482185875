import { gql } from "@apollo/client"

const SEARCH_FILE = gql`
query File(
  $first: Int
  $page: Int
  $orderBy: [QueryFilesOrderByOrderByClause!]
  $where: QueryFilesWhereWhereConditions
) {
  files(first: $first, page: $page, orderBy: $orderBy, where: $where) {
    paginatorInfo {
      total
      currentPage
    }
    data {
      id
      status
      revisor_name
      creator_name
      filename
      content
      created_at
      updated_at
    }
  }
}
`

export default SEARCH_FILE