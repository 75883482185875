import React from "react"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Avatar from "@material-ui/core/Avatar"
import Box from "@material-ui/core/Box"
import useSession from "../../Hooks/useSession"
import { Menu, MenuItem } from "@material-ui/core"
import { navigate } from "@reach/router"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import useUserInfo from "../../Hooks/useUserInfo"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      padding: "4px 10px",
    },
    avatar: {
      width: 25,
      height: 25,
    },
  })
)

export default function UserMenu() {
  const classes = useStyles()
  const { logout, isLogin } = useSession()
  const { user, refetch } = useUserInfo()
  const [anchorEl, setAnchorEl] = React.useState<any>(null)
  const name = user ? user.name : "Unkonw"

  const renderAvatar = () => {
    return <Avatar className={classes.avatar} src={user?.avatar}></Avatar>
  }

  React.useEffect(() => {
    if (!isLogin) {
      // 如果已登录直接调转到欢迎界面
      navigate("/login")
    } else {
      refetch()
    }
  }, [isLogin, refetch])

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box position="relative">
      <Box
        className={classes.root}
        boxShadow={0}
        borderRadius={5}
        onClick={(event) => {
          setAnchorEl(event.currentTarget.nextSibling)
        }}
      >
        {renderAvatar()}
        <Box ml={2}>{name}</Box>
        <ExpandMoreIcon color="disabled" />
      </Box>
      <Box position="absolute" top="55px"></Box>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            navigate("/user/avatar")
          }}
        >
          Change Avatar
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/user/password")
          }}
        >
          Change Password
        </MenuItem>
        <MenuItem
          onClick={() => {
            navigate("/orginization/chart")
          }}
        >
          Organization Chart
        </MenuItem>

        <MenuItem onClick={logout}>Logout</MenuItem>
      </Menu>
    </Box>
  )
}
