import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core"
import { StringParam, useQueryParams } from "use-query-params"
import { DefaultNumberZero, downloadUrl } from "../../../Utils"
import FETCH_TBA_INTEGRAL_ALL from "../Gqls/FETCH_TBA_INTEGRAL_ALL"
import { useQuery } from "@apollo/client"
import { DataGrid, GridSortModel } from "@material-ui/data-grid"
import moment from "moment"
import AllUnitTeam from "../Components/AllUnitTeam"
import useAllUsers from "../../../Hooks/useAllUsers"

const columns = [
  // {
  //   headerName: "Index",
  //   field: "id",
  //   width: 100,
  // },
  { headerName: "User", field: "user", width: 200 },
  {
    headerName: "Total Used TBA",
    field: "used",
    width: 220,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value.toFixed(2)}
        </Box>
      )
    },
  },
  {
    headerName: "Total Remaining",
    field: "totalRemaining",
    width: 220,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value.toFixed(2)}
        </Box>
      )
    },
  },
  // { headerName: "Total Income", field: "income", width: 220 },
  {
    headerName: "To be expired in half year",
    field: "expire",
    width: 250,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value.toFixed(2)}
        </Box>
      )
    },
  },
]
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      background: "linear-gradient(0, #FFECD3, #FDF2E2)",
      fontSize: "16px",
      textAlign: "center",
      paddingTop: 32,
      paddingBottom: 32,
      borderRadius: "4px",
      "& span": {
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
    },
  })
)

const incomeFunc = (rows: any) => {
  let sum = 0
  rows.forEach((v: any) => {
    if (v.type !== 3 && v.type !== 5) {
      sum += Number(v.amount)
    }
  })
  return sum
}
const usedFunc = (rows: any) => {
  let used = 0
  rows.forEach((v: any) => {
    if (v.type === 3) {
      used -= Number(v.amount)
    }
  })
  return used
}
const outdatedFunc = (rows: any) => {
  let out = 0
  rows.forEach((v: any) => {
    if (v.type === 5) {
      out += Number(v.amount)
    }
  })
  return out
}
const expireInHalfYearFunc = (rows: any, used: any, outdated: any) => {
  const half = moment().add(6, "months")
  let expire = 0
  rows.forEach((v: any) => {
    if (v.expire_at && half.isSameOrAfter(moment(v.expire_at))) {
      expire += Number(v.amount)
    }
  })
  const res = expire - Number(used) + Number(outdated)
  return res > 0 ? res : 0
}

const Overview = (props: RouteComponentProps) => {
  const classes = useStyles()
  const { groupByUnit, groupByTeam } = useAllUsers()
  const [query] = useQueryParams({
    type: DefaultNumberZero,
    value: StringParam,
  })
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "totalRemaining",
      sort: "desc",
    },
  ])

  const { data, loading } = useQuery(FETCH_TBA_INTEGRAL_ALL, {
    fetchPolicy: "cache-and-network",
    variables: {},
  })

  // 再次过滤筛选已离职的、当前季度离职还保持
  const filterLeft = React.useMemo(() => {
    const array = data ? data.TbaIntegralLogs : []

    return array.filter((row: any) => {
      if (row.user) {
        const date = moment().format("YYYY-MM-DD")
        return row.user.term >= date
      }
      return false
    })
  }, [data])

  // 根据筛选条件unit/team用户
  const filterData = React.useMemo(() => {
    const rows = filterLeft
    if (query.type === 1 && query.value && groupByUnit[query.value]) {
      const userArray = groupByUnit[query.value].map((v: any) => v.id)
      return rows.filter((v: any) => userArray.includes(v.user_id))
    }
    if (query.type === 2 && query.value && groupByTeam[query.value]) {
      const userArray = groupByTeam[query.value].map((v: any) => v.id)
      return rows.filter((v: any) => userArray.includes(v.user_id))
    }
    if (query.type === 0) {
      return rows
    }
    return []
  }, [filterLeft, query, groupByTeam, groupByUnit])

  const rows = React.useMemo(() => {
    const users: any = {}
    // 按照用户group By
    filterData.forEach((row: any) => {
      if (!users[row["user_id"]]) {
        users[row["user_id"]] = []
      }
      users[row["user_id"]].push(row)
    })
    const rows: any[] = []
    for (let user_id in users) {
      const user = users[user_id][0].user.name
      const income = incomeFunc(users[user_id])
      const used = usedFunc(users[user_id])
      const outdated = outdatedFunc(users[user_id])
      const expire = expireInHalfYearFunc(users[user_id], used, outdated)
      const totalRemaining = Number(income) - Number(used) + Number(outdated)
      rows.push({
        id: user_id,
        user,
        income,
        used,
        expire,
        totalRemaining,
      })
    }
    return rows
  }, [filterData])

  const income = React.useMemo(() => {
    let income = 0
    rows.forEach((v: any) => {
      income += Number(v.income)
    })
    return income.toFixed(2)
  }, [rows])

  const used = React.useMemo(() => {
    let used = 0
    rows.forEach((v: any) => {
      used += Number(v.used)
    })
    return used.toFixed(2)
  }, [rows])

  const totalRemaining = React.useMemo(() => {
    return (Number(income) - Number(used)).toFixed(2)
  }, [income, used])

  const expireInHalfYear = React.useMemo(() => {
    let expire = 0
    rows.forEach((v: any) => {
      expire += Number(v.expire)
    })
    return expire.toFixed(2)
  }, [rows])

  // 数据导出
  const exportLink = React.useCallback(() => {
    const params: any = {}
    if (query.type === 1) {
      params.unit = query.value
    }
    if (query.type === 2) {
      params.team_id = query.value
    }
    downloadUrl("/api/export/training/overview", params)
  }, [query])

  return (
    <Box m="2%">
      <Box mb={2} display={"flex"} flexDirection={"row-reverse"}>
        <Button variant="contained" color="primary" onClick={exportLink}>
          Export
        </Button>
      </Box>
      <Box>
        <AllUnitTeam />
      </Box>
      <Box my={4}>
        <Grid container spacing={3}>
          <Grid item sm={4}>
            <Box
              className={classes.paper}
              style={{ background: "linear-gradient(0,#BBE7B9,#E2F7E5)" }}
            >
              Total Used: <span>￥{used}</span>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box
              className={classes.paper}
              style={{ background: "linear-gradient(0,#F6DADA,#FEF6F6)" }}
            >
              Total Remaining: <span>￥{totalRemaining}</span>
            </Box>
          </Grid>
          <Grid item sm={4}>
            <Box
              className={classes.paper}
              style={{ background: "linear-gradient(0,#D1E8F2,#EBF5FF)" }}
            >
              Total Expire In Half Year: <span>￥{expireInHalfYear}</span>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <DataGrid
          density={"compact"}
          loading={loading}
          sortModel={sortModel}
          onSortModelChange={(sortModel) => {
            setSortModel(sortModel)
          }}
          disableColumnMenu={true}
          hideFooterSelectedRowCount
          autoHeight
          rows={rows}
          columns={columns}
        />
      </Box>
    </Box>
  )
}

export default Overview
