import { createMuiTheme } from "@material-ui/core"
import red from "@material-ui/core/colors/red"

const theme = createMuiTheme({
  spacing: 4,
  palette: {
    background: {
      // default: "white",
    },
    primary: {
      main: red[500],
    },
    secondary: {
      main: "#3f51b5",
    },
  },
  props: {
    // MuiButtonBase: {
    //   disableRipple: true, // button 水波纹效果
    // },
    MuiButton: {
      size: "small",
      disableElevation: true
    }
  },
  overrides: {
    MuiChip: {
      deletable: {
        height: "20px"
      },
      deleteIcon: {
        height: "12px",
        width: "12px"
      }
    },

    MuiCssBaseline: {
      "@global": {
        body: {
          fontFamily: '"Calibri", "Arial", "Roboto", "Helvetica"'
        }
      }
    },
    MuiTooltip: {
      popper: {
        pointerEvents: "auto"
      }
    },
    // @ts-ignore
    MuiDataGrid: {
      root: {
        "& .MuiDataGrid-footerContainer": {
          justifyContent: "start"
        }
      }
    },
    MuiAccordionSummary: {
      root: {
        height: 30,
        "& .Mui-expanded": {
          minHeight: 30
        }
      },
      content: {
        margin: 0
      }
    },
    MuiListItem: {
      root: {
        paddingTop: 4,
        paddingBottom: 4
      },
      gutters: {
        paddingLeft: 8,
        paddingRight: 8,
      }
    },
    MuiButton: {
      root: {
        minWidth: 30,
        textTransform: "inherit"
      }
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: 0,
        marginBottom: 0
      }
    },

    MuiTypography: {
      subtitle1: {
        fontSize: 20,
      },
      h4: {
        fontSize: 16
      }
    }
  },
})

export default theme
