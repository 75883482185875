import { useCallback } from "react"
import Box from "@material-ui/core/Box"
import { RouteComponentProps } from "@reach/router"
import { DropzoneArea } from "material-ui-dropzone"
import { useMutation } from "@apollo/client"
import UPLOAD_REPORT from "../Gqls/UPLOAD_TRAINING"
import { toast } from "material-react-toastify"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useQueryParam } from "use-query-params"
import { DefaultNumberOne, downloadUrl } from "../../../Utils"
import { Button } from "@material-ui/core"
import ImportLog from "../../../Components/ImportLog"

const Import = (props: RouteComponentProps) => {
  const [platform, setPlatform] = useQueryParam("platform", DefaultNumberOne)

  // 上传报告
  const [uploadReport, { loading: uploading }] = useMutation(UPLOAD_REPORT, {
    // onCompleted: (data) => {
    //   if (data.uploadTraining.code === 0) {
    //     showMessage("success", "success")
    //   } else {
    //     const list = data.uploadTraining.message.split("<br/>")
    //     toast.error(() => list.map((v: any) => <Box>{v}</Box>), {
    //       position: "top-center",
    //       autoClose: 3000,
    //       closeOnClick: false,
    //       draggable: false,
    //     })
    //   }
    // },
  })
  // 上传
  const uploadFiles = useCallback(
    async (loadedFiles: File[]) => {
      if (loadedFiles.length === 0) {
        return
      }
      let sum = 0
      let index = 0
      let total = loadedFiles.length
      loadedFiles.forEach(async (file) => {
        const res = await uploadReport({
          variables: { file, platform },
        })
        if (res.data.uploadTraining.code === 0) {
          sum++
        }
        index++
        if (index === total) {
          toast.info(`imported success ${sum}. faild ${total-sum}` ,{
            position: "top-center",
            autoClose: 5000,
            closeOnClick: false,
            draggable: false,
          })
        }
      })
    },
    [uploadReport, platform]
  )
  return (
    <Box mx={[2, 4, 8]} py={5} mt={5}>
      <Box my={4} component="h1">
        Import External Training
      </Box>
      <Box mb={2} display="flex">
        <Box>
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setPlatform(1)
            }}
            color={platform === 1 ? "primary" : undefined}
          >
            得到
          </Button>
        </Box>
        <Box ml={2} display="flex" alignItems="center">
          <Button
            variant="contained"
            disableElevation
            onClick={() => {
              setPlatform(2)
            }}
            color={platform === 2 ? "primary" : undefined}
          >
            Python
          </Button>
          <Box
            ml={2}
            color="primary.main"
            style={{ cursor: "pointer" }}
            onClick={() => {
              downloadUrl("/tba_example.xlsx")
            }}
          >
            example.xlsx
          </Box>
        </Box>
      </Box>
      <Box mb={5}>
        {uploading && <CircularProgress />}
        {!uploading ? (
          <Box>
            <DropzoneArea
              showPreviewsInDropzone={false}
              showFileNames={true}
              onChange={uploadFiles}
              filesLimit={20}
              showAlerts={false}
              acceptedFiles={[".xls", ".xlsx"]}
            />
          </Box>
        ) : null}
      </Box>
      {!uploading ? <ImportLog type={2} /> : null}
    </Box>
  )
}

export default Import
