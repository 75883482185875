import * as React from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api"
import { useApolloClient } from "@apollo/client"
import BECOME_FORMAL from "../Gqls/BECOME_FORMAL"
import CANCEL_BECOME_FORMAL from "../Gqls/CANCEL_BECOME_FORMAL"
import USER_TO_LEAVING from "../Gqls/USER_TO_LEAVING"
import USER_CANCEL_LEAVING from "../Gqls/USER_CANCEL_LEAVING"
import moment from "moment"
import ChangeTeam from "../Component/ChangeTeam"
import { useConfirm } from "material-ui-confirm"
import UpdateJobLevel from "./UpdateJobLevel"

const getTypeAndStatus = (status: string, type: string) => {
  if (status === "Leaving" || status === "Left") {
    return status
  }
  return type
}

const UserStatusInfo = (props: any) => {
  const formApi = useFormApi()
  const confirm = useConfirm()
  const [dialogTXT, setDialogTXT] = React.useState<any>({
    title: ``,
    content1: ``,
    content2: ``,
    label: ``,
  })
  const values = formApi.getState().values
  const [open, setOpen] = React.useState(false)
  const [selectDate, setSelectDate] = React.useState<any>()

  const graphqlClient = useApolloClient()

  const submitBecomeFormal = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: BECOME_FORMAL,
      variables: {
        id: values.id,
        formal_date: moment(selectDate).format("YYYY-MM-DD"),
      },
    })
  }, [graphqlClient, selectDate, values])

  const submitLeaving = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: USER_TO_LEAVING,
      variables: {
        id: values.id,
        term: moment(selectDate).format("YYYY-MM-DD"),
      },
    })
  }, [graphqlClient, selectDate, values])

  const submitCancelLeaving = React.useCallback(async () => {
    return graphqlClient.mutate({
      mutation: USER_CANCEL_LEAVING,
      variables: {
        id: values.id,
        term: moment(selectDate).format("YYYY-MM-DD"),
      },
    })
  }, [graphqlClient, selectDate, values])

  const submitConfitm = async () => {
    if (dialogTXT.title === "Become Formal") {
      await submitBecomeFormal()
      window.history.back()
    }
    if (dialogTXT.title === "Leaving") {
      await submitLeaving()
      window.history.back()
    }
    if (dialogTXT.title === "Cancel Leaving") {
      await submitCancelLeaving()
      window.history.back()
    }
  }
  // 取消转正
  const cancelBecomeFormal = async () => {
    await confirm({
      title: "warning!",
      description: "Cancel Become Formal Setting?",
    })
    await graphqlClient.mutate({
      mutation: CANCEL_BECOME_FORMAL,
      variables: {
        id: values.id,
      },
    })

    window.history.back()
  }
  //
  const LeavingContext = React.useMemo(() => {
    if (values.status === "Leaving") {
      return ` (The last day has been set to ${values.term})`
    }
    return ""
  }, [values])
  //
  const ProbationContext = React.useMemo(() => {
    if (values.type === "Probation" && values.formal_date) {
      return ` (The user has been set to "Become formal" on ${values.formal_date})`
    }
    return ""
  }, [values])

  const handleOpenBecomeFormal = () => {
    setDialogTXT({
      title: `Become Formal`,
      content1: `Please confirm that ${values.name} will become formal`,
      content2: `Note This operation cannot be undone`,
      label: `By Day`,
    })
    setSelectDate(values.formal_date)
    setOpen(true)
  }
  const handleOpenLeaving = () => {
    setDialogTXT({
      title: `Leaving`,
      content1: `Please confirm that ${values.name} will left`,
      content2: ``,
      label: `Last Day`,
    })
    setSelectDate(values.term)
    setOpen(true)
  }
  const handleOpenCancelLeaving = () => {
    setDialogTXT({
      title: `Cancel Leaving`,
      content1: `Please confirm that ${values.name} will cancel Leaving`,
      content2: `reset last day`,
      label: `Last Day`,
    })
    setSelectDate(values.term)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const showBecomeFormal = React.useMemo(() => {
    if (values.type === "Formal") {
      return false
    }
    if (values.status === "Left") {
      return false
    }
    if (values.status === "Leaving") {
      return false
    }
    return true
  }, [values])
  const mixStatus = getTypeAndStatus(values.status, values.type)
  return (
    <Box flexGrow={1} fontSize={14} p="6px">
      <ChangeTeam values={values} />
      <Box display="flex" alignItems="center" height={30} mt={2}>
        <Box color="text.secondary" fontWeight="bold">
          Status:
        </Box>
        <Box ml={2}>
          {mixStatus}
          {LeavingContext ? LeavingContext : null}
          {ProbationContext ? ProbationContext : null}
        </Box>
        {showBecomeFormal ? (
          <Box display="flex">
            <Box ml={2}>
              {ProbationContext ? (
                <Box>
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleOpenBecomeFormal}
                  >
                    Change Become formal Time
                  </Button>
                  <Button
                    style={{ marginLeft: 8 }}
                    variant="contained"
                    size="small"
                    onClick={cancelBecomeFormal}
                  >
                    Cancel
                  </Button>
                </Box>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  onClick={handleOpenBecomeFormal}
                >
                  Become Formal
                </Button>
              )}
            </Box>
          </Box>
        ) : null}
        {["Active", "Leaving"].includes(values.status) && !ProbationContext ? (
          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenLeaving}
            >
              Enter Leaving Process
            </Button>
          </Box>
        ) : null}
        {values.status === "Leaving" ? (
          <Box ml={2}>
            <Button
              variant="contained"
              size="small"
              onClick={handleOpenCancelLeaving}
            >
              Cancel Leaving
            </Button>
          </Box>
        ) : null}
      </Box>
      <Box display="flex" alignItems="center" height={30} mt={2}>
        <Box color="text.secondary" fontWeight="bold">
          Leave Status:
        </Box>
        <Box ml={2}>
          {values.status === "Left" ? "Left" : values.leave_status_name}
        </Box>
      </Box>

      <UpdateJobLevel values={values} />

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="become-formal-title"
      >
        <DialogTitle id="become-formal-title">{dialogTXT.title}</DialogTitle>
        <DialogContent>
          <Box width={300}>
            <DialogContentText>{dialogTXT.content1}</DialogContentText>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                fullWidth
                label={dialogTXT.label}
                value={selectDate}
                showTodayButton
                onChange={(date) => setSelectDate(date)}
                format="yyyy/MM/DD"
              />
              <DialogContentText>{dialogTXT.content2}</DialogContentText>
            </MuiPickersUtilsProvider>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={submitConfitm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default UserStatusInfo
