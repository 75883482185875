import { gql } from "@apollo/client"

const ALL_PROJECT_PURCHASE_MINI = gql`
  query AllProjectsPurchasesMini(
  $number: String
  $team: String
  $project: QueryAllProjectPurchasesProjectWhereHasConditions
  $status: String
  $start: DateRange
  $end: DateRange
) {
  allProjectPurchases(
    number: $number
    team: $team
    project: $project
    status: $status
    start: $start
    end: $end
  ) {
    id
    number
    project_slug
    project {
      id
      slug
      name
    }
    members {
      id
      abbr
      slug
      name
    }
    start
    end
    team
  }
}
`

export default ALL_PROJECT_PURCHASE_MINI