import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"
import { Types} from './index'

const getCreateSchema = () => {
  const schema = {
    fields: [
      {
        component: FieldTypes.SUB_FORM,
        name: "subform",
        ItemsGridProps: {
          className: "custom-form-border",
          spacing: 3,
          sm: 12,
          container: true
        },
        fields: [
          {
            name: "name",
            label: "Team Name",
            FormFieldGridProps: {
              sm: 12,
            },
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "myUnit",
            label: "Unit",
            component: "UnitSelectorField",
            isRequired: true,
            width: "100%",
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
            FormFieldGridProps: {
              sm: 6,
            },
            TextFieldProps: {
              margin: "none",
            },
          },
          {
            name: "type",
            label: "Type",
            component: FieldTypes.SELECT,
            isRequired: true,
            options: Types,
            FormFieldGridProps: {
              sm: 6,
            },
            TextFieldProps: {
              margin: "none",
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },

          {
            name: "managers",
            component: 'TeamManager',
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
        ],
      }
    ]
  };

  return schema
}

export default getCreateSchema
