import * as React from "react"

import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { useQueryParams } from "use-query-params"
import { DefaultNumberZero, DefaultYear } from "../../../Utils"
const names = [
  "First Half",
  "Second Half",
]
const YearSearch = (props: any) => {
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    type: DefaultNumberZero,
  })

  const handleDateChange = (v: any) => {
    setQuery({ year: v.format("YYYY") + "" })
  }

  const QuickSelect = React.useMemo(() => {
    if (!query.year) {
      return null
    }
    return (
      <Box display="flex">
        {names.map((title, index) => {
          const value = index
          const active = value === query.type
          return (
            <Box ml={2} key={value}>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setQuery({ type: value })
                }}
                color={active ? "primary" : undefined}
              >
                {title}
              </Button>
            </Box>
          )
        })}
      </Box>
    )
  }, [query, setQuery])

  return (
    <Box flexGrow={1} boxShadow={1}>
      <Box display="flex" p={2} justifyContent="space-between">
        <Box display="flex">
          <Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                views={["year"]}
                placeholder="choose year"
                clearable={false}
                value={query["year"] ? query["year"] : null}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </Box>
          {QuickSelect}
        </Box>
      </Box>
    </Box>
  )
}

export default YearSearch
