import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react"

const ALL_USER = gql`
  query AllUsers {
    allUsers {
      id
      name
      avatar
      email
      created_at
      updated_at
      roles{
        id
        name
      }
      myTeam {
        id
        name
        type
        type_name
      }
      type
      token
      slug
      abbr
      unit
      team_id
      team
      eid
      start
      term
      birthday
      phone
      status
      formal_date
      job_level
      job_title
      competency
      menu
    }
  }
`

const useAllUsers = () => {
  const { data, loading, error } = useQuery(ALL_USER, {
    fetchPolicy: "cache-and-network",
  })

  const users = useMemo(() => (loading || error ? [] : data.allUsers), [data, loading, error])

  const groupByUnit = useMemo(() => {
    const obj: any = {}
    users.forEach((user: any) => {
      if (!obj[user.unit]) {
        obj[user.unit] = [];
      }
      obj[user.unit].push(user);
    })
    return obj;
  }, [users])

  const groupByTeam = useMemo(() => {
    const obj: any = {}
    users.forEach((user: any) => {
      if (!obj[user.team_id]) {
        obj[user.team_id] = [];
      }
      obj[user.team_id].push(user);
    })
    return obj;
  }, [users])


  const name2avatar = useMemo(() => {
    const obj: any = {}
    users.forEach((user: any) => {
      obj[user.name] = user.avatar
    })
    return obj;
  }, [users])
  return { users, loading, groupByUnit, groupByTeam,name2avatar }
}

export default useAllUsers