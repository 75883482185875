const release = [
  {
    "version": "2.1.0",
    "at": "2022-01-16",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Added the KPI Bonus Overview and Job Level Overview in the Dashboard module.',
          'Updated the homepage to the My Information page for all.',
          'Added Role Management module for admin.',
          'Fixed the bug of TBA expired failed issue.',
        ]
      }
    ]
  },
  {
    "version": "2.0.4",
    "at": "2022-09-28",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Added export function for TBA overview (only for Country Manager)',
          'Fixed all the data related to “Leaving” and “Left” including KPI, team management, and organization chart.',
          'Verified all the key data about KPI and included all the team managers in the KPI',
          'Added operation log info for Job Level.',
        ]
      }
    ]
  },
  {
    "version": "2.0.3",
    "at": "2022-08-25",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Added competency attribute in user management and organization chart module.',
          'Added OKR tracking chart on My Team OKR page.',
        ]
      }
    ]
  },
  {
    "version": "2.0.2",
    "at": "2022-08-02",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Added Documents module to publish all the company policy and guides.',
          'Configured admin rights for Shaoli.',
          'Added email send and receive statistics to operation log for admin.',
          'Added ICP info on the login page.'
        ]
      }
    ]
  },
  {
    "version": "2.0.1",
    "at": "2022-07-17",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Added function of uploading your photo as avatar on the top right corner of the page for all.',
          'Added function of viewing organization chart information on the top right on the top right corner of the page.',
          'Optimized the UI of user management for managing the different status of all consultants.',
          'Supported exporting operation log by searching result.'
        ]
      }
    ]
  },
  {
    "version": "2.0.0",
    "at": "2022-06-22",
    "items": [
      {
        "name": "Overview: ",
        "items": [{
          "title": "Upgrade the ERP system security mechanism from the back-end and front-end, following the rules:",
          "items": [
            'Changing password mandatorily for 1st login of new users',
            'Updating password requirement to follow Sigma general password requirement',
            'Locking account when entering the password repeatedly',
            'Login again after timeout',
            'Changing password regularly',
          ]
        },
          'Added function (Optional for Team Manager): Sending email about ERP account info automatically by ERP after creating new users in ERP. ',
          'Update the Login page: Login by both Sigma email and Sigma ID, reset password by all employees.',
        ]
      }
    ]
  },
  {
    "version": "1.2.4",
    "at": "2022-06-10",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Modified all the probation data of all employees from 2021 to 2022 on the Probation Data Analysis page.',
          'Added the User Name to search filer on the Probation Data Analysis.',
          'Added the Gender attribute on the User Management page and supported to search by gender attribute.',
          'Included all the operations to operation logs in the Maintenance module.'
        ]
      }
    ]
  },
  {
    "version": "1.2.3",
    "at": "2022-05-29",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          // 'New Data Validation Page for PO/User Allocation',
          // 'Data Importing Inspection Improvements for "Import PX Report", "Import KPI Data" and "Import External Training"',
          // 'DevOps Deployment on CodeUp',
          // '[Internal] Test Automation Improvement: add 13 API interface test cases',

          'Open the view permission of Dashboard module for all team managers and finance.',
          'Optimized the data presentation for Dashboard module.',
          'Added the Leave Data Analysis and Probation Data Analysis in the Teams&Users module (optional for all managers).',
          'Added the PO Assignment page to check if all users have been assigned PO on the PO management page (optional for finance).',
          'Added Diagnostic module (dummy data) in the Maintenance module (optional for country manager).'

        ],
      }
    ]
  },
  {
    "version": "1.2.2",
    "at": "2022-03-31",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Added the Team Building item on the bottom of Allocate My Team Bonus page.',
          'Changed the unit number to align with the unit number in PX.',
          'Separated the role permission of Admin and Finance.',
          'Fixed the bug of bonus balance for rainbow team due to the team change.',
          'Added the bonus balance of last quarter on the Archive Bonus page.',
          'Added the sorting and export function for the external trainings on the TBA management.',
          'Fixed the OKR of team member wrong location due to team change.'
        ],
      }
    ]
  },
  {
    "version": "1.2.1",
    "at": "2021-12-23",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Showed detailed import info after importing all the data in the Maintenance module.',
          'Added monthly attendance rate chart in the Dashboard module.',
          'Optimized data display in the Dashboard module.',
          'Added sorting for remaining rate in the PO Management module.',
          'Added sorting for total remaining TBA in the TBA Overview module.',
          'Fix the bug of uncomplete search result.',
        ],
      }
    ]
  },
  {
    "version": "1.2.0",
    "at": "2021-12-14",
    "items": [
      {
        "name": "Overview: ",
        "items": [
          'Added TBA Overview module to view the detailed TBA data of all employees by All, Unit, and Team.',
          'Added Import External Trainings module to import the external trainings such as Dedao, Python.',
          'Improved the process of user status update for user management module.',
          'Supported to verify the data integrity when importing PX reports and KPI data.',
          'Supported to export monthly, quarterly, and yearly project reports.',
          'Move the page turning to the left bottom for all pages.',
          'Supported to sort by month and team for leave management module.',
          'Fixed the bug of logging issue using Firefox explorer.',
          'Fixed the bug of exported file for training management module.',
        ],
      }
    ]
  },
  {
    "version": "1.1.2",
    "at": "2021-11-28",
    "items": [
      {
        name: "Overview: ",
        items: [
          'Added chart view for all yearly project reports on Project Report page of Project module.',
          'Supported to export the yearly project reports and detailed data.',
          'Synced the team members in Team Management with the data of User Management.',
          'Supported to export all the trainings for Training Management module',
        ]
      },
    ]
  },
  {
    "version": "1.1.1",
    "at": "2021-11-24",
    "items": [
      {
        name: "Overview: ",
        items: [
          'Reorganized the menus in the navigation panel and folded all the 2nd level menus in default view.',
          'Added chart view for yearly project reports for all projects.',
          'Showed data of the past months for all charts of dashboard.'
        ]
      },
    ]
  },
  {
    "version": "1.1.0",
    "at": "2021-11-21",
    "items": [
      {
        name: "Overview: ",
        items: [
          'Added Team Management and Team Change module to manage team members in Teams & Users module.',
          'Updated the Leave Management module. Managers need to create special long leaves by month to view Attendance Rate in KPI module by month.',
          'Updated the User Management module to manage the process of the team and leave of your team members in a new way.',
          'Added management Dashboard module.'
        ]
      },
    ]
  },
  {
    "version": "1.0.6",
    "at": "2021-11-04",
    "items": [
      {
        name: "Overview: ",
        items: [
          "Added TBA Management module. You could check TBA details, Used TBA Ranking, and Earned Training Fund.",
          "For team managers, updated “Create User” and “Update User” process to include the type from probation to formal, and status from formal to leaving and left.",
          "For team managers, added the used TBA value for every team member on “Allocate My Team Bonus page”.",
        ]
      },
    ]
  },
  {
    "version": "1.0.5",
    "at": "2021-10-18",
    "items": [
      {
        name: "Overview: ",
        items: [
          "Modified the algorithm of Attrition Rate in KPI Management module.",
          "Limited the permission for User Management module by roles. Team managers could only edit the user info of your team members. ",
          "Added the function of Import PX data in batch.",
          "Value type of all KPI Parameters could be modified by country manager. TBA has been changed from percentage to number.",
          "PO management list could be exported when the search criteria are set.",
          "Consultant could not view team KPI until the country manager archive the team KPI of this quarter.",
        ]
      },
    ]
  },
  {
    "version": "1.0.4",
    "at": "2021-10-12",
    "items": [
      {
        name: "For all users: ",
        items: [
          "Fixed the bug in OKR Management module-inconsistent progress on difference pages.",
        ]
      },
      {
        name: "For manager team: ",
        items: [
          "Add Leave Management Module for the long leaves.",
          "Exclude the attendance rate of team members with special long leaves from the team KPI. ",
          "Add Fresh permission on the All Teams KPI page.",
          "Add “Team” and “Manager” items in the Project Management module and sync the team info in the PO Management with it. ",
          "Add “Special Long Leave” items for status in the User Management module.",
          "Add detailed attendance info for team members on the Allocate My Team Bonus page.",
        ]
      },
      {
        name: "For finance team: ",
        items: [
          "Fixed the bug in PO Management module- add more POs for one user.",]
      }
    ]
  },
  {
    "version": "1.0.3",
    "at": "2021-09-23",
    "items": [
      {
        name: "New features: ",
        items: [
          "Add Bonus module"
        ]
      }
    ]
  },
  {
    "version": "1.0.2",
    "at": "2021-09-12",
    "items": [
      {
        name: "New features: ",
        items: [
          "Add KPI module"
        ]
      }
    ]
  },
  {
    "version": "1.0.1",
    "at": "2021-09-05",
    "items": [
      {
        name: "New features: ",
        items: [
          "Add product revision and release note"
        ]
      }, {
        name: "Bugs fixed: ",
        items: [
          "My OKRs disappear after page switching",
          "Navigation menu icon display problem at slow loading speed"
        ]
      }
    ]
  },
  {
    "version": "1.0.0",
    "at": "2021-08-31",
    "items": [{
      name: "New features: ",
      items: [
        "OKR Module",
        "Add access control based on roles"
      ]
    }]
  }
];

export default release;