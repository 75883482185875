import * as React from "react"
import { useMemo } from "react"
import { RouteComponentProps } from "@reach/router"
import Filter from "../../../Components/Filter"
import TeamOkrList from "../teamList"
import { useQuery } from "@apollo/client"
import { CircularProgress, Box } from "@material-ui/core"
import { useQueryParams, JsonParam } from "use-query-params"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"
import OKR_LIST_VIEW from "../Gqls/OKR_LIST_VIEW"
import OkrAllChart from "../Components/OkrAllChart"

const AllOkr = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())

  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
    search: JsonParam,
  })
  const { teamIds, userIds } = useMemo(() => {
    const teamIds: any = []
    const userIds: any = []
    query.search &&
      query.search.forEach((v: any) => {
        if (v.team_id) {
          teamIds.push(v.team_id)
        }
        if (v.user_id) {
          userIds.push(v.user_id)
        }
      })
    return { teamIds, userIds }
  }, [query])

  const variables = useMemo(() => {
    return {
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
      team_id: teamIds,
      user_id: userIds,
    }
  }, [query, teamIds, userIds])
  // 调用搜索数据API
  const { loading, data } = useQuery(OKR_LIST_VIEW, {
    variables: variables,
    fetchPolicy: "cache-and-network",
  })
  // 筛选过滤器
  const expanded = useMemo(() => {
    if (userIds && userIds.length > 0) {
      return 1
    }
    return 0
  }, [userIds])

  return (
    <Box m="2%">
      <Box display="flex" flexDirection="row-reverse">
        <Filter
          hasFilter={true}
          data={query}
          handleSubmit={(value: any) => setQuery(value)}
        ></Filter>
      </Box>
      <Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            <TeamOkrList data={data.okrListView} expandedType={expanded} />
          </Box>
        )}
        <Box>
          <OkrAllChart />
        </Box>
      </Box>
    </Box>
  )
}

export default AllOkr
