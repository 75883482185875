import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react";

const FETCH_MENU = gql`
  query Menus{
    menus{
      name
      value
      children{
        name
        value
        children{
          name
          value
        }
      }
    }
  }
`

const useAllMenu = () => {
  const { data, loading, error } = useQuery(FETCH_MENU, {
    fetchPolicy: "cache-and-network",
  })

  const menus = useMemo(() => {
    return loading || error ? [] : data.menus;
  }, [loading, data, error])


  const menusList = useMemo(() => {
    const arr: any = [];
    menus.forEach(v => {
      arr.push({ name: v.name, value: v.value })
      if (v.children) {
        v.children.forEach(vv => {
          arr.push({ name: vv.name, value: vv.value })
          if (vv.children) {
            vv.children.forEach(vvv => {
              arr.push({ name: vvv.name, value: vvv.value })
            })
          }
        })
      }
    });
    return arr;
  }, [menus])

  const menusListValues = useMemo(() => {
    return menusList.map(v => v.value);
  }, [menusList])

  return { menus, loading, menusList ,menusListValues}
}

export default useAllMenu