import { gql } from "@apollo/client"

const FETCH_KPI_TEAM_EDITOR = gql`
query FetchKpiTeamEditor($year: Int!, $quarter: Int!) {
  kpiTeamEditor(year: $year, quarter: $quarter) {
    id
    team_id
    team{
      id
      name
    }
    content {
      column
      value
    }
  }
}
`
export default FETCH_KPI_TEAM_EDITOR