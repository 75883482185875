import { gql } from "@apollo/client"

const ALL_PROJECT_PURCHASE = gql`
  query AllProjectsPurchases (
      $number: String
      $team: String
      $project: QueryAllProjectPurchasesProjectWhereHasConditions
      $status: String
    ) {
    allProjectPurchases(
      number: $number
      team: $team
      project: $project
      status: $status
    ) {
      id
      number
      project_slug
      project {
        id
        slug
        name
      }
      members {
        id
        abbr
        slug
        name
      }
      start
      end
      total_amount
      remainingAmount
      remainingRate
      remainingDays
      status
      created_at
      updated_at
      team
    }
  }
`

export default ALL_PROJECT_PURCHASE