import { Schema } from "@data-driven-forms/react-form-renderer"
import { FieldTypes, ValidatorTypes } from "../../Components/DataGrid/Form"

export const searchMonthSchema = () => {
  const filterSchema: Schema = {
    fields: [
      {
        name: "project_slug",
        label: "ProjectID",
        component: FieldTypes.TEXT_FIELD,
      },
      {
        name: "project",
        label: "Project",
        component: 'ProjectSelector'
      },
      {
        name: "team",
        label: "Team",
        component: "TeamSelectorField",
        width: "100%",
      },
      {
        name: "month",
        component: FieldTypes.DATE_PICKER,
        DatePickerProps: {
          clearable: true,
          label: "Year Month",
          views: ["year", "month"],
        },
      }
    ],
  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}
export const searchQuarterSchema = () => {
  const filterSchema: Schema = {
    fields: [
      {
        name: "project_slug",
        label: "ProjectID",
        component: FieldTypes.TEXT_FIELD,
      },
      {
        name: "project",
        label: "Project",
        component: 'ProjectSelector'
      },
      {
        name: "team",
        label: "Team",
        component: "TeamSelectorField",
        width: "100%",
      },
      {
        name: "year",
        component: FieldTypes.DATE_PICKER,
        DatePickerProps: {
          clearable: true,
          label: "Year",
          views: ["year"],
        },
      },

      {
        component: "select",
        name: "quarter",
        isClearable: true,
        options: [
          { "value": "1", "label": "Q1" },
          { "value": "2", "label": "Q2" },
          { "value": "3", "label": "Q3" },
          { "value": "4", "label": "Q4" }
        ],
        label: "Quarter",
        TextFieldProps: {
          margin: "none",
        },
      }
    ],
  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}

export const searchYearSchema = () => {
  const filterSchema: Schema = {
    fields: [
      {
        name: "project_slug",
        label: "ProjectID",
        component: FieldTypes.TEXT_FIELD,
      },
      {
        name: "project",
        label: "Project",
        component: 'ProjectSelector'
      },
      {
        name: "team",
        label: "Team",
        component: "TeamSelectorField",
        width: "100%",
      },
      {
        name: "year",
        component: FieldTypes.DATE_PICKER,
        DatePickerProps: {
          clearable: true,
          label: "Year",
          views: ["year"],
        },
      },

    ],
  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}

export const getSearchDashboardSchema = (filter: any, onChange: Function) => {
  const yearOptions: any[] = []
  for (let i = 2021; i <= 2050; i++) {
    yearOptions.push({
      "label": i + "",
      "value": i,
    })
  }

  const monthOptions: any[] = []
  for (let i = 1; i <= 12; i++) {
    monthOptions.push({
      "label": i + "",
      "value": i,
    })
  }

  const quarterlyOptions: any[] = []
  for (let i = 1; i <= 4; i++) {
    quarterlyOptions.push({
      "label": i + "",
      "value": i,
    })
  }


  const filterSchema: Schema = {
    fields: [
      {
        name: "project",
        label: "Project",
        component: 'ProjectSelector',
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
        initialValue: filter && filter.project && filter.project.id
      },
      {
        name: "type",
        label: "Report Type",
        component: FieldTypes.SELECT,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
        isSearchable: false,
        initialValue: filter && filter.type,
        options: [
          {
            "label": "Customized Report",
            "value": "0"
          },
          {
            "label": "Monthly Report",
            "value": "1"
          },
          {
            "label": "Quarterly Report",
            "value": "2"
          },
          {
            "label": "Yearly Report",
            "value": "3"
          }
        ]
      },
      {
        name: "year",
        label: "Year",
        component: FieldTypes.SELECT,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
        options: yearOptions,
        initialValue: filter && filter.year,
        condition: {
          when: 'type',
          is: "0",
          notMatch: true,
        }
      },
      {
        name: "quarter",
        label: "Quarter",
        component: FieldTypes.SELECT,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
        options: quarterlyOptions,
        condition: {
          when: 'type', // name of controlled field
          is: "2", // condition
        },
        initialValue: filter && filter.quarter
      },
      {
        name: "month",
        label: "Month",
        component: FieldTypes.SELECT,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
        options: monthOptions,
        condition: {
          when: 'type', // name of controlled field
          is: "1", // condition
        },
        initialValue: filter && filter.month
      },
      {
        name: "start_period",
        label: "Start Period",
        component: FieldTypes.TEXT_FIELD,
        isRequired: true,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
        initialValue: filter && filter.start_period,
        condition: {
          when: 'type',
          is: "0",
        },
      },
      {
        name: "end_period",
        label: "End Period",
        component: FieldTypes.TEXT_FIELD,
        isRequired: true,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
        initialValue: filter && filter.end_period,
        condition: {
          when: 'type',
          is: "0",
        },
      },
      // {
      //   name: 'listener',
      //   hideField: true,
      //   component: 'FormWatcher',
      //   onChange
      // }
    ],
  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}


export const createActivitiesHours = (labelData: [string], data: [number]) => {
  return {
    title: {
      text: 'Total working hours for activities',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    grid: {
      left: 10,
      right: 10,
      bottom: 10,
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: labelData,
      // axisLabel: {
      //   left:"left",
      //   overflow: "break",
      //   width: 200
      // }
    },
    series: [
      {
        type: 'bar',
        data: data,
        label: {
          show: true,
          position: 'right'
        },
        barWidth: 20,//固定柱子宽度
        showBackground: true,
      }
    ]
  }
}

export const createMemberHours = (nameData: [string], labelData: [string], data: [number], lineNumber: number) => {
  return {
    title: {
      text: 'Working hours for project members',
    },

    grid: {
      left: 20,
      right: 30,
      bottom: 20,
      containLabel: true
    },
    xAxis: {
      type: "category",
      data: labelData,
      axisLabel: {
        width: 50,
        overflow: 'break',
        interval: 0,
      },
    },
    yAxis: {
      type: "value",
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        const name = nameData[params[0].dataIndex];
        let content = '<div>' + name + '</div>';
        params.forEach((v: any) => {
          content += '<div>' + v.marker + v.value + '</div>';
        })
        return content
      },
    },
    series: [
      {
        barWidth: 20,//固定柱子宽度
        data: data,
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
        label: {
          show: true,
          position: 'top'
        },
        markLine: {
          data: [
            // { type: 'average', name: '平均值' },
            {
              name: 'Y 轴值为 168 的水平线',
              yAxis: lineNumber
            },
          ]
        }
      },

    ],
  }
};



// const tip = "Your project is not in the system, please create your project first!";

// const tip2 = "No PO is available for your project, please create your PO first!";




export const createProjectRevenue = (data: any) => {
  const labelData = data.name;
  const revenue = data.revenues;
  const hour = data.hours;
  return {
    title: {
      text: 'Project Revenue',
    },
    // tooltip: {
    //   trigger: 'axis',
    //   axisPointer: {
    //     type: 'shadow'
    //   }
    // },
    legend: {
      right: "right",
      selected: {
        revenue: true,
        hour: false,
      }
    },
    grid: {
      left: 20,
      right: 30,
      bottom: 20,
      containLabel: true
    },
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let content = '<div>' + params[0].axisValue + '</div>';
        params.forEach((v: any) => {
          if (v.seriesName === "revenue") {
            content += '<div>' + v.marker + v.value + ' RMB</div>';
          } else {
            content += '<div>' + v.marker + v.value + ' Hrs</div>';
          }
        })
        return content
      },
    },
    xAxis: {
      type: "category",
      data: labelData,
      axisLabel: {
        width: 50,
        overflow: 'break',
        interval: 0,
      },
    },
    yAxis: [{ type: "value" }, { type: "value" }],
    series: [
      {
        name: "revenue",
        barWidth: 20,//固定柱子宽度
        data: revenue,
        type: "bar",
        showBackground: true,
        yAxisIndex: 0
      },
      {
        name: "hour",
        barWidth: 20,//固定柱子宽度
        data: hour,
        type: "bar",
        showBackground: true,
        yAxisIndex: 1
      },
    ],
  }
}