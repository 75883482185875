import React, { useCallback } from "react"
import { RouteComponentProps } from "@reach/router"
import { useApolloClient } from "@apollo/client"
import UPDATE_PASSWORD from "./Gqls/UPDATE_PASSWORD"
import { Box } from "@material-ui/core"
import DataEditor from "../../Components/DataGrid/DataEditor"
import { navigate } from "@reach/router"
import updatePasswordSchema from "./Schemas/updatePasswordSchema"
import { toast } from "material-react-toastify"

const UpdatePassword = (props: RouteComponentProps) => {
  const graphqlClient = useApolloClient()
  const schema = updatePasswordSchema()
  // 调用创建数据API
  const handleSubmit = useCallback(
    async (variables: any) => {
      try {
        await graphqlClient.mutate({
          mutation: UPDATE_PASSWORD,
          variables,
        })
        toast.success("Your password has been changed.", {
          position: "top-center",
        })
      } catch (errors) {
        const err = errors.graphQLErrors[0].extensions.validation
        for (var key in err) {
          toast.error(err[key][0], {
            position: "top-center",
          })
        }
        return err
      }
    },
    [graphqlClient]
  )

  return (
    <Box m="2%">
      <Box>
        <h1>Change My Password</h1>
        <DataEditor
          schema={schema}
          onSubmit={handleSubmit}
          onCancel={() => {
            navigate("/")
          }}
        />
      </Box>
    </Box>
  )
}

export default UpdatePassword
