import * as React from "react"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"

import useAllRight from "../../../Hooks/useAllRight"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { Checkbox, FormControlLabel, FormGroup } from "@material-ui/core"

const useStyles = makeStyles({
  error: {
    padding: "1rem",
    color: "red",
  },
  checkBox: {
    padding: 2,
    marginLeft: 16,
  },
})
const RightField = (props: any) => {
  const classes = useStyles()
  const { rights } = useAllRight()
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta

  const [expanded, setExpanded] = React.useState<string[]>([])
  const [selected, setSelected] = React.useState<string[]>([])

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const handleSelect = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    // console.log("nodeIds", nodeIds)
    setSelected(nodeIds)
  }

  const changeItem = (name) => {
    if (input.value.includes(name)) {
      const newValue = [...input.value].filter((val) => val !== name)
      input.onChange(newValue)
    } else {
      const newValue = [...input.value, name]
      input.onChange(newValue)
    }
  }

  // React.useEffect(() => { 
  //   const expanded = rights.map(v => v.name)
  //   setExpanded(expanded);
  // },[rights])

  // const changeItemList = (right) => {
  //   if (right.value) {
  //     changeItem(right.value)
  //     return
  //   }
  //   const values = right.children.map((item) => item.value)
  //   const jiaoji = values.filter((item) => input.values.includes(item))
  //   if (jiaoji.length === values.length) {
  //   }
  // }

  return (
    <Box border={1} flexGrow={1} mt={2}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        selected={selected}
        onNodeToggle={handleToggle}
        onNodeSelect={handleSelect}
      >
        {rights.map((right) => (
          <TreeItem
            nodeId={right.name}
            label={right.name}
            key={right.name}
          >
            {right.children ? (
              <FormGroup>
                {right.children.map((v) => (
                  <FormControlLabel
                    key={v.value}
                    control={
                      <Checkbox
                        color="primary"
                        className={classes.checkBox}
                        checked={input.value.includes(v.value)}
                        onChange={() => {
                          changeItem(v.value)
                        }}
                      />
                    }
                    label={v.name}
                  />
                ))}
              </FormGroup>
            ) : null}
          </TreeItem>
        ))}
      </TreeView>

      {submitFailed && error && <Box className={classes.error}>{error}</Box>}
    </Box>
  )
}

export default RightField
