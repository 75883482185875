import { gql } from "@apollo/client"

const DELETE_DATA = gql`
mutation DeleteTeamBuilding($id: ID!) {
  deleteTeamBuilding(id: $id) {
    id
    name
  }
}

`
export default DELETE_DATA