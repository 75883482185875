import { gql } from "@apollo/client"

const IMPORT_LOG = gql`
query ImportLogs(
  $type: Int
  $orderBy: [QueryImportLogsOrderByOrderByClause!]
  $first: Int!
  $page: Int!
) {
  importLogs(type: $type, orderBy: $orderBy, first: $first, page: $page) {
    data {
      id
      info
      filename
      status
      user {
        name
      }
      created_at
    }
    paginatorInfo {
      total
      currentPage
    }
  }
}


`
export default IMPORT_LOG