import * as React from "react"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import { useCallback } from "react"
import { Box, Grid } from "@material-ui/core"
import TeamSelector from "./TeamSelector"
import useSession from "../Hooks/useSession"

const TeamSelectorFieldLeader = (props: any) => {
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta
  const { session } = useSession()
  const list = React.useMemo(() => {
    if (!session) {
      return []
    }
    return session.underTeams.map((v: any) => ({ id: v.id, name: v.name }))
  }, [session])

  const handleChangeTeam = useCallback(
    (_, value) => {
      if (value) {
        input.onChange({ id: value.id, name: value.name })
      } else {
        input.onChange(null)
      }
    },
    [input]
  )
  return (
    <Grid item {...props.FormFieldGridProps}>
      <TeamSelector
        list={list}
        onChange={handleChangeTeam}
        required={props.isRequired}
        width={props.width}
        label={props.label}
        value={input.value}
        name={props.name}
      />
      {submitFailed && error && <Box color="primary.main">{error}</Box>}
    </Grid>
  )
}

export default TeamSelectorFieldLeader
