import * as React from "react"

import Box from "@material-ui/core/Box"
import { StringParam, useQueryParams, ObjectParam } from "use-query-params"
import UnitSelector from "../../../Components/UnitSelector"
import TeamSelector from "../../../Components/TeamSelector"
import { Grid } from "@material-ui/core"
import JobLevelSelector from "../../../Components/JobLevelSelector"

const SearchJobLevelData = () => {
  const [query, setQuery] = useQueryParams({
    job_level: StringParam,
    job_level_t: ObjectParam,
    unit: StringParam,
    unit_t: ObjectParam,
    team: StringParam,
    team_t: ObjectParam,
  })

  const handleJobLevelChange = (e: any, value: any) => {
    if (value) {
      setQuery({ ...query, job_level: value.id, job_level_t: value })
    } else {
      setQuery({ ...query, job_level: "", job_level_t: value })
    }
  }

  const handleTeamChange = (e: any, value: any) => {
    if (value) {
      setQuery({ ...query, team: value.id, team_t: value })
    } else {
      setQuery({ ...query, team: "", team_t: value })
    }
  }
  const handleUnitChange = (e: any, value: any) => {
    if (value) {
      setQuery({ ...query, unit: value.id, unit_t: value })
    } else {
      setQuery({ ...query, unit: "", unit_t: value })
    }
  }

  return (
    <Box display="flex" p={2} boxShadow={1}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={3}>
          <JobLevelSelector
            fullWidth
            label="Job Level"
            onChange={handleJobLevelChange}
            value={query.job_level_t}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <UnitSelector
            fullWidth
            label="Unit"
            onChange={handleUnitChange}
            value={query.unit_t}
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <TeamSelector
            label="Team"
            onChange={handleTeamChange}
            value={query.team_t}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default SearchJobLevelData
