import { Schema } from "@data-driven-forms/react-form-renderer"
import { FieldTypes } from "../../../Components/DataGrid/Form"

const getFilterSchema = () => {
  const filterSchema: Schema = {
    fields: [
      {
        name: "start",
        label: "Start",
        component: FieldTypes.DATE_PICKER,
        DatePickerProps: {
          clearable: true,
          format: "yyyy/MM/DD"
        }
      },
      {
        name: "end",
        label: "End",
        component: FieldTypes.DATE_PICKER,
        DatePickerProps: {
          clearable: true,
          format: "yyyy/MM/DD"
        }
      },
      {
        name: "user_id",
        label: "User Name",
        component: "UserSelectorField",
        width: "auto"
      },
      {
        name: "type",
        label: "Operation",
        component: "OperationTypeSelectorField",
        width: "auto"
      },
    ],

  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}

export default getFilterSchema
