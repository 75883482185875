import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react";

const FETCH_RIGHT = gql`
  query Rights{
    rights{
      name
      value
      children{
        name
        value
      }
    }
  }
`

const useAllRight = () => {
  const { data, loading, error } = useQuery(FETCH_RIGHT, {
    fetchPolicy: "cache-and-network",
  })

  const rights = useMemo(() => {
    return loading || error ? [] : data.rights;
  }, [loading, data, error])


  return { rights, loading }
}

export default useAllRight