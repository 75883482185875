import { useQuery } from "@apollo/client"
import { useMemo } from "react";
import FETCH_KPI_SETUP from "../Pages/Kpi/Gqls/FETCH_KPI_SETUP";
import { useQueryParams, } from "use-query-params"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../Utils"
const useKpiSetup = () => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const [query] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const variables = useMemo(() => {
    return {
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
    }
  }, [query]);

  const { data, loading, refetch, error } = useQuery(FETCH_KPI_SETUP, {
    fetchPolicy: "cache-and-network",
    variables
  })

  const kpiSetup = useMemo(() => {
    return loading || error ? [] : data.kpiSetup;
  }, [loading, data, error])

  const columnLabel = useMemo(() => {
    if (kpiSetup.length === 0) {
      return null;
    }
    const obj: any = {};
    kpiSetup.content.forEach((v: any) => {
      obj[v.column] = v.label
    })
    return obj;
  }, [kpiSetup])

  const columnWeight = useMemo(() => {
    if (kpiSetup.length === 0) {
      return null;
    }
    const obj: any = {};
    kpiSetup.content.forEach((v: any) => {
      obj[v.column] = v.weight
    })
    return obj;
  }, [kpiSetup])


  return { kpiSetup, refetch, loading, columnLabel, columnWeight }
}

export default useKpiSetup
