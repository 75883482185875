import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box } from "@material-ui/core"
import Search from "../../Components/Search"
import ReactEcharts from "../../../../Components/Echart"
import { useQueryParams } from "use-query-params"
import {
  DefaultNumberZero,
  DefaultYear,
  Type2DateRange,
} from "../../../../Utils"
import FETCH_TBA_INTEGRAL_LOG from "../../Gqls/FETCH_TBA_INTEGRAL_LOG"
import { useQuery } from "@apollo/client"
import useSession from "../../../../Hooks/useSession"
import { DataGrid } from "@material-ui/data-grid"
import moment from "moment"
import { createRankingChart } from "./Schema"
import useAllUsers from "../../../../Hooks/useAllUsers"

const columns = [
  { headerName: "Quarter", field: "quarter", width: 108 },
  { headerName: "Date", field: "at", width: 108 },
  { headerName: "Training Title", field: "training_name", width: 280 },
  { headerName: "Trainer", field: "trainer", width: 220 },
  {
    headerName: "Price/Persom",
    field: "amount",
    width: 220,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value}
        </Box>
      )
    },
  },
]

const MyDetail = (props: RouteComponentProps) => {
  const { users } = useAllUsers()
  const { session } = useSession()
  const [query] = useQueryParams({
    year: DefaultYear,
    type: DefaultNumberZero,
  })

  const variables = React.useMemo(() => {
    const where: any = {
      AND: [
        {
          column: "TYPE",
          operator: "EQ",
          value: 3,
        },
      ],
    }
    const orderBy: any = [
      {
        column: "AT",
        order: "DESC",
      },
    ]

    return { where, orderBy }
  }, [])

  const { data, loading } = useQuery(FETCH_TBA_INTEGRAL_LOG, {
    fetchPolicy: "cache-and-network",
    variables,
  })
  // 过滤时间段筛选
  const filterData = React.useMemo(() => {
    const list = data ? data.TbaIntegralLogs : []
    const { year, type } = query
    if (type === 0) {
      return list
    }
    const range = Type2DateRange(year, type)
    return list.filter((v: any) => {
      return moment(v.at).isBetween(range[0], range[1], null, "[]")
    })
  }, [query, data])
  const filterUsers = React.useMemo(() => {
    const { year, type } = query
    if (type === 0) {
      return users.filter((v: any) => {
        const now = moment()
        const q = now.quarter()
        const d = ["", "01-01", "04-01", "07-01", "10-01"]
        const date = now.format("YYYY") + "-" + d[q]
        return v.term >= date
      })
    }
    const range = Type2DateRange(year, type)
    return users.filter((v: any) => {
      return v.start < range[0] && v.term > range[0]
    })
  }, [users, query])
  // 计算Used排名，展示chart
  const usedDataOptions = React.useMemo(() => {
    const id = session?.id
    // 计算Abbr 数据和
    const arr = filterData
    const users_expense: any = {}
    const abbr2id: any = {}
    // 填充默认用户信息
    filterUsers.forEach((v: any) => {
      users_expense[v.abbr] = 0
      abbr2id[v.abbr] = v
    })
    arr.forEach((v: any) => {
      if (abbr2id[v.user.abbr]) {
        // 过滤离职人员选项
        abbr2id[v.user.abbr] = v.user
        users_expense[v.user.abbr] -= v.amount
      }
    })
    const userArr:any[] = []
    for (const abbr in users_expense) {
      userArr.push({
        name: abbr,
        value: users_expense[abbr],
        user: abbr2id[abbr],
      })
    }
    const res = userArr.sort((a: any, b: any) => b.value - a.value)
    const label: any = []
    const value: any = []

    let currentRanking = 1
    let ranking = 1
    let myRanking = 1
    const list: any = []
    // 进行排名
    res.forEach((v, k) => {
      if (k > 0 && res[k - 1].value === v.value) {
        list.push({ ...v, ranking: currentRanking })
      } else {
        list.push({ ...v, ranking: ranking })
        currentRanking = ranking
      }
      ranking++
    })
    list.forEach((v: any) => {
      if (v.user.id === id) {
        myRanking = v.ranking
        label.push(v.name + `(${v.ranking})`)
        value.push({
          value: v.value.toFixed(2),
          itemStyle: { color: "#a90000" },
          user: v.user,
        })
      } else {
        label.push(v.name + `(${v.ranking})`)
        value.push({ value: v.value.toFixed(2), user: v.user })
      }
    })

    return createRankingChart(
      `Ranking (${myRanking} / ${userArr.length})`,
      label.reverse(),
      value.reverse()
    )
  }, [filterData, session, filterUsers])

  // 手动过滤金额
  const rows = React.useMemo(() => {
    const id = session?.id
    const filter = filterData.filter((v: any) => {
      return v.user_id === id
    })
    // 先按照sort at排序计算余额
    let balance = 0
    return filter.map((v: any) => {
      balance += v.amount
      let trainer = "/"
      let training_name = '';
      if (v.training) {
        trainer = v.training.trainers.map((v: any) => v.abbr).join(" , ")
        training_name = v.training.training_name;
      } 
      if (v.ext_training) { 
        training_name = v.ext_training.training_name;
      }
      return {
        id: v.id,
        quarter: "Q" + moment(v.at).quarter(),
        at: v.at,
        training_name: training_name,
        trainer: trainer,
        description: v.description,
        amount: (-v.amount).toFixed(2),
        balance,
      }
    })
  }, [filterData, session])

  const used = React.useMemo(() => {
    let used = 0
    rows.forEach((v: any) => {
      used += Number(v.amount)
    })
    return used.toFixed(2)
  }, [rows])

  return (
    <Box m="2%">
      <Box mb={2}>
        <Search />
      </Box>
      <Box>
        <DataGrid
          density={"compact"}
          disableColumnMenu={true}
          hideFooterPagination
          hideFooterRowCount
          hideFooterSelectedRowCount
          loading={loading}
          autoHeight
          rows={rows}
          columns={columns}
          components={{
            Toolbar: () => (
              <Box p={4} fontSize="14px">
                Total Used TBA: {used}
              </Box>
            ),
          }}
        />
      </Box>
      <Box pt={4} width={500}>
        <ReactEcharts
          opts={{ height: 25 * filterUsers.length }}
          option={usedDataOptions}
        />
      </Box>
    </Box>
  )
}

export default MyDetail
