import { useApolloClient } from "@apollo/client"
import { useCallback, useState } from "react"
import { MenuItemData } from "../Components/Layout/LeftMenu"
import useUserInfo from "./useUserInfo"


type Team = {
  id: any
  name: string
}

type SessionType = {
  id: string
  name: string
  avatar: string
  token: string
  myTeam: Team
  underTeams: Team[]
  menu: string[]
  render_menu: MenuItemData[]
}

const localSession =
  localStorage.getItem("session") || sessionStorage.getItem("session")

let localSessionData = localSession ? JSON.parse(localSession) : null

const useSession = () => {
  const client = useApolloClient()
  const [session, setSession] = useState<SessionType | null>(localSessionData)
  const { can } = useUserInfo();

  const login = useCallback((session: SessionType, isRemember: boolean = false) => {
    setSession(session)
    console.log("login", session);
    // 设置前先清除以防造成 token 脏读
    localStorage.clear()
    sessionStorage.clear()
    localSessionData = session
    if (isRemember) {
      localStorage.setItem("token", session.token)
      localStorage.setItem("session", JSON.stringify(session))
    } else {
      sessionStorage.setItem("token", session.token)
      sessionStorage.setItem("session", JSON.stringify(session))
    }
  }, [])

  const logout = useCallback(() => {
    localSessionData = null
    setSession(null)
    client.resetStore()
    // localStorage.clear()
    // sessionStorage.clear()
  }, [client])

  // const can = useCallback((name, action) => {
  //   if (session?.menu.includes(name) || session?.menu.includes("*")) {
  //     return action;
  //   }

  //   return undefined;
  // }, [session])

  const isLogin = !!session

  return {
    session,
    isLogin,
    login,
    logout,
    can,
  }
}

export default useSession
