import { gql } from "@apollo/client"

const FIND_USER = gql`
query FindUser($id: ID!) {
  user(id: $id) {
    id
    name
    email
    created_at
    updated_at
    future_team_apply_at
    future_team
    future_team_id
    formal_date
    gender
    token
    slug
    abbr
    unit
    roles {
      id
      name
    }
    team
    eid
    start
    term
    birthday
    phone
    type
    status
    leave_status
    leave_status_name
    job_level
    job_title
    competency
  }
}

`

export default FIND_USER