import { gql } from "@apollo/client"

const CREATE_ROLE = gql`
mutation CreateeRole($name: String!, $menu: [String]!, $is_default:Boolean) {
  createRole(name: $name, menu: $menu, is_default:$is_default) {
    id
    name
    menu
  }
}

`
export default CREATE_ROLE