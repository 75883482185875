import { gql } from "@apollo/client"
// 重命名 UpdateTeamBonus
const UPDATE_TEAM_BONUS = gql`
mutation AllocateBonus(
  $id: ID!
  $members: [TeamMemberBonusInput]!
  $create_buildings: [CreateTeamBuildingBonusInput]!
  $update_buildings: [UpdateTeamBuildingBonusInput]!
  $remove_buildings: [ID]!
) {
  updateTeamBonus(
    id: $id
    members: $members
    create_buildings: $create_buildings
    update_buildings: $update_buildings
    remove_buildings: $remove_buildings
  ) {
    id
    members {
      id
      username
      bonus
    }
    teamBuildings {
      id
      bonus
      team_building_id
      teamBuilding {
        name
      }
      remark
    }
  }
}


`
export default UPDATE_TEAM_BONUS