import { Schema } from "@data-driven-forms/react-form-renderer"
import { FieldTypes } from "../../../Components/DataGrid/Form"

const getFilterSchema = () => {
  const filterSchema: Schema = {
    fields: [
      {
        name: "number",
        label: "PO Number",
        component: FieldTypes.TEXT_FIELD,
      },
      {
        name: "project",
        label: "Project ID",
        component: FieldTypes.TEXT_FIELD,
      },
      {
        name: "status",
        label: "Status",
        component: FieldTypes.SELECT,
        isClearable: true,
        options: [
          {
            label: "Valid",
            value: "Valid",
          },
          {
            label: "Expired",
            value: "Expired",
          },
          {
            label: "All",
            value: "All",
          },
        ],
        TextFieldProps: {
          margin: "none",
        },
      },
      {
        name: "team",
        label: "Team",
        component: 'TeamSelectorField',
        width:"100%"
      },
    ],
  }

  filterSchema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 6,
        sm: 4,
        md: 3,
        lg: 2,
        xl: 1,
      }
    }
  })

  return filterSchema
}

export default getFilterSchema
