import { gql } from "@apollo/client"

const FETCH_ORGINIZATION = gql`
query OrganizationChart {
  organizationChart {
    id
    users {
      name
      job_title
      job_level
      competency
      avatar
    }
    children {
      id
      users {
        name
        job_title
        job_level
        competency
        avatar
      }
      children {
        id
        users {
          name
          job_title
          job_level
          competency
          avatar
        }
      }
    }
  }
}

`
export default FETCH_ORGINIZATION