import { gql } from "@apollo/client"
// UpdateProjectPurchase 重命名
const UPDATE_PROJECT_PURCHASE = gql`
  mutation UpdatePo (
    $id: ID!
    $number: String
    $project_id: ID
    $start: Date
    $end: Date
    $total_amount: Float
    $members: UpdateUsersBelongsToMany
  ) {
    updateProjectPurchase(
      id: $id
      number: $number
      project_id: $project_id
      start: $start
      end: $end
      total_amount: $total_amount
      members: $members
    )  {
      id
      number
      project {
        id
        slug
        name
      }
      members {
        id
        slug
        name
      }
      start
      end
      total_amount
      remainingAmount
      remainingRate
      remainingDays
      status
      created_at
      updated_at
    }
  }
`
export default UPDATE_PROJECT_PURCHASE