import * as React from "react"

import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Box from "@material-ui/core/Box"
import {
  ObjectParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params"
import { globalYear } from "../../../Utils"
import TeamSelector from "../../../Components/TeamSelector"
import UserSelector from "../../../Components/UserSelector"

const Search = (props: any) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    user: StringParam,
    team: StringParam,
    team_t: ObjectParam,
    user_t: ObjectParam,
  })

  const handleDateChange = (v: any) => {
    setQuery({ ...query, year: v.format("YYYY") + "" })
  }
  const handleUserChange = (e: any,value: any) => {
    if (value) {
      setQuery({ ...query, user: value.name, user_t: value })
    } else {
      setQuery({ ...query, user: "", user_t: value })
    }
  }
  const handleTeamChange = (e: any, value: any) => {
    if (value) {
      setQuery({ ...query, team: value.name, team_t: value })
    } else {
      setQuery({ ...query, team: "", team_t: value })
    }
  }
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box display="flex" p={2} boxShadow={1}>
        <Box ml={2}>
          <DatePicker
            label="Year"
            views={["year"]}
            placeholder="choose year"
            clearable={false}
            value={query["year"] ? query["year"] : null}
            onChange={handleDateChange}
          />
        </Box>
        <Box ml={2}>
          <UserSelector label="User Name" onChange={handleUserChange} />
        </Box>
        <Box ml={2}>
          <TeamSelector label="Team" onChange={handleTeamChange} />
        </Box>
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export default Search
