import React from "react"
import ReactEChartsCore from "echarts-for-react/lib/core"
import * as echarts from "echarts/core"
import { EChartsReactProps } from "echarts-for-react/lib/types"
import {
  BarChart,
  LineChart ,
} from "echarts/charts"
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  MarkLineComponent,
  MarkAreaComponent,
  LegendComponent,
  MarkPointComponent,
} from "echarts/components"
import {
  CanvasRenderer,
} from "echarts/renderers"

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  MarkLineComponent,
  MarkAreaComponent,
  LegendComponent,
  MarkPointComponent
])

const options: EChartsReactProps = {
  notMerge: true,
  lazyUpdate: true,
  theme: "",
  onChartReady: () => {},
  onEvents: {},
  opts: {},
  option: {},
}

const EchartComponent = (props: EChartsReactProps) => {
  return <ReactEChartsCore echarts={echarts} {...options} {...props} />
}

export default EchartComponent
