import * as React from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useAllMenu from "../Hooks/useAllMenu"

const filterOptions = (options: Array<any>, { inputValue }: any) => {
  if (!inputValue) {
    return options
  }

  return options.filter((user) => {
    const matchValue = inputValue.toLowerCase()

    return user.name.toLowerCase().indexOf(matchValue) !== -1
  })
}

const PermissionSelector = (props: any) => {
  const { menusList, loading } = useAllMenu()

  const list = React.useMemo(() => {
    if (props.list) {
      return props.list
    }
    return menusList.map((v: any) => ({ id: v.value, name: v.value }))
  }, [menusList, props])

  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={list}
      getOptionLabel={(option: any) => (option ? option.name : "")}
      loading={loading}
      renderInput={(params) => (
        <TextField {...params} label={props.label} required={props.required} />
      )}
      {...props}
    />
  )
}

export default PermissionSelector
