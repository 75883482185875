import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core"
import Search from "../Components/YearSearch"
import { useQueryParams } from "use-query-params"
import { DefaultNumberZero, DefaultYear } from "../../../Utils"
import FIND_TBA_ARCHIVE from "../Gqls/FIND_TBA_ARCHIVE"
import { useQuery } from "@apollo/client"
import { DataGrid } from "@material-ui/data-grid"
import ArchiveButton from "./ArchiveButton"
import useSession from "../../../Hooks/useSession"

const columns = [
  { headerName: "Name", field: "name", width: 170 },
  { headerName: "Team", field: "team", width: 280 },
  { headerName: "Status", field: "status_name", width: 220 },
  {
    headerName: "Amount",
    field: "amount",
    width: 100,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value}
        </Box>
      )
    },
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      fontSize: "16px",
      textAlign: "center",
      padding: 12,
      flexGrow: 1,
      borderRadius: "4px",
    },
  })
)

const FundArchive = (props: RouteComponentProps) => {
  const classes = useStyles()
  const { can } = useSession()
  const [query] = useQueryParams({
    year: DefaultYear,
    type: DefaultNumberZero,
  })

  const at = React.useMemo(() => {
    const year = query.year
    const type = "0" + query.type
    return year + type
  }, [query])

  const { data, loading, refetch } = useQuery(FIND_TBA_ARCHIVE, {
    fetchPolicy: "cache-and-network",
    variables: { at },
  })
  const isArchived = React.useMemo(() => {
    const tba = data ? data.FindTbaArchive : { status: 0 }
    return tba.status === 1
  }, [data])

  const rows = React.useMemo(() => {
    const arr = data ? data.FindTbaArchive.funds : []
    const status = ["UnArchived", "Archived", "Leaving/Left"]

    //1.数据按照 member 合并
    const u: any = {}
    const u2s: any = {}
    const u2u: any = {}
    arr.forEach((v: any) => {
      if (!u[v.user.id]) {
        u[v.user.id] = 0
      }
      u[v.user.id] += v.amount
      u2s[v.user.id] = v.status
      u2u[v.user.id] = v.user
    })
    const res:any[] = []
    for (const user_id in u) {
      res.push({
        id: user_id,
        name: u2u[user_id].name,
        team: u2u[user_id].team,
        amount: u[user_id].toFixed(2),
        status_name: status[u2s[user_id]],
        status: u2s[user_id],
      })
    }
    return res
  }, [data])

  const amountToPay = React.useMemo(() => {
    return data ? data.FindTbaArchive.pay.toFixed(2) : "0.00"
  }, [data])

  const DisabledFund = React.useMemo(() => {
    return data ? data.FindTbaArchive.disabled.toFixed(2) : "0.00"
  }, [data])

  const total = React.useMemo(() => {
    return data ? data.FindTbaArchive.total.toFixed(2) : "0.00"
  }, [data])

  return (
    <Box m="2%">
      <Box>
        <Search />
      </Box>
      <Box my={4} display="flex" alignItems="center">
        <Box className={classes.paper} border={1}>
          <Box>Total:</Box>
          <Box color="primary.main" fontWeight="bold">
            ￥{total}
          </Box>
        </Box>
        <Box fontSize={24} p={2}>
          =
        </Box>
        <Box className={classes.paper} style={{ background: "#CCCCCC" }}>
          <Box>Disabled Fund:</Box>
          <Box color="primary.main" fontWeight="bold">
            ￥{DisabledFund}
          </Box>
        </Box>
        <Box fontSize={24} p={2}>
          +
        </Box>
        <Box className={classes.paper} style={{ background: "#F6DADA" }}>
          <Box>Amount to pay: </Box>
          <Box color="primary.main" fontWeight="bold">
            ￥{amountToPay}
          </Box>
        </Box>
      </Box>
      <Box>
        <DataGrid
          density={"compact"}
          disableColumnMenu={true}
          hideFooterPagination
          hideFooterRowCount
          hideFooterSelectedRowCount
          loading={loading}
          autoHeight
          rows={rows}
          columns={columns}
        />
      </Box>
      <Box pt={2}>
        {can(
          "tba-archive.update",
          <ArchiveButton
            loading={loading}
            isArchived={isArchived}
            at={at}
            refetch={refetch}
          />
        )}
      </Box>
    </Box>
  )
}

export default FundArchive
