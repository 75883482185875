import * as React from "react"
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import Filter from "../../../Components/Filter"
import { useQueryParam, useQueryParams } from "use-query-params"
import { useApolloClient, useQuery } from "@apollo/client"
import FETCH_KPI_TEAM_BONUS from "../Gqls/FETCH_KPI_TEAM_BONUS"
import useSession from "../../../Hooks/useSession"
import { DataGrid } from "@material-ui/data-grid"

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import UPDATE_TEAM_BONUS from "../Gqls/UPDATE_TEAM_BONUS"
import { toast } from "material-react-toastify"
import Tooltip from "@material-ui/core/Tooltip"
import UnderTeamsSelect from "../../../Components/UnderTeamsSelect"
import useAllTeamBuildings from "../../../Hooks/useAllTeamBuildings"
import DeleteIcon from "@material-ui/icons/Delete"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"
import useUserInfo from "../../../Hooks/useUserInfo"


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputRoot: {
      "& input": {
        height: "31px",
        lineHight: "31px",
      },
    },
    textAreaRoot: {
      width: "500px",
      height: "70px",
      overflowY: "auto",
    },
    header: {
      marginTop: 8,
      display: "flex",
      alignItems: "center",
      backgroundColor: "#F4F6F8",
    },
    itemBox: {
      padding: 16,
      flexGrow: 1,
    },
    label: {
      fontSize: "16px",
      textAlign: "center",
    },
    value: {
      paddingTop: 10,
      fontSize: "16px",
      fontWeight: "bold",
      textAlign: "center",
    },
    symbol: {
      fontSize: "24px",
    },
  })
)

interface AllocateTeamProps extends RouteComponentProps {
  id?: String
}

const line_break = (str: string) => {
  const arr = str.split("\n")
  return arr.map((v) => {
    return (
      <Box lineHeight="16px" key={v}>
        {v}
      </Box>
    )
  })
}

const AllocateTeam = ({ id }: AllocateTeamProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const { user } = useUserInfo()
  const [teamId] = useQueryParam("team_id", StringParam)
  const [mode, setMode] = useQueryParam("edit", StringParam)
  const { team_buildings, buildingKv }: any = useAllTeamBuildings()

  const graphqlClient = useApolloClient()
  const classes = useStyles()
  const { can } = useSession()
  const [form, setForm] = React.useState<any>([])
  // 必须设置个默认值：否在点击菜单再次重置 无法触发useEffect 导致原默认值设置失效
  const underTeams = user?.underTeams
  let team_id = teamId ? teamId : user?.myTeam.id
  if (!teamId && underTeams && underTeams.length > 0) {
    team_id = teamId ? teamId : underTeams[0].id
  }
  const { data } = useQuery(FETCH_KPI_TEAM_BONUS, {
    fetchPolicy: "cache-and-network",
    variables: {
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
      team_id,
    },
  })
  const team_bonus_id = React.useMemo(() => {
    if (data && data.kpiTeamBonuses) {
      return data.kpiTeamBonuses.id
    }
    return undefined
  }, [data])

  const list = React.useMemo(() => {
    if (data && data.kpiTeamBonuses) {
      const result = [...data.kpiTeamBonuses.members]
      data.kpiTeamBonuses.teamBuildings.forEach((v: any) => {
        result.push({
          id: v.id,
          type: "team_building",
          bonus: v.bonus,
          remark: v.remark,
          team_building_id: v.team_building_id + "",
          attendance: "",
          attendance_rate: "",
          tba_expend: "",
        })
      })
      return result
    }
    return []
  }, [data])

  const updateTeamBonus = React.useCallback(async () => {
    const members = form
      .filter((v: any) => !v.type)
      .map((v: any) => {
        return {
          id: v.id,
          remark: v.remark,
          bonus: v.bonus && v.bonus > 0 ? v.bonus : 0,
        }
      })
    const new_buildings_list = form.filter((v: any) => v.type)
    const old_buildings_list = list.filter((v: any) => v.type)
    const old_ids = old_buildings_list.map((v: any) => v.id)
    const new_ids = new_buildings_list.map((v: any) => v.id)
    const create_buildings: any = []
    const update_buildings: any = []
    new_buildings_list.forEach((v: any) => {
      const obj = {
        bonus: v.bonus,
        remark: v.remark,
        team_building_id: v.team_building_id,
      }
      if (old_ids.includes(v.id)) {
        update_buildings.push({ ...obj, id: v.id })
      } else {
        create_buildings.push(obj)
      }
    })
    const remove_buildings = old_buildings_list
      .filter((v: any) => !new_ids.includes(v.id))
      .map((v: any) => v.id)

    return graphqlClient.mutate({
      mutation: UPDATE_TEAM_BONUS,
      variables: {
        id: team_bonus_id,
        members,
        create_buildings,
        update_buildings,
        remove_buildings,
      },
    })
  }, [graphqlClient, team_bonus_id, form, list])
  const previous = React.useMemo(() => {
    if (data && data.kpiTeamBonuses) {
      return data.kpiTeamBonuses.previous_balance
    }
    return 0
  }, [data])
  const bonus = React.useMemo(() => {
    if (data && data.kpiTeamBonuses) {
      return data.kpiTeamBonuses.bonus
    }
    return 0
  }, [data])
  const allocated = React.useMemo(() => {
    let sum = 0
    form.forEach((v: any) => {
      if (v.bonus) {
        sum += v.bonus
      }
    })
    return sum
  }, [form])
  const editAble = React.useMemo(() => {
    if (!data) {
      return false;
    }
    if (data && !data.kpiTeamBonuses) {
      // 当未生成奖金表时，也不能修改
      return false;
    }
    if (
      data &&
      data.kpiTeamBonuses &&
      data.kpiTeamBonuses.kpiBonus.status === "archived"
    ) {
      return false
    }
    return can("allocate-my-team-bonus", true)
  }, [data, can])

  const viewMode = React.useMemo(() => {
    // 任意一个情况都会变为视图模式
    return mode === "view" || !editAble
  }, [mode, editAble])

  React.useEffect(() => {
    setForm(JSON.parse(JSON.stringify(list)))
  }, [list])

  const changeBonusColumn = React.useCallback(
    (id, value) => {
      const row = form.find((v: any) => v.id === id)
      row["bonus"] = Number(value) > 0 ? Number(value) : 0
      if (value === "") {
        row["bonus"] = ""
      }
      setForm(JSON.parse(JSON.stringify(form)))
    },
    [form]
  )
  const changeTeamBuildingIdColumn = React.useCallback(
    (id, value) => {
      const row = form.find((v: any) => v.id === id)
      row["team_building_id"] = value
      setForm(JSON.parse(JSON.stringify(form)))
    },
    [form]
  )
  const changeRemarkColumn = React.useCallback(
    (id, value) => {
      const row = form.find((v: any) => v.id === id)
      row["remark"] = value
      setForm(JSON.parse(JSON.stringify(form)))
    },
    [form]
  )
  const handleCancel = () => {
    setForm(JSON.parse(JSON.stringify(list)))
    setMode("view")
  }

  const createNewItem = () => {
    setForm([
      ...form,
      {
        id: "new" + form.length,
        type: "team_building",
        bonus: 0,
        remark: "",
        team_building_id: "1",
        attendance: "",
        attendance_rate: "",
        tba_expend: "",
      },
    ])
  }
  const removeItem = (id: any) => {
    const res = form.filter((v: any) => v.id !== id)
    setForm(JSON.parse(JSON.stringify(res)))
  }
  const handleSave = async () => {
    const validateBonus = form.filter((v: any) => v.bonus < 0)
    if (validateBonus.length > 0) {
      toast.error("bonus must be a positive", {
        position: "top-center",
        autoClose: 3000,
      })
      return
    }
    if (previous + bonus - allocated < 0) {
      toast.error("The amount allocated exceeds the limit ", {
        position: "top-center",
        autoClose: 3000,
      })
      return
    }

    await updateTeamBonus()
    setMode("view")
    toast.success("success!", {
      position: "top-center",
      autoClose: 3000,
    })
  }

  const columns = [
    {
      headerName: "Team Member/Item",
      field: "username",
      width: 200,
      renderCell: (params: any) => {
        const value = params.row.username
        // 不存在type说明是member数据
        if (!params.row.type) {
          return value
        }
        const key = params.row.team_building_id
        return viewMode ? (
          <Box width="100%">{buildingKv && buildingKv[key]}</Box>
        ) : (
          <Select
            fullWidth
            value={key}
            onChange={(e) => {
              changeTeamBuildingIdColumn(params.row.id, e.target.value)
            }}
          >
            {team_buildings.map((v: any) => (
              <MenuItem value={v.id + ""} key={v.id}>
                {v.name}
              </MenuItem>
            ))}
          </Select>
        )
      },
    },
    {
      headerName: "Attendance",
      field: "attendance",
      width: 120,
      renderCell: (params: any) => {
        const value = params.row.attendance
        // 不存在type说明是member数据
        if (!params.row.type) {
          return value
        }
        return viewMode ? (
          "-"
        ) : (
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => {
              removeItem(params.row.id)
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )
      },
    },
    {
      headerName: "Attendance Rate",
      field: "attendance_rate",
      width: 150,
      renderCell: (params: any) => {
        if (params.row.attendance_rate) {
          return params.row.attendance_rate.toFixed(2) + "%"
        }
        return "-"
      },
    },
    {
      headerName: "Used TBA",
      field: "tba_expend",
      width: 120,
      renderCell: (params: any) => {
        if (params.row.tba_expend) {
          return params.row.tba_expend.toFixed(2)
        }
        return "-"
      },
    },
    {
      headerName: "Allocated Bonus to Member",
      field: "bonus",
      width: 300,
      renderHeader: () => (
        <Box
          fontWeight="bold"
          color="primary.main"
          width="100%"
          textAlign="center"
        >
          <Box lineHeight="20px">Allocated Bonus to Member</Box>
          <Box lineHeight="20px">(￥{allocated})</Box>
        </Box>
      ),
      renderCell: (params: any) => {
        // 实时验证：
        let helperText = ""
        let error = false
        if (params.row.bonus < 0) {
          params.row.bonus = 0
        }
        if (previous + bonus - allocated < 0) {
          helperText = "remaining balance must >= 0"
          error = true
        }
        return viewMode ? (
          <Box width="100%" textAlign="center">
            {params.row.bonus + ""}
          </Box>
        ) : (
          <TextField
            error={error}
            helperText={helperText}
            value={params.row.bonus}
            onChange={(e) => {
              changeBonusColumn(params.row.id, e.target.value)
            }}
            classes={{ root: classes.inputRoot }}
            type="number"
          />
        )
      },
    },
    {
      headerName: "Remark",
      field: "remark",
      width: 500,
      renderCell: (params: any) => {
        return viewMode ? (
          <Tooltip title={line_break(params.row.remark)}>
            <Box height="50px">{line_break(params.row.remark)}</Box>
          </Tooltip>
        ) : (
          <textarea
            rows={3}
            className={classes.textAreaRoot}
            placeholder="please enter remark"
            defaultValue={params.row.remark}
            onChange={(e) => changeRemarkColumn(params.row.id, e.target.value)}
          ></textarea>
        )
      },
    },
  ]

  return (
    <Box m="2%">
      <Box>
        <Filter
          CustomLeftText={
            <Box display="flex">
              <UnderTeamsSelect />
              <Box ml={2}>
                {viewMode && editAble ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setMode("edit")
                    }}
                  >
                    Edit
                  </Button>
                ) : null}
              </Box>
            </Box>
          }
          data={query}
          handleSubmit={(value: any) => setQuery(value)}
        />
      </Box>
      <Box className={classes.header}>
        <Box className={classes.itemBox}>
          <Box className={classes.label}>Previous Remaining Balance</Box>
          <Box className={classes.value}>￥{previous}</Box>
        </Box>
        <Box className={classes.symbol}>+</Box>
        <Box className={classes.itemBox}>
          <Box className={classes.label}>
            {query.year + "-Q" + query.quarter} Team Bonus
          </Box>
          <Box className={classes.value}>￥{bonus}</Box>
        </Box>
        <Box className={classes.symbol}>-</Box>
        <Box className={classes.itemBox} color="primary.main">
          <Box className={classes.label}>Total Allocated Bonus</Box>
          <Box className={classes.value}>￥{allocated}</Box>
        </Box>
        <Box className={classes.symbol}>=</Box>
        <Box className={classes.itemBox}>
          <Box className={classes.label}>Current Remaining Balance</Box>
          <Box className={classes.value}>￥{previous + bonus - allocated}</Box>
        </Box>
      </Box>
      <Box pt={2}>
        <DataGrid
          headerHeight={52}
          rowHeight={viewMode ? 52 : 80}
          disableColumnMenu={true}
          autoHeight
          components={{
            Footer: () => {
              if (viewMode) {
                return <Box></Box>
              }
              return (
                <Box p={2}>
                  <Button
                    onClick={createNewItem}
                    variant="contained"
                    color="primary"
                  >
                    New Item
                  </Button>
                </Box>
              )
            },
          }}
          columns={columns}
          rows={form}
        />
        {viewMode ? null : (
          <Box pt={2}>
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              variant="contained"
              color="default"
              onClick={handleCancel}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default AllocateTeam
