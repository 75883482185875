import * as React from "react"
import { useMemo } from "react"
import { RouteComponentProps } from "@reach/router"
import Filter from "../../../Components/Filter"
import TeamOkrList from "../teamList"
import { useQuery } from "@apollo/client"
import OKR_LIST_VIEW from "../Gqls/OKR_LIST_VIEW"
import { CircularProgress, Box } from "@material-ui/core"
import { useQueryParams } from "use-query-params"
import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"
import OkrTeamChart from "../Components/OkrTeamChart"
import useUserInfo from "../../../Hooks/useUserInfo"

const TeamOkr = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const { user } = useUserInfo()
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const variables = useMemo(() => {
    let ids = [user?.myTeam.id]
    if (user && user.underTeams && user.underTeams.length > 0) {
      ids = user.underTeams.map((v) => v.id)
      ids.push(user.myTeam.id)
    }
    return {
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
      team_id: ids,
    }
  }, [query, user])

  // 调用搜索数据API
  const { loading, data } = useQuery(OKR_LIST_VIEW, {
    variables: variables,
    fetchPolicy: "cache-and-network",
  })

  return (
    <Box m="2%">
      <Box>
        <Filter data={query} handleSubmit={(value: any) => setQuery(value)} />
      </Box>
      <Box>
        {loading ? (
          <CircularProgress />
        ) : (
          <Box>
            <TeamOkrList data={data.okrListView} expandedType={0} />
            <OkrTeamChart sortData={data.okrListView} variables={variables} />
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default TeamOkr
