import * as React from "react"

import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { StringParam, useQueryParams } from "use-query-params"
import { DefaultNumberZero } from "../../../Utils"
import useAllUsers from "../../../Hooks/useAllUsers"

const names = ["All", "Unit", "Team"]
const AllUnitTeam = (props: any) => {
  const { groupByUnit, groupByTeam } = useAllUsers()

  const Units = React.useMemo(() => {
    const Units:any[] = []
    for (let i in groupByUnit) {
      Units.push({
        id: i,
        value: i,
      })
    }
    return Units
  }, [groupByUnit])

  const Teams = React.useMemo(() => {
    const Teams:any[] = []
    for (let i in groupByTeam) {
      Teams.push({
        id: groupByTeam[i][0].team_id,
        value: groupByTeam[i][0].team,
      })
    }
    return Teams
  }, [groupByTeam])

  const [query, setQuery] = useQueryParams({
    type: DefaultNumberZero,
    value: StringParam,
  })

  const QuickSelect = React.useMemo(() => {
    return (
      <Box display="flex">
        {names.map((title, index) => {
          const value = index
          const active = value === query.type
          return (
            <Box ml={2} key={value}>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setQuery({ type: value, value: null })
                }}
                color={active ? "primary" : undefined}
              >
                {title}
              </Button>
            </Box>
          )
        })}
      </Box>
    )
  }, [query, setQuery])
  const UnitSelect = React.useMemo(() => {
    return (
      <Box display="flex" pb={2} ml={2}>
        {Units.map((v) => {
          const id = v.id
          const active = id === query.value
          return (
            <Box ml={2} key={id}>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setQuery({ ...query, value: id })
                }}
                color={active ? "primary" : undefined}
              >
                {v.value}
              </Button>
            </Box>
          )
        })}
      </Box>
    )
  }, [query, setQuery, Units])

  const TeamSelect = React.useMemo(() => {
    return (
      <Box display="flex" pb={2} ml={2}>
        {Teams &&
          Teams.map((v: any) => {
            const id = v.id
            const active = id === query.value
            return (
              <Box ml={2} key={id}>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setQuery({ ...query, value: id })
                  }}
                  color={active ? "primary" : undefined}
                >
                  {v.value}
                </Button>
              </Box>
            )
          })}
      </Box>
    )
  }, [query, setQuery, Teams])

  return (
    <Box flexGrow={1} boxShadow={1}>
      <Box display="flex" p={2}>
        {QuickSelect}
      </Box>
      {query.type === 1 ? UnitSelect : null}
      {query.type === 2 ? TeamSelect : null}
    </Box>
  )
}

export default AllUnitTeam
