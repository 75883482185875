import * as React from 'react';
import FormSpy from '@data-driven-forms/react-form-renderer/form-spy/form-spy';

type FormWatcherType = {
  onChange: any
}

const FormWatcher = ({ onChange }: FormWatcherType) => {
  return (
    <FormSpy
      subscription={{ values: true }}
      onChange={onChange}
    />
  )
}

export default FormWatcher