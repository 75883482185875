import { gql } from "@apollo/client"

const FETCH_TBA_INTEGRAL_ALL = gql`
query FetchTbaIntegralLogs {
  TbaIntegralLogs {
    id
    type
    user_id
    user {
      id
      name
      term
    }
    amount
    expire_at
    at
    created_at
  }
}

`

export default FETCH_TBA_INTEGRAL_ALL