import * as React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

export const LeaderIcon = function (props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M12 3C14.21 3 16 4.79 16 7S14.21 11 12 11 8 9.21 8 7 9.79 3 12 3M16 13.54C16 14.6 15.72 17.07 13.81 19.83L13 15L13.94 13.12C13.32 13.05 12.67 13 12 13S10.68 13.05 10.06 13.12L11 15L10.19 19.83C8.28 17.07 8 14.6 8 13.54C5.61 14.24 4 15.5 4 17V21H20V17C20 15.5 18.4 14.24 16 13.54Z"
      />
    </SvgIcon>
  )
}
export const MaleIcon = function (props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M9.5,11c1.93,0,3.5,1.57,3.5,3.5S11.43,18,9.5,18S6,16.43,6,14.5S7.57,11,9.5,11z M9.5,9C6.46,9,4,11.46,4,14.5 S6.46,20,9.5,20s5.5-2.46,5.5-5.5c0-1.16-0.36-2.23-0.97-3.12L18,7.42V10h2V4h-6v2h2.58l-3.97,3.97C11.73,9.36,10.66,9,9.5,9z"
      />
    </SvgIcon>
  )
}
export const FeMaleIcon = function (props: any) {
  return (
    <SvgIcon {...props}>
      <path
        fill="currentColor"
        d="M17.5,9.5C17.5,6.46,15.04,4,12,4S6.5,6.46,6.5,9.5c0,2.7,1.94,4.93,4.5,5.4V17H9v2h2v2h2v-2h2v-2h-2v-2.1 C15.56,14.43,17.5,12.2,17.5,9.5z M8.5,9.5C8.5,7.57,10.07,6,12,6s3.5,1.57,3.5,3.5S13.93,13,12,13S8.5,11.43,8.5,9.5z"
      />
    </SvgIcon>
  )
}

export const OkrIcon = function (props: any) {
  return (
    <SvgIcon {...props}>
      <g
        id="页面-1"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="My-okr" transform="translate(-280.000000, -89.000000)">
          <g id="iocn/进度/灰" transform="translate(280.000000, 90.000000)">
            <g id="iocn/进度" transform="translate(4.000000, 4.000000)">
              <g id="编组-4">
                <rect
                  id="矩形"
                  fillOpacity="0.5"
                  fill="#444549"
                  x="0"
                  y="0"
                  width="4"
                  height="3"
                  rx="0.5"
                ></rect>
                <rect
                  id="矩形备份-4"
                  fill="#444549"
                  opacity="0.503813244"
                  x="5"
                  y="5.5"
                  width="4"
                  height="3"
                  rx="0.5"
                ></rect>
                <rect
                  id="矩形备份-6"
                  fill="#444549"
                  opacity="0.503813244"
                  x="12"
                  y="11"
                  width="4"
                  height="3"
                  rx="0.5"
                ></rect>
                <path
                  d="M5,1 L15.4761905,1 C15.7654825,1 16,1.22385763 16,1.5 L16,6.5 C16,6.77614237 15.7654825,7 15.4761905,7 L12.5074627,7"
                  id="形状"
                  stroke="#9C9EA1"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                ></path>
                <polyline
                  id="路径"
                  fill="#9C9EA1"
                  transform="translate(12.500000, 7.000000) rotate(-45.000000) translate(-12.500000, -7.000000) "
                  points="10.5 9 10.5 5 14.5 5"
                ></polyline>
                <path
                  d="M2.48494051,7 C4.37188495,7 7.2023016,7 10.9761905,7 C11.2654825,7 11.5,7.22385763 11.5,7.5 L11.5,12.5 C11.5,12.7761424 11.2654825,13 10.9761905,13 L8.00746267,13"
                  id="形状备份"
                  stroke="#9C9EA1"
                  strokeWidth="1.5"
                  strokeLinejoin="round"
                  transform="translate(6.000000, 10.000000) scale(-1, -1) translate(-6.000000, -10.000000) "
                ></path>
                <polyline
                  id="路径备份"
                  fill="#9C9EA1"
                  transform="translate(8.500000, 13.000000) scale(-1, -1) rotate(-45.000000) translate(-8.500000, -13.000000) "
                  points="6.5 15 6.5 11 10.5 11"
                ></polyline>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}
