import { gql, useQuery } from "@apollo/client"

const ALL_PROJECTS = gql`
  query AllProjects {
    allProjects {
      id
      name
      slug
    }
  }
`

const useAllProjects = () => {
  const { data, loading, error } = useQuery(ALL_PROJECTS, {
    fetchPolicy: "cache-and-network",
  })
  const projects = loading || error ? [] : data.allProjects
  return { projects, loading, error }
}

export default useAllProjects