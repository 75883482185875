import { useQuery } from "@apollo/client"
import { useMemo } from "react";
import { useQueryParams, } from "use-query-params"
import FETCH_KPI_TEAM_EDITOR from "../Pages/Kpi/Gqls/FETCH_KPI_TEAM_EDITOR";

import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../Utils"
const useKpiTeamEditor = () => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const [query] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const variables = useMemo(() => {
    return {
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
    }
  }, [query]);

  const { data, loading, refetch, error } = useQuery(FETCH_KPI_TEAM_EDITOR, {
    fetchPolicy: "cache-and-network",
    variables
  })

  const kpiTeamEditor = useMemo(() => {
    return loading || error ? [] : data.kpiTeamEditor;
  }, [loading, data, error])

  return { kpiTeamEditor, loading, refetch }
}

export default useKpiTeamEditor
