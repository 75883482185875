import React from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { Box, Button, Icon } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import SEARCH_TIME_PERIOD_FILES from "./Gqls/SEARCH_TIME_PERIOD_FILES"
import { useQuery } from "@apollo/client"
import { useConfirm } from "material-ui-confirm"
import { DataGrid } from "@material-ui/data-grid"

const Module = (props: RouteComponentProps) => {
  const confirm = useConfirm()

  const { loading, data } = useQuery(SEARCH_TIME_PERIOD_FILES, {
    variables: {},
    fetchPolicy: "cache-and-network",
  })
  const handleConfirm = async (row: any) => {
    await confirm({
      title: "warning!",
      description: "Are you sure replace this period?",
    })
    navigate(`/timeperiod/import/${row.id}`)
  }

  const columnSchema = [
    { headerName: "Period", field: "name", width: 200 },
    { headerName: "Import Time", field: "updated_at", width: 200 },
    {
      headerName: "Operate",
      field: "id",
      width: 200,
      renderCell: (params: any) => {
        return (
          <Button onClick={() => handleConfirm(params.row)}>
            <Icon color="primary">edit</Icon>
          </Button>
        )
      },
    },
  ]

  return (
    <Box mx="2%">
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Box my={4} component="h1">
          Period History
        </Box>
      </Grid>
      <Box>
        <DataGrid
          loading={loading}
          autoHeight
          pagination
          columns={columnSchema}
          rows={data ? data["timePeriodFiles"] : []}
        />
      </Box>
    </Box>
  )
}

export default Module
