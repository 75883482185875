import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import { useLocation, navigate } from "@reach/router"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import MuiIcon from "@material-ui/core/Icon"
import useUserInfo from "../../Hooks/useUserInfo"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      maxWidth: 256,
      fontSize: 16,
      padding: 0,
      lineHeight: 50,
    },
    btn: {
      "&:hover": {
        background: "transparent",
      },
    },
    active: {
      background: "red",
    },
    itemIcon: {
      minWidth: 20,
    },
    itemText: {
      paddingLeft: 6,
    },
  })
)

export interface MenuItemData {
  title: string
  name?: string
  icon?: string
  url?: string
  children?: Array<MenuItemData>
  active?: boolean
}

const compareUrl = (u1: string | undefined, u2: string | undefined) => {
  let url1 = u1 && u1.replace(/^\/|\/$/gm, "")
  let url2 = u2 && u2.replace(/^\/|\/$/gm, "")
  if (url1 && url1.indexOf("?") > 0) {
    url1 = url1.slice(0, url1.indexOf("?"))
  }
  return url1 === url2
}

const Icon = ({ type }: { type: string }) => {
  return <MuiIcon style={{ color: "white" }}>{type}</MuiIcon>
}

const MenuItem = ({ title, url, children, icon, active ,name}: MenuItemData) => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const handleClick = () => {
    if (children) {
      setOpen(!open)
    } else if (url) {
      if (url === ":8001") {
        const host = document.location.hostname
        const url = "http://" + host + ":8001"
        window.open(url)
        return
      }
      navigate(url)
    }
  }
  const style = active ? { background: "#EE2E24" } : {}
  React.useEffect(() => {
    const act = children && children.find((v) => v.active)
    if (act) {
      setOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <ListItem
        button
        onClick={handleClick}
        className={classes.btn}
        style={style}
      >
        <ListItemIcon className={classes.itemIcon}>
          {icon && <Icon type={icon} />}
        </ListItemIcon>
        <ListItemText primary={title} className={classes.itemText} />
        {children && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {<Menu items={children} />}
        </Collapse>
      )}
    </React.Fragment>
  )
}

const Menu = ({ items }: { items: MenuItemData[] }) => {
  const classes = useStyles()
  // const Subheader: any = title
  //   ? () => <ListSubheader component="div">{title}</ListSubheader>
  //   : undefined

  return (
    <List component="nav" className={classes.root}>
      <React.Fragment>
        {items.map((item: MenuItemData, key) => (
          <MenuItem {...item} key={key} />
        ))}
      </React.Fragment>
    </List>
  )
}

const composeItems = (menuItems: MenuItemData[], url: string) => {
  return menuItems.map((menu) => {
    if (menu.children) {
      menu.children = composeItems(menu.children, url)
    }
    return {
      ...menu,
      active: compareUrl(menu.url, url),
    }
  })
}

export default function ListMenu() {
  const location = useLocation()
  const { user } = useUserInfo()
  if (!user) {
    return null
  }
  const render_menu= JSON.parse(JSON.stringify(user.render_menu));
  const menu_add_active = composeItems(render_menu, location.pathname)
  return (
    <React.Fragment>
      <Menu items={menu_add_active} />
    </React.Fragment>
  )
}
