import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, createStyles, Grid, makeStyles, Theme } from "@material-ui/core"
import Search from "../../Components/Search"
import { useQueryParams } from "use-query-params"
import {
  DefaultNumberZero,
  DefaultYear,
  Type2DateRange,
} from "../../../../Utils"
import FETCH_TBA_INTEGRAL_LOG from "../../Gqls/FETCH_TBA_INTEGRAL_LOG"
import { useQuery } from "@apollo/client"
import useSession from "../../../../Hooks/useSession"
import { DataGrid } from "@material-ui/data-grid"
import moment from "moment"

const columns = [
  { headerName: "Date", field: "at", width: 108 },
  { headerName: "Description", field: "description", width: 280 },
  {
    headerName: "TBA In and Out",
    field: "amount",
    width: 220,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value}
        </Box>
      )
    },
  },
  {
    headerName: "Account Balance",
    field: "balance",
    width: 220,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value}
        </Box>
      )
    },
  },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      background: "linear-gradient(0, #FFECD3, #FDF2E2)",
      fontSize: "16px",
      textAlign: "center",
      paddingTop: 32,
      paddingBottom: 32,
      borderRadius: "4px",
      "& span": {
        color: theme.palette.primary.main,
        fontWeight: "bold",
      },
    },
  })
)

const MyDetail = (props: RouteComponentProps) => {
  const classes = useStyles()
  const { session } = useSession()
  const [query] = useQueryParams({
    year: DefaultYear,
    type: DefaultNumberZero,
  })

  const variables = React.useMemo(() => {
    const where: any = {
      AND: [
        {
          column: "USER_ID",
          operator: "EQ",
          value: session?.id,
        },
      ],
    }
    const orderBy: any = [
      {
        column: "AT",
        order: "ASC",
      },
    ]

    return { where, orderBy }
  }, [session])

  const { data, loading } = useQuery(FETCH_TBA_INTEGRAL_LOG, {
    fetchPolicy: "cache-and-network",
    variables,
  })
  // 手动过滤金额
  const rows = React.useMemo(() => {
    const arr = data ? data.TbaIntegralLogs : []
    // 先按照sort at排序计算余额
    let balance = 0
    const list = arr.map((v: any) => {
      balance += v.amount
      return {
        id: v.id,
        at: v.at,
        type: v.type,
        description: v.description,
        amount: v.amount.toFixed(2),
        expire_at: v.expire_at,
        balance: balance.toFixed(2),
      }
    })
    const { year, type } = query
    if (type === 0) {
      return list
    }
    const range = Type2DateRange(year, type)
    return list.filter((v: any) => {
      return moment(v.at).isBetween(range[0], range[1], null, "[]")
    })
  }, [data, query])

  const income = React.useMemo(() => {
    let sum = 0
    rows.forEach((v: any) => {
      if (v.type !== 3) {
        sum += Number(v.amount)
      }
    })
    return sum.toFixed(2)
  }, [rows])
  const used = React.useMemo(() => {
    let used = 0
    rows.forEach((v: any) => {
      if (v.type === 3) {
        used -= Number(v.amount)
      }
    })
    return used.toFixed(2)
  }, [rows])

  const totalRemaining = React.useMemo(() => {
    return (Number(income) - Number(used)).toFixed(2)
  }, [income, used])
  const outdated = React.useMemo(() => {
    let sum = 0
    rows.forEach((v: any) => {
      if (v.type === 5) {
        sum += Number(v.amount)
      }
    })
    return sum.toFixed(2)
  }, [rows])
  const expireInHalfYear = React.useMemo(() => {
    const half = moment().add(6, "months")
    let expire = 0
    rows.forEach((v: any) => {
      if (v.expire_at && half.isSameOrAfter(moment(v.expire_at))) {
        expire += Number(v.amount)
      }
    })
    const res = expire - Number(used) + Number(outdated);
    return res > 0 ? res.toFixed(2) : "0.00"
  }, [rows, used, outdated])

  return (
    <Box m="2%">
      <Box>
        <Search />
      </Box>
      <Box my={4}>
        {query.type > 0 ? (
          <Grid container spacing={3}>
            <Grid item xs={6} sm={6}>
              <Box className={classes.paper}>
                Income: <span>{income}</span>
              </Box>
            </Grid>
            <Grid item xs={6} sm={6}>
              <Box
                className={classes.paper}
                style={{ background: "linear-gradient(0,#BBE7B9,#E2F7E5)" }}
              >
                Used: <span>{used}</span>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={6} sm={3}>
              <Box className={classes.paper}>
                Income: <span>￥{income}</span>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box
                className={classes.paper}
                style={{ background: "linear-gradient(0,#BBE7B9,#E2F7E5)" }}
              >
                Used: <span>￥{used}</span>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box
                className={classes.paper}
                style={{ background: "linear-gradient(0,#F6DADA,#FEF6F6)" }}
              >
                Total Remaining: <span>￥{totalRemaining}</span>
              </Box>
            </Grid>
            <Grid item xs={6} sm={3}>
              <Box
                className={classes.paper}
                style={{ background: "linear-gradient(0,#D1E8F2,#EBF5FF)" }}
              >
                Expire In Half Year: <span>￥{expireInHalfYear}</span>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
      <Box>
        <DataGrid
          density={"compact"}
          loading={loading}
          disableColumnMenu={true}
          hideFooterPagination
          hideFooterRowCount
          hideFooterSelectedRowCount
          autoHeight
          rows={[...rows].reverse()}
          columns={columns}
        />
      </Box>
    </Box>
  )
}

export default MyDetail
