import * as React from "react"
import { Box, CircularProgress } from "@material-ui/core"
import ReactEcharts from "../../../Components/Echart"
import { createLineChart } from "./Schemas"
import { useQuery } from "@apollo/client"
import DASHBOARD_OKR_TEAM from "../Gqls/DASHBOARD_OKR_TEAM"
// import useAllUsers from "../../../Hooks/useAllUsers"

const OkrTeamChart = ({ variables, sortData }) => {
  const names = [...sortData].sort((a, b) => b.progress - a.progress)

  const { data, loading } = useQuery(DASHBOARD_OKR_TEAM, {
    fetchPolicy: "cache-and-network",
    variables,
  })
  const options: any = React.useMemo(() => {
    if (data && data.DashboardOkrTeam) {
      const kv: any = {}
      data.DashboardOkrTeam.forEach((v) => {
        kv[v.name] = {
          name: v.name,
          option: createLineChart(v.data, true),
        }
      })
      const res: any = []
      names.forEach((v) => {
        if (kv[v.name]) {
          res.push(kv[v.name])
        }
      })
      return res
    }
    return []
  }, [data, names])

  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }
  if (options.length === 0) {
    return <Box></Box>
  }
  return options.map((v) => (
    <Box
      boxShadow={5}
      p={4}
      mt={4}
      style={{ background: "#FEFEFE" }}
      key={v.name}
    >
      <Box p={4} pt={2} color="#5A4ECD" fontSize="20px" fontWeight="bold">
        {v.name} Team
      </Box>
      <ReactEcharts
        option={v.option}
        style={{
          height: 400,
        }}
      />
    </Box>
  ))
}

export default OkrTeamChart
