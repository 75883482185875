import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"

const getCreateSchema = () => {
  const schema = {
    fields: [
      {
        name: "name",
        label: "Role Name",
        FormFieldGridProps: {
          sm: 12,
        },
        component: FieldTypes.TEXT_FIELD,
        isRequired: true,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
      {
        name: "menu",
        component: 'MenuField',
        isRequired: true,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
      {
        name: "is_default",
        label: "Default",
        component: FieldTypes.SELECT,
        options: [
          {
            label: "Yes",
            value:  true,
          },
          {
            label: "No",
            value: false,
          },
        ],
      },
    ]
  };

  return schema
}
export default getCreateSchema
