import * as React from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useOperationTypes from "../Hooks/useOperationTypes"

const filterOptions = (options: Array<any>, { inputValue }: any) => {
  if (!inputValue) {
    return options
  }

  return options.filter((user) => {
    const matchValue = inputValue.toLowerCase()

    return (
      user.name.toLowerCase().indexOf(matchValue) !== -1
    )
  })
}

const OperationTypeSelector = (props: any) => {
  const { types, loading } = useOperationTypes()
  const list = React.useMemo(
    () => types.map((v: any) => ({ id: v.id, name: v.namee })),
    [types]
  )
  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={list}
      getOptionLabel={(option: any) => (option ? option.name : "")}
      style={{ width: props.width || 200 }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          required={props.required}
        />
      )}
      {...props}
    />
  )
}
export default OperationTypeSelector
