import React from "react"
import { ThemeProvider } from "@material-ui/core/styles"
import {
  // Redirect,
  Router,
  globalHistory,
} from "@reach/router"
import { CssBaseline } from "@material-ui/core"
import theme from "./theme"
import Layout from "./Components/Layout/Layout"
import { ConfirmProvider } from "material-ui-confirm"
import { ToastContainer } from "material-react-toastify"
import "material-react-toastify/dist/ReactToastify.css"

import Dashboard from "./Pages/Dashboard"
import UserIndex from "./Pages/User"
import UpdatePassword from "./Pages/User/UpdatePassword"
import Login from "./Pages/Login/Login"
import Project from "./Pages/Project"
import Po from "./Pages/Po"
import POAssignment from "./Pages/Po/assignment"
import Team from "./Pages/Team"
import TeamLog from "./Pages/Team/Log"
// import CreateProject from "./Pages/CreateProject"
// import CreatePo from "./Pages/CreatePo"
import TimePeriod from "./Pages/TimePeriod/TimePeriod"
import ImportAttendance from "./Pages/Kpi/ImportAttendance"
import ImportPeriod from "./Pages/TimePeriod/Import"
// import OperationLog from "./Pages/OperationLog"
import NewOperationLog from "./Pages/NewOperationLog"
import PeriodHistory from "./Pages/TimePeriod/PeriodHistory"
// import Team from "./Pages/Team"
import ReportIndex from "./Pages/Report"
import ReportDashboard from "./Pages/Report/Dashboard"
import ReportImport from "./Pages/Report/Import"
import MyOkr from "./Pages/Okr/My"
import TeamOkr from "./Pages/Okr/Team"
import AllOkr from "./Pages/Okr/All"
import SetupKpi from "./Pages/Kpi/Setup"
import TeamKpi from "./Pages/Kpi/MyTeam"
import AllKpi from "./Pages/Kpi/All"
import ArchiveKpi from "./Pages/Kpi/Archive"
import UserLeave from "./Pages/UserLeave/index"

import AllocateTeamBonus from "./Pages/Bonus/AllocateTeam"
import ArchiveBonus from "./Pages/Bonus/Archive"
import MyTeamBonus from "./Pages/Bonus/MyTeam"

import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"
import FormWatcher from "./Components/FormWatcher"
import { QueryParamProvider } from "use-query-params"
import Release from "./Pages/ReleaseNote"

import MyDetailTBA from "./Pages/Tba/My/Detail"
import MyUsedTBA from "./Pages/Tba/My/Used"
import MyEarnnedTBA from "./Pages/Tba/My/Earnned"
import TBAManagement from "./Pages/Tba/Internal"
import TBAExternalManagement from "./Pages/Tba/External"
import TBAImport from "./Pages/Tba/Import"
import TBAFundArchive from "./Pages/Tba/FundArchive"
import TBAOverview from "./Pages/Tba/Overview"

import Home from "./Pages/Home"
import TeamBuilding from "./Pages/TeamBuilding"
import LeaveAttritionData from "./Pages/User/LeaveAttritionData"
import FormalData from "./Pages/User/FormalData"
import Diagnostic from "./Pages/Diagnostic"
import NeedChangePass from "./Pages/NeedChangePass"
import Settings from "./Pages/Settings"
import UpdateAvatar from "./Pages/User/UpdateAvatar"
import Organization from "./Pages/Dashboard/Organization"
import FileSystem from "./Pages/FileSystem"
import FileSystemEditor from "./Pages/FileSystem/editor"
import FileSystemView from "./Pages/FileSystem/view"
import Role from "./Pages/Role"
import TeamLeaderView from "./Pages/User/TeamLeaderView"
import RoleAssignment from "./Pages/Role/Assignment"
import RoleAssignmentEditor from "./Pages/Role/AssignmentEditor"
import KpiBonusData from "./Pages/User/KpiBonusData"
import JobLevelData from "./Pages/User/JobLevelData"

ComponentMapper["FormWatcher"] = FormWatcher

const Pages = () => {
  return (
    <Router>
      <QueryParamProvider {...{ path: "/" }} reachHistory={globalHistory}>
        <Layout path="/">
          <Home path="/home" />
          <Dashboard path="/dashboard" />
          <Organization path="/orginization/chart" />
          <UpdatePassword path="/user/password" />
          <UpdateAvatar path="/user/avatar" />
          <UserIndex path="/user" />
          <TeamLeaderView path="/user/scope" />
          <Role path="/role" />
          <RoleAssignment path="/role/assignment" />
          <RoleAssignmentEditor path="/role/assignment/editor/:id" />
          <Team path="/team" />
          <TeamLog path="/team/log" />
          <UserLeave path="/leave" />
          <NewOperationLog path="/operation/log" />
          <Project path="/project" />
          <Po path="/po" />
          <TimePeriod path="/timeperiod" />
          <ImportPeriod path="/timeperiod/import" />
          <ImportPeriod path="/timeperiod/import/:id" />
          <PeriodHistory path="/periodhistory" />
          <MyOkr path="/okr/my" />
          <TeamOkr path="/okr/team" />
          <AllOkr path="/okr/all" />

          <SetupKpi path="/kpi/setup" />
          <TeamKpi path="/kpi/team" />
          <ImportAttendance path="/kpi/import/attendance" />
          <AllKpi path="/kpi/all" />
          <ArchiveKpi path="/kpi/archive" />

          <MyDetailTBA path="/tba/my/detail" />
          <MyUsedTBA path="/tba/my/used" />
          <MyEarnnedTBA path="/tba/my/earnned" />
          <TBAManagement path="/tba/management" />
          <TBAOverview path="/tba/overview" />
          <TBAExternalManagement path="/tba/external" />

          <TBAFundArchive path="/tba/fund/archive" />
          <TBAImport path="/tba/training/import" />

          <AllocateTeamBonus path="/bonus/allocate/team" />
          <ArchiveBonus path="/bonus/archive" />
          <MyTeamBonus path="/bonus/my" />
          {/* <ReportIndex path="/report" /> */}
          <ReportIndex path="/report" />
          <ReportDashboard path="/report/dashboard" />
          <ReportImport path="/report/import" />

          <TeamBuilding path="/item/config" />
          <POAssignment path="/po/assignment" />

          <FormalData path="/user/formal-data" />
          <KpiBonusData path="/user/kpi-bonus-data" />
          <JobLevelData path="/user/job-level-data" />
          <LeaveAttritionData path="/user/leave-attrition-data" />
          <Diagnostic path="/diagnostic" />
          <Settings path="/settings" />
          <FileSystem path="/document" />
          <FileSystemEditor path="/document/editor/:id" />
          <FileSystemEditor path="/document/editor" />
          <FileSystemView path="/document/view/:id" />
          {/* <FileSystemEditor path="/document/editor" /> */}

          {/* 
          <CreateProject path="/project/create" />
          <CreatePo path="/po/create" />
          <Team path="/team" />
        */}
        </Layout>
        <Login path="/login" />
        <NeedChangePass path="/please/change/password" />
        <Release path="/release" />
      </QueryParamProvider>
    </Router>
  )
}

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <ConfirmProvider>
        <CssBaseline />
        <Pages />
        <ToastContainer />
      </ConfirmProvider>
    </ThemeProvider>
  )
}

export default App
