export const makeOptions = (data: Array<string>) => data.map(item => ({
  label: item,
  value: item
}))
export const makeOptionsWithNa = (data: Array<string>) => {
  const na = { label: "N/A", value: "" }
  const arr = makeOptions(data);
  arr.push(na);
  return arr;
}
export const makeItemOptions = (data: Array<string>) => data.map((item, index) => ({
  label: item,
  value: String(index)
}))

export const filterArray = (A, B) => {
  let newArr = A.filter((itemA) => {
    return B.every((itemB) => {
      return itemA !== itemB
    })
  })
  return newArr
}

export const USER_JOB_TITLE = ['Country Manager', 'Team Manager', 'Consultant'];
export const USER_JOB_COMPETENCY = [
  'IA',
  'CDD',
  'Coach',
  'IPD',
  'Finance'
];
export const USER_JOB_LEVEL = ['J1', 'J2', 'E1', 'E2', 'S1', 'S2', 'EX1', 'EX2'];
export const USER_LEAVE_TYPE = ['Maternity Leave', 'Long Sick Leave', 'Unpaid Leave']

export const FILE_STATUS = ['Unpublished', 'Published', 'Archived']
export const FILE_STATUS_OPTIONS = makeItemOptions(FILE_STATUS);

export const USER_JOB_TITLE_OPTIONS = makeOptions(USER_JOB_TITLE);
export const USER_JOB_COMPETENCY_OPTIONS = makeOptions(USER_JOB_COMPETENCY);
export const USER_JOB_LEVEL_OPTIONS = makeOptionsWithNa(USER_JOB_LEVEL);
export const USER_LEAVE_TYPE_OPTIONS = makeOptions(USER_LEAVE_TYPE);

