import { gql } from "@apollo/client"

const UPDATE_DATA = gql`
mutation UpdateTeamBuilding($id: ID!, $name: String!) {
  updateTeamBuilding(id: $id, name: $name) {
    id
    name
  }
}

`
export default UPDATE_DATA