import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"

const updatePasswordSchema = () => {
  const schema = {
    fields: [
      {
        name: "oldPassword",
        label: "Old Password",
        component: FieldTypes.TEXT_FIELD,
        type: "password",
        isRequired: true,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
      {
        name: "newPassword",
        label: "New Password",
        component: FieldTypes.TEXT_FIELD,
        type: "password",
        isRequired: true,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
      {
        name: "rePassword",
        label: "Repeat New Password",
        component: FieldTypes.TEXT_FIELD,
        type: "password",
        isRequired: true,
        validate: [
          {
            type: ValidatorTypes.REQUIRED,
          },
        ],
      },
     
    ],
  }

  schema.fields.forEach((field: any) => {
    if (!field.FormFieldGridProps) {
      field.FormFieldGridProps = {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      }
    }
  })

  return schema
}

export default updatePasswordSchema
