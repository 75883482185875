import { gql } from "@apollo/client"

const UPDATE_FILE = gql`
mutation UpdateFile($id: ID!, $input: FileInput!) {
  updateFile(id: $id, input: $input) {
    id
    filename
    content
    status
    creator_id
    revisor_id
  }
}
`
export default UPDATE_FILE