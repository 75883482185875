import { Box, Tooltip } from "@material-ui/core"
import * as React from "react"
import DataGrid from "../../Components/DataGrid"
import IMPORT_LOG from "./IMPORT_LOG"
import Dialog from "../Dialog"

const ImportLog = ({ type }: any) => {
  const [open, setOpen] = React.useState(false)
  const [content, setContent] = React.useState<any>("")
  const column = [
    { headerName: "Filename", field: "filename", width: 200, sortable: false },
    { headerName: "Status", field: "status", width: 100, sortable: false },
    {
      headerName: "Info",
      field: "info",
      width: 500,
      sortable: false,
      renderCell: (params: any) => {
        const list = params.value.split("<br/>")
        const title = list.map((v: any) => <Box key={v}>{v}</Box>)
        const children = list.join("\n")
        const detail = (
          <Box p={2} minHeight={200}>
            <Box fontSize={20} pb={2}>
              FileName: {params.row.filename}
            </Box>
            <Box fontSize={16} pb={2}>
              {title}
            </Box>
          </Box>
        )

        return (
          <Tooltip title={title}>
            <Box
              overflow="hidden"
              onClick={() => {
                if (params.value === "N/A") {
                  return
                }
                setOpen(true)
                setContent(detail)
                // toast.error(() => title, {
                //   position: "top-center",
                //   autoClose: false,
                //   closeOnClick: false,
                //   draggable: false,
                // })
              }}
            >
              {children}
            </Box>
          </Tooltip>
        )
      },
    },
    {
      headerName: "Import Time",
      field: "created_at",
      width: 150,
    },
    {
      headerName: "Import User",
      sortable: false,
      field: "user",
      width: 150,
      renderCell: (params: any) => {
        return params.value.name
      },
    },
  ]

  const beforeSearchData = (data: any) => {
    let orderBy = [{ column: "CREATED_AT", order: "DESC" }]
    if (data.orderBy) {
      orderBy = data.orderBy
    }
    const res = {
      ...data,
      orderBy,
      type,
    }
    return res
  }
  return (
    <>
      <DataGrid
        noMargin
        title="log"
        name="importLogs"
        searchGql={IMPORT_LOG}
        columnSchema={column}
        beforeSearchData={beforeSearchData}
      />
      <Dialog
        open={open}
        handleClose={() => setOpen(false)}
        children={content}
      />
    </>
  )
}

export default ImportLog
