import { gql } from "@apollo/client"

const BECOME_FORMAL = gql`
mutation BecomeFormal($id: ID!, $formal_date: Date) {
  userBecomeFormal(id: $id, formal_date: $formal_date) {
      id
      name
      leave_status_name
      type
      term
      formal_date
      status
    }
  }
`
export default BECOME_FORMAL