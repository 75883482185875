import React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, Button, createStyles, makeStyles, Theme } from "@material-ui/core"
import useAllUsers from "../../Hooks/useAllUsers"
import { DataGrid, GridSortModel } from "@material-ui/data-grid"
import moment from "moment"
import { downloadUrl } from "../../Utils"
import Search from "./Field/Search"
import { StringParam, useQueryParams } from "use-query-params"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bg: {
      backgroundColor: theme.palette.primary.main,
      color: "#ffffff",
      fontSize: "16px",
      padding: "8px"
    },
  })
)
const columns = [
  { headerName: "Year", field: "year", width: 80 },
  { headerName: "Year/Quarter", field: "year_quarter", width: 120 },
  { headerName: "User Name", field: "name", width: 120 },
  { headerName: "Unit", field: "unit", width: 80 },
  { headerName: "Team", field: "team", width: 100 },
  { headerName: "Start", field: "start", width: 100 },
  { headerName: "End", field: "term", width: 100 },
  { headerName: "Birthday", field: "birthday", width: 100 },
  {
    headerName: "Period (roughly) in months",
    field: "period",
    width: 120,
  },
  { headerName: "Type", field: "type", width: 100 },
  { headerName: "Status", field: "status", width: 100 },
]

const LeaveAttritionData = (props: RouteComponentProps) => {
  const classes = useStyles()
  const [query] = useQueryParams({
    year: StringParam,
    quarter: StringParam,
    unit: StringParam,
    team: StringParam,
    username: StringParam
  })
  const { users, loading } = useAllUsers()
  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "year_quarter",
      sort: "asc",
    },
  ])
  // 数据导出
  const exportLink = React.useCallback(() => {
    downloadUrl("/api/export/user/leave-attrition-data", { query })
  }, [query])

  const data = React.useMemo(
    () =>
      users
        .filter((user: any) => user.status === "Left")
        .map((user: any) => {
          const start = moment(user.start)
          const term = moment(user.term)
          const period = (term.diff(start, "days") / 30).toFixed(1)
          const year = term.year() + ""
          const quarter = term.quarter() + ""
          const year_quarter = term.year() + "Q" + term.quarter()
          return {
            ...user,
            year,
            quarter,
            year_quarter,
            period,
          }
        }),
    [users]
  )

  const list = React.useMemo(() => {
    const { year, quarter, unit, team ,username} = query
    return data.filter((v: any) => {
      if (year && year !== v.year) {
        return false
      }
      if (quarter && quarter !== v.quarter) {
        return false
      }
      if (unit && unit !== v.unit) {
        return false
      }
      if (team && team !== v.team) {
        return false
      }
      if (username && v.name.toUpperCase().indexOf(username.toUpperCase()) === -1) {
        return false
      }
      return true
    })
  }, [data, query])
  const avg = React.useMemo(() => {
    let sum = 0
    let len = 0
    list.forEach((user: any) => {
      const start = moment(user.start)
      const term = moment(user.term)
      sum += term.diff(start, "days")
      len++
    })
    return (sum / len / 30).toFixed(1)
  }, [list])
  return (
    <Box m="2%">
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        flexDirection="row-reverse"
      >
        <Button onClick={exportLink} variant="contained" color="primary">
          Export
        </Button>
      </Box>
      <Box mb={2}>
        <Search />
      </Box>
      <DataGrid
        components={{
          Toolbar: () => <Box className={classes.bg}>Avg period: {avg}</Box>,
        }}
        autoHeight
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        loading={loading}
        density={"compact"}
        rows={loading ? [] : list}
        columns={columns}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
      />
    </Box>
  )
}

export default LeaveAttritionData
