import * as React from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { Box, Button, createStyles, makeStyles } from "@material-ui/core"
import DataGrid from "../../Components/DataGrid"
import SEARCH_FILE from "./Gqls/SEARCH_FILE"
import useSession from "../../Hooks/useSession"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Edit"
import { useApolloClient } from "@apollo/client"
import DELETE_FILE from "./Gqls/DELETE_FILE"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { useConfirm } from "material-ui-confirm"
import { FILE_STATUS } from "../../Utils/config"

const useStyles = makeStyles(() => {
  return createStyles({
    box: {
      cursor: "pointer",
      width: "100%",
    },
    Unpublished: {
      display: "inline",
      padding: "4px",
      color: "rgba(0, 0, 0, 0.87)",
      background: "#e0e0e0",
    },
    Published: {
      display: "inline",
      padding: "4px",
      color: "#fff",
      background: "#1976d2",
    },
    Archived: {
      display: "inline",
      padding: "4px",
      color: "#fff",
      background: "#ffcf33",
    },
  })
})

const FileSystem = (_: RouteComponentProps) => {
  const { can } = useSession()
  const graphqlClient = useApolloClient()
  const confirm = useConfirm()
  const classes = useStyles()

  const beforeSearchData = (params) => {
    if (can("document.update", true)) {
      return params
    }
    params.where = {
      AND: [{ column: "STATUS", operator: "EQ", value: "1" }],
    }
    return params
  }
  // 调用删除数据API
  const deleteApi = React.useCallback(
    (id) => {
      return graphqlClient.mutate({
        mutation: DELETE_FILE,
        refetchQueries: [
          {
            query: SEARCH_FILE,
          },
        ],
        variables: { id },
      })
    },
    [graphqlClient]
  )
  // 点击删除数据按钮
  const handleDelete = async (data: any) => {
    await confirm({
      description: "Are you sure to delete " + data.filename,
    })
    await deleteApi(data.id)
  }

  const toView = (id) => navigate(`/document/view/${id}`)
  const columnSchema = [
    {
      headerName: "Operation",
      field: "operation",
      width: 150,
      renderCell: (params: any) => {
        const arr: any[] = []
        arr.push(
          <Button
            key="view"
            color="primary"
            size="small"
            onClick={() => toView(params.row.id)}
          >
            <VisibilityIcon />
          </Button>
        )
        if (can("document.update", true)) {
          arr.push(
            <Button
              key="update"
              color="primary"
              size="small"
              onClick={() => navigate(`/document/editor/${params.row.id}`)}
            >
              <EditIcon />
            </Button>
          )
        }
        if (can("document.delete", true)) {
          arr.push(
            <Button
              key="delete"
              color="primary"
              size="small"
              onClick={() => handleDelete(params.row)}
            >
              <DeleteIcon />
            </Button>
          )
        }

        return arr
      },
    },
    {
      headerName: "Document Title",
      field: "filename",
      width: 250,
      renderCell: (params: any) => (
        <Box className={classes.box} onClick={() => toView(params.row.id)}>
          {params.row.filename}
        </Box>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      width: 150,
      renderCell: (params: any) => {
        const status = FILE_STATUS
        const status_name = status[params.row.status]
        return (
          <Box className={classes.box} onClick={() => toView(params.row.id)}>
            <Box className={classes[status_name]}>{status_name}</Box>
          </Box>
        )
      },
    },
    {
      headerName: "Creator",
      field: "creator_id",
      width: 150,
      renderCell: (params: any) => (
        <Box className={classes.box} onClick={() => toView(params.row.id)}>
          {params.row.creator_name}
        </Box>
      ),
    },

    {
      headerName: "Modified by",
      field: "revisor_id",
      width: 150,
      renderCell: (params: any) => (
        <Box className={classes.box} onClick={() => toView(params.row.id)}>
          {params.row.revisor_name}
        </Box>
      ),
    },
    {
      headerName: "Created At",
      field: "created_at",
      width: 150,
      renderCell: (params: any) => (
        <Box className={classes.box} onClick={() => toView(params.row.id)}>
          {params.row.created_at}
        </Box>
      ),
    },
    {
      headerName: "Modified At",
      field: "updated_at",
      width: 150,
      renderCell: (params: any) => (
        <Box className={classes.box} onClick={() => toView(params.row.id)}>
          {params.row.updated_at}
        </Box>
      ),
    },
  ]

  return (
    <Box>
      <DataGrid
        leftButton={can(
          "document.create",
          <Button
            variant="contained"
            disableElevation
            size="small"
            color={"primary"}
            onClick={() => {
              navigate(`/document/editor`)
            }}
          >
            Create New Document
          </Button>
        )}
        title="File"
        name="files"
        searchGql={SEARCH_FILE}
        columnSchema={columnSchema}
        beforeSearchData={beforeSearchData}
      />
    </Box>
  )
}

export default FileSystem
