import { gql } from "@apollo/client"

// CreateKpiArchive 重命名 
const CREATE_KPI_ARCHIVE = gql`
mutation KpiArchive(
  $year: Int!
  $quarter: Int!
) {
  createKpiArchive( year: $year, quarter: $quarter) {
    id
    year
    quarter
    budget
    manager_per
    content {
      team_id
      team_name
      content {
        weight
        value
        label
        column
        score
        is_custom
      }
    }
  }
}
`
export default CREATE_KPI_ARCHIVE