import * as React from "react"
import { gql, useMutation } from "@apollo/client"
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core"
import { RouteComponentProps } from "@reach/router"
import useSettings from "../../Hooks/useSettings"
import { toast } from "material-react-toastify"
import useSession from "../../Hooks/useSession"

const SAVE_API = gql`
  mutation UpdateSetting($input: [SettingInput]!) {
    updateSetting(input: $input) {
      id
      name
      value
    }
  }
`
const Settings = (_: RouteComponentProps) => {
  const { settingsKV } = useSettings()
  const { can } = useSession()
  const [form, setForm] = React.useState<any>({
    LOGOUT_WHEN_IDLE: "",
    MAINTENANCE_MODE: "",
  })
  React.useEffect(() => {
    if (JSON.stringify(settingsKV) !== "{}") {
      setForm(settingsKV)
    }
  }, [settingsKV])

  const [saveApi, { loading }] = useMutation(SAVE_API, {
    onCompleted(res) {
      if (!res) {
        return
      }
      toast.success("success!", {
        position: "top-center",
        autoClose: 3000,
      })
    },
    onError(error) {
      console.log(error)
    },
  })

  const handleChangeLogout = (event: React.ChangeEvent<HTMLInputElement>) => {
    const LOGOUT_WHEN_IDLE = event.target.value
    setForm({...form, LOGOUT_WHEN_IDLE })
  }
  const handleChangeMaintenance = (event: any) => {
    const MAINTENANCE_MODE =  event.target.value;
    setForm({...form, MAINTENANCE_MODE })
  }
  const handleSubmit = () => {
    const input: any = []
    for (let key in form) {
      input.push({
        name: key,
        value: form[key],
      })
    }
    saveApi({ variables: { input } })
  }

  if (!can("setting.view", true)) {
    return null
  }

  return (
    <Box p={2}>
      <Card variant="outlined" style={{ maxWidth: 500 }}>
        <CardContent>
          <form noValidate autoComplete="off">
            <TextField
              onChange={handleChangeLogout}
              value={form.LOGOUT_WHEN_IDLE}
              type="number"
              label="Logout after no operation for (minutes)"
              fullWidth
            />
            <FormControl style={{ width: "100%", marginTop: 10 }}>
              <InputLabel id="maintenance">Maintenance mode</InputLabel>
              <Select
                labelId="MAINTENANCE_MODE"
                id="maintenance"
                value={form.MAINTENANCE_MODE}
                onChange={handleChangeMaintenance}
              >
                <MenuItem value="0">OFF</MenuItem>
                <MenuItem value="1">ON</MenuItem>
              </Select>
            </FormControl>
            <Box mt={4} textAlign="center">
              {loading ? (
                <Box textAlign="center">
                  <CircularProgress />
                </Box>
              ) : (
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="primary"
                >
                  Confirm
                </Button>
              )}
            </Box>
          </form>
        </CardContent>
      </Card>
    </Box>
  )
}

export default Settings
