import { gql } from "@apollo/client"

const FETCH_KPI_BONUS_LOGS = gql`
query FetchKpiBonusLogs(
  $team_id: ID,
  ) {
  kpiBonusLogs(
    team_id: $team_id,
  ) {
      id
      type
      amount
      year
      quarter
      team {
        id
        name
      }
      user {
        id
        name
      }
      created_at
  }
}
`
export default FETCH_KPI_BONUS_LOGS