import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import FETCH_DATA from "./Gqls/FETCH_DATA"
import DataGrid from "../../Components/DataGrid"
import CheckIcon from '@material-ui/icons/Check';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
const columnSchema = [
  {
    headerName: "Name",
    field: "name",
    width: 250,
  },
  {
    headerName: "Reference",
    field: "reference",
    width: 150,
  },
  {
    headerName: "Value",
    field: "value",
    width: 150,
  },
  {
    headerName: "Verification",
    field: "id",
    width: 150,
    renderCell: (params: any) => {
      const str = params.row.reference.replace(/\s*/g, "").replace(/%*/g, "");
      const current = Number(params.row.value.replace(/%*/g, ""));
      const arr = str.split("~");
      if (Number(arr[0]) <= current && current <= Number(arr[1])) { 
        return <CheckIcon color="secondary" />
      }
      return <ErrorOutlineIcon color="primary" />
    }
  },
  
]
const beforeSearchData = (params:any) => { 
  return {show:true};
}
const Diagnostic = (_: RouteComponentProps) => {
  return (
    <DataGrid
      title="Item"
      name="DiagnosticAll"
      mode="local"
      beforeSearchData={beforeSearchData}
      searchGql={FETCH_DATA}
      columnSchema={columnSchema}
    />
  )
}

export default Diagnostic
