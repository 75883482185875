import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"
import validatorTypes from '@data-driven-forms/react-form-renderer/validator-types';
import { USER_LEAVE_TYPE_OPTIONS } from "../../../Utils/config";

const getCreateSchema = () => {
  const schema = {
    fields: [
      {
        component: FieldTypes.SUB_FORM,
        name: "subform",
        ItemsGridProps: {
          className: "custom-form-border",
          spacing: 3,
          sm: 12,
          container: true
        },
        fields: [
          {
            component: FieldTypes.SUB_FORM,
            name: "subform1",
            ItemsGridProps: {
              container: true,
              spacing: 3,
            },
            fields: [
              {
                name: "user",
                label: "Leave User Name",
                component: "UserSelectorField",
                isRequired: true,
                width: "100%",
                FormFieldGridProps: {
                  xs: 6,
                },
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
              },
              {
                name: "type",
                label: "Leave Type",
                component: "select",
                options: USER_LEAVE_TYPE_OPTIONS,
                FormFieldGridProps: {
                  xs: 6,
                },
                isRequired: true,
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
                getOptionSelected: (option: any, value: any) => option.value === value,
              },
              {
                name: "myTeam",
                label: "Team",
                component: "TeamSelectorField",
                isRequired: true,
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
                FormFieldGridProps: {
                  xs: 6,
                },
              },
              {
                name: "myUnit",
                label: "Unit",
                component: "UnitSelectorField",
                isRequired: true,
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                ],
                FormFieldGridProps: {
                  xs: 6,
                },
              },
              {
                name: "duration",
                label: "Duration",
                component: FieldTypes.TEXT_FIELD,
                isRequired: true,
                FormFieldGridProps: {
                  xs: 12,
                },
                validate: [
                  {
                    type: ValidatorTypes.REQUIRED,
                  },
                  {
                    type: validatorTypes.MIN_NUMBER_VALUE,
                    includeThreshold: true,
                    value: 0,
                  },

                ],
              },
            ]
          },
          {
            component: FieldTypes.SUB_FORM,
            name: "subform2",
            ItemsGridProps: {
              container: true,
              spacing: 3,
            },
            fields: [{
              component: FieldTypes.SUB_FORM,
              name: "subform2-2",
              ItemsGridProps: {
                container: true,
              },
              fields: [
                {
                  name: "range",
                  label: "DateRange",
                  FormFieldGridProps: {
                    container: true,
                    spacing: 3,
                  },
                  component: "DateRangeField",
                  isRequired: true,
                  validate: [
                    {
                      type: ValidatorTypes.REQUIRED,
                    },
                  ],
                },
              ],
            },
            {
              name: "note",
              label: "Note",
              component: FieldTypes.TEXT_FIELD,
            }]
          }

        ],
      },

    ],
  }

  return schema
}


export default getCreateSchema
