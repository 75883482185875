import { gql } from "@apollo/client"

const UPLOAD_TRAINING = gql`
  mutation UploadTraining($file: Upload!, $platform:Int!) {
    uploadTraining(file: $file, platform:$platform) {
      code
      message
    }
  }
`
export default UPLOAD_TRAINING