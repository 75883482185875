import React from "react"
import Tooltip from '@material-ui/core/Tooltip';
const columnSchema = [
  { headerName: "PO Number", field: "number", width: 120 },
  {
    headerName: "Project ID",
    field: "project_slug",
    width: 108,
  },

  { headerName: "Start", field: "start", width: 85 },
  { headerName: "End", field: "end", width: 85 },
  {
    headerName: "Total Amount", field: "total_amount", width: 110, renderCell: (params: any) => {
      return React.createElement("div", {
        style: {
          textAlign: 'right',
          width: "100%"
        }
      }, params.value.toFixed(2))
    }
  },
  {
    headerName: "Remaining Amount", field: "remainingAmount", width: 110,  renderCell: (params: any) => {
      return React.createElement("div", {
        style: {
          textAlign: 'right',
          width: "100%"
        }
      }, params.value.toFixed(2))
    }
  },
  {
    headerName: "Remaining Rate", field: "remainingRate", width: 110,  renderCell: (params: any) => {
      return React.createElement("div", {
        style: {
          textAlign: 'right',
          width: "100%"
        }
      }, params.value)
    }
  },
  {
    headerName: "Remaining Days", field: "remainingDays", width: 110,  type:'number'
  },
  { headerName: "Team", field: "team", width: 90, },
  { headerName: "Status", field: "status", width: 70,  },
  {
    headerName: "PO User ID",
    field: "members",
    width: 180,
    
    renderCell: (params: any) => {
      const label = params.value.map((item: any) => item.abbr.toUpperCase()).join(",")
      const title = params.value.map((item: any) => item.abbr.toUpperCase() + "(" + item.name + ")").join(",");
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      }, label)
      return React.createElement(Tooltip, { title, children });
    }
    // cellClassName: () => 'table-cell--scroll'
  },
]

export default columnSchema
