import { gql } from "@apollo/client"

const DELETE_USERLEAVE = gql`
  mutation DeleteUserLeave (
    $id: ID!,
  ) {
    deleteUserLeave(
      id: $id,
    )  {
      id
    }
  }
`

export default DELETE_USERLEAVE