import * as React from "react"
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  withStyles,
} from "@material-ui/core"
import moment from "moment"
import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selected: {
      padding: "1px 4px",
      background: "#666666",
      color: "#ffffff",
      border: "1px solid #666666",
      "&:hover": {
        background: "#666666",
        color: "#ffffff",
      },
    },
    unselected: {
      padding: "1px 4px",
      background: "transparent",
      color: "#666666",
      border: "1px solid #666666",
      "&:hover": {
        background: "#666666",
        color: "#ffffff",
      },
    },
    margin: {
      marginRight: theme.spacing(1),
    },
  })
)

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[2],
    fontSize: 11,
  },
}))(Tooltip)

const typeBtns: any[] = [
  {
    name: "Month",
    type: 0,
  },
  {
    name: "Quarter",
    type: 1,
  },
]

const abscissaBtns: any[] = [
  {
    name: "Team",
    type: 0,
  },
  {
    name: "Unit",
    type: 1,
  },
  {
    name: "All",
    type: 2,
  },
]

function FormDialog({ period, setPeriod, setAbscissa }: any) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [start, setStart] = React.useState<any>()
  const [end, setEnd] = React.useState<any>()

  const handleClickOpen = () => {
    setStart(period[0])
    setEnd(period[1])
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleConfirm = () => {
    setPeriod([start, end])
    if (start.format("YYYY") !== end.format("YYYY")) {
      setAbscissa(0)
    }
    setOpen(false)
  }

  const title = React.useMemo(
    () => period[0].format("MMM YYYY") + " ~ " + period[1].format("MMM YYYY"),
    [period]
  )
  return (
    <div>
      <LightTooltip
        open={open}
        placement="bottom-start"
        title={
          <React.Fragment>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                label="From"
                style={{ width: 120 }}
                format="MMM yyyy"
                views={["year", "month"]}
                clearable={false}
                value={start}
                onChange={(e) => {
                  setStart(e)
                }}
              />
              <DatePicker
                label="To"
                style={{ width: 120 }}
                format="MMM yyyy"
                views={["year", "month"]}
                clearable={false}
                value={end}
                onChange={(e) => {
                  setEnd(e)
                }}
              />
            </MuiPickersUtilsProvider>
            <Box mt={2}>
              <Button
                className={classes.margin}
                variant="outlined"
                onClick={() => {
                  const s = moment().subtract(13, "months").startOf("month")
                  const e = moment().subtract(1, "months").startOf("month")
                  setStart(s)
                  setEnd(e)
                }}
              >
                Last 12 Month
              </Button>
              <Button
                className={classes.margin}
                variant="outlined"
                onClick={() => {
                  setStart(moment("2021-01-01"))
                  setEnd(moment("2021-12-31"))
                }}
              >
                2021
              </Button>
              <Button
                className={classes.margin}
                variant="outlined"
                onClick={() => {
                  setStart(moment("2022-01-01"))
                  setEnd(moment("2022-12-31"))
                }}
              >
                2022
              </Button>
            </Box>
            <Box display="flex" pt={2} flexDirection="row-reverse">
              <Button onClick={handleConfirm} color="primary">
                Confirm
              </Button>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </Box>
          </React.Fragment>
        }
      >
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {title}
        </Button>
      </LightTooltip>
    </div>
  )
}

const Toolbar = ({
  setFilter,
  hiddenAbscissa,
  hiddenType,
  propType,
  propAbscissa,
}: any) => {
  const classes = useStyles()
  const [period, setPeriod] = React.useState(() => [
    moment().startOf("year"),
    moment().endOf("year"),
  ])
  const [type, setType] = React.useState(propType)
  const [abscissa, setAbscissa] = React.useState(propAbscissa)
  const showUnit = React.useMemo(() => {
    if (
      period[0].format("YYYY") === "2021" ||
      period[1].format("YYYY") === "2021"
    ) {
      return period[0].format("YYYY") !== period[1].format("YYYY")
    }
    return false
  }, [period])
  React.useEffect(() => {
    const start = period[0].format("YYYY-MM-DD")
    const end = period[1].format("YYYY-MM-DD")
    setFilter({ start, end, type, abscissa })
  }, [period, abscissa, type, setFilter])

  return (
    <Box
      px={5}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box display="flex" alignItems="center">
        <FormDialog
          period={period}
          setPeriod={setPeriod}
          setAbscissa={setAbscissa}
        />
        {hiddenType
          ? null
          : typeBtns.map((v) => (
              <Box ml={2} key={v.name}>
                <Button
                  className={
                    type === v.type ? classes.selected : classes.unselected
                  }
                  onClick={() => setType(v.type)}
                >
                  {v.name}
                </Button>
              </Box>
            ))}
      </Box>
      {hiddenAbscissa ? null : (
        <Box display="flex" alignItems="center">
          {abscissaBtns.map((v) => (
            <Box ml={2} key={v.name}>
              <Button
                className={
                  abscissa === v.type ? classes.selected : classes.unselected
                }
                disabled={v.type === 1 ? showUnit : false}
                onClick={() => setAbscissa(v.type)}
              >
                {v.name}
              </Button>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  )
}

export default Toolbar
