import * as React from "react" 
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"
import { DatePicker,  } from "@material-ui/pickers"
import moment from "moment"

const LeaveDateRange = (props: any) => {
  const { onChange, value, year, month, changeYear, changeMonth } = props
  const startDate = value[0] ?? null
  const endDate = value[1] ?? null

  const minDate = React.useMemo(() => {
    return moment([year, month]).toDate()
  }, [year, month])

  const maxDate = React.useMemo(() => {
    return moment([year, month]).endOf("month").toDate()
  }, [year, month])

  const endMinDate = React.useMemo(() => {
    if (startDate) {
      return startDate.toDate()
    }
    return moment([year, month]).toDate()
  }, [year, month, startDate])

  const handleChangeEnd = React.useCallback(
    (date: any) => {
      onChange([startDate, date])
    },
    [startDate, onChange]
  )
  const handleChangeStart = React.useCallback(
    (date: any) => {
      onChange([date, endDate])
    },
    [endDate, onChange]
  )
  //
  const handleYearChange = (value: any) => {
    changeYear(value.format("YYYY"))
    onChange([null, null])
  }
  //
  const handleMonthChange = (e: any) => {
    changeMonth(e.target.value)
    onChange([null, null])
  }
  return (
    <>
      <Grid item xs={6}>
        <DatePicker
          label="Year"
          fullWidth
          required
          views={["year"]}
          clearable={false}
          value={year ?? null}
          onChange={handleYearChange}
        />
      </Grid>
      <Grid item xs={6}>
        <FormControl required fullWidth>
          <InputLabel id="month-select-label">Month</InputLabel>
          <Select
            labelId="month-select-label"
            name="month"
            fullWidth
            value={month ?? ""}
            onChange={handleMonthChange}
          >
            <MenuItem value={0}>1</MenuItem>
            <MenuItem value={1}>2</MenuItem>
            <MenuItem value={2}>3</MenuItem>
            <MenuItem value={3}>4</MenuItem>
            <MenuItem value={4}>5</MenuItem>
            <MenuItem value={5}>6</MenuItem>
            <MenuItem value={6}>7</MenuItem>
            <MenuItem value={7}>8</MenuItem>
            <MenuItem value={8}>9</MenuItem>
            <MenuItem value={9}>10</MenuItem>
            <MenuItem value={10}>11</MenuItem>
            <MenuItem value={11}>12</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="Start"
          required
          clearable
          fullWidth
          minDate={minDate}
          maxDate={maxDate}
          value={startDate}
          placeholder="start date"
          onChange={(date) => {
            handleChangeStart(date)
          }}
          format="MM/DD/yyyy"
        />
      </Grid>
      <Grid item xs={6}>
        <DatePicker
          label="End"
          required
          fullWidth
          clearable
          minDate={endMinDate}
          maxDate={maxDate}
          value={endDate}
          placeholder="end date"
          onChange={(date) => {
            handleChangeEnd(date)
          }}
          format="MM/DD/yyyy"
        />
      </Grid>
    </>
  )
}

export default LeaveDateRange
