import * as React from "react"
import Box from "@material-ui/core/Box"
import { RouteComponentProps } from "@reach/router"
import { DropzoneArea } from "material-ui-dropzone"
import { useCallback } from "react"
import { useMutation } from "@apollo/client"
import { toast } from "material-react-toastify"
import { useState } from "react"
import { DataGrid } from "@material-ui/data-grid"
import { Button } from "@material-ui/core"
import UPGRADE_TIME_PERIOD from "./Gqls/UPGRADE_TIME_PERIOD"
import { TimePeriodColumnSchema } from "./Schemas"
import moment from "moment"
const readXlsxFile: any = require("read-excel-file").default

interface ImportProps extends RouteComponentProps {
  id?: String
}

const Import = (props: ImportProps) => {
  const [data, setData] = useState<Array<any>>([])
  const [name, setName] = useState("")
  const id = props.id
  const [updateFileData, { loading }] = useMutation(UPGRADE_TIME_PERIOD)
  const handleFileSelected = useCallback(async (loadedFiles) => {
    if (loadedFiles.length) {
      const rows = await readXlsxFile(loadedFiles[0])
      const res: any[] = []
      const MonthHours: any = {}
      rows.forEach((row: any) => {
        if (row["1"] === "Week (VECK)") {
          const period = row["6"].split("-")
          const month = period[0]
          if (MonthHours[month] === undefined) {
            MonthHours[month] = row["4"]
          } else {
            MonthHours[month] += row["4"]
          }
          res.push({
            period: row["0"],
            start_date: moment(row["2"]).format("YYYY-MM-DD"),
            final_date: moment(row["3"]).format("YYYY-MM-DD"),
            working_hours: row["4"],
            year: row["5"],
            month: month,
          })
        }
      })
      setData(
        res.map((v) => {
          return { ...v, total_month_working_hours: MonthHours[v.month] }
        })
      )
      setName(loadedFiles[0].name)
    }
  }, [])

  const handleSubmit = async () => {
    const res = await updateFileData({
      variables: {
        id,
        name,
        data,
      },
    }).catch((e) => {
      toast.error(e.message, {
        position: "top-center",
        autoClose: 3000,
      })
      return
    })
    if (res) {
      toast.success("success!", {
        position: "top-center",
        autoClose: 3000,
      })
    }
  }
  return (
    <Box mx={[2, 4, 8]} py={5} mt={5}>
      <Box my={4} component="h1" display="flex" justifyContent="space-between">
        <Box>Period Import</Box>
      </Box>
      <Box mb={5}>
        <DropzoneArea
          showPreviewsInDropzone={false}
          showFileNames={true}
          onChange={handleFileSelected}
          filesLimit={1}
          acceptedFiles={[".xls", ".xlsx"]}
        />
      </Box>

      {data.length ? (
        <Box>
          <Box pb={2}>
            <Button
              variant="contained"
              disabled={loading}
              color="primary"
              onClick={handleSubmit}
            >
              {id ? "Submit & Replace" : "Submit"}
            </Button>
          </Box>
          <DataGrid
            autoHeight={true}
            density={"compact"}
            columns={TimePeriodColumnSchema}
            rows={data}
            getRowId={(row) => row.period}
          />
        </Box>
      ) : null}
    </Box>
  )
}

export default Import
