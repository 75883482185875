import { gql } from "@apollo/client"

const USER_CANCEL_LEAVING = gql`
  mutation UserCancelLaving (
    $id: ID!
    $term: Date!
  ) {
    userCancelLaving(id: $id, term: $term , status: "Active") {
      id
      name
      leave_status_name
      term
      status
    }
  }
`
export default USER_CANCEL_LEAVING