import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import DataGrid from "../../Components/DataGrid"
import CREATE_USER from "./Gqls/CREATE_USERLEAVE"
import UPDATE_USER from "./Gqls/UPDATE_USERLEAVE"
import SEARCH_USER from "./Gqls/SEARCH_USERLEAVE"
import columnSchema from "./Schemas/columnSchema"
import getUpdateSchema from "./Schemas/updateSchema"
import getCreateSchema from "./Schemas/createSchema"
import getFilterSchema from "./Schemas/filterSchema"
import DELETE_USER from "./Gqls/DELETE_USERLEAVE"
import { useMemo } from "react"
import useSession from "../../Hooks/useSession"
import { Box } from "@material-ui/core"
import DateRangeField from "./Field/DateRangeField"
import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"

ComponentMapper["DateRangeField"] = DateRangeField
const filterDateValue = (value: any) => {
  return typeof value == "string" ? value : value.format("YYYY-MM-DD")
}
const beforeSearchData = (data: any) => {
  const res = {
    ...data,
  }
  if (data.user) {
    res["user_id"] = data.user.id
    delete res["user"]
  }
  return res
}

const beforeFormSubmit = (data: any) => {
  let res: any = {}

  if (data.duration) {
    res["duration"] = Number(data.duration)
  }
  if (data.user) {
    res["user_id"] = data.user.id
  }
  if (data.range) {
    res["start"] = filterDateValue(data.range[0])
    res["end"] = filterDateValue(data.range[1])
  }
  if (data.type) {
    res["type"] = data.type
  }
  if (data.note) {
    res["note"] = data.note
  }
  if (data.myTeam) {
    res['team_id']=data.myTeam.id;
  }
  if (data.myUnit) {
    res['unit_id']=data.myUnit.id;
  }

  if (data.id) {
    return { id: data.id, input: res }
  }

  return { input: res }
}

const User = (_: RouteComponentProps) => {
  const { can } = useSession()
  const createSchema = useMemo(() => getCreateSchema(), [])

  const updateSchema = useMemo(() => getUpdateSchema(), [])

  const filterSchema = useMemo(() => getFilterSchema(), [])

  return (
    <Box>
      <DataGrid
        title="User Leave"
        name="userLeave"
        searchGql={SEARCH_USER}
        createGql={can("user-leave.create", CREATE_USER)}
        updateGql={can("user-leave.update", UPDATE_USER)}
        deleteGql={can("user-leave.delete", DELETE_USER)}
        filterSchema={filterSchema}
        createSchema={can("user-leave.create", createSchema)}
        updateSchema={can("user-leave.update", updateSchema)}
        columnSchema={columnSchema}
        beforeCreateFormSubmit={beforeFormSubmit}
        beforeUpdateFormSubmit={beforeFormSubmit}
        beforeSearchData={beforeSearchData}
      />
    </Box>
  )
}

export default User
