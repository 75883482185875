import * as React from "react"
import { useState, useEffect, useMemo, useCallback } from "react"
import { Box, Button, CircularProgress } from "@material-ui/core"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import { useConfirm } from "material-ui-confirm"
import { RouteComponentProps } from "@reach/router"
import { useApolloClient } from "@apollo/client"
import useSession from "../../../Hooks/useSession"
import { OkrObjectDataType } from "../Types"
import DeleteOkr from "../Gqls/DELETE_OKR_OBJECT"
import FetchOkr from "../Gqls/FETCH_OKR_OBJECT"
import Filter from "../../../Components/Filter"
import Object from "./object"
import { OkrIcon } from "../../../Utils/Icon"
import { useQueryParams } from "use-query-params"
import { List } from "immutable"

import { StringParam, withDefault } from "serialize-query-params"
import { globalYear, globalQuarter } from "../../../Utils"

const MyOkr = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const { session } = useSession()
  const [customObject, setCustomObject] = useState<List<OkrObjectDataType>>(
    List([])
  )
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const confirm = useConfirm()
  const graphqlClient = useApolloClient()
  const variables = useMemo(() => {
    return {
      where: {
        AND: [
          {
            column: "YEAR",
            operator: "EQ",
            value: query.year,
          },
          {
            column: "QUARTER",
            operator: "EQ",
            value: query.quarter,
          },
          { column: "USER_ID", operator: "EQ", value: session?.id },
        ],
      },
    }
  }, [query, session])

  const allProgress = useMemo(() => {
    let sum = 0
    let num = 0
    customObject.forEach((o: OkrObjectDataType) => {
      sum += Number(o.progress)
      num++
    })
    return num > 0 ? Math.round(sum / num) : 0
  }, [customObject])

  const getObject = useCallback(
    (variables) => {
      return graphqlClient.query({
        query: FetchOkr,
        fetchPolicy: "network-only",
        variables,
      })
    },
    [graphqlClient]
  )

  useEffect(() => {
    setLoading(true)
    getObject(variables).then((v) => {
      setCustomObject(List(v.data.okrObjects))
      setLoading(false)
    })
  }, [variables, getObject])

  const deleteObject = useCallback(
    async (index: number) => {
      await confirm({
        title: "warning!",
        description: "Delete Object?",
      })
      const object = customObject.get(index)
      if (object && object.id) {
        graphqlClient.mutate({
          mutation: DeleteOkr,
          variables: { id: object.id },
        })
      }
      setCustomObject(customObject.delete(index))
    },
    [customObject, confirm, graphqlClient]
  )

  const createObject = useCallback(() => {
    const obj = customObject.push({
      content: "",
      key: Math.random(),
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
      progress: 0,
      keyResults: [],
    })
    setCustomObject(obj)
  }, [query, customObject])

  const updateObject = useCallback(
    (index, object, sync = false) => {
      const obj = customObject.set(index, object)
      setCustomObject(obj)
    },
    [customObject]
  )

  return (
    <Box m="2%">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <OkrIcon />
          <Box fontWeight={500} ml={1} fontSize={14}>
            All My OKRs Progress
          </Box>
          <Box fontWeight={500} ml={1} fontSize={16}>
            {allProgress}%
          </Box>
        </Box>
        <Box>
          <Filter data={query} handleSubmit={(value: any) => setQuery(value)} />
        </Box>
      </Box>
      {loading ? (
        <CircularProgress />
      ) : (
        <Box>
          {customObject.size > 0 &&
            customObject.map((v, k) => (
              <Object
                data={v}
                index={k}
                key={v.id ? v.id : v.key}
                updateObject={updateObject}
                deleteObject={deleteObject}
              />
            ))}
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={createObject}
              startIcon={<AddCircleOutlineIcon />}
            >
              Create Object
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default MyOkr
