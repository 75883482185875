import { gql } from "@apollo/client"

const CANCEL_BECOME_FORMAL = gql`
mutation CancelBecomeFormal($id: ID!) {
  userBecomeFormal(id: $id, formal_date: null) {
      id
      name
      leave_status_name
      type
      term
      formal_date
      status
    }
  }
`
export default CANCEL_BECOME_FORMAL