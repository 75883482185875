import { gql } from "@apollo/client"

const UPDATE_ROLE = gql`
mutation UpdateRole($id: ID!, $name: String, $menu: [String], $is_default:Boolean) {
  updateRole(id: $id, name: $name, menu: $menu, is_default:$is_default) {
    id
    name
    menu
    is_default
  }
}
`
export default UPDATE_ROLE