import * as React from "react"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import UserSelector from "../../../Components/UserSelector"
import Button from "@material-ui/core/Button"
import { useTheme } from "@material-ui/core/styles"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import CloudUploadOutlinedIcon from "@material-ui/icons/CloudUploadOutlined"
import Chip from "@material-ui/core/Chip"
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api"
import Papa from "papaparse"
import moment from "moment"
import useAllUsers from "../../../Hooks/useAllUsers"

const useStyles = makeStyles({
  header: {
    backgroundColor: "#87888A",
    lineHeight: "2rem",
    padding: "1rem",
    fontSize: "1.2rem",
    fontWeight: "bolder",
    color: "white",
  },
  chip: {
    marginRight: "1rem",
    marginBottom: "1rem",
  },
  error: {
    padding: "1rem",
    color: "red",
  },
  uploadInput: {
    display: "none",
  },
})

const TrainersMember = (props: any) => {
  const classes = useStyles()
  const theme = useTheme()
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta

  const formApi = useFormApi()
  const values = formApi.getState().values
  const [trainers, setTrainers] = React.useState<any[]>([])
  const [attendees, setAttendees] = React.useState<any[]>([])
  const [attendeesFromFile, setAttendeesFromFile] = React.useState<any[]>([])

  const [importErrors, setImportErrors] = React.useState<any[]>([])

  const { users } = useAllUsers()

  React.useEffect(() => {
    // 首次渲渲染（更新值时使用）通过trainers,attendees 字段配置默认值
    setTrainers(values.trainers ?? [])
    setAttendees(values.attendees ?? [])
    input.onChange({
      trainers: values.trainers ?? [],
      attendees: values.attendees ?? [],
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [trainer, setTrainer] = React.useState<any>()
  const [trainerInput, setTrainerInput] = React.useState<string>("")
  const [attendee, setAttendee] = React.useState<any>()
  const [attendeeInput, setAttendeeInput] = React.useState<string>("")

  const handleChangeTrainer = React.useCallback(
    (_, value) => {
      setTrainer(value)
    },
    [setTrainer]
  )
  const handleAddTrainers = React.useCallback(() => {
    const existedFilter = (item: any) => item.id === trainer.id
    if (trainer && (!trainers || !trainers.filter(existedFilter).length)) {
      const value = trainers ? [...trainers, trainer] : [trainer]
      setTrainers(value)
      input.onChange({ trainers: value, attendees })
    }
    setTrainerInput("")
  }, [trainer, trainers, input, attendees])
  const handleDeleteTrainer = (userId: any) => {
    const validFilter = (item: any) => item.id !== userId
    if (trainers) {
      const value = trainers.filter(validFilter)
      setTrainers(value)
      input.onChange({ trainers: value, attendees })
    }
  }

  const handleChangeAttendee = React.useCallback(
    (_, value) => {
      setAttendee(value)
    },
    [setAttendee]
  )
  const handleAddAttendees = React.useCallback(() => {
    const existedFilter = (item: any) => item.id === attendee.id
    if (attendee && (!attendees || !attendees.filter(existedFilter).length)) {
      const value = attendees ? [...attendees, attendee] : [attendee]
      setAttendees(value)
      input.onChange({ trainers, attendees: value })
    }
    setAttendeeInput("") //清空输入框
  }, [attendee, attendees, trainers, input])
  const handleDeleteAttendee = (userId: any) => {
    const validFilter = (item: any) => item.id !== userId
    if (attendees) {
      const value = attendees.filter(validFilter)
      setAttendees(value)
      input.onChange({ trainers, attendees: value })
    }
  }

  const handleImportTeamsLog = React.useCallback((event) => {
    setImportErrors([])
    Papa.parse(event.target.files[0], {
      skipEmptyLines: true,
      complete: (results: any, file) => {
        // console.log("Parsing complete:", results, file);
        const { data, errors } = results

        if (errors.length > 0) {
          setImportErrors(errors.map((err: any) => err.message))
          return false
        }

        const meetingStartTimeRow: any[] = data.find((row: any[]) =>
          ["Start time", "Meeting Start Time"].includes(row[0])
        )
        const meetingStartTime: any = moment(meetingStartTimeRow[1])

        const meetingEndTimeRow: any[] = data.find((row: any[]) =>
          ["Meeting End Time", "End time"].includes(row[0])
        )
        let meetingEndTime: any = moment(meetingEndTimeRow[1])

        const meetingDuration: number = meetingEndTime.diff(
          meetingStartTime,
          "minutes"
        )
        // console.log('meetingDuration', meetingDuration);

        const attendeesFilter: any[] = []
        data.forEach((row: any[], index: number) => {
          if (index > 7) {
            const joinTime: any = moment(row[1])
            const leaveTime: any = moment(row[2])
            const joinDuration: number = leaveTime.diff(joinTime, "minutes")
            if (joinDuration > meetingDuration * 0.1) {
              attendeesFilter.push({
                name: row[0],
                email: row[4],
              })
            }
          }
        })
        console.log("wocao", data)
        setAttendeesFromFile(attendeesFilter)
      },
    })
  }, [])

  React.useEffect(() => {
    if (!attendeesFromFile.length || !users.length) {
      return undefined
    }

    let usersNotFound: any[] = []
    let newAttendees: any[] = attendees
    attendeesFromFile.forEach((attendee) => {
      const user: any = users.find(
        (item: any) =>
          item.email.toLowerCase() === attendee.email.toLowerCase() ||
          item.name.toLowerCase() === attendee.name.toLowerCase()
      )
      if (user) {
        const index: number = attendees.findIndex(
          (item: any) => item.id === user.id
        )
        if (index < 0) {
          newAttendees.push(user)
        }
      } else {
        usersNotFound.push(`${attendee.name}(${attendee.email})`)
      }
    })
    if (usersNotFound.length > 0) {
      setImportErrors([
        "Some users can not be found: " + usersNotFound.join(","),
      ])
    }
    setAttendees([...newAttendees])
    input.onChange({ trainers, attendees: newAttendees })
    setAttendeesFromFile([])
  }, [attendeesFromFile, users, attendees, input, trainers])

  // 排除select中的userid
  const exclude = React.useMemo(() => {
    const arr: any[] = []
    trainers.forEach((v) => {
      arr.push(v.id)
    })
    attendees.forEach((v) => {
      arr.push(v.id)
    })
    return arr
  }, [trainers, attendees])

  return (
    <Box flexGrow={1}>
      <Box border={1} flexGrow={1} mt={2}>
        <Box className={classes.header}>Add Trainers</Box>
        <Box display="flex" p={2}>
          <Box mr={2}>
            <UserSelector
              onChange={handleChangeTrainer}
              exclude={exclude}
              label="User Name"
              onInputChange={(_: any, v: any) => {
                setTrainerInput(v)
              }}
              inputValue={trainerInput}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleAddTrainers}
            startIcon={<AddCircleOutlineIcon />}
          >
            Add
          </Button>
        </Box>
        <Box p={2}>
          {trainers &&
            trainers.map((item: any, index: number) => (
              <Chip
                className={classes.chip}
                key={index}
                variant="outlined"
                size="small"
                label={item.name}
                onDelete={() => handleDeleteTrainer(item.id)}
                color="primary"
              />
            ))}
        </Box>
        {submitFailed && error === "Trainers is required" && (
          <Box className={classes.error}>{error}</Box>
        )}
      </Box>

      <Box border={1} flexGrow={1} mt={2}>
        <Box className={classes.header}>Add Attendees</Box>
        <Box display="flex" p={2}>
          <Box mr={2}>
            <UserSelector
              exclude={exclude}
              onChange={handleChangeAttendee}
              inputValue={attendeeInput}
              label="User Name"
              onInputChange={(_: any, v: any) => {
                setAttendeeInput(v)
              }}
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleAddAttendees}
            startIcon={<AddCircleOutlineIcon />}
            style={{ marginRight: `${theme.spacing(2)}px` }}
          >
            Add
          </Button>
          <Box>
            <input
              accept=".csv"
              className={classes.uploadInput}
              id="contained-button-file"
              type="file"
              onChange={handleImportTeamsLog}
            />
            <label htmlFor="contained-button-file">
              <Button
                variant="contained"
                color="primary"
                size="small"
                component="span"
                startIcon={<CloudUploadOutlinedIcon />}
                style={{ height: "100%" }}
              >
                Import Teams Log
              </Button>
            </label>
          </Box>
        </Box>
        <Box p={2}>
          {attendees &&
            attendees.map((item: any, index: number) => (
              <Chip
                className={classes.chip}
                key={index}
                variant="outlined"
                size="small"
                label={item.name}
                onDelete={() => handleDeleteAttendee(item.id)}
                color="primary"
              />
            ))}
        </Box>
        {submitFailed && error === "Attendees is required" && (
          <Box className={classes.error}>{error}</Box>
        )}
        {importErrors.length > 0 && (
          <Box className={classes.error}>{importErrors.join(",")}</Box>
        )}
      </Box>
    </Box>
  )
}

export default TrainersMember
