import * as React from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useAllRoles from "../Hooks/useAllRoles"


const RoleSelector = (props: any) => {
  const { roles, loading } = useAllRoles()
  const list = React.useMemo(() => {
    if (props.list) {
      return props.list
    }
    return roles.map((v: any) => ({ id: v.id, name: v.name }))
  }, [roles, props])

  return (
    <Autocomplete
      options={list}
      getOptionLabel={(option: any) => (option ? option.name : "")}
      style={{ width: props.width || 200 }}
      loading={loading}
      renderInput={(params) => (
        <TextField {...params} label={props.label} required={props.required} />
      )}
      {...props}
    />
  )
}

export default RoleSelector
