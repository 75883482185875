import { gql } from "@apollo/client"

const OVERRIDE_REPORT = gql`
  mutation override_report($key: String!) {
    overrideProjectReport(key: $key) {
      id
      project_slug
      start_period
      end_period
    }
  }
`
export default OVERRIDE_REPORT