import { gql } from "@apollo/client"

const USER_TO_LEAVING = gql`
  mutation UserToLaving (
    $id: ID!
    $term: Date!
  ) {
    userToLaving(id: $id, term: $term ,status: "Leaving") {
      id
      name
      leave_status_name
      term
      status
    }
  }
`
export default USER_TO_LEAVING