import { gql } from "@apollo/client"

const FETCH_TEAM = gql`
  query FetchTeam {
    teamsList {
      id
      name
      unit_id
      myUnit{
        id
        name
      }
      unit
      type
      type_name
      userMembers{
        id
        name
        abbr
      }
      managers {
        id
        name
        abbr
      }
    }
  }
`

export default FETCH_TEAM