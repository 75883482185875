import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box } from "@material-ui/core"
import { DataGrid } from "@material-ui/data-grid"
import useAllUsers from "../../Hooks/useAllUsers"
import columnSchema from "./Schemas/AssignmentColumnSchema"
import filterSchema from "./Schemas/AssignmentFilterSchema"
import DataFilter from "../../Components/DataGrid/DataFilter"
import { JsonParam, useQueryParam } from "use-query-params"

const Assignment = (_: RouteComponentProps) => {
  const { users, loading } = useAllUsers()
  const [filter, setFilter] = useQueryParam("search", JsonParam)

  const list = React.useMemo(() => {
    if (!filter) {
      return users.filter((user) => user.status !== "Left")
    }
    const result = users.filter((user) => {
      const ok = true
      if (
        filter.name &&
        user.name.toLowerCase().indexOf(filter.name.toLowerCase()) === -1
      ) {
        return false
      }
      if (
        filter.slug &&
        user.slug.toLowerCase().indexOf(filter.slug.toLowerCase()) === -1
      ) {
        return false
      }
      if (
        filter.role &&
        !user.roles.map((v) => v.id).includes(filter.role.id)
      ) {
        return false
      }
      if (filter.job_title && filter.job_title !== user.job_title) {
        return false
      }
      if (filter.job_level && filter.job_level !== user.job_level) {
        return false
      }
      if (filter.competency) {
        if (!user.competency) {
          return false
        }
        if (!user.competency.includes(filter.competency)) {
          return false
        }
      }
      if (filter.type && filter.type !== user.type) {
        return false
      }
      if (filter.team && filter.team.id !== user.team_id + "") {
        return false
      }
      if (user.status === "Left") {
        return false
      }
      if (filter.permission && !user.menu.includes(filter.permission.id)) {
        return false
      }
      return ok
    })
    return result
  }, [users, filter])

  const filterChangeUpdate = (e) => {
    setFilter(e)
  }
  return (
    <Box m={"2%"}>
      <Box mb={2}>
        <DataFilter
          filterSchema={filterSchema()}
          onFilterChanged={filterChangeUpdate}
        />
      </Box>
      <DataGrid
        autoHeight
        loading={loading}
        density={"compact"}
        rows={loading ? [] : list}
        columns={columnSchema}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
      />
    </Box>
  )
}

export default Assignment
