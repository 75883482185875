import * as React from "react"

import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import { StringParam, useQueryParams } from "use-query-params"
import { DefaultNumberZero } from "../../../Utils"
const names = [
  "Whole Year",
  "First Half",
  "Second Half",
  "Q1",
  "Q2",
  "Q3",
  "Q4",
]
const Search = (props: any) => {
  const [query, setQuery] = useQueryParams({
    year: StringParam,
    type: DefaultNumberZero,
  })

  const handleDateChange = (v: any) => {
    setQuery({ year: v.format("YYYY") + "", type: 1 })
  }

  const QuickSelect = React.useMemo(() => {
    if (!query.year) {
      return null
    }
    return (
      <Box display="flex">
        {names.map((title, index) => {
          const value = index + 1
          const active = value === query.type
          return (
            <Box ml={2} key={value}>
              <Button
                variant="contained"
                disableElevation
                onClick={() => {
                  setQuery({ type: value })
                }}
                color={active ? "primary" : undefined}
              >
                {title}
              </Button>
            </Box>
          )
        })}
      </Box>
    )
  }, [query, setQuery])

  return (
    <Box flexGrow={1} boxShadow={1}>
      <Box display="flex" p={2}>
        <Box>
          <Button
            style={{ width: 50 }}
            variant="contained"
            disableElevation
            onClick={() => {
              if (query.type) {
                setQuery({ type: 0, year: null })
              }
            }}
            color={query.type === 0 ? "primary" : undefined}
          >
            All
          </Button>
        </Box>
        <Box display="flex" pl={2}>
          <Box>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                views={["year"]}
                placeholder="choose year"
                clearable={false}
                value={query["year"] ? query["year"] : null}
                onChange={handleDateChange}
              />
            </MuiPickersUtilsProvider>
          </Box>
          {QuickSelect}
        </Box>
      </Box>
    </Box>
  )
}

export default Search
