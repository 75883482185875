import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box } from "@material-ui/core"
import Search from "../../Components/Search"
import { useQueryParams } from "use-query-params"
import {
  DefaultNumberZero,
  DefaultYear,
  Type2DateRange,
} from "../../../../Utils"
import FETCH_TBA_FUND_LOG from "../../Gqls/FETCH_TBA_FUND_LOG"
import { useQuery } from "@apollo/client"
import useSession from "../../../../Hooks/useSession"
import { DataGrid } from "@material-ui/data-grid"
import moment from "moment"

const columns = [
  { headerName: "Date", field: "at", width: 108 },
  { headerName: "Training Title", field: "training_name", width: 280 },
  { headerName: "Trainer", field: "trainer", width: 140 },
  {
    headerName: "Price/Persom",
    field: "expense",
    width: 140,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value}
        </Box>
      )
    },
  },
  { headerName: "No. of Attendees", field: "attendees", width: 150 },
  { headerName: "Satisfaction Rate", field: "satisfaction", width: 150 },
  {
    headerName: "Earned Cash",
    field: "amount",
    width: 150,
    renderCell: (params: any) => {
      return (
        <Box textAlign="right" width="100%">
          {params.value}
        </Box>
      )
    },
  },
]

const MyDetail = (props: RouteComponentProps) => {
  const { session } = useSession()
  const [query] = useQueryParams({
    year: DefaultYear,
    type: DefaultNumberZero,
  })

  const variables = React.useMemo(() => {
    const where: any = {
      AND: [
        {
          column: "USER_ID",
          operator: "EQ",
          value: session?.id,
        },
      ],
    }
    const orderBy: any = [
      {
        column: "AT",
        order: "DESC",
      },
    ]

    return { where, orderBy }
  }, [session])

  const { data, loading } = useQuery(FETCH_TBA_FUND_LOG, {
    fetchPolicy: "cache-and-network",
    variables,
  })
  // 手动过滤金额
  const rows = React.useMemo(() => {
    const arr = data ? data.TbaFundLogs : []
    const list = arr.map((v: any) => {
      const attendees = v.training.attendees.length
      const trainers = v.training.trainers.map((v: any) => v.abbr)
      return {
        id: v.id,
        at: v.at,
        expense: v.training.expense.toFixed(2),
        training_name: v.training.training_name,
        trainer: trainers.join(" , "),
        attendees,
        satisfaction: v.training.satisfaction.toFixed(2) + "%",
        description: v.description,
        amount: v.amount.toFixed(2),
      }
    })
    const { year, type } = query
    if (type === 0) {
      return list
    }
    const range = Type2DateRange(year, type)
    return list.filter((v: any) => {
      return moment(v.at).isBetween(range[0], range[1], null, "[]")
    })
  }, [data, query])

  const fund = React.useMemo(() => {
    let fund = 0
    rows.forEach((v: any) => {
      fund += Number(v.amount)
    })
    return fund.toFixed(2)
  }, [rows])

  return (
    <Box m="2%">
      <Box mb={2}>
        <Search />
      </Box>
      <Box >
        <DataGrid
          density={"compact"}
          disableColumnMenu={true}
          hideFooterPagination
          hideFooterRowCount
          hideFooterSelectedRowCount
          loading={loading}
          autoHeight
          rows={rows}
          columns={columns}
          components={{
            Toolbar: () => (
              <Box p={4} fontSize="14px">
                Total My Earnned Training Fund: {fund}
              </Box>
            ),
          }}
        />
      </Box>
    </Box>
  )
}

export default MyDetail
