import { gql } from "@apollo/client"

const FETCH_ATTRITION_ANALYSIS = gql`
query DashboardAttritionAnalysis($start: Date!,$end: Date!, $type: Int!) {
  DashboardAttritionAnalysis(start: $start,end: $end, type: $type) {
    x
    age
    length
    number
    attritions
  }
}
`
export default FETCH_ATTRITION_ANALYSIS