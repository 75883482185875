import React from "react"
import Lodash from "lodash"
import { Box, Button } from "@material-ui/core"
import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import FilterInput from "../Pages/Okr/input"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      width: 50,
      "& input": {
        fontSize: "14px",
        height: "22px",
        padding: 0,
      },
    },
    select: {
      fontSize: "14px",
      lineHeight: "22px",
      height: "22px",
      padding: 0,
    },
  })
)

const Filter = ({ data, handleSubmit, hasFilter, CustomLeftText }: any) => {
  const classes = useStyles()
  const values = React.useMemo(() => Lodash.omitBy(data, Lodash.isNull), [data])
  const handleChange = (value: any) => {
    localStorage.setItem("GLOBAL_QUARTER", value)
    handleSubmit({ ...values, quarter: value })
  }
  const handleDateChange = (value: any) => {
    localStorage.setItem("GLOBAL_YEAR", value.format("yyyy"))
    handleSubmit({ ...values, year: value.format("yyyy") })
  }
  return (
    <Box display="flex" justifyContent="space-between" width="100%">
      <Box>
        {hasFilter && (
          <FilterInput value={values} handleSubmit={handleSubmit} />
        )}
        {CustomLeftText ? CustomLeftText : null}
      </Box>
      <Box display="flex" alignItems="center">
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <DatePicker
            className={classes.input}
            views={["year"]}
            clearable={false}
            value={values["year"] ? values["year"] : null}
            onChange={handleDateChange}
          />
        </MuiPickersUtilsProvider>
        {["1", "2", "3", "4"].map((v) => (
          <Box ml={2} key={v}>
            <Button
              variant="contained"
              disableElevation
              size="small"
              color={values["quarter"] === v ? "primary" : undefined}
              onClick={() => {
                handleChange(v)
              }}
            >
              Q{v}
            </Button>
          </Box>
        ))}

        {/* <Select
          classes={{ select: classes.select }}
          name="quarter"
          value={values["quarter"] ? values["quarter"] : ""}
          onChange={handleChange}
        >
          <MenuItem value={1}>Q1</MenuItem>
          <MenuItem value={2}>Q2</MenuItem>
          <MenuItem value={3}>Q3</MenuItem>
          <MenuItem value={4}>Q4</MenuItem>
        </Select> */}
      </Box>
    </Box>
  )
}

export default Filter
