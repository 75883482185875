import "./index.d.ts"
import React from "react"
import ReactDOM from "react-dom"
import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client"
import { createUploadLink } from "apollo-upload-client"
import { setContext } from "@apollo/client/link/context"
import { onError } from "@apollo/client/link/error"
import { toast } from "material-react-toastify"

import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { getParams } from "./Utils"
import { Alive } from "./Utils/Alive"

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    const { message, locations, path } = graphQLErrors[0]
    if (
      message === "Unauthenticated." ||
      message === "Session expired, please log in again."
    ) {
      localStorage.clear()
      sessionStorage.clear()
      window.location.reload()
      return
    }
    console.log(
      `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
    )
    if (message) {
      if (path && path[0] === "login") {
        return
      }
      // 过滤验证器报错
      if (message.indexOf("Validation failed") > -1) {
        return
      }
      toast.error(message, {
        position: "top-center",
        autoClose: 3000,
      })
    }
  }
  // graphQLErrors.forEach(({ message, locations, path }) => {
  //   if (message) {
  //     toast.error(message, {
  //       position: "top-center",
  //       autoClose: 3000,
  //     })
  //   }
  //   console.log(
  //     `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
  //   )

  //   if (message === "Unauthenticated.") {
  //     localStorage.clear()
  //     sessionStorage.clear()
  //     navigate("/login");
  //   }
  // })
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem("token") || sessionStorage.getItem("token")
  // return the headers to the context so httpLink can read them
  if (!token) {
    // 直接使用路由中的token
    const params = getParams(window.location.search)
    if (params.token) {
      token = params.token
    }
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  }
})
const uploadLink = createUploadLink({ uri: process.env.REACT_APP_API_HOST })

const client = new ApolloClient({
  link: authLink.concat(from([errorLink, uploadLink])),
  cache: new InMemoryCache(),
})

const keepalive = new Alive(client)
document.addEventListener("mousemove", () => {
  keepalive.heartbeat()
})
document.addEventListener("keydown", () => {
  keepalive.heartbeat()
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
