import { gql } from "@apollo/client"
// CreateProjectPurchase 重命名
const CREATE_PROJECT_PURCHASE = gql`
  mutation CreatePo (
    $number: String!
    $project_id: ID!
    $start: Date!
    $end: Date!
    $total_amount: Float!
    $members: CreateUsersBelongsToMany
  ) {
    createProjectPurchase(
      number: $number
      project_id: $project_id
      start: $start
      end: $end
      total_amount: $total_amount
      members: $members
    )  {
      id
      number
      project {
        id
        slug
        name
      }
      members {
        id
        slug
        name
      }
      start
      end
      total_amount
      remainingAmount
      remainingRate
      remainingDays
      status
      created_at
      updated_at
    }
  }
`
export default CREATE_PROJECT_PURCHASE