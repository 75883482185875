import { gql } from "@apollo/client"

const SEARCH_TIME_PERIODE = gql`
query SearchTimeProids($id: ID, $first: Int!, $page: Int, $orderBy: [QueryTimePeriodsOrderByOrderByClause!]) {
  timePeriods(file_id: $id, first: $first, page: $page, orderBy:$orderBy) {
    data {
      id
      period
      start_date
      final_date
      working_hours
      year
      month
      total_month_working_hours
      created_at
    }
    paginatorInfo {
      total
      currentPage
    }
  }
}
`

export default SEARCH_TIME_PERIODE