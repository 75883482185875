import { gql } from "@apollo/client"

const CALCULATE_KPI_TEAM_EDITOR = gql`
mutation CalculateKpiTeamEditor(
  $year: Int!
  $quarter: Int!
) {
  calculateKpiTeamEditor(year: $year, quarter: $quarter) {
    id
    year
    quarter
    team {
      id
      name
    }
    content {
      value
      column
    }
    status
  }
}

`
export default CALCULATE_KPI_TEAM_EDITOR