import * as React from "react"
import { Box, CircularProgress } from "@material-ui/core"
import ReactEcharts from "../../../Components/Echart"
import { createLineChart } from "./Schemas"
import { useQuery } from "@apollo/client"
import DASHBOARD_OKR from "../Gqls/DASHBOARD_OKR"
import { globalQuarter, globalYear } from "../../../Utils"
import { StringParam, useQueryParams, withDefault } from "use-query-params"

const AllOkrChart = () => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const DefaultQuarter = withDefault(StringParam, globalQuarter())
  const [query] = useQueryParams({
    year: DefaultYear,
    quarter: DefaultQuarter,
  })
  const { data, loading } = useQuery(DASHBOARD_OKR, {
    fetchPolicy: "cache-and-network",
    variables: {
      year: parseInt(query.year),
      quarter: parseInt(query.quarter),
    },
  })
  const option: any = React.useMemo(() => {
    if (data && data.DashboardOkr) {
      return createLineChart(data.DashboardOkr, true)
    }
    return []
  }, [data])
  if (loading) {
    return (
      <Box>
        <CircularProgress />
      </Box>
    )
  }
  if (option.length === 0) {
    return <Box></Box>
  }
  return (
    <Box boxShadow={5} p={4} mt={4} style={{ background: "#FEFEFE" }}>
      <ReactEcharts
        option={option}
        style={{
          height: 400,
        }}
      />
    </Box>
  )
}

export default AllOkrChart
