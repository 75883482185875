import * as React from "react"

import MomentUtils from "@date-io/moment"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import Box from "@material-ui/core/Box"
import { StringParam, useQueryParams, ObjectParam, withDefault } from "use-query-params"
import UnitSelector from "../../../Components/UnitSelector"
import TeamSelector from "../../../Components/TeamSelector"
import { Grid } from "@material-ui/core"

const SearchKpiBonusData = () => {
  const DefaultYear = withDefault(StringParam, "2022")
  const [query, setQuery] = useQueryParams({
    year: DefaultYear,
    unit: StringParam,
    unit_t: ObjectParam,
    team: StringParam,
    team_t: ObjectParam,
  })

  const handleDateChange = (v: any) => {
    if (v) {
      setQuery({ ...query, year: v.format("YYYY") + "" })
    } else {
      setQuery({ ...query, year: "" })
    }
  }
  const handleTeamChange = (e: any, value: any) => {
    if (value) {
      setQuery({ ...query, team: value.id, team_t: value })
    } else {
      setQuery({ ...query, team: "", team_t: value })
    }
  }
  const handleUnitChange = (e: any, value: any) => {
    if (value) {
      setQuery({ ...query, unit: value.id, unit_t: value })
    } else {
      setQuery({ ...query, unit: "", unit_t: value })
    }
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Box display="flex" p={2} boxShadow={1}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={3}>
            <DatePicker
              label="Year"
              fullWidth
              views={["year"]}
              placeholder="choose year"
              clearable={true}
              value={query["year"] ? query["year"] : null}
              onChange={handleDateChange}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <UnitSelector
              fullWidth
              label="Unit"
              onChange={handleUnitChange}
              value={query.unit_t}
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <TeamSelector
              label="Team"
              onChange={handleTeamChange}
              value={query.team_t}
            />
          </Grid>
        </Grid>
      </Box>
    </MuiPickersUtilsProvider>
  )
}

export default SearchKpiBonusData
