import * as React from "react"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import { useCallback } from "react"
import { Box, Grid } from "@material-ui/core"
import RoleSelector from "./RoleSelector"

const RoleSelectorField = (props: any) => {
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta
  const handleChange = useCallback(
    (_, value) => {
      if (value) {
        input.onChange(value)
      } else {
        input.onChange(null)
      }
    },
    [input]
  )
  const empvalue = props.multiple ? [] : ""
  return (
    <Grid item {...props.FormFieldGridProps}>
      <RoleSelector
        list={props.list}
        onChange={handleChange}
        required={props.isRequired}
        width={props.width}
        label={props.label}
        value={input.value ? input.value : empvalue}
        multiple={props.multiple}
        name={props.name}
      />
      {submitFailed && error && <Box color="primary.main">{error}</Box>}
    </Grid>
  )
}

export default RoleSelectorField
