import { gql } from "@apollo/client"

const DELETE_TBA_TRAINING = gql`
mutation DeleteTbaTraining($id:ID!) {
  deleteTbaTraining(id:$id) {
    id
    training_name
  }
}
`
export default DELETE_TBA_TRAINING