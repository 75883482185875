
export const createRankingChart = (title: string, labelData: [string], data: [number]) => {
  return {
    title: {
      text: title,
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      formatter: function (params: any) {
        const user = params[0]['data']['user'];
        // console.log(user)
        return `<table>\n
          <tr><td>Team: </td><td>${user.team}</td></tr>\n
          <tr><td>Name: </td><td>${user.name}</td></tr>\n
          <tr><td>Role: </td><td>${user.roles.map(v=>v.name).join(", ")}</td></tr>\n
          <tr><td>Expense: </td><td>${params[0]['value']}</td></tr>\n
        </table>`;
      }
    },
    grid: {
      top: 50,
      left: 10,
      right: 10,
      bottom: 10,
      containLabel: true
    },
    xAxis: {
      type: 'value',
      boundaryGap: [0, 0.01]
    },
    yAxis: {
      type: 'category',
      data: labelData,
    },
    series: [
      {
        type: 'bar',
        data: data,
        itemStyle: {
          normal: {
            //这里是重点
            color: "#CCCCCC"
          }
        },
        backgroundStyle: {
          color: 'none',
        },
        label: {
          show: true,
          position: 'right'
        },
        barWidth: 10,//固定柱子宽度
        showBackground: true,
      }
    ]
  }
}