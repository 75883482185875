import * as React from "react"
import {
  Box,
  MenuItem,
  Select,
  Slider,
  TextField,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core"

import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"

function valuetext(value: number) {
  return `${value}%`
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "14px",
      backgroundColor: "#F4F6F8",
    },
    body: {
      fontSize: "14px",
      lineHeight: "34px",
      borderBottom: "2px solid #D1D5D8",
    },
    cell: {
      padding: "0 24px 0 16px",
    },
    inputRoot: {
      height: "34px",
      lineHeight: "34px",
      padding: 0,
      "& input": {
        lineHeight: "34px",
        height: "34px",
        fontSize: "14px",
        padding: 0,
      },
    },
  })
)

export default function From({ form, setForm }: any) {
  const classes = useStyles()
  const handleChangeForm = (column: string, value: any, item: any) => {
    const content = form.content.map((v: any) => {
      if (v.column === column) {
        const res = { ...v }
        res[item] = value
        return res
      }
      return { ...v }
    })
    setForm({ ...form, content })
  }
  return (
    <Box>
      <Box pt={4} width="60%" fontSize={14} margin="0 auto">
        <Box
          display="flex"
          alignItems="center"
          borderBottom="1px solid #D1D5D8"
        >
          <Box fontWeight="bold" pr={2}>
            Quarter Bonus Budget:
          </Box>
          <Box flexGrow={1}>
            <TextField
              type="number"
              classes={{ root: classes.inputRoot }}
              fullWidth
              value={form.budget}
              onChange={(event: any) => {
                setForm({ ...form, budget: Number(event.target.value) })
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Box>
        </Box>
        <Box pt={4} display="flex" justifyContent="space-between">
          <Box fontWeight="bold">
            Team Manager: {(form.budget * form.manager_per) / 100} (
            {form.manager_per} %)
          </Box>
          <Box fontWeight="bold">
            Team Member: {(form.budget * (100 - form.manager_per)) / 100} (
            {100 - form.manager_per} %)
          </Box>
        </Box>
        <Box>
          <Slider
            value={form.manager_per}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider-restrict"
            step={5}
            valueLabelDisplay="auto"
            onChange={(_, manager_per) => {
              setForm({ ...form, manager_per })
            }}
          />
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead className={classes.header}>
            <TableRow>
              <TableCell>Column</TableCell>
              <TableCell>Label</TableCell>
              <TableCell>Weight</TableCell>
              <TableCell>Value Type</TableCell>
              <TableCell>Ranking Sort</TableCell>
              <TableCell>Editable</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {form.content &&
              form.content.map((val: any) => (
                <TableRow key={val.column}>
                  <TableCell className={classes.cell}>{val.column}</TableCell>
                  <TableCell className={classes.cell}><TextField
                      classes={{ root: classes.inputRoot }}
                      value={val.label}
                      onChange={(event) => {
                        handleChangeForm(
                          val.column,
                          event.target.value,
                          "label"
                        )
                      }}
                      fullWidth
                  />
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <TextField
                      classes={{ root: classes.inputRoot }}
                      value={val.weight}
                      type="number"
                      onChange={(event) => {
                        handleChangeForm(
                          val.column,
                          Number(event.target.value),
                          "weight"
                        )
                      }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Select
                      classes={{ select: classes.inputRoot }}
                      fullWidth
                      value={val.value_type}
                      onChange={(event) => {
                        handleChangeForm(val.column, event.target.value, "value_type")
                      }}
                    >
                      <MenuItem value="Percentage">Percentage</MenuItem>
                      <MenuItem value="Number">Number</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Select
                      classes={{ select: classes.inputRoot }}
                      fullWidth
                      value={val.sort}
                      onChange={(event) => {
                        handleChangeForm(val.column, event.target.value, "sort")
                      }}
                    >
                      <MenuItem value="ASC">ASC</MenuItem>
                      <MenuItem value="DESC">DESC</MenuItem>
                    </Select>
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <Select
                      fullWidth
                      classes={{ select: classes.inputRoot }}
                      value={val.is_custom}
                      onChange={(event) => {
                        handleChangeForm(
                          val.column,
                          event.target.value,
                          "is_custom"
                        )
                      }}
                    >
                      <MenuItem value={0}>NO</MenuItem>
                      <MenuItem value={1}>YES</MenuItem>
                    </Select>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
