import React from "react"
import { Box } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"

interface MenuProps {
  items: [string]
}

export default function ButtonMenu(props: MenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const items = props.items
  let show_text = "PO:" + items[0]
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <Box>
      {items.length === 1 ? (
        show_text
      ) : (
        <>
          <Button
            aria-controls="button-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            {`PO: click to view (${items.length})`}
          </Button>
          <Menu
            id="button-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {items.map((val) => (
              <MenuItem onClick={handleClose}>{val}</MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  )
}
