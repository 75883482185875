import { gql } from "@apollo/client"

const CREATE_DATA = gql`
mutation CreateTeamBuilding($name: String!) {
  createTeamBuilding(name: $name) {
    id
    name
  }
}

`
export default CREATE_DATA