import { gql } from "@apollo/client"

const FETCH_ATTENDANCE_RATE = gql`
query DashboardAttendanceRate($start: Date!,$end: Date!,$abscissa:Int!) {
  DashboardAttendanceRate(start: $start,end: $end, abscissa:$abscissa) {
    name
    x
    y
  }
}
`
export default FETCH_ATTENDANCE_RATE