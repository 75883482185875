import { gql } from "@apollo/client"

const DASHBOARD_OKR_TEAM = gql`
query DashboarOkr($year: Int!, $quarter: Int!, $team_id: [ID]!) {
  DashboardOkrTeam(year: $year, quarter: $quarter, team_id: $team_id) {
    name
    data {
      name
      x
      y
    }
  }
}
`
export default DASHBOARD_OKR_TEAM