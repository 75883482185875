import { gql } from "@apollo/client"

const UPDATE_TBA_TRAINING = gql`
  mutation UpdateTbaTraining($input: UpdateTbaTrainingInput!) {
  updateTbaTraining(input: $input) {
    id
    training_name
    expense
    training_at
    during
    trainers {
      id
      name
    }
    attendees {
      id
      name
    }
    satisfaction
    created_at
  }
}

`
export default UPDATE_TBA_TRAINING