import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react";

const FETCH_UNIT = gql`
  query AllUnit {
    allUnit {
      id
      name
    }
  }
`

const useAllUnits = () => {
  const { data, loading, error } = useQuery(FETCH_UNIT, {
    fetchPolicy: "cache-and-network",
  })

  const units = useMemo(() => {
    return loading || error ? [] : data.allUnit;
  }, [loading, data, error])


  return { units, loading }
}

export default useAllUnits