import { gql } from "@apollo/client"

const UPLOAD_USER_ATTENDANCE = gql`
  mutation uploadUserAttendance($file: Upload!) {
    uploadUserAttendance(file: $file) {
      code
      message
    }
  }
`
export default UPLOAD_USER_ATTENDANCE