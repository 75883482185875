import { Button, Tooltip } from "@material-ui/core"
import { navigate } from "@reach/router"
import React from "react"
import EditIcon from "@material-ui/icons/Edit"

const columnSchema = [
  {
    headerName: "Operation",
    field: "operation",
    sortable: false,
    width: 100,
    renderCell: (params: any) => {
      return (
        <Button
          key="update"
          color="primary"
          size="small"
          onClick={() => navigate(`/role/assignment/editor/${params.row.id}`)}
        >
          <EditIcon />
        </Button>
      )
    },
  },
  {
    headerName: "User Name",
    field: "name",
    width: 130,
  },
  {
    headerName: "User ID",
    field: "slug",
    width: 100,
  },
  {
    headerName: "Team",
    field: "team",
    width: 120,
  },
  {
    headerName: "Role",
    field: "roles",
    sortable: false,
    width: 120,
    renderCell: (params: any) => {
      if (!params.value) {
        return
      }
      const title = params.value.map((v) => v.name).join(", ")
      const children = React.createElement(
        "div",
        {
          style: {
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
        title
      )
      return React.createElement(Tooltip, { title, children })
    },
  },
  {
    headerName: "Job Title",
    field: "job_title",
    width: 150,
  },
  {
    headerName: "Competency",
    field: "competency",
    sortable: false,
    width: 120,
    renderCell: (params: any) => {
      if (!params.value) {
        return
      }
      const title = params.value.join("/")
      const children = React.createElement(
        "div",
        {
          style: {
            width: "100%",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          },
        },
        title
      )
      return React.createElement(Tooltip, { title, children })
    },
  },
  {
    headerName: "Type",
    field: "type",
    width: 100,
  },
  {
    headerName: "Status",
    field: "status",
    width: 100,
  },
]

export default columnSchema
