import * as React from "react"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import { useCallback } from "react"
import { Box, Grid } from "@material-ui/core"
import TeamSelector from "./TeamSelector"

const TeamSelectorField = (props: any) => {
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta

  const handleChangeTeam = useCallback(
    (_, value) => {
      if (value) {
        input.onChange({ id: value.id, name: value.name })
      } else {
        input.onChange(null)
      }
    },
    [input]
  )
  
  return (
    <Grid item {...props.FormFieldGridProps} >
      <TeamSelector
        onChange={handleChangeTeam}
        required={props.isRequired}
        width={props.width}
        label={props.label}
        value={input.value}
        name={props.name}
      />
      {submitFailed && error && <Box color="primary.main">{error}</Box>}
    </Grid>
  )
}

export default TeamSelectorField
