import { useApolloClient } from "@apollo/client"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core"
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import React from "react"
import UPDATA_JOB_LEVEL from "../Gqls/UPDATA_JOB_LEVEL"
import MomentUtils from "@date-io/moment"
import { USER_JOB_LEVEL_OPTIONS } from "../../../Utils/config"
import moment from "moment"

const UpdateJobLevel = ({ values }) => {
  const [open, setOpen] = React.useState(false)
  const [year, setYear] = React.useState<any>(moment())
  const [jobLevel, setJobLevel] = React.useState<any>()
  const [at, setAt] = React.useState<any>()

  const graphqlClient = useApolloClient()

  const changeJobLevel = (e) => {
    setJobLevel(e.target.value)
  }
  const changeAt = (e) => {
    setAt(e.target.value)
  }

  const handleSubmit = React.useCallback(async () => {
    // console.log(jobLevel, at, year.format("yyyy"))
    const future_job_level_apply_at = year.format("yyyy") + "-" + at
    const future_job_level = jobLevel
    graphqlClient
      .mutate({
        mutation: UPDATA_JOB_LEVEL,
        variables: {
          id: values.id,
          future_job_level,
          future_job_level_apply_at,
        },
      })
      .then(() => {
        window.history.back()
      })
  }, [graphqlClient, values, jobLevel, at, year])

  const handleClose = () => {
    setOpen(false)
  }
  let will = <Box></Box>
  if (values.future_job_level_apply_at) {
    will = (
      <Box ml={2}>
        (The user has been set to {values.future_job_level} on {values.future_job_level_apply_at})
      </Box>
    )
  }
  return (
    <Box>
      <Box display="flex" alignItems="center" height={30} mt={2}>
        <Box color="text.secondary" fontWeight="bold">
          Job Level
        </Box>
        <Box ml={2}>{values.job_level ? values.job_level : "N/A"} </Box>

        {will}
        <Box ml={2}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => setOpen(true)}
          >
            Update Job Level
          </Button>
        </Box>
      </Box>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="update-job-level"
      >
        <DialogTitle id="update-job-level">Update Job Level</DialogTitle>
        <DialogContent>
          <Box width={300}>
            <DialogContentText>
              Please confirm that {values.name} will change job level
            </DialogContentText>
            <FormControl fullWidth>
              <InputLabel>Future Job Level</InputLabel>
              <Select
                label={"job level"}
                value={jobLevel}
                onChange={changeJobLevel}
              >
                {USER_JOB_LEVEL_OPTIONS.map((v) => (
                  <MenuItem key={v.value} value={v.value}>
                    {v.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                fullWidth
                label={"Year"}
                views={["year"]}
                value={year}
                showTodayButton
                onChange={(date) => setYear(date)}
                format="yyyy"
              />
            </MuiPickersUtilsProvider>
            <FormControl fullWidth>
              <InputLabel>Future Apply At</InputLabel>
              <Select value={at} onChange={changeAt}>
                <MenuItem value={"01-01"}>01-01</MenuItem>
                <MenuItem value={"07-01"}>07-01</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSubmit} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

export default UpdateJobLevel
