import { gql } from "@apollo/client"

const FETCH_TBA_FUND_LOG = gql`
query FetchTbaFundLogs(
  $where: QueryTbaFundLogsWhereWhereConditions
  $orderBy: [QueryTbaFundLogsOrderByOrderByClause!]
) {
  TbaFundLogs(where: $where, orderBy: $orderBy) {
    id
    tba_training_id
    user_id
    training {
      id
      expense
      training_name
      satisfaction
      trainers {
        id
        name
        abbr
      }
      attendees {
        name
      }
    }
    user {
      id
      name
    }
    amount
    status
    at
    created_at
  }
}

`

export default FETCH_TBA_FUND_LOG