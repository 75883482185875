import { gql } from "@apollo/client"

const FETCH_TBA_INTEGRAL_LOG = gql`
query FetchTbaIntegralLogs(
  $where: QueryTbaIntegralLogsWhereWhereConditions
  $orderBy: [QueryTbaIntegralLogsOrderByOrderByClause!]
) {
  TbaIntegralLogs(where: $where, orderBy: $orderBy) {
    id
    type
    tba_training_id
    user_id
    description
    ext_training{
      platform
      training_name
    }
    training {
      id
      training_name
      trainers{
        id
        name
        abbr
      }
    }
    user {
      id
      name
      abbr
      team
      roles{
        name
      }
      term
    }
    amount
    expire_at
    at
    created_at
  }
}

`

export default FETCH_TBA_INTEGRAL_LOG