import { gql } from "@apollo/client"

const SEARCH_OPERATION_LOG = gql`
query FetchOperationLog(
  $where: QueryNewOperationLogsWhereWhereConditions
  $orderBy: [QueryNewOperationLogsOrderByOrderByClause!]
  $first: Int!
  $page: Int
) {
  newOperationLogs(
    where: $where
    first: $first
    page: $page
    orderBy: $orderBy
  ) {
    data {
      id
      type
      status
      additional
      operator
      content
      created_at
    }
    paginatorInfo {
        total
        currentPage
      }
  }
}
`

export default SEARCH_OPERATION_LOG