import { gql } from "@apollo/client"

const DELETE_FILE = gql`
mutation DeleteFile($id:ID!) {
  deleteFile(id: $id) {
    id
    filename
    content
    creator_id
    revisor_id
  }
}
`

export default DELETE_FILE