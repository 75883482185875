import * as React from "react"
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
} from "@material-ui/core"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles"
import Chip from "@material-ui/core/Chip"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    AccordionRoot: {
      boxShadow: "none",
    },
    AccordionSummaryRoot: {
      background: "#F7F9FA",
      "&.Mui-expanded": {
        minHeight: "48px",
      },
    },
    AccordionSummaryContent: {
      margin: "0",
      "&.Mui-expanded": {
        margin: "0",
      },
    },
    AccordionDetail: {
      display: "block",
      padding: 0,
    },
  })
)

const OkrObject = ({ objects, expandedType }: any) => {
  const classes = useStyles()

  const [expandedArr, setExpandedArr] = React.useState<any[]>([])
  // 展开事件--懒加载提升加载速度
  const onAccordion = (id: string, expanded: boolean) => {
    const newArr = [...expandedArr, id]
    if (expanded && !expandedArr.includes(id)) {
      setExpandedArr(newArr)
    } 
  }

  return (
    <Box>
      {objects.map((object: any, o: number) => (
        <Box pt={2} key={object.id}>
          <Accordion
            square={true}
            classes={{ root: classes.AccordionRoot }}
            defaultExpanded={expandedType > 2}
            onChange={(e, expanded) => {
              onAccordion(object.id, expanded)
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              classes={{
                root: classes.AccordionSummaryRoot,
                content: classes.AccordionSummaryContent,
              }}
            >
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center">
                  <Chip label={"O" + (o + 1)} color="primary" size="small" />
                  <Box ml={2} fontWeight="bold" fontSize={14}>
                    {object.content}
                  </Box>
                </Box>
                <Box ml={2} width="80px">
                  <Box textAlign="center" fontSize={12} color="#979FA7">
                    Progress
                  </Box>
                  <Box
                    textAlign="center"
                    fontSize={14}
                    fontWeight="bold"
                    color="primary.main"
                  >
                    {object.progress}%
                  </Box>
                </Box>
              </Box>
            </AccordionSummary>
            <AccordionDetails className={classes.AccordionDetail}>
              {expandedArr.includes(object.id) || expandedType > 2
                ? object.keyResults.map((item: any, index: number) => (
                    <Box key={item.id} borderBottom="1px solid #eee">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Box
                          display="flex"
                          height={48}
                          alignItems="center"
                          ml={13}
                        >
                          <Box color="primary.main" fontWeight="bold">
                            KR{index + 1}
                          </Box>
                          <Box ml={2}>{item.content}</Box>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          textAlign="center"
                          mr={2}
                          width="80px"
                        >
                          {item.progress}%
                        </Box>
                      </Box>
                    </Box>
                  ))
                : null}
            </AccordionDetails>
          </Accordion>
        </Box>
      ))}
    </Box>
  )
}
export default OkrObject
