import React, { useCallback, useMemo } from "react"
import { GridSortItem, GridSortModel } from "@material-ui/data-grid"
import { DataTableProps, OrderByItem, OrderByModel } from "./Types"
import {
  DataGrid as Table,
  GridCellParams as TableCellParams,
} from "@material-ui/data-grid"
import Button from "@material-ui/core/Button"
import EditIcon from "@material-ui/icons/Edit"
import DeleteIcon from "@material-ui/icons/Delete"
import lodash from "lodash"
import Tooltip from "@material-ui/core/Tooltip"

const convertSortModelToOrderBy = (sortModel: GridSortModel) =>
  sortModel.map((item: GridSortItem) => ({
    column: item.field.toUpperCase(),
    order: item.sort!.toUpperCase(),
  }))

const convertOrderByToSortModel = (orderBy: OrderByModel) =>
  orderBy.map(
    (item: OrderByItem) =>
      ({
        field: item.column.toLowerCase(),
        sort: item.order.toLowerCase(),
      } as GridSortItem)
  )

const DataTable = ({
  columnSchema,
  height,
  page,
  pageSize,
  orderBy,
  loading,
  data,
  rowCount,
  onOrderByChanged,
  onRowEdit,
  onRowDelete,
  ...rest
}: DataTableProps) => {
  // 修改排序规则
  const sortModelChangedHandler = useCallback(
    (model: GridSortModel) => {
      const newSortModel = convertSortModelToOrderBy(model)
      if (
        !lodash.isMatch(newSortModel, orderBy) ||
        newSortModel.length !== orderBy.length
      ) {
        onOrderByChanged(newSortModel)
      }
    },
    [onOrderByChanged, orderBy]
  )

  const sortModel = useMemo(() => convertOrderByToSortModel(orderBy), [orderBy])
  const columns = React.useMemo(() => {
    const myColumnSchema = columnSchema.map((v) => {
      if (v.renderCell) {
        return v
      }
      v.renderCell = (params: any) => {
        const title = params.value
        if (!title) {
          return title
        }
        const children = React.createElement("div", {}, title)
        return React.createElement(Tooltip, { title, children })
      }
      return v
    })

    if (!onRowEdit && !onRowDelete) {
      return myColumnSchema
    }

    const renderEditButton = (params: any) => {
      return (
        <Button
          data-id={params.id}
          color="primary"
          size="small"
          style={{ marginRight: 5 }}
          onClick={() => onRowEdit && onRowEdit(params.row)}
        >
          <EditIcon />
        </Button>
      )
    }

    const renderDeleteButton = (params: any) => {
      return (
        <Button
          color="primary"
          size="small"
          onClick={() => onRowDelete && onRowDelete(params.row)}
        >
          <DeleteIcon />
        </Button>
      )
    }
    return [
      {
        headerName: "Operate",
        width: 80,
        field: "__action",
        sortable: false,
        renderCell: (params: TableCellParams) => (
          <>
            {onRowEdit && renderEditButton(params)}
            {onRowDelete && renderDeleteButton(params)}
          </>
        ),
      },
      ...myColumnSchema,
    ]
  }, [columnSchema, onRowEdit, onRowDelete])

  return (
    <>
      <Table
        autoHeight
        rowHeight={height}
        loading={loading}
        density={"compact"}
        rows={loading ? [] : data}
        page={page}
        pageSize={pageSize}
        rowCount={rowCount}
        sortingMode="server"
        sortModel={sortModel}
        onSortModelChange={sortModelChangedHandler}
        columns={columns}
        paginationMode="server"
        sortingOrder={["asc", "desc"]}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
        {...rest}
      />
    </>
  )
}

export default DataTable
