import React, { useMemo, useCallback } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import GetAppIcon from "@material-ui/icons/GetApp"
import VisibilityIcon from "@material-ui/icons/Visibility"
import { useQuery } from "@apollo/client"
import SEARCH_PROJECT_REPORT from "./Gqls/SEARCH_PROJECT_REPORT"
import { GridCellParams as TableCellParams } from "@material-ui/data-grid"
import moment from "moment"
import Search from "../../Components/DataGrid/DataFilter"

import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core"
import {
  searchMonthSchema,
  searchQuarterSchema,
  searchYearSchema,
} from "./Schemas"
import DataTable from "../../Components/DataGrid/DataTable"
import { downloadUrl } from "../../Utils"
import {
  useQueryParams,
  StringParam,
  NumberParam,
  DateParam,
  JsonParam,
  NumericObjectParam,
} from "use-query-params"

import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"
import ReportFileldsListener from "./Components/ReportFiledsListener"
import { withDefault } from "serialize-query-params"
import useSession from "../../Hooks/useSession"

ComponentMapper["ReportFileldsListener"] = ReportFileldsListener
const NumberDefaultParam = withDefault(NumberParam, 0)
const month_s = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

const searchSchemaArr = [
  searchMonthSchema,
  searchQuarterSchema,
  searchYearSchema,
]
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: "none",
      "& .Mui-selected": {
        color: theme.palette.primary.main,
      },
    },
    tabs: {
      height: 36,
      minHeight: 36,
    },
    tab: {
      padding: 0,
      minWidth: 100,
      minHeight: 36,
      marginRight: 18,
      "&>span": {
        alignItems: "flex-start",
      },
      textTransform: "none",
    },
    paper: {
      padding: theme.spacing(2),
    },
    addLink: {
      textDecoration: "none",
    },
  })
)
export const DefaultPage = withDefault(NumericObjectParam, {
  page: 0,
  pageSize: 25,
})
export const DefaultOrderBy = withDefault(JsonParam, [])
const ReportIndex = (props: RouteComponentProps) => {
  const { can } = useSession()
  const classes = useStyles()

  const [query, setQuery] = useQueryParams({
    project_slug: StringParam,
    project: NumericObjectParam,
    year: DateParam,
    team: NumericObjectParam,
    month: DateParam,
    quarter: StringParam,
    type: NumberDefaultParam,
    pageInfo: DefaultPage,
    orderBy: DefaultOrderBy,
  })

  const columnSchema = useMemo(() => {
    return [
      { headerName: "Project ID", field: "project_slug", width: 110 },
      { headerName: "Project Name", field: "project_name", width: 280 },
      {
        headerName: "Team",
        field: "project",
        width: 110,
        renderCell: (params: any) =>
          params.value.team && params.value.team.name,
        sortable: false,
      },

      { headerName: "Year", field: "year", width: 80 },
      {
        headerName: "Quarter",
        field: "quarter",
        width: 100,
        hide: query.type !== 1,
        renderCell: (params: any) => params.value && `Q${params.value}`,
      },
      {
        headerName: "Month",
        field: "month",
        width: 90,
        renderCell: (params: any) => {
          return month_s[parseInt(params.value)]
        },
        hide: query.type !== 0,
      },
      {
        headerName: "Total Working Hours",
        field: "actual_working_hours",
        width: 150,
      },
      { headerName: "Import Time", field: "updated_at", width: 200 },
    ]
  }, [query])

  const handleTabChange = (event: React.ChangeEvent<{}>, type: number) => {
    setQuery({ type })
  }
  const setPageSize = (pageSize: number) => {
    const pageInfo = {
      ...query.pageInfo,
      pageSize,
    }
    setQuery({ ...query, pageInfo })
  }
  const setPage = (page: number) => {
    const pageInfo = {
      ...query.pageInfo,
      page,
    }
    setQuery({ ...query, pageInfo })
  }
  const setOrderBy = (orderBy: any) => {
    setQuery({ ...query, orderBy })
  }

  const variables = useMemo(() => {
    const {
      project_slug,
      month,
      team,
      quarter,
      year,
      project,
      pageInfo,
      orderBy,
      type,
    } = query
    let variables: any = {}
    if (project_slug) {
      variables.project_slug = project_slug
    }
    if (project) {
      variables.project_id = project.id
    }
    if (year) {
      variables.year = moment(year).format("YYYY")
    }
    if (type === 0 && month) {
      variables.month = moment(month).format("MM")
    }
    if (type === 1 && quarter) {
      variables.quarter = quarter
    }
    if (team) {
      variables.team_id = team.id
    }

    variables.orderBy = orderBy
    variables.page = pageInfo.page ? pageInfo.page + 1 : 1
    variables.first = pageInfo.pageSize ? pageInfo.pageSize : 25
    variables.type = type ? type : 0
    return variables
  }, [query])

  const { loading, data } = useQuery(SEARCH_PROJECT_REPORT, {
    fetchPolicy: "cache-and-network",
    variables: variables,
  })

  const tableList = data ? data["projectExportReports"]["data"] : []
  const rowsCount =
    data && data["projectExportReports"]["paginatorInfo"]["total"]

  const handleDownload = (row: any) => {
    downloadUrl(
      `/api/project-dashboard/export/${row.project_slug}/${row.start_period}/${row.end_period}`
    )
  }
  const handleExport = useCallback(() => {
    return downloadUrl(`/api/export/project/report`, variables)
  }, [variables])

  const handleView = async (row?: any) => {
    navigate(
      `/report/dashboard?project_slug=${row.project_slug}&start_period=${row.start_period}&end_period=${row.end_period}`
    )
  }

  const filterUpdatedHandler = useCallback(
    (filter) => {
      const { project_slug, project, month, quarter, year, team } = filter

      let params: any = {}
      if (query.type > 0) {
        params.year = moment(year).toDate()
      }
      if (query.type === 0) {
        params.year = month ? moment(month).toDate() : null
        params.month = month ? moment(month).toDate() : null
      }
      if (query.type === 1) {
        params.quarter = quarter
      }
      params.pageInfo = {
        page: 0,
        first: 25,
      }
      params.team = team
      params.project = project
      params.project_slug = project_slug
      params.orderBy = []
      setQuery(params)
    },
    [query, setQuery]
  )

  const updateSchema = useMemo(() => {
    const chema = searchSchemaArr[query.type]()
    chema.fields.push({
      component: "ReportFileldsListener",
      name: "report-listener",
      hideField: true,
    })
    return chema
  }, [query])

  const column = [
    ...columnSchema,
    {
      headerName: "Operate",
      width: 150,
      field: "__action",
      sortable: false,
      renderCell: (params: TableCellParams) => (
        <>
          <Button
            color="primary"
            size="small"
            onClick={() => handleView(params.row)}
          >
            <VisibilityIcon />
          </Button>

          {can(
            "project-report.download",
            <Button
              color="primary"
              size="small"
              style={{ marginRight: 5 }}
              onClick={(event) => {
                event.stopPropagation()
                handleDownload(params.row)
              }}
            >
              <GetAppIcon />
            </Button>
          )}
          {/* <Button
            color="primary"
            size="small"
            onClick={() => handleDelete(params.row)}
          >
            <DeleteIcon />
          </Button> */}
        </>
      ),
    },
  ]
  const header = (
    <Box pb={2}>
      <AppBar position="static" color="transparent" className={classes.root}>
        <Tabs
          value={query.type}
          onChange={handleTabChange}
          indicatorColor="primary"
          className={classes.tabs}
        >
          <Tab value={0} label="Monthly Report" className={classes.tab} />
          <Tab value={1} label="Quarterly Report" className={classes.tab} />
          <Tab value={2} label="Yearly Report" className={classes.tab} />
        </Tabs>
      </AppBar>
    </Box>
  )
  return (
    <>
      <Box mx={[2, 4, 6]} py={5} className={classes.root}>
        {can(
          "project-report.export",
          <Box mb={2} textAlign="right">
            <Button variant="contained" color="primary" onClick={handleExport}>
              Export
            </Button>
          </Box>
        )}

        <Box mb={2}>
          <Search
            slotHeader={header}
            filterSchema={updateSchema}
            onFilterChanged={filterUpdatedHandler}
          />
        </Box>
        <DataTable
          orderBy={query.orderBy ? query.orderBy : []}
          onOrderByChanged={setOrderBy}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          loading={loading}
          rowCount={rowsCount ? rowsCount : 0}
          page={query.pageInfo.page ?? 0}
          pageSize={query.pageInfo.pageSize ?? 25}
          columnSchema={column}
          data={tableList}
        />
      </Box>
    </>
  )
}

export default ReportIndex
