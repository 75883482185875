import * as React from "react"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"
import TreeView from "@material-ui/lab/TreeView"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import TreeItem from "@material-ui/lab/TreeItem"
import { Checkbox, FormControlLabel } from "@material-ui/core"
import useAllMenu from "../../../Hooks/useAllMenu"
import { filterArray } from "../../../Utils/config"

const useStyles = makeStyles({
  error: {
    padding: "1rem",
    color: "red",
  },
  checkBoxMain: {
    padding: 2,
  },
  checkBox: {
    padding: 2,
    marginLeft: 16,
  },
})

const BaseTreeItem = (props: any) => {
  const classes = useStyles()
  const { name, value, input, changeItem } = props
  return (
    <TreeItem
      nodeId={name}
      label={
        <FormControlLabel
          key={value}
          onClick={(e) => {
            e.stopPropagation()
          }}
          control={
            <Checkbox
              color="primary"
              className={classes.checkBoxMain}
              checked={input.value.includes(value)}
              onChange={(e) => {
                changeItem(value)
              }}
            />
          }
          label={name}
        />
      }
      key={name}
    >
      {props.children}
    </TreeItem>
  )
}

// 输入节点，返回节点and节点的所有子孙
const treeItemChildren = (node) => {
  if (!node) {
    return []
  }
  const arr = [node.value]
  const loop = (item: any) => {
    if (item.children) {
      item.children.map((n) => loop(n))
    }
    arr.push(item.value)
  }
  loop(node)
  return arr
}
// 输入tree和节点name ，查找对应节点
const treeFindItem = (tree, value) => {
  for (let i = 0; i < tree.length; i++) {
    const item = tree[i]
    if (item.value === value) {
      return item
    }
    if (item.children) {
      const res = treeFindItem(item.children, value)
      if (res) {
        return res
      }
    }
  }
  return null
}

// 实现tree 联动选取
const treeReation = (tree, value) => {
  const r = treeFindItem(tree, value)
  return treeItemChildren(r)
}

const MenuField = (props: any) => {
  const classes = useStyles()
  const { menus, menusListValues } = useAllMenu()
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta

  const [expanded, setExpanded] = React.useState<string[]>([])

  const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
    setExpanded(nodeIds)
  }

  const changeItem = (value) => {
    const select = treeReation(menus, value)
    const exc = filterArray([...input.value], select)
    let newValue: any = []
    if (input.value.includes(value)) {
      newValue = [...exc]
    } else {
      newValue = [...exc, ...select]
    }
    const nn:any = []
    menusListValues.forEach((v:any) => {
      if (newValue.includes(v)) {
        nn.push(v)
      }
    })
    input.onChange(nn)
  }

  React.useEffect(() => {
    let arr: any = []
    menus.forEach((v: any) => {
      arr.push(v.name)
      if (v.children) {
        v.children.forEach((vv) => {
          arr.push(vv.name)
        })
      }
    })
    setExpanded(arr)
  }, [menus])

  const myProps = {
    input,
    changeItem,
  }
  return (
    <Box border={1} flexGrow={1} mt={2}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        expanded={expanded}
        onNodeToggle={handleToggle}
      >
        {menus.map((menu) =>
          menu.children ? (
            <BaseTreeItem
              name={menu.name}
              value={menu.value}
              key={menu.value}
              {...myProps}
            >
              {menu.children.map((v) =>
                v.children ? (
                  <BaseTreeItem
                    name={v.name}
                    value={v.value}
                    key={v.value}
                    {...myProps}
                  >
                    {v.children.map((vv) => (
                      <BaseTreeItem
                        name={vv.name}
                        value={vv.value}
                        key={vv.value}
                        {...myProps}
                      />
                    ))}
                  </BaseTreeItem>
                ) : (
                  <BaseTreeItem
                    name={v.name}
                    value={v.value}
                    key={v.value}
                    {...myProps}
                  />
                )
              )}
            </BaseTreeItem>
          ) : (
            <BaseTreeItem
              name={menu.name}
              value={menu.value}
              key={menu.value}
              {...myProps}
            />
          )
        )}
      </TreeView>

      {submitFailed && error && <Box className={classes.error}>{error}</Box>}
    </Box>
  )
}

export default MenuField
