import { gql } from "@apollo/client"

const FETCH_KPI_BONUS = gql`
query FetchKpiBonus($year: Int, $quarter: Int) {
  kpiBonuses(year: $year, quarter: $quarter) {
    id
    year
    quarter
    amount
    status
    target_quarter_team_member_number{
      team_id
      number
    }
    teams {
      id
      year
      quarter
      ranking
      bonus
      allocated
      previous_balance
      team {
        id
        name
      }
      members {
        id
        bonus
        remark
      }
    }
    created_at
  }
}
`
export default FETCH_KPI_BONUS