import React, { useEffect } from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core"
import OrganizationChart from "@dabeng/react-orgchart"
import OrgNode from "./Component/OrgNode"
import { useQuery } from "@apollo/client"
import FETCH_ORGANIZATION from "./Gqls/FETCH_ORGANIZATION"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .orgchart-container": {
        height: "calc(100vh - 100px)",
        "& .orgchart": {
          backgroundImage: "none",
          "& ul": {
            "& ul": {
              "& ul": {
                display: "flex",
                flexDirection: "column",
                "& .oc-node": {
                  marginBottom: "10px",
                },
                "& li::before": {
                  borderTop: "none",
                },
              },
            },
          },
        },
      },
    },
  })
)

const Organization = (props: RouteComponentProps) => {
  const classes = useStyles()
  const { data, loading } = useQuery(FETCH_ORGANIZATION)
  const datascource = React.useMemo(() => {
    if (loading) {
      return null
    }
    return JSON.parse(JSON.stringify(data.organizationChart))
  }, [data, loading])

  useEffect(() => {
    const ele: any = document.getElementsByClassName("orgchart-container")[0]
    if (!ele) {
      return
    }
    let baseX = 0
    let baseY = 0
    let cLeft = 0
    let cTop = 0
    ele.onmousedown = function (e) {
      baseX = e.clientX
      baseY = e.clientY
      cLeft = ele.scrollLeft
      cTop = ele.scrollTop
      document.onmousemove = function (e) {
        // let xx = e.clientX
        // let yy = e.clientY
        //首先获得鼠标位置坐标
        ele.scrollLeft = cLeft + baseX - e.clientX
        ele.scrollTop = cTop + baseY - e.clientY
      }
      document.onmouseup = function () {
        document.onmouseup = null
        document.onmousemove = null
      }
    }
  }, [datascource])

  return (
    <Box>
      <Box className={classes.root}>
        {datascource ? (
          <OrganizationChart
            pan={false}
            zoom={false}
            datasource={datascource}
            NodeTemplate={OrgNode}
          />
        ) : null}
      </Box>
    </Box>
  )
}

export default Organization
