import { gql } from "@apollo/client"

const UPDATE_PROJECT = gql`
  mutation UpdateProject (
    $id: ID!
    $slug: String
    $name: String
    $abbr: String
    $customer: String
    $team_id: ID
    $manager_id: ID
    $members: UpdateUsersBelongsToMany
  ) {
    updateProject(
      id: $id
      slug: $slug
      name: $name
      abbr: $abbr
      team_id: $team_id
      manager_id: $manager_id
      customer: $customer
      members: $members
    )  {
      id
      slug
      name
      abbr
      customer
      remainingPO
      projectUsers {
        id
        user {
          id
          name
          slug
          abbr
        }
        unit_price
      }
      created_at
      updated_at
    }
  }
`
export default UPDATE_PROJECT