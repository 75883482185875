
export const Types = [
  {
    label: 'Business Team',
    value: '0'
  },
  {
    label: 'Other Team',
    value: '1'
  }
]