import { Tooltip } from "@material-ui/core"
import React from "react"

const columnSchema = [
  {
    headerName: "User Name", field: "user_id", width: 150,
    renderCell: (params: any) => {
      return params.row.operator
    }
  },
  {
    headerName: "Operation", field: "mutation_id", width: 200,
    renderCell: (params: any) => {
      return params.row.type
    }
  },
  {
    headerName: "Content", field: "content", width: 350, sortable: false,
    renderCell: (params: any) => {
      const str = params.row.content
      const title = str.replace(/,/, ", ");
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      }, title)
      return React.createElement(Tooltip, { title, children });
    }
  },
  { headerName: "Additional Info", field: "additional", width: 250, sortable: false },
  {
    headerName: "Status", field: "status", width: 100, renderCell: (params: any) => {
      return params.row.status ? "Success" : "Failed"
    }
  },
  { headerName: "When", field: "created_at", width: 140, },
]

export default columnSchema
