import { gql } from "@apollo/client"

const DASHBOARD_OKR = gql`
query DashboarOkr($year: Int!, $quarter: Int!) {
  DashboardOkr(year: $year, quarter: $quarter) {
    name
    x
    y
  }
}
`
export default DASHBOARD_OKR