import { useState, useCallback } from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { Box, Button, Grid, SvgIcon, Typography } from "@material-ui/core"
import ReactEcharts from "../../Components/Echart"
import Card from "../../Components/Card"
import Menu from "./Components/Menu"
import {
  createActivitiesHours,
  createMemberHours,
  createProjectRevenue,
} from "./Schemas"
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms"
import PersonIcon from "@material-ui/icons/Person"
import { useQuery } from "@apollo/client"
import PROJECT_DASHBOARD from "./Gqls/PROJECT_DASHBOARD"
import CircularProgress from "@material-ui/core/CircularProgress"
import { downloadUrl, getParams } from "../../Utils"
import WarningIcon from "@material-ui/icons/Warning"
import useSession from "../../Hooks/useSession"
// import DataFilter from "../../Components/DataGrid/DataFilter"

const RMBIcon = function (props: any) {
  return (
    <SvgIcon {...props}>
      <path d="M11,21V16H6V14H11V13.71L10.16,12H6V10H9.19L5.77,3H8L12,11.2L16,3H18.23L14.81,10H18V12H13.84L13,13.71V14H18V16H13V21H11Z" />
    </SvgIcon>
  )
}

const Dashboard = (props: RouteComponentProps) => {
  const { can } = useSession()
  const params = getParams(window.location.search)
  const [filter] = useState<any>(params ? params : { type: "0" })
  // 调用搜索数据API
  const { loading, data } = useQuery(PROJECT_DASHBOARD, {
    variables: { ...filter },
    fetchPolicy: "cache-and-network",
  })

  const handleDownload = useCallback(() => {
    const projectSlug = filter && filter.project_slug ? filter.project_slug : ""
    const startPeriod = filter && filter.start_period ? filter.start_period : ""
    const endPeriod = filter && filter.end_period ? filter.end_period : ""
    if (projectSlug && startPeriod && endPeriod) {
      return downloadUrl(
        `/api/project-dashboard/export/${projectSlug}/${startPeriod}/${endPeriod}`
      )
    }

    const year = filter && filter.year ? filter.year : ""
    const quarter = filter && filter.quarter ? filter.quarter : ""
    const month = filter && filter.month ? filter.month : ""

    if (projectSlug && year) {
      return downloadUrl(`/api/project-dashboard/export/${projectSlug}`, {
        year,
        month,
        quarter,
      })
    }

    return "#"
  }, [filter])

  const mdata = data && data.projectDashboard
  const activitiesOption =
    mdata &&
    createActivitiesHours(
      mdata.activities_hours.name,
      mdata.activities_hours.value
    )
  const membersOption =
    mdata &&
    createMemberHours(
      mdata.members_hours.label,
      mdata.members_hours.name,
      mdata.members_hours.value,
      mdata.total_working_hours
    )

  const revenueOption =
    mdata && mdata.year_revenue && createProjectRevenue(mdata.year_revenue)

  return (
    <Box mx={[2, 4, 6]} pt={2}>
      <Box py={2}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {can(
            "import-px-report",
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/report/import")}
            >
              Import PX Report
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate("/report")}
          >
            View All Reports
          </Button>
          {can(
            "project-report.download",
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleDownload()}
              disabled={loading || !data || data.ProjectDashboard}
            >
              Export Project Report
            </Button>
          )}
        </Grid>
      </Box>
      {loading && <CircularProgress />}
      {!loading && filter.project_slug && (!data || !data.projectDashboard) && (
        <Typography align="center" variant="h3" gutterBottom>
          Empty data. Please change the search criteria and retry.
        </Typography>
      )}
      {!loading && data && data.projectDashboard && (
        <>
          <Grid container>
            <Grid item xs={3}>
              {mdata.project.name}
            </Grid>
            <Grid item xs={3}>
              {mdata.project.slug}
            </Grid>
            <Grid item xs={3}>
              {mdata.start_period + " - " + mdata.end_period}
            </Grid>
            <Grid item xs={3}>
              {mdata.pos && mdata.pos.length ? (
                <Menu items={mdata.pos.map((v: any) => v.number)} />
              ) : (
                <Box>
                  <Box
                    color="primary.main"
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    No PO is available for this project in this time period.
                  </Box>
                  <Box
                    style={{ display: "inline-block", verticalAlign: "middle" }}
                  >
                    <WarningIcon color="primary" />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
          <Box py={2}>
            <Grid container spacing={2}>
              <Grid item sm={12} md={9}>
                <Box boxShadow={1} mb={2} p={2}>
                  <ReactEcharts
                    option={activitiesOption}
                    style={{
                      height: 40 * mdata.activities_hours.name.length + 40,
                    }}
                  />
                </Box>
                <Box boxShadow={1} p={2}>
                  <ReactEcharts
                    option={membersOption}
                    style={{ height: 350 }}
                  />
                </Box>
                {mdata.year_revenue ? (
                  <Box boxShadow={1} p={2} mt={2}>
                    <ReactEcharts
                      option={revenueOption}
                      style={{ height: 350 }}
                    />
                  </Box>
                ) : null}
              </Grid>
              <Grid item sm={12} md={3}>
                <Card
                  title="Total Working Hours"
                  content={mdata.actual_working_hours + "h"}
                >
                  <AccessAlarmsIcon style={{ fontSize: 36 }} color="primary" />
                </Card>
                <Card
                  title="Total Cost (Tax Included)"
                  content={mdata.total_cost.toFixed(2)}
                >
                  <RMBIcon style={{ fontSize: 36 }} color="primary" />
                </Card>
                <Card
                  title="Remaining PO budget (Tax Included)"
                  content={mdata.remaining_po_budget.toFixed(2)}
                >
                  <RMBIcon style={{ fontSize: 36 }} color="primary" />
                </Card>
                <Box height={16}></Box>
                <Card title="Total Members" content={mdata.total_members}>
                  <PersonIcon style={{ fontSize: 36 }} color="primary" />
                </Card>
                <Card
                  title="Members with Leaves"
                  content={mdata.members_with_leaves}
                >
                  <PersonIcon style={{ fontSize: 36 }} color="primary" />
                </Card>
                <Card
                  title="Total Leave hours"
                  content={mdata.total_leave_hours + "h"}
                >
                  <AccessAlarmsIcon style={{ fontSize: 36 }} color="primary" />
                </Card>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Dashboard
