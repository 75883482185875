import * as React from "react"
import TextField from "@material-ui/core/TextField"
import Grid from "@material-ui/core/Grid"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useAllProjects from "../Hooks/useAllProjects"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import Box from "@material-ui/core/Box"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  error: {
    color: "red",
  },
})

const ProjectSelector = (props: any) => {
  const classes = useStyles()
  const { projects, loading } = useAllProjects()
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta
  const handleChangeProject = (_: any, value: any) => input.onChange(value)
  return (
    <Grid item {...props.FormFieldGridProps}>
      <Box className={classes.root}>
        <Autocomplete
          onChange={handleChangeProject}
          options={projects}
          getOptionLabel={(option: any) => (option ? option.name : "")}
          getOptionSelected={(option: any, value: any) =>
            option.id === value.id
          }
          style={{ width: "100%" }}
          loading={loading}
          value={input.value}
          renderInput={(params) => (
            <TextField
              {...params}
              label={props.label}
              error={submitFailed && error}
            />
          )}
          {...props}
        />
        {submitFailed && error && <Box className={classes.error}>{error}</Box>}
      </Box>
    </Grid>
  )
}

export default ProjectSelector
