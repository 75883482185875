import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, CircularProgress } from "@material-ui/core"
import Toolbar from "./Toolbar"
import ReactEcharts from "../../../Components/Echart"
import { createLineChart } from "../Schemas"
import moment from "moment"
import { useQuery } from "@apollo/client"
import FETCH_ATTRITION_RATE from "../Gqls/FETCH_ATTRITION_RATE"

const AttritionRate = (props: RouteComponentProps) => {
  const [filter, setFilter] = React.useState(() => ({
    start: moment().format("YYYY-01-01"),
    end: moment().format("YYYY-12-31"),
    type:0,
    abscissa: 0,
  }))
  const { data, loading } = useQuery(FETCH_ATTRITION_RATE, {
    fetchPolicy: "cache-and-network",
    variables: filter,
  })
  const option = React.useMemo(() => {
    if (data && data.DashboardAttritionRate) {
      return createLineChart(data.DashboardAttritionRate, true)
    }
    return []
  }, [data])

  return (
    <Box boxShadow={5} p={4} mt={4} style={{ background: "#FDF9F3" }}>
      <Box p={4} pt={2} color="#FF6913" fontSize="20px" fontWeight="bold">
        Trend of Attrition Rate
      </Box>
      <Toolbar setFilter={setFilter} propType={0} propAbscissa={0} />
      {loading ? (
        <Box
          height="400px"
          alignItems="center"
          justifyContent="center"
          display="flex"
        >
          <CircularProgress />
        </Box>
      ) : (
        <ReactEcharts
          option={option}
          style={{
            height: 400,
          }}
        />
      )}
    </Box>
  )
}

export default AttritionRate
