import { gql } from "@apollo/client"

const UPDATE_USERLEAVE = gql`
mutation UpdateUserLeave($id: ID!, $input: UpdateUserLeaveInput!) {
  updateUserLeave(id: $id, input: $input) {
    id
    type
    start
    end
    note
    duration
    user {
      id
      name
    }
    approver {
      id
      name
    }
    created_at
  }
}
`
export default UPDATE_USERLEAVE