import { gql } from "@apollo/client"

const CREATE_TBA_TRAINING = gql`
mutation CreateTbaTraining($input: CreateTbaTrainingInput!) {
  createTbaTraining(input: $input) {
    id
    training_name
    expense
    training_at
    during
    trainers {
      id
      name
    }
    attendees {
      id
      name
    }
    satisfaction
    created_at
  }
}
`
export default CREATE_TBA_TRAINING