import { gql } from "@apollo/client"

const CREATE_USERLEAVE = gql`
mutation CreateUserLeave($input: CreateUserLeaveInput!) {
  createUserLeave(input: $input) {
    id
    type
    start
    end
    note
    duration
    user {
      id
      name
    }
    approver {
      id
      name
    }
    created_at
  }
}
`

export default CREATE_USERLEAVE