import React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box } from "@material-ui/core"
import MyAvatar from "./Component/MyAvatar"

const UpdateAvatar = (props: RouteComponentProps) => {
  return (
    <Box m="2%">
      <Box>
        <h1>Change My Avatar</h1>
        <MyAvatar />
      </Box>
    </Box>
  )
}

export default UpdateAvatar
