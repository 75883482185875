import { Tooltip } from "@material-ui/core";
import React from "react";

const columnSchema = [
  { headerName: "User Name", field: "name", width: 120, },
  { headerName: "User ID", field: "slug", width: 100 },
  { headerName: "Name Abbr", field: "abbr", width: 100, sortable: false },
  { headerName: "Unit", field: "unit", width: 60, sortable: false },
  { headerName: "Team", field: "team", width: 100, },
  { headerName: "Role", field: "roles", width: 100, sortable: false, renderCell: (params: any) => {
    if (!params.value) {
      return;
    }
    const title = params.value.map(v=>v.name).join(", ")
    const children = React.createElement("div", {
      style: {
        width: "100%",
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }
    }, title)
    return React.createElement(Tooltip, { title, children });
  }},
  { headerName: "Job Level", field: "job_level", width: 110, },
  { headerName: "Job Title", field: "job_title", width: 110, },
  {
    headerName: "Competency", field: "competency", sortable: false, width: 120, renderCell: (params: any) => {
      if (!params.value) {
        return;
      }
      const title = params.value.join("/")
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      }, title)
      return React.createElement(Tooltip, { title, children });
    }
  },
  { headerName: "EID/XID", field: "eid", width: 100, sortable: false },
  {
    headerName: "Start", field: "start", width: 100
  },
  { headerName: "End", field: "term", width: 100 },
  {
    headerName: "Gender", field: "gender", width: 100, renderCell: (params: any) => {
      const rev = ["Female", "Male"]
      return rev[params.row.gender]
    }
  },
  { headerName: "Birthday", field: "birthday", width: 100, sortable: false },
  { headerName: "Email", field: "email", width: 250, sortable: false },
  { headerName: "Phone", field: "phone", width: 100, sortable: false },
  { headerName: "Type", field: "type", width: 70, sortable: false },
  {
    headerName: "Leave Status", field: "leave_status", width: 140,
    renderCell: (params: any) => {
      if (params.row.status === "Left") {
        return "Left";
      }
      const title = params.row.leave_status_name
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      }, title)
      return React.createElement(Tooltip, { title, children });
    }
  },
  { headerName: "Status", field: "status", width: 100 },
]

export default columnSchema
