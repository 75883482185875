import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import ALL_PROJECT_PURCHASE_MINI from "./Gqls/ALL_PROJECT_PURCHASE_MINI"
import columnSchema from "./Schemas/overviewSchema"
import { DataGrid } from "@material-ui/data-grid"
import { Box, Button } from "@material-ui/core"
import { StringParam, useQueryParams, withDefault } from "use-query-params"
import { downloadUrl, globalYear } from "../../Utils"
import { useQuery } from "@apollo/client"
import Search from "./Field/Search"
import useAllUsers from "../../Hooks/useAllUsers"
import moment from "moment"

const ProjectPO = (_: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, globalYear())
  const [query] = useQueryParams({
    year: DefaultYear,
    user: StringParam,
    team: StringParam,
  })
  const { users } = useAllUsers()
  const from = React.useMemo(() => query.year + "-01-01", [query])
  const to = React.useMemo(() => query.year + "-12-31", [query])
  // 调用搜索数据API
  const { loading, data } = useQuery(ALL_PROJECT_PURCHASE_MINI, {
    variables: { end: { from, to } },
    fetchPolicy: "cache-and-network",
  })

  // 数据导出
  const exportLink = React.useCallback(() => {
    downloadUrl("/api/export/po/assignment", query)
  }, [query])

  const rows = React.useMemo(() => {
    if (!data) {
      return []
    }
    const all: any = data.allProjectPurchases
    const obj: any = {} //以用户为KEY 的PO数组
    users.forEach((user: any) => {
      if (user.myTeam.type === "0") {
        obj[user.id] = {
          id: user.id,
          status: user.status,
          term: user.term,
          start: user.start,
          abbr: user.abbr,
          name: user.name,
          team: user.team,
          po: [], // 存放该用户所属po [id,number,start,end]
        }
      }
    })

    all.forEach((po: any) => {
      po.members.forEach((u: any) => {
        if (obj[u.id]) {
          obj[u.id].po.push({
            id: po.id,
            //number: item.number + "(" + item.team + ")",
            number: po.number,
            start: po.start,
            end: po.end,
          })
        }
      })
    })

    const result: any = []
    for (let user_id in obj) {
      let user = obj[user_id]
      const numbers: any = []
      for (let i = 1; i <= 12; i++) {
        numbers[i] = []
      }
      let has_po = false
      user.po.forEach((po: any) => {
        for (let i = 1; i <= 12; i++) {
          let ym = query.year + i + ""
          if (i < 10) {
            ym = query.year + "0" + i
          }
          const start = moment(po.start).format("YYYYMM")
          const end = moment(po.end).format("YYYYMM")
          // console.log(start,end,ym)
          let number = ""
          if (start <= ym && ym <= end) {
            number = po.number
          }
          if (number) {
            numbers[i].push(number)
            has_po = true
          }
          const term = moment(user.term).format("YYYYMM")
          const join = moment(user.start).format("YYYYMM")
          if (term < ym) {
            numbers[i] = ["N/A"]
          }
          if (join > ym) {
            numbers[i] = ["N/A"]
          }
        }
      })
      // 过滤已离职，且当前时间段没有在PO Member中的人
      if (has_po || user.status === "Active") {
        result.push({
          id: user.id,
          user: user.name,
          team: user.team,
          m1: numbers[1].join(","),
          m2: numbers[2].join(","),
          m3: numbers[3].join(","),
          m4: numbers[4].join(","),
          m5: numbers[5].join(","),
          m6: numbers[6].join(","),
          m7: numbers[7].join(","),
          m8: numbers[8].join(","),
          m9: numbers[9].join(","),
          m10: numbers[10].join(","),
          m11: numbers[11].join(","),
          m12: numbers[12].join(","),
        })
      }
    }
    return result
  }, [data, users, query])

  const list = React.useMemo(() => {
    const { user, team } = query
    return rows
      .filter(
        (v: any) => {
          if ( user && user !== v.user){ 
            return false;
          }
          if ( team && team !== v.team) { 
            return false;
          }
          return true;
        }
      )
      
  }, [rows, query])

  return (
    <Box m={"2%"}>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        flexDirection="row-reverse"
      >
        <Button onClick={exportLink} variant="contained" color="primary">
          Export
        </Button>
      </Box>
      <Box mb={2}>
        <Search />
      </Box>
      <DataGrid
        autoHeight
        loading={loading}
        density={"compact"}
        rows={loading ? [] : list}
        columns={columnSchema}
      />
    </Box>
  )
}

export default ProjectPO
