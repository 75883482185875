import { gql } from "@apollo/client"

// UpdateBatchKpiTeamEditor 重命名
const UPDATE_BATCH_KPI_TEAM_EDITOR = gql`
mutation  UpdateKpiTeam($form: [BatchKpiTeamEditorInput]) {
  updateBatchKpiTeamEditor(form: $form) {
    id
    year
    quarter
    team {
      id
      name
    }
    content {
      value
      column
    }
    status
  }
}

`
export default UPDATE_BATCH_KPI_TEAM_EDITOR