import * as React from "react"
import { navigate, RouteComponentProps } from "@reach/router"

import CREATE_TEAM from "./Gqls/CREATE_TEAM"
import UPDATE_TEAM from "./Gqls/UPDATE_TEAM"
import FETCH_TEAM from "./Gqls/FETCH_TEAM"
import DELETE_TEAM from "./Gqls/DELETE_TEAM"
import columnSchema from "./Schemas/columnSchema"
import getCreateSchema from "./Schemas/createSchema"
import getUpdateeSchema from "./Schemas/updateSchema"
import { useMemo } from "react"
import DataGrid from "../../Components/DataGrid"
import useSession from "../../Hooks/useSession"

import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"
import TeamManager from "./Field/TeamManager"
import { Button } from "@material-ui/core"

ComponentMapper["TeamManager"] = TeamManager

const beforeFormSubmit = (data: any) => {
  const res: any = {}
  if (data.id) {
    res["id"] = data.id
  }
  if (data.myUnit) {
    res["unit_id"] = data.myUnit.id
  }
  if (data.name) {
    res["name"] = data.name
  }
  if (data.type) {
    res["type"] = data.type
  }
  if (data.managers) {
    res["managers"] = {
      sync: data.managers.map((v: any) => v.id),
    }
  }
  return res
}

const Team = (_: RouteComponentProps) => {
  const { can } = useSession()
  const createSchema = useMemo(() => getCreateSchema(), [])
  const updateSchema = useMemo(() => getUpdateeSchema(), [])
  return (
    <DataGrid
      title="Team"
      name="teamsList"
      mode="local"
      leftButton={
        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={() => navigate("/orginization/chart")}
        >
          Orginization Chart
        </Button>
      }
      searchGql={FETCH_TEAM}
      createGql={can("team.create", CREATE_TEAM)}
      updateGql={can("team.update", UPDATE_TEAM)}
      deleteGql={can("team.delete", DELETE_TEAM)}
      createSchema={can("team.create", createSchema)}
      updateSchema={can("team.update", updateSchema)}
      columnSchema={columnSchema}
      beforeCreateFormSubmit={beforeFormSubmit}
      beforeUpdateFormSubmit={beforeFormSubmit}
    />
  )
}

export default Team
