import { gql } from "@apollo/client"

const UPDATE_TEAM = gql`
mutation UpdateTeam(
  $id: ID!
  $name: String!
  $type: String!
  $unit_id: ID!
  $managers: UpdateManagersBelongsToMany
) {
  updateTeam(id: $id, name: $name, unit_id: $unit_id, type: $type,  managers: $managers) {
    id
    name
    unit_id
    unit
    managers {
      name
    }
  }
}


`
export default UPDATE_TEAM