import { gql } from "@apollo/client"

const CHANGE_TEAM = gql`
  mutation ChangeTeam (
    $id: ID!
    $future_team_id: ID!
    $future_team_apply_at: Date!
  ) {
    changeTeam(
      id: $id
      future_team_id: $future_team_id
      future_team_apply_at: $future_team_apply_at
    )  {
      id
      name
      email
      created_at
      updated_at
      token
      slug
      abbr
      unit
      team
      eid
      start
      term
      birthday
      phone
      status
    }
  }
`
export default CHANGE_TEAM