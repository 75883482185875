import OSS from "ali-oss";
import { useEffect, useState } from "react";
import { generateOss } from "../Utils/AliOSS";

const useOss = () => {
  const [client, setClient] = useState<OSS | null>(null);
  useEffect(() => {
    generateOss().then(client => {
      setClient(client);
    })
  }, [setClient])

  return { client }
}

export default useOss