import * as React from "react";
import useKpiSetup from "./useKpiSetup";
import useKpiTeamEditor from "./useKpiTeamEditor";
import Lodash from "lodash"
import { KpiSetupType, KpiTeamEditorType } from "../Pages/Kpi/Type"

const ranking = (setup: KpiSetupType, teams: [KpiTeamEditorType]) => {
  if (!(setup && setup.content) || !teams) {
    return
  }
  const first_score = teams.length - 1
  const newTeams: any = []
  teams.forEach((team) => {
    let tmp: any = { team_id: team.team_id }
    team.content.forEach((v) => {
      tmp[v["column"]] = v["value"]
    })
    newTeams.push(tmp)
  })
  let result: any = {}
  setup.content.forEach((setupItem) => {
    const column_name = setupItem.column
    const sort = Lodash.sortBy(newTeams, (o) => {
      if (setupItem.sort === "ASC") {
        return o[column_name]
      }
      return -o[column_name]
    })
    let index = 0
    let preScore = 0
    let preRanking = 0
    sort.forEach((team) => {
      let score = first_score - index
      if (!result[team["team_id"]]) {
        result[team["team_id"]] = {}
      }
      if (!result[team["team_id"]][column_name]) {
        result[team["team_id"]][column_name] = {}
      }
      result[team["team_id"]][column_name]["score"] = score
      result[team["team_id"]][column_name]["ranking"] = index + 1
      if (
        index > 0 &&
        sort[index][column_name] === sort[index - 1][column_name]
      ) {
        result[team["team_id"]][column_name]["score"] = preScore
        result[team["team_id"]][column_name]["ranking"] = preRanking
      } else {
        preScore = score
        preRanking = index + 1
      }
      index++
    })
  })
  return result
}

const useKpiData = () => {
  const { kpiSetup, columnLabel, columnWeight } = useKpiSetup()
  const { kpiTeamEditor } = useKpiTeamEditor();
  // 1.通过setup && team_editor 据算出team column对应 rank和score
  const teamsRank = React.useMemo(() => {
    if (!(kpiSetup && kpiSetup.content) || kpiTeamEditor.length === 0) {
      return null
    }
    return ranking(kpiSetup, kpiTeamEditor)
  }, [kpiSetup, kpiTeamEditor])
  // 2.整合teams 动态rank和score 计算出teams的合计 rank和score
  const mixtureTeams = React.useMemo(() => {
    if (kpiTeamEditor.length === 0 || !teamsRank) {
      return []
    }
    const hasScore = kpiTeamEditor.map((team: any) => {
      const id = team.id;
      const team_id = team.team.id
      const team_name = team.team.name
      let score = 0
      const content = team.content.map((v: any) => {
        score += columnWeight[v.column] * teamsRank[team_id][v.column]["score"]
        return {
          id: v.column,
          value: v.value,
          column: v.column,
          label: columnLabel[v.column],
          weight: columnWeight[v.column],
          ranking: teamsRank[team_id][v.column]["ranking"],
          score: teamsRank[team_id][v.column]["score"],
        }
      })
      return {
        id,
        team_id,
        team_name,
        score,
        content,
      }
    })
    // 倒序
    const sort = Lodash.sortBy(hasScore, (o) => {
      return -o["score"]
    })
    let preRanking = 0
    return sort.map((v: any, i: number) => {
      const currentRanking = i + 1
      if (i > 0 && sort[i].score === sort[i - 1].score) {
        return { ...v, ranking: preRanking }
      } else {
        preRanking = currentRanking
        return { ...v, ranking: currentRanking }
      }
    })
  }, [teamsRank, kpiTeamEditor, columnLabel, columnWeight])

  return { mixtureTeams ,kpiSetup}
}

export default useKpiData
