import { gql } from "@apollo/client"

const FETCH_KPI_SETUP = gql`
query FetchKpiSetup($year: Int!, $quarter: Int!) {
  kpiSetup(year: $year, quarter: $quarter) {
    id
    year
    quarter
    budget
    manager_per
    content {
      weight
      sort
      value_type
      is_custom
      column
      label
    }
  }
}
`
export default FETCH_KPI_SETUP