import * as React from "react"
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core"
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api"
import { USER_JOB_COMPETENCY } from "../../../Utils/config"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import { Autocomplete } from "@mui/material"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textfield: {
      "& .MuiSvgIcon-root": {
        height: "12px",
        width: "12px",
      },
      "& .MuiChip-root": {
        height: "20px",
      },
    },
  })
)

const CompetencyField = (props: any) => {
  const classes = useStyles()
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta
  const { value, onChange } = input
  const { label } = props
  const { getFieldState } = useFormApi()
  const [show, setShow] = React.useState(false)
  React.useEffect(() => {
    const relation = getFieldState("job_title")
    const job_title = relation?.value
    setShow(job_title === "Consultant")
  }, [props, getFieldState])


  return show ? (
    <Grid item {...props.FormFieldGridProps}>
      <Autocomplete
        multiple={true}
        options={USER_JOB_COMPETENCY}
        value={value ? value : []}
        onChange={(_, value) => {
          onChange(value)
        }}
        renderInput={(params) => (
          <TextField
            className={classes.textfield}
            {...params}
            label={label}
            margin="normal"
          />
        )}
      />
      {submitFailed && error && <Box color="primary.main">{error}</Box>}
    </Grid>
  ) : null
}

export default CompetencyField
