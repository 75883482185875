import React, { useCallback, useMemo, useState } from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import DataTable from "./DataTable"
import { useConfirm } from "material-ui-confirm"
import { useApolloClient, useQuery } from "@apollo/client"
import DataEditor from "./DataEditor"
import { DataGridProps } from "./Types"
import Snackbar from "@material-ui/core/Snackbar"
import Alert from "@material-ui/lab/Alert"
import DataFilter from "./DataFilter"
import { toast } from "material-react-toastify"
import { downloadUrl } from "../../Utils"
import { useQueryParam, JsonParam } from "use-query-params"
import { DefaultPageSize, DefaultPage, DefaultOrderBy } from "../../Utils"
import DataTableLocal from "./DataTableLocal"

const MyMsg = ({ message }: any) => {
  const list = message.split("\n")
  return (
    <div>
      {list.map((v: any, k: any) => (
        <div key={k}>
          {k + 1}.{v}
        </div>
      ))}
    </div>
  )
}

const DataGrid = ({
  title,
  name,
  noMargin,
  height,
  searchGql,
  createGql,
  updateGql,
  deleteGql,
  filterSchema,
  createSchema,
  updateSchema,
  columnSchema,
  exportUrl,
  mode,
  hiddenCreate,
  leftButton,
  beforeCreateFormSubmit,
  beforeUpdateFormSubmit,
  beforeDeleteFormSubmit,
  beforeSetEditorData,
  beforeSearchData,
  beforeDownloadData,
  getRowClassName,
  rowHeight,
}: DataGridProps) => {
  const confirm = useConfirm()
  const [editorData, setEditorData] = useQueryParam("form", JsonParam)
  const [filter, setFilter] = useQueryParam("search", JsonParam)
  const [orderBy, setOrderBy] = useQueryParam("orderBy", DefaultOrderBy)
  const [page, setPage] = useQueryParam("page", DefaultPage)
  const [pageSize, setPageSize] = useQueryParam("pageSize", DefaultPageSize)
  const [extLoading, setExtLoading] = useState(false)

  const filterUpdatedHandler = useCallback(
    (filter) => {
      setFilter({
        ...filter,
      })
    },
    [setFilter]
  )

  const filterSchemaParam = useMemo(() => {
    const fields =
      filterSchema && filterSchema.fields ? filterSchema.fields : []
    fields.push({
      component: "FileldsListener",
      name: "report-listener",
      hideField: true,
    })
    return {
      ...filterSchema,
      fields,
    }
  }, [filterSchema])

  const query = useMemo(() => {
    const variables = {
      ...filter,
      page: page ? page + 1 : 1,
      first: pageSize ? pageSize : 25,
      orderBy,
    }
    // 过滤null值
    const fliterVariable: any = {}
    for (let key in variables) {
      if (variables[key] !== null) {
        fliterVariable[key] = variables[key]
      }
    }
    return beforeSearchData ? beforeSearchData(fliterVariable) : fliterVariable
  }, [filter, orderBy, pageSize, page, beforeSearchData])

  const downloadParams = useMemo(() => {
    const variables = {
      ...filter,
      page: page ? page + 1 : 1,
      first: pageSize ? pageSize : 25,
      orderBy,
    }
    // 过滤null值
    const fliterVariable: any = {}
    for (let key in variables) {
      if (variables[key] !== null) {
        fliterVariable[key] = variables[key]
      }
    }
    return beforeDownloadData
      ? beforeDownloadData(fliterVariable)
      : fliterVariable
  }, [filter, orderBy, beforeDownloadData, page, pageSize])

  // 调用搜索数据API
  const { loading, data, error, refetch } = useQuery(searchGql, {
    variables: query,
    fetchPolicy: "network-only",
  })
  let rowCount = 0
  if (mode === "local") {
    rowCount = data ? data[name].length : 0
  } else {
    rowCount = data ? data[name].paginatorInfo.total : 0
  }

  const finnalLoading = useMemo(
    () => loading || extLoading,
    [loading, extLoading]
  )
  // 刷新数据配置
  const refetchQueries = useMemo(
    () => [
      {
        query: searchGql,
        variables: query,
      },
    ],
    [searchGql, query]
  )

  const graphqlClient = useApolloClient()

  // 调用创建数据API
  const createData = useCallback(
    (params: any) => {
      if (!createGql) return null
      return graphqlClient.mutate({
        mutation: createGql,
        refetchQueries,
        ...params,
      })
    },
    [graphqlClient, refetchQueries, createGql]
  )

  // 调用修改数据API
  const updateData = useCallback(
    (params: any) => {
      if (!updateGql) return null
      return graphqlClient.mutate({
        mutation: updateGql,
        refetchQueries,
        ...params,
      })
    },
    [graphqlClient, refetchQueries, updateGql]
  )

  // 调用删除数据API
  const deleteData = useCallback(
    (params: any) => {
      if (!deleteGql) return null
      return graphqlClient.mutate({
        mutation: deleteGql,
        refetchQueries,
        ...params,
      })
    },
    [graphqlClient, refetchQueries, deleteGql]
  )

  // 创建数据事件处理
  const createDataHandler = useCallback(
    async (data) => {
      try {
        const variables = beforeCreateFormSubmit
          ? beforeCreateFormSubmit(data)
          : data
        await createData({ variables })
        window.history.back()
      } catch (errors) {
        const err = errors.graphQLErrors[0].extensions.validation
        for (var key in err) {
          toast.error(<MyMsg message={err[key][0]} />, {
            position: "top-center",
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          })
        }
        return err
      }
    },
    [createData, beforeCreateFormSubmit]
  )

  // 数据导出
  const exportLink = useCallback(() => {
    exportUrl && downloadUrl(exportUrl, downloadParams)
  }, [exportUrl, downloadParams])

  // 修改数据事件处理
  const updateDatahandler = useCallback(
    async (data) => {
      if (!updateData) return null
      try {
        const variables = beforeUpdateFormSubmit
          ? beforeUpdateFormSubmit(data)
          : data
        await updateData({ variables })
        window.history.back()
      } catch (errors) {
        const err = errors.graphQLErrors[0].extensions.validation
        for (var key in err) {
          toast.error(<MyMsg message={err[key][0]} />, {
            position: "top-center",
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          })
        }
        return err
      }
    },
    [updateData, beforeUpdateFormSubmit]
  )

  // 点击编辑数据按钮
  const rowEditHandler = useCallback(
    (row) => {
      const data = beforeSetEditorData ? beforeSetEditorData(row) : row
      setEditorData(data)
    },
    [beforeSetEditorData, setEditorData]
  )

  // 点击删除数据按钮
  const rowDeleteHandler = useCallback(
    async (data) => {
      if (!deleteData) return null
      await confirm({
        description: "Are you sure to delete this " + title + "?",
      })
      setExtLoading(true)
      const variables = beforeDeleteFormSubmit
        ? beforeDeleteFormSubmit(data)
        : data
      await deleteData({
        variables,
      })?.catch((errors) => {
        const err = errors.graphQLErrors[0].extensions.validation
        for (var key in err) {
          toast.error(<MyMsg message={err[key][0]} />, {
            position: "top-center",
            autoClose: false,
            closeOnClick: false,
            draggable: false,
          })
        }
        return err
      })
      // 手动刷新
      await refetch()
      setExtLoading(false)
    },
    [deleteData, title, confirm, refetch, beforeDeleteFormSubmit]
  )

  const editorView = (
    <>
      <h1>{(editorData?.id ? "Update" : "Create") + " " + title}</h1>
      <DataEditor
        data={editorData}
        // @ts-ignore
        schema={editorData?.id ? updateSchema : createSchema}
        onSubmit={editorData?.id ? updateDatahandler : createDataHandler}
        onCancel={() => window.history.back()}
      />
    </>
  )

  const tableView = (
    <Box>
      <Box display="flex" mb={2} justifyContent="space-between">
        <Box>
          {createSchema && !hiddenCreate && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setEditorData({})}
            >
              Create {title}
            </Button>
          )}
          {leftButton}
        </Box>
        {exportUrl && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => exportLink()}
            disabled={rowCount === 0}
          >
            Export
          </Button>
        )}
      </Box>
      {filterSchema && (
        <Box mb={2}>
          <DataFilter
            filterSchema={filterSchemaParam}
            onFilterChanged={filterUpdatedHandler}
          />
        </Box>
      )}

      {mode === "local" ? (
        <DataTableLocal
          height={height}
          name={name}
          rowHeight={rowHeight}
          loading={finnalLoading}
          data={data ? data[name] : []}
          columnSchema={columnSchema}
          onRowEdit={updateGql ? rowEditHandler : undefined}
          onRowDelete={deleteGql ? rowDeleteHandler : undefined}
          getRowClassName={getRowClassName}
        />
      ) : (
        <DataTable
          rowHeight={rowHeight}
          height={height}
          loading={finnalLoading}
          data={data ? data[name].data : []}
          rowCount={rowCount}
          page={page}
          pageSize={pageSize}
          orderBy={orderBy}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
          onOrderByChanged={setOrderBy}
          columnSchema={columnSchema}
          onRowEdit={updateGql ? rowEditHandler : undefined}
          onRowDelete={deleteGql ? rowDeleteHandler : undefined}
          getRowClassName={getRowClassName}
        />
      )}
    </Box>
  )
  return (
    <Box m={noMargin ? 0 : "2%"}>
      {!!editorData ? editorView : tableView}
      <Snackbar open={!!error}>
        <Alert severity="error">{error ? error.message : null}</Alert>
      </Snackbar>
    </Box>
  )
}

export default DataGrid
