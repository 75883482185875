import { Box, Button, TextField } from "@material-ui/core"
import React from "react"
import Dialog from "../../../Components/Dialog"
import { generateMdTable } from "../../../Utils"
const controller: any = { setOpen: null, api: null }
let row = 2
let col = 1

const CustomIcon = () => {
  const [open, setOpen] = React.useState(false)
  controller.setOpen = setOpen
  return (
    <>
      <svg
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
      >
        <path d="M0 64l0 896 1024 0 0-896-1024 0zM384 640l0-192 256 0 0 192-256 0zM640 704l0 192-256 0 0-192 256 0zM640 192l0 192-256 0 0-192 256 0zM320 192l0 192-256 0 0-192 256 0zM64 448l256 0 0 192-256 0 0-192zM704 448l256 0 0 192-256 0 0-192zM704 384l0-192 256 0 0 192-256 0zM64 704l256 0 0 192-256 0 0-192zM704 896l0-192 256 0 0 192-256 0z" />
      </svg>
      <Box
        onClick={(e) => {
          // 阻止冒泡
          e.stopPropagation()
        }}
      >
        <Dialog
          open={open}
          handleClose={() => {
            setOpen(false)
          }}
          action={
            <Box>
              <Button
                variant="contained"
                disableElevation
                size="small"
                color={"primary"}
                onClick={() => {
                  const res = generateMdTable(row, col)
                  controller.api.replaceSelection("\n\n " + res + " \n")
                  setOpen(false)
                }}
              >
                Submit
              </Button>
            </Box>
          }
        >
          <Box>
            <Box>
              <TextField
                label="row"
                fullWidth
                type="number"
                defaultValue={row}
                onChange={(e) => {
                  row = parseInt(e.target.value)
                }}
              />
            </Box>
            <Box>
              <TextField
                label="col"
                type="number"
                fullWidth
                defaultValue={col}
                onChange={(e) => {
                  col = parseInt(e.target.value)
                }}
              />
            </Box>
          </Box>
        </Dialog>
      </Box>
    </>
  )
}

const table = {
  name: "table",
  keyCommand: "table",
  buttonProps: { "aria-label": "Insert table" },
  icon: <CustomIcon />,
  execute: (state, api) => {
    controller.setOpen(true)
    controller.api = api
  },
}

export default table
