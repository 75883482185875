import { gql } from "@apollo/client"

const CREATE_PROJECT = gql`
  mutation CreateProject (
    $slug: String!
    $name: String!
    $abbr: String!
    $customer: String!
    $team_id: ID!
    $manager_id: ID!
    $members: CreateUsersBelongsToMany
  ) {
    createProject(
      slug: $slug
      name: $name
      abbr: $abbr
      customer: $customer
      team_id: $team_id
      manager_id: $manager_id
      members: $members
    )  {
      id
      slug
      name
      abbr
      customer
      remainingPO
      projectUsers {
        id
        user {
          id
          name
          slug
          abbr
        }
        unit_price
      }
      created_at
      updated_at
    }
  }
`
export default CREATE_PROJECT