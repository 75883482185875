import React, { useEffect } from "react"
import FormSpy from "@data-driven-forms/react-form-renderer/form-spy"
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api"
import {
  useQueryParams,
  StringParam,
  DateParam,
  ObjectParam,
  JsonParam,
} from "use-query-params"

const FieldListener = () => {
  const { change } = useFormApi()
  const [query] = useQueryParams({
    project_slug: StringParam,
    project: ObjectParam,
    year: DateParam,
    month: DateParam,
    quarter: StringParam,
    team: ObjectParam,
    pageInfo: ObjectParam,
    orderBy: JsonParam,
  })
  useEffect(() => {
    ;[
      "project_slug",
      "project",
      "year",
      "month",
      "quarter",
      "team",
      "pageInfo",
      "orderBy",
    ].forEach((name) => {
      // @ts-ignore
      change(name, query[name] ? query[name] : null)
    })
  }, [query, change])

  return null
}

const ReportFieldListenerWrapper = () => (
  <FormSpy subscription={{ values: true }}>{() => <FieldListener />}</FormSpy>
)

export default ReportFieldListenerWrapper
