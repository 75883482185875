import { gql, useQuery } from "@apollo/client"
// import { useMemo } from "react";
import * as React from "react"

export const ME = gql`
query ME {
  me {
    id
    name
    gender
    email
    birthday
    slug
    unit
    avatar
    team
    phone
    myTeam {
      id
      name
      managers {
        id
        name
      }
    }
    underTeams {
      id
      name
    }
    menu
    render_menu{
      title
      name
      icon
      url
      children {
        title
        name
        url
      }
    }
    job_title
    competency
    job_level
    timeline{
      at
      content
    }
  }
}
`

const useUserInfo = () => {
  const { loading, error, data, refetch } = useQuery(ME);
  const user = React.useMemo(() => {
    if (data) {
      return data.me
    }
    return null
  }, [data])

  const can = (name, action) => {
    // console.log(user?.menu,name)
    if (user?.menu.includes(name) || user?.menu.includes("*")) {
      return action;
    }

    return undefined;
  }

  return {
    loading,
    error,
    user,
    refetch,
    can
  }
}

export default useUserInfo
