import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import DataGrid from "../../../Components/DataGrid"
import UPDATE_TBA_EXTERNAL_TRAINING from "../Gqls/UPDATE_TBA_EXTERNAL_TRAINING"
import FETCH_TBA_EXTERNAL_TRAINING from "../Gqls/FETCH_TBA_EXTERNAL_TRAINING"
import DELETE_TBA_EXTERNAL_TRAINING from "../Gqls/DELETE_TBA_EXTERNAL_TRAINING"
import columnSchema from "../Schemas/columnExternalSchema"
import getCreateSchema from "../Schemas/createExternalSchema"
import getFilterSchema from "../Schemas/filterExternalSchema"
import { useMemo } from "react"
import useSession from "../../../Hooks/useSession"

import moment from "moment"
import { Type2DateRange } from "../../../Utils"

const beforeSearchData = (data: any) => {
  const res: any = {
    where: { AND: [] },
  }
  if (data["training_name"]) {
    res.where.AND.push({
      column: "TRAINING_NAME",
      operator: "LIKE",
      value: "%" + data["training_name"] + "%",
    })
  }
  if (data["platform"]) {
    res.where.AND.push({
      column: "PLATFORM",
      operator: "EQ",
      value: data["platform"],
    })
  }
  if (data["user"]) {
    res.where.AND.push({
      column: "USER_ID",
      operator: "EQ",
      value: data["user"]["id"],
    })
  }
  if (data["year"]) {
    const year = moment(data["year"]).format("YYYY")
    let range = [year + "-01-01", year + "-12-31"]
    if (data["period"]) {
      range = Type2DateRange(year, data["period"])
    }
    res.where.AND.push({
      column: "TRAINING_AT",
      operator: "BETWEEN",
      value: range,
    })
  }
  return res
}

const beforeFormSubmit = (data: any) => {
  const res: any = {}
  if (data["id"]) {
    res["id"] = data["id"]
  }
  if (data["training_name"]) {
    res["training_name"] = data["training_name"]
  }
  if (data["price"]) {
    res["price"] = Number(data["price"])
  }
  if (data["user"]) {
    res["user_id"] = data["user"]["id"]
  }
  res["training_at"] = moment(data.training_at).format("YYYY-MM-DD")

  return { input: res }
}

const TBAExternal = (_: RouteComponentProps) => {
  const { can } = useSession()

  const updateSchema = useMemo(() => getCreateSchema(), [])

  const filterSchema = useMemo(() => getFilterSchema(), [])

  return (
    <DataGrid
      title="Training"
      name="TbaExternalTrainings"
      mode="local"
      searchGql={FETCH_TBA_EXTERNAL_TRAINING}
      hiddenCreate
      updateGql={can("tba-external-training.update", UPDATE_TBA_EXTERNAL_TRAINING)}
      deleteGql={can("tba-external-training.delete", DELETE_TBA_EXTERNAL_TRAINING)}
      filterSchema={filterSchema}
      updateSchema={can("tba-external-training.update", updateSchema)}
      columnSchema={columnSchema}
      beforeCreateFormSubmit={beforeFormSubmit}
      beforeUpdateFormSubmit={beforeFormSubmit}
      beforeSearchData={beforeSearchData}
    />
  )
}

export default TBAExternal
