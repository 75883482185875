import React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, Button } from "@material-ui/core"
import { DataGrid, GridSortModel } from "@material-ui/data-grid"
import { downloadUrl } from "../../Utils"
import { StringParam, useQueryParams, withDefault } from "use-query-params"
import { useQuery } from "@apollo/client"
import USER_KPI_BONUS from "./Gqls/USER_KPI_BONUS"
import SearchKpiBonusData from "./Field/SearchKpiBonusData"

/**
 * 奖金统计
 *
 */
const KpiBonusData = (props: RouteComponentProps) => {
  const DefaultYear = withDefault(StringParam, "2022")
  const [query] = useQueryParams({
    year: DefaultYear,
    unit: StringParam,
    team: StringParam,
  })

  const { data, loading } = useQuery(USER_KPI_BONUS, {
    fetchPolicy: "cache-and-network",
    variables: {
      year: query.year,
      team_id: query.team ? [query.team] : [],
      unit_id: query.unit ? [query.unit] : [],
    },
  })

  const [sortModel, setSortModel] = React.useState<GridSortModel>([
    {
      field: "rank",
      sort: "asc",
    },
  ])
  // 数据导出
  const exportLink = React.useCallback(() => {
    downloadUrl("/api/export/user/kpi-bonus-data", { query })
  }, [query])

  const columns = React.useMemo(() => {
    return [
      { headerName: "User Name", field: "name", width: 150 },
      { headerName: "User ID", field: "slug", width: 120 },
      { headerName: "Unit", field: "unit", width: 80 },
      { headerName: "Team", field: "team", width: 100 },
      {
        headerName: query.year + " Q1 Bonus",
        field: "q1",
        width: 120,
        sortable: false,
      },
      {
        headerName: query.year + " Q2 Bonus",
        field: "q2",
        width: 120,
        sortable: false,
      },
      {
        headerName: query.year + " Q3 Bonus",
        field: "q3",
        width: 120,
        sortable: false,
      },
      {
        headerName: query.year + " Q4 Bonus",
        field: "q4",
        width: 120,
        sortable: false,
      },
      { headerName: "Total Bonus", field: "total", width: 150 },
      { headerName: "Rank", field: "rank", width: 100 },
    ]
  }, [query])

  return (
    <Box m="2%">
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        flexDirection="row-reverse"
      >
        <Button onClick={exportLink} variant="contained" color="primary">
          Export
        </Button>
      </Box>
      <Box mb={2}>
        <SearchKpiBonusData />
      </Box>
      <DataGrid
        // components={{
        //   Toolbar: () => <Box className={classes.bg}>Avg period: {avg}</Box>,
        // }}
        autoHeight
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        loading={loading}
        density={"compact"}
        rows={
          loading
            ? []
            : data.userKpiBonus.map((v) => ({
                ...v,
                rank: parseInt(v.rank),
                total: parseInt(v.total),
              }))
        }
        columns={columns}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
      />
    </Box>
  )
}

export default KpiBonusData
