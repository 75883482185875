import { gql } from "@apollo/client"

const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    id
    name
    email
    created_at
    updated_at
    token
    slug
    abbr
    roles {
      id
      name
    }
    unit
    team
    eid
    start
    term
    birthday
    phone
    status
  }
}

`
export default UPDATE_USER