import { gql } from "@apollo/client"

const CREATE_USER = gql`
 mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    name
    email
    created_at
    updated_at
    token
    slug
    abbr
    roles{
      id
      name
    }
    unit
    team
    eid
    start
    term
    birthday
    phone
    status
  }
}

`
export default CREATE_USER