import { gql, useQuery } from "@apollo/client"
import { useMemo } from "react";

const OPERATION_TYPE = gql`
  query OperationTypes {
    newOperations {
      id
      namee
    }
  }
`

const useOperationTypes = () => {
  const { data, loading ,error } = useQuery(OPERATION_TYPE, {
    fetchPolicy: "cache-and-network",
  })

  const types = useMemo(() => {
    return loading || error ? [] : data.newOperations;
  }, [loading, data,error])
  
  return { types, loading }
}

export default useOperationTypes