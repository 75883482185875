import { gql } from "@apollo/client"

const FIND_TBA_ARCHIVE = gql`
query FindTbaArchive($at: String!) {
  FindTbaArchive(at: $at) {
    at
    status
    pay
    disabled
    total
    funds {
      id
      user {
        id
        name
        abbr
        team
      }
      amount
      status
    }
  }
}
`
export default FIND_TBA_ARCHIVE