import { gql } from "@apollo/client"

const SEARCH_TIME_PERIOD_FILES = gql`
query SearchTimePeriodFiles($orderBy: [QueryTimePeriodFilesOrderByOrderByClause!]) {
  timePeriodFiles(orderBy:$orderBy) {
  	id
    name
    created_at
    updated_at
  }
}
`

export default SEARCH_TIME_PERIOD_FILES