import { gql } from "@apollo/client"

const FETCH_KPI_ARCHIVE = gql`
query FetchKpiArchive($year: Int!, $quarter: Int!) {
  kpiArchive(year: $year, quarter: $quarter) {
    id
    year
    quarter
    budget
    manager_per
    content {
      team_id
      team_name
      ranking
      score
      content {
        column
        value_type
        value
        label
        ranking
      }
    }
    auditor{
      id
      name
    }
    created_at
  }
}
`
export default FETCH_KPI_ARCHIVE