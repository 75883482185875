import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"

const getCreateSchema = () => {
  const schema = {
    fields: [
      {
        component: FieldTypes.SUB_FORM,
        name: "subform",
        ItemsGridProps: {
          className: "custom-form-border",
          spacing: 3,
          sm: 12,
          container: true
        },
        fields: [
          {
            name: "name",
            label: "Item Name",
            FormFieldGridProps: {
              sm: 12,
            },
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
        ],
      }
    ]
  };

  return schema
}

export default getCreateSchema
