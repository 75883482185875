import { gql } from "@apollo/client"

const CHANGE_AVATAR = gql`
  mutation ChangeAvatar (
    $avatar: String!
  ) {
    changeAvatar(
      avatar:$avatar
    )  {
      id
      avatar
      name
      created_at
      updated_at
    }
  }
`
export default CHANGE_AVATAR