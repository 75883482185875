import React from "react"
import FormRenderer, {
  FormRendererProps,
} from "@data-driven-forms/react-form-renderer/form-renderer"
import ComponentTypes from "@data-driven-forms/react-form-renderer/component-types"
import validatorTypes from "@data-driven-forms/react-form-renderer/validator-types"
import FormTemplateCommonProps from "@data-driven-forms/common/form-template"
import MuiFormTemplate from "@data-driven-forms/mui-component-mapper/form-template"
import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"

import FileldsListener from "../FileldsListener"
import UserSelectorField from "../UserSelectorField"
import TeamSelectorField from "../TeamSelectorField"
import TeamSelectorFieldLeader from "../TeamSelectorFieldLeader"
import UnitSelectorField from "../UnitSelectorField"
import OperationTypeSelectorField from "../OperationTypeSelectorField"
import DateTimePickerField from "../DateTimePickerField"
import RoleSelectorField from "../RoleSelectorField"
import PermissionSelectorField from "../PermissionSelectorField"
import JobLevelField from "../../Pages/User/Component/JobLevelField"
import CompetencyField from "../../Pages/User/Component/CompetencyField"

ComponentMapper["TeamSelectorField"] = TeamSelectorField
ComponentMapper["TeamSelectorFieldLeader"] = TeamSelectorFieldLeader
ComponentMapper["RoleSelectorField"] = RoleSelectorField
ComponentMapper["PermissionSelectorField"] = PermissionSelectorField
ComponentMapper["UnitSelectorField"] = UnitSelectorField
ComponentMapper["UserSelectorField"] = UserSelectorField
ComponentMapper["OperationTypeSelectorField"] = OperationTypeSelectorField
ComponentMapper["FileldsListener"] = FileldsListener
ComponentMapper["date-time-picker"] = DateTimePickerField
ComponentMapper["JobLevelField"] = JobLevelField
ComponentMapper["CompetencyField"] = CompetencyField

const FieldTypes = ComponentTypes
const ValidatorTypes = validatorTypes
export { FieldTypes, ValidatorTypes }

export interface FormProps
  extends Omit<FormRendererProps, "componentMapper" | "FormTemplate"> {
  templateProps?: Partial<FormTemplateCommonProps>
}

const Form = (props: FormProps) => {
  const FormTemplate = React.useCallback(
    (formTemplateProps: any) => (
      <MuiFormTemplate {...formTemplateProps} {...props.templateProps} />
    ),
    [props.templateProps]
  )
    
  const formRendererProps: any = {
    componentMapper: ComponentMapper,
    FormTemplate,
    ...props
  }
  return <FormRenderer {...formRendererProps} />
}

export default Form
