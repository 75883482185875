import { useCallback } from "react"
import Box from "@material-ui/core/Box"
import { RouteComponentProps } from "@reach/router"
import { DropzoneArea } from "material-ui-dropzone"
import { useMutation } from "@apollo/client"
import { toast, TypeOptions, ToastContent } from "material-react-toastify"
import CircularProgress from "@material-ui/core/CircularProgress"
import UPLOAD_USER_ATTENDANCE from "../Gqls/UPLOAD_USER_ATTENDANCE"
import ImportLog from "../../../Components/ImportLog"

const UploadUserAttendance = (props: RouteComponentProps) => {
  const showMessage = useCallback(
    (type: TypeOptions, message: ToastContent) => {
      toast(message, {
        type,
        position: "top-center",
        autoClose: 3000,
      })
    },
    []
  )

  // 上传报告
  const [uploadReport, { loading: uploading }] = useMutation(
    UPLOAD_USER_ATTENDANCE,
    {
      onCompleted: (data) => {
        // if (data.uploadUserAttendance.code === 0) {
        //   showMessage("success", "success")
        // } else {
        //   const list = data.uploadUserAttendance.message.split("<br/>")
        //   toast.error(() => list.map((v: any) => <Box>{v}</Box>), {
        //     position: "top-center",
        //     autoClose: 3000,
        //     closeOnClick: false,
        //     draggable: false,
        //   })
        // }
      },
      onError: (error) => {
        // const message = 'file analyze error . Please check the uploaded file';
        const message =
          "Oho, something is wrong. Please retry or contact the administrator."
        showMessage("error", message)
      },
    }
  )
  // 上传
  const uploadFiles = useCallback(
    (loadedFiles: File[]) => {
      let sum = 0;
      let index = 0;
      let total = loadedFiles.length;
      loadedFiles.forEach(async (file) => {
        const res = await uploadReport({
          variables: { file },
        })
        if (res.data.uploadUserAttendance.code === 0) {
          sum++
        }
        index++;
        if (index === total) {
          toast.info(`imported success ${sum}. faild ${total-sum}` ,{
            position: "top-center",
            autoClose: 5000,
            closeOnClick: false,
            draggable: false,
          })
        }
      })
    },
    [uploadReport]
  )
  return (
    <Box mx={[2, 4, 8]} py={5} mt={5}>
      <Box my={4} component="h1">
        Import Monthly Attendance Data File:
      </Box>
      <Box mb={5}>
        {uploading && <CircularProgress />}
        {!uploading ? (
          <DropzoneArea
            showPreviewsInDropzone={false}
            showFileNames={true}
            onChange={uploadFiles}
            filesLimit={10}
            showPreviews={false}
            showAlerts={false}
            clearOnUnmount={true}
            acceptedFiles={[".xls", ".xlsx"]}
          />
        ) : null}
      </Box>
      {!uploading ? <ImportLog type={1} /> : null}
    </Box>
  )
}

export default UploadUserAttendance
