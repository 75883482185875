import { gql } from "@apollo/client"

const FETCH_TBA_EXTERNAL_TRAINING = gql`
query TBAExternalTraining($where: QueryTbaExternalTrainingsWhereWhereConditions) {
  TbaExternalTrainings(where:$where) {
    id
    training_name
    training_at
    platform_name
    price
    user{
      id
      name
      abbr
    }
  }
}
`

export default FETCH_TBA_EXTERNAL_TRAINING