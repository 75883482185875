import { Gender } from "."
import { FieldTypes, ValidatorTypes } from "../../../Components/DataGrid/Form"
import { USER_JOB_TITLE_OPTIONS } from "../../../Utils/config"

const getCreateSchema = () => {
  const schema = {
    fields: [
      {
        component: FieldTypes.SUB_FORM,
        name: "subform1",
        ItemsGridProps: {
          className: "custom-form-border",
          spacing: 3,
          container: true
        },
        sm: 12,
        fields: [
          {
            name: "name",
            label: "User Name",
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "slug",
            label: "User ID (Login Account)",
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "abbr",
            label: "Name Abbr",
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "team",
            label: "Team",
            component: "TeamSelectorField",
            isRequired: true,
            width: "100%",
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "eid",
            label: "EID/XID",
            component: FieldTypes.TEXT_FIELD
          },
          {
            name: "gender",
            label: "Gender",
            isRequired: true,
            component: FieldTypes.SELECT,
            isClearable: true,
            options: Gender,
            TextFieldProps: {
              margin: "none",
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "start",
            label: "Start",
            isRequired: true,
            component: FieldTypes.DATE_PICKER,
            DatePickerProps: {
              showTodayButton: true,
              format: "yyyy/MM/DD"
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "myUnit",
            label: "Unit",
            component: "UnitSelectorField",
            isRequired: true,
            width: "100%",
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
            TextFieldProps: {
              margin: "none",
            },
          },
          {
            name: "term",
            label: "End",
            component: FieldTypes.DATE_PICKER,
            DatePickerProps: {
              showTodayButton: true,
              format: "yyyy/MM/DD"
            }
          },
          {
            name: "birthday",
            label: "Birthday",
            isRequired: true,
            component: FieldTypes.DATE_PICKER,
            DatePickerProps: {
              showTodayButton: true,
              format: "yyyy/MM/DD"
            },
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "email",
            label: "Email (Login Account)",
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "phone",
            label: "Phone",
            component: FieldTypes.TEXT_FIELD,
            isRequired: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          // {
          //   name: "roles",
          //   label: "Role",
          //   component: "RoleSelectorField",
          //   isRequired: true,
          //   multiple: true,
          //   width: "100%",
          //   validate: [
          //     {
          //       type: ValidatorTypes.REQUIRED,
          //     },
          //   ],
          // },
          {
            name: "job_title",
            label: "Job Title",
            component: FieldTypes.SELECT,
            isRequired: true,
            options: USER_JOB_TITLE_OPTIONS,
            width: "100%",
            isClearable: true,
            validate: [
              {
                type: ValidatorTypes.REQUIRED,
              },
            ],
          },
          {
            name: "competency",
            label: "Competency",
            component: 'CompetencyField',
          },
          // {
          //   name: "job_level",
          //   label: "Job Level",
          //   component: 'JobLevelField',
          // },
        ]
      }
    ],
  }






  schema.fields.forEach((field: any) => {
    field.fields.forEach((field: any) => {
      if (field.component === "select") {
        const props = {
          margin: "none",
        }
        field.TextFieldProps = field.TextFieldProps ? {
          ...field.TextFieldProps,
          ...props
        } : props
      } else if (field.component === FieldTypes.DATE_PICKER) {
        const props = {
          margin: "none",
        }
        field.DatePickerProps = field.DatePickerProps ? {
          ...field.DatePickerProps,
          ...props
        } : props
      }

      if (!field.FormFieldGridProps) {
        field.FormFieldGridProps = {
          xs: 12,
          sm: 4,
          md: 4,
          lg: 4,
          xl: 4,
        }
      }
    })
  })


  return schema
}

export default getCreateSchema
