
export const createLineChart = (data: any[], per = false) => {
  const legendData = data.map(v => v.name);
  const xData = data[0].x;
  const seriesData = data.map((v, k) => {
    const obj: any = {
      name: v.name,
      type: 'line',
      data: v.y,
    }
    if (v.name === "Avg") {
      obj.lineStyle = {
        width: 2,
        type: 'dotted' //'dotted'虚线 'solid'实线
      };
    }
    return obj
  })
  const yAxis: any = {
    type: 'value',
    position: 'left',
    axisLine: {
      show: true,
    },
    min: function (value: any) {
      return Math.floor(value.min);
    },
    max: function (value: any) {
      return Math.ceil(value.max);
    }
  }
  if (per) {
    yAxis['axisLabel'] = {
      formatter: '{value} %'
    };
  }

  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let content = '<div>' + params[0].axisValue + '</div>';
        params.sort(((a: any, b: any) => {
          return Number(b.value) - Number(a.value)
        }))
        params.forEach((v: any) => {
          if (per) {
            content += '<div style="display:flex;justify-content:space-between"><div>' + v.marker + v.seriesName + ": </div><div style='padding-left:20px;text-align:right'>" + Number(v.value).toFixed(2) + '%</div></div>';
          } else {
            content += '<div style="display:flex;justify-content:space-between"><div>' + v.marker + v.seriesName + ": </div><div style='padding-left:20px;text-align:right''>" + Number(v.value).toFixed(2) + '</div></div>';
          }
        })
        return content
      },
    },
    legend: {
      data: legendData,
      top: 10,
      right: 20,
    },
    grid: {
      left: '20',
      right: '20',
      bottom: '20',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xData
    },
    yAxis,
    series: seriesData
  };
}


export const createAttendanceChart = (data: any[], per = false) => {
  const legendData = data.map(v => v.name);
  const xData = data[0].x;
  const seriesData: any = data.map((v, k) => {
    const obj: any = {
      name: v.name,
      type: 'line',
      data: v.y,
    }
    if (v.name === "Avg") {
      obj.lineStyle = {
        width: 2,
        type: 'dotted' //'dotted'虚线 'solid'实线
      };
    }
    return obj
  })
  // 一个健康的出勤率范围
  seriesData.push({
    type: "line",
    markArea: {
      itemStyle: {
        color: 'rgba(152,216,124, 0.4)'
      },
      data: [
        [
          {
            yAxis: 94
          },
          {
            yAxis: 96
          }
        ]
      ]
    }
  })
  const yAxis: any = {
    type: 'value',
    position: 'left',
    axisLine: {
      show: true,
    },
    min: function (value: any) {
      return 85;
    },
    max: function (value: any) {
      return 100;
    }
  }
  if (per) {
    yAxis['axisLabel'] = {
      formatter: '{value} %'
    };
  }

  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let content = '<div>' + params[0].axisValue + '</div>';
        params.sort(((a: any, b: any) => {
          return Number(b.value) - Number(a.value)
        }))
        params.forEach((v: any) => {
          if (per) {
            content += '<div style="display:flex;justify-content:space-between"><div>' + v.marker + v.seriesName + ": </div><div style='padding-left:20px;text-align:right'>" + Number(v.value).toFixed(2) + '%</div></div>';
          } else {
            content += '<div style="display:flex;justify-content:space-between"><div>' + v.marker + v.seriesName + ": </div><div style='padding-left:20px;text-align:right''>" + Number(v.value).toFixed(2) + '</div></div>';
          }
        })
        return content
      },
    },
    legend: {
      data: legendData,
      top: 10,
      right: 20,
    },
    grid: {
      left: '20',
      right: '20',
      bottom: '20',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xData
    },
    yAxis,
    series: seriesData
  };
}



export const createMultipleYAxesLineChart = (data: any[]) => {
  const legendData = ['Attrition Rate', 'No. of Employee', 'Average Age', 'Length of Service']
  const attritions: any[] = [];
  const number: any[] = [];
  const age: any[] = [];
  const length: any[] = [];
  const xData = data[0] ? data[0].x : [];

  data.forEach(v => {
    attritions.push(v.attritions);
    number.push(v.number);
    age.push(v.age);
    length.push(v.length);
  })

  return {
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let content = '<div>' + params[0].axisValue + '</div>';
        params.forEach((v: any) => {
          if (v.seriesName === 'Attrition Rate') {
            content += '<div style="display:flex;justify-content:space-between"><div>' + v.marker + v.seriesName + ": </div><div style='width:70px;text-align:right'>" + v.value + '%</div></div>';
          } else {
            content += '<div style="display:flex;justify-content:space-between"><div>' + v.marker + v.seriesName + ": </div><div style='width:70px;text-align:right''>" + v.value + '</div></div>';
          }
        })
        return content
      },
    },
    grid: {
      left: '20',
      right: '22',
      bottom: '20',
      containLabel: true
    },
    legend: {
      data: legendData
    },
    xAxis: [
      {
        type: 'category',
        axisTick: {
          alignWithLabel: true
        },
        boundaryGap: false,
        data: xData
      }
    ],
    yAxis: [
      {
        type: 'value',
        name: 'Attrition Rate',
        position: 'left',
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: '{value} %'
        },

      },
      {
        type: 'value',
        name: 'No./ Age/ Month',
        position: 'right',
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: '{value}'
        },
        min: function (value: any) {
          return Math.floor(value.min);
        },
        max: function (value: any) {
          return Math.ceil(value.max);
        }
      },
    ],
    series: [
      {
        name: 'No. of Employee',
        type: 'line',
        lineStyle: {
          type: 'dotted' //'dotted'虚线 'solid'实线
        },
        yAxisIndex: 1,
        data: number
      },
      {
        name: 'Average Age',
        type: 'line',
        lineStyle: {
          type: 'dotted' //'dotted'虚线 'solid'实线
        },
        yAxisIndex: 1,
        data: age
      },
      {
        name: 'Length of Service',
        type: 'line',
        lineStyle: {
          type: 'dotted' //'dotted'虚线 'solid'实线
        },
        yAxisIndex: 1,
        data: length
      },
      {
        name: 'Attrition Rate',
        type: 'line',
        yAxisIndex: 0,
        data: attritions
      },
    ]
  };
}