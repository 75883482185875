import * as React from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import useAllUsers from "../Hooks/useAllUsers"

const filterOptions = (options: Array<any>, { inputValue }: any) => {
  if (!inputValue) {
    return options
  }
  return options.filter((user) => {
    const matchValue = inputValue.toLowerCase()

    return (
      user.name.toLowerCase().indexOf(matchValue) !== -1 ||
      user.slug.toLowerCase().indexOf(matchValue) !== -1 ||
      user.abbr.toLowerCase().indexOf(matchValue) !== -1
    )
  })
}

const UserSelector = (props: any) => {
  const { users, loading } = useAllUsers()
  const exclude: any[] = props.exclude
  const customOptions: any[] = props.options
  const options = React.useMemo(() => {
    if (customOptions && customOptions.length > 0) {
      return customOptions
    }
    if (exclude) {
      // 过滤用户
      return users.filter((v: any) => exclude.indexOf(v.id) === -1)
    }
    return users
  }, [users, exclude, customOptions])

  return (
    <Autocomplete
      filterOptions={filterOptions}
      options={options}
      getOptionLabel={(option: any) => (option ? option.name : "")}
      style={{ width: props.width || 200 }}
      loading={loading}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={props.label}
            required={props.required}
          />
        )
      }}
      {...props}
    />
  )
}

export default UserSelector
