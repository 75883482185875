import { Avatar, Box, createStyles, makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: "flex",
      padding: "10px",
      background: "#fff",
    },
  })
)
const OrgNode = ({ nodeData }) => {
  const classes = useStyles()

  return (
    <Box boxShadow={3}>
      {nodeData.users &&
        nodeData.users.map((user, index) => {
          let blod = user.job_title
          if (
            blod === "Consultant" &&
            user.job_level &&
            user.competency &&
            user.competency.length > 0
          ) {
            blod = user.competency.join("/") + " (" + user.job_level + ")"
          }
          if (blod === "Consultant" && user.competency && user.competency.length > 0) {
            blod = user.competency.join("/")
          }
          if (blod === "Consultant" && user.job_level) {
            blod = "Consultant (" + user.job_level + ")"
          }
          if (blod === "Consultant") {
            blod = "Consultant"
          }
          return (
            <Box className={classes.root} key={index}>
              <Box>
                <Avatar src={user.avatar} />
              </Box>
              <Box width={150}>
                <Box fontWeight={"bold"}>{blod}</Box>
                <Box>{user.name}</Box>
              </Box>
            </Box>
          )
        })}
    </Box>
  )
}

export default OrgNode
