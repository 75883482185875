import { gql } from "@apollo/client"

// DeleteKpiArchive 重命名
const DELETE_KPI_ARCHIVE = gql`
mutation KpiUnarchive(
  $id: ID! ,
  $year: Int! ,
  $quarter: Int!
) {
  deleteKpiArchive( id:$id ,year: $year, quarter: $quarter) {
    id
    year
    quarter
    budget
    manager_per
    content {
      team_id
      team_name
      content {
        weight
        value
        label
        column
        score
        is_custom
      }
    }
  }
}
`
export default DELETE_KPI_ARCHIVE