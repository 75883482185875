import React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, Button } from "@material-ui/core"
import AttritionRate from "./Component/AttritionRate"
import PORemaining from "./Component/PORemaining"
import AttritionAnalysis from "./Component/AttritionAnalysis"
import Revenue from "./Component/Revenue"
import RevenuePerCapita from "./Component/RevenuePerCapita"
import AttendanceRate from "./Component/AttendanceRate"
import { navigate } from "@reach/router"

const Dashboard = (props: RouteComponentProps) => {
  return (
    <Box mx={[2, 4, 8]} py={5}>
      <Box display="flex">
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate("/user/leave-attrition-data")}
        >
          Attrition Data Analysis
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={() => navigate("/user/formal-data")}
        >
          Probation Data Analysis
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={() => navigate("/user/kpi-bonus-data")}
        >
          KPI Bonus Overview
        </Button>

        <Button
          variant="contained"
          color="primary"
          style={{ marginLeft: 8 }}
          onClick={() => navigate("/user/job-level-data")}
        >
          Job Level Overview
        </Button>
      </Box>
      <AttendanceRate />
      <AttritionRate />
      <AttritionAnalysis />
      <PORemaining />
      <Revenue />
      <RevenuePerCapita />
    </Box>
  )
}

export default Dashboard
