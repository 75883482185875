import { gql } from "@apollo/client"

const SEARCH_USERLEAVE = gql`
query SearchUserLeave(
  $first: Int!
  $page: Int!
  $user_id: ID
  $approver_id: ID
  $orderBy: [QueryUserLeaveOrderByOrderByClause!]
) {
  userLeave(
    user_id: $user_id
    approver_id: $approver_id
    first: $first
    page: $page
    orderBy: $orderBy
  ) {
    data {
      id
      type
      start
      end
      duration
      myTeam{
        id
        name
      }
      myUnit{
        id
        name
      }
      month
      user {
        id
        name
      }
      approver {
        id
        name
      }
      note
      created_at
    }
    paginatorInfo {
      total
      currentPage
    }
  }
}
`

export default SEARCH_USERLEAVE