import * as React from "react"
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import { useCallback } from "react"
import { Box, Grid } from "@material-ui/core"

const DateTimePickerField = (props: any) => {
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta

  const handleDateChange = useCallback(
    (value) => {
      if (value) {
        input.onChange(value)
      } else {
        input.onChange(null)
      }
    },
    [input]
  )
  return (
    <Grid item {...props.FormFieldGridProps}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          {...props.DateTimePickerProps}
          value={input.value ? input.value : null}
          onChange={handleDateChange}
          name={props.name}
        />
      </MuiPickersUtilsProvider>
      {submitFailed && error && <Box color="primary.main">{error}</Box>}
    </Grid>
  )
}

export default DateTimePickerField
