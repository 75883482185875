import * as React from "react"
import { createStyles, Theme } from "@material-ui/core/styles"
import makeStyles from "@material-ui/core/styles/makeStyles"
import Form from "./Form"
import Lodash from "lodash"
import { Schema } from "@data-driven-forms/react-form-renderer"
import { useMemo } from "react"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "inline-block",
      // background:"black"
      // margin: "2%",
      // [theme.breakpoints.up("sm")]: {
      //   margin: "2%",
      // },
    },
    // btnGroup: {
    //   display: 'flex',
    //   justifyContent: 'flex-start',
    //   '&>button:not(:first-child)': {
    //     marginLeft: 8,
    //   },
    // }
  })
)

type DataEditorType = {
  data?: any
  onCancel: () => void
  onSubmit: (value: any) => void
  schema: Schema
}

const DataEditor = ({ data, onCancel, onSubmit, schema }: DataEditorType) => {
  const classes = useStyles()
  const values = React.useMemo(() => Lodash.omitBy(data, Lodash.isNull), [data])

  const templateProps = useMemo(
    () => ({
      canReset: true,
      onCancel,
      // buttonGroupProps: {
      //   className: classes.btnGroup
      // },
    }),
    [onCancel]
  )

  return (
    <div className={classes.root}>
      <Form
        subscription={{ values: true }}
        schema={schema}
        onSubmit={onSubmit}
        initialValues={values}
        templateProps={templateProps}
      />
    </div>
  )
}

export default DataEditor
