import React from "react"
import { RouteComponentProps } from "@reach/router"
import { Box, Button, createStyles, makeStyles } from "@material-ui/core"
import { DataGrid, GridSortModel } from "@material-ui/data-grid"
import { downloadUrl } from "../../Utils"
import { StringParam, useQueryParams } from "use-query-params"
import { useQuery } from "@apollo/client"
import USER_JOB_LEVEL from "./Gqls/USER_JOB_LEVEL"
import SearchJobLevelData from "./Field/SearchJobLevelData"

const useStyles = makeStyles(() =>
  createStyles({
    active: {},
    left: {
      background: "#C0C4CC",
    },
    leaving: {
      background: "#E6A23C",
    },
  })
)

/**
 * 奖金统计
 *
 */
const JobLevelData = (props: RouteComponentProps) => {
  const classes = useStyles()
  const [query] = useQueryParams({
    unit: StringParam,
    team: StringParam,
    job_level: StringParam,
  })

  const { data, loading } = useQuery(USER_JOB_LEVEL, {
    fetchPolicy: "cache-and-network",
    variables: {
      team_id: query.team ? query.team : "",
      unit_id: query.unit ? query.unit : "",
      job_level: query.job_level ? query.job_level : "",
    },
  })

  const [sortModel, setSortModel] = React.useState<GridSortModel>([])
  // 数据导出
  const exportLink = React.useCallback(() => {
    downloadUrl("/api/export/user/job-level-data", { query })
  }, [query])

  const columns = React.useMemo(() => {
    return [
      { headerName: "User Name", field: "name", width: 150 },
      { headerName: "Unit", field: "unit", width: 80 },
      { headerName: "Team", field: "team", width: 100 },
      {
        headerName: "Current Job Level",
        field: "sort",
        width: 200,
        renderCell: (params: any) => params.row.job_level,
      },
      { headerName: "J1", field: "J1", width: 100 },
      { headerName: "J2", field: "J2", width: 100 },
      { headerName: "E1", field: "E1", width: 100 },
      { headerName: "E2", field: "E2", width: 100 },
      { headerName: "S1", field: "S1", width: 100 },
      { headerName: "S2", field: "S2", width: 100 },
      { headerName: "EX1", field: "EX1", width: 100 },
      { headerName: "EX2", field: "EX2", width: 100 },
    ]
  }, [])

  return (
    <Box m="2%">
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}
        flexDirection="row-reverse"
      >
        <Button onClick={exportLink} variant="contained" color="primary">
          Export
        </Button>
      </Box>
      <Box mb={2}>
        <SearchJobLevelData />
      </Box>
      <DataGrid
        getRowClassName={(e) => {
          if (e.row.status === "Leaving") {
            return classes.leaving
          }
          if (e.row.status === "Left") {
            return classes.left
          }
          return ""
        }}
        // components={{
        //   Toolbar: () => <Box className={classes.bg}>Avg period: {avg}</Box>,
        // }}
        // pageSize={200}
        autoHeight
        sortModel={sortModel}
        onSortModelChange={(model) => setSortModel(model)}
        loading={loading}
        density={"compact"}
        rows={loading ? [] : data.userJobLevels}
        columns={columns}
        disableColumnMenu={true}
        disableColumnSelector={true}
        disableDensitySelector={true}
        disableSelectionOnClick={true}
      />
    </Box>
  )
}

export default JobLevelData
