import { Tooltip } from "@material-ui/core";
import React from "react";

const columnSchema = [
  { headerName: "Team", field: "name", width: 120 },
  { headerName: "Type", field: "type_name", width: 120 },
  { headerName: "Unit", field: "unit", width: 80 },
  {
    headerName: "Team Managers", field: "managers", width: 250,
    renderCell: (params: any) => {
      if (!params.value) {
        return ''
      }
      const label = params.value.map((item: any) => item.name).join(" , ")
      const title = params.value.map((item: any) => item.name).join(" , ")
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      }, label)
      return React.createElement(Tooltip, { title, children });
    }
  },
  {
    headerName: "Members", field: "userMembers", width: 520, renderCell: (params: any) => {
      if (!params.value) {
        return ''
      }
      const ids = params.row.managers.map(v => v.id)
      const filter = params.value.filter(v=>!ids.includes(v.id))
      const label =filter.map((item: any) => item.name).join(" , ")
      const title =  filter.map((item: any) => item.name).join(" , ")
      const children = React.createElement("div", {
        style: {
          width: "100%",
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
        }
      }, label)
      return React.createElement(Tooltip, { title, children });
    }
  },
]

export default columnSchema
