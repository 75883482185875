import React from "react"
import { navigate, RouteComponentProps } from "@reach/router"
import { Box, Button, createStyles, makeStyles, Theme } from "@material-ui/core"
import Grid from "@material-ui/core/Grid"
import { Link } from "@reach/router"
import { TimePeriodColumnSchema } from "./Schemas"
import DataGrid from "../../Components/DataGrid"
import SEARCH_TIME_PERIOD from "./Gqls/SEARCH_TIME_PERIOD"
import useSession from "../../Hooks/useSession"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ml2: {
      marginLeft: theme.spacing(2),
    },
    link: {
      textDecoration: "none",
    },
  })
)

const TimePeriod = (props: RouteComponentProps) => {
  const classes = useStyles()
  const { can } = useSession()
  return (
    <Box>
      <Box mx="2%">
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          <Box my={4} component="h2">
            Time Period
          </Box>
          <Box>
            {can("time-period.update", 
              <Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => navigate("/timeperiod/import")}
                >
                  Import New Period
                </Button>

                <Link to="/periodhistory" className={classes.link}>
                  <Button className={classes.ml2} variant="contained">
                    View Period History
                  </Button>
                </Link>
              </Box>
            ) }
          </Box>
        </Grid>
      </Box>
      <DataGrid
        title="SearchTimePeriod"
        name="timePeriods"
        columnSchema={TimePeriodColumnSchema}
        searchGql={SEARCH_TIME_PERIOD}
      />
    </Box>
  )
}

export default TimePeriod
