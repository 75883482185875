import { gql } from "@apollo/client"

const UPDATE_USER_ROLE = gql`
mutation UpdateUserRole($id: ID!, $roles: UpdateRoleBelongsToMany!) {
  updateUserRole(id: $id, roles: $roles) {
    id
    roles {
      id
      name
    }
  }
}
`
export default UPDATE_USER_ROLE