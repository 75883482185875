import * as React from "react"
import { RouteComponentProps } from "@reach/router"
import DataGrid from "../../../Components/DataGrid"
import CREATE_TBA_TRAINING from "../Gqls/CREATE_TBA_TRAINING"
import UPDATE_TBA_TRAINING from "../Gqls/UPDATE_TBA_TRAINING"
import FETCH_TBA_TRAINING from "../Gqls/FETCH_TBA_TRAINING"
import DELETE_TBA_TRAINING from "../Gqls/DELETE_TBA_TRAINING"
import columnSchema from "../Schemas/columnSchema"
import getCreateSchema from "../Schemas/createSchema"
import getFilterSchema from "../Schemas/filterSchema"
import { useMemo } from "react"
import useSession from "../../../Hooks/useSession"

import ComponentMapper from "@data-driven-forms/mui-component-mapper/component-mapper"
import TrainingMember from "../Field/TrainingMember"
import moment from "moment"
import { Type2DateRange } from "../../../Utils"

ComponentMapper["TrainingMember"] = TrainingMember
const beforeDownloadData = (data: any) => {
  const res: any = {}
  if (data["training_name"]) {
    res["training_name"] = data["training_name"]
  }
  if (data["user_id"]) {
    res["trainer_id"] = data["user_id"]["id"]
  }
  if (data["host_site"]) {
    res["host_site"] = data["host_site"]
  }
  if (data["year"]) {
    const year = moment(data["year"]).format("YYYY")
    let range = [year + "-01-01", year + "-12-31"]
    if (data["period"]) {
      range = Type2DateRange(year, data["period"])
    }
    res["range"] = range
  }
  return res
}
const beforeSearchData = (data: any) => {
  const res: any = {
    where: { AND: [] },
  }
  if (data["training_name"]) {
    res.where.AND.push({
      column: "TRAINING_NAME",
      operator: "LIKE",
      value: "%" + data["training_name"] + "%",
    })
  }
  if (data["platform"]) {
    res.where.AND.push({
      column: "PLATFORM",
      operator: "EQ",
      value: data["platform"],
    })
  }
  if (data["user_id"]) {
    res.hasTrainers = {
      column: "ID",
      operator: "EQ",
      value: data["user_id"]["id"],
    }
  }
  if (data["host_site"]) {
    res.where.AND.push({
      column: "HOST_SITE",
      operator: "EQ",
      value: data["host_site"],
    })
  }
  if (data["year"]) {
    const year = moment(data["year"]).format("YYYY")
    let range = [year + "-01-01", year + "-12-31"]
    if (data["period"]) {
      range = Type2DateRange(year, data["period"])
    }
    res.where.AND.push({
      column: "TRAINING_AT",
      operator: "BETWEEN",
      value: range,
    })
  }
  return res
}

const beforeFormSubmit = (data: any) => {
  const res: any = {}
  if (data["id"]) {
    res["id"] = data["id"]
  }
  if (data["training_name"]) {
    res["training_name"] = data["training_name"]
  }
  if (data["area"]) {
    res["area"] = data["area"]
  }
  if (data["host_site"]) {
    res["host_site"] = data["host_site"]
  }
  if (data["during"]) {
    res["during"] = Number(data["during"])
  }
  if (data["expense"]) {
    res["expense"] = Number(data["expense"])
  }
  if (data["satisfaction"]) {
    res["satisfaction"] = Number(data["satisfaction"])
  }
  res["training_at"] = moment(data.training_at).format("YYYY-MM-DD")
  const trainers = data.trainingMember.trainers.map((v: any) => v.id)
  const tmp_attendees = data.trainingMember.attendees.map((v: any) => v.id)
  // 过滤参会人中的主持人
  const attendees = tmp_attendees.filter(
    (items: any) => trainers.indexOf(items) === -1
  )
  res["trainers"] = { sync: trainers }
  res["attendees"] = { sync: attendees }

  return { input: res }
}

const TBAInternal = (_: RouteComponentProps) => {
  const { can } = useSession()
  const createSchema = useMemo(() => getCreateSchema(), [])

  const updateSchema = useMemo(() => getCreateSchema(), [])

  const filterSchema = useMemo(() => getFilterSchema(), [])

  return (
    <DataGrid
      title="Training"
      name="TbaTrainings"
      mode="local"
      searchGql={FETCH_TBA_TRAINING}
      exportUrl={can("tba-internal-training.export", "/api/export/training")}
      createGql={can("tba-internal-training.create", CREATE_TBA_TRAINING)}
      updateGql={can("tba-internal-training.update", UPDATE_TBA_TRAINING)}
      deleteGql={can("tba-internal-training.delete", DELETE_TBA_TRAINING)}
      filterSchema={filterSchema}
      createSchema={can("tba-internal-training.create", createSchema)}
      updateSchema={can("tba-internal-training.update", updateSchema)}
      columnSchema={columnSchema}
      beforeDownloadData={beforeDownloadData}
      beforeCreateFormSubmit={beforeFormSubmit}
      beforeUpdateFormSubmit={beforeFormSubmit}
      beforeSearchData={beforeSearchData}
    />
  )
}

export default TBAInternal
