import * as React from "react"
import useFieldApi from "@data-driven-forms/react-form-renderer/use-field-api"
import useFormApi from "@data-driven-forms/react-form-renderer/use-form-api"
import MomentUtils from "@date-io/moment"

import { useCallback } from "react"
import { Box, Grid } from "@material-ui/core"
import LeaveDateRange from "./LeaveDateRange"
import moment from "moment"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
const DefaultYear = moment().format("YYYY") + ""
const DefaultMonth = moment().month() + ""

const DateRangeField = (props: any) => {
  const { input, meta } = useFieldApi(props)
  const { error, submitFailed } = meta
  const [year, changeYear] = React.useState(DefaultYear)
  const [month, changeMonth] = React.useState(DefaultMonth)
  const formApi = useFormApi()
  const values = formApi.getState().values
  React.useEffect(() => {
    // 首次渲渲染（更新值时使用）通过start,end 字段配置默认值
    if (values.start && values.end) {
      input.onChange([moment(values.start), moment(values.end)])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  React.useEffect(() => {
    if (input.value && input.value[0]) {
      const year = moment(input.value[0]).format("YYYY")
      const month = moment(input.value[0]).month() + ""
      changeYear(year)
      changeMonth(month)
    }
  }, [input])
  const handleChangeRange = useCallback(
    (value) => {
      input.onChange(value)
    },
    [input]
  )

  return (
     <MuiPickersUtilsProvider utils={MomentUtils}>
    <Grid  {...props.FormFieldGridProps}>
      <LeaveDateRange
        onChange={handleChangeRange}
        year={year}
        changeMonth={changeMonth}
        changeYear={changeYear}
        month={month}
        value={input.value}
      />
      {submitFailed && error && <Box color="primary.main">{error}</Box>}
    </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default DateRangeField
